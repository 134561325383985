import React from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import createCompositionTableDict from './createCompositionTableDict';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { languageCodeDisplay } from './CodeSystemLookup';

const SectionDisplay = ({ sect, sectIndex }) => {
  return <div key={sectIndex} >
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {sect.code && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {sect.focus && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {sect.text && <div>
        <p><b>Narrative Status: </b>{sect.text.status}</p>
        <div>
          <b>Narrative Summary: </b>
          <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
            <DisplayFromFHIR xhtml={sect.text.div} />
          </div>
        </div>
        <br />
      </div>}
      {sect.orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
        sect.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {sect.emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex} /></span>;
        })}
    </div>
  </div>
}

const CompositionDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.targetMarkdown || relatedArtifactEntry.targetReference?.display || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }

  const getNetEffectPointEstimate = () => {
    if (fhirJson.section) {
      for (const section of fhirJson.section) {
        if (section.code?.text == 'Net Effect Point Estimate') {
          if (section.text?.div) {
            return section.text.div;
          }
        }
      }
    }
  }
  const getNetEffectEstimate = () => {
    if (fhirJson.section) {
      for (const section of fhirJson.section) {
        if (section.code?.text == 'Net Effect Estimate') {
          if (section.text?.div) {
            return section.text.div;
          }
        }
      }
    }
  }

  let tableData = createCompositionTableDict(fhirJson);
  let netEffectPointEstimate = getNetEffectPointEstimate() || null;
  let netEffectEstimate = getNetEffectEstimate() || null;

  let compositionDescription;
  if (Array.isArray(fhirJson.section)) {
    for (let section of fhirJson.section) {
      if (section.code?.text === "Description" && section.text?.div) {
        compositionDescription = section.text.div;
        break;
      }
    }
  }
  if (Array.isArray(fhirJson.extension)) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/StructureDefinition/artifact-description" && extension.valueMarkdown) {
        compositionDescription = extension.valueMarkdown;
        break;
      }
    }
  }
  return <div>
    <div><div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <span><b>Title: </b> {fhirJson.title ? fhirJson.title : <>Not stated</>}</span>
        <br />
        {compositionDescription &&
          <span>
            <b>Description: </b><DisplayFromFHIR markdown={compositionDescription} /><br />
          </span>}
        {(Array.isArray(fhirJson.note) && fhirJson.note.length > 0) &&
          fhirJson.note.map((note, noteIndex) => {
            return <div key={noteIndex}><b>Note: </b><DisplayFromFHIR annotation={note} /></div>;
          })}
        {fhirJson.type &&
          <span>
            <b>Type: </b><DisplayFromFHIR codeableConcept={fhirJson.type} /><br />
          </span>}
        {(Array.isArray(fhirJson.category) && fhirJson.category.length > 0) &&
          fhirJson.category.map((category, categoryIndex) => {
            return <div key={categoryIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={category} /></div>;
          })}
        {fhirJson.language &&
          <span>
            <b>Language: </b>{languageCodeDisplay[fhirJson.language]}<br />
          </span>}
        {fhirJson.subject && <span><b>Subject: </b>
          {fhirJson.subject.length > 0 ?
            <>{fhirJson.subject.map((subject, subjectIndex) => {
              return <div key={subjectIndex}><DisplayFromFHIR reference={subject} /></div>;
            })}
            </>
            :
            <>Not properly structured JSON or missing subject data</>
          }
        </span>}
        {fhirJson.encounter &&
          <span>
            <b>Encounter: </b><DisplayFromFHIR reference={fhirJson.encounter} /><br />
          </span>}
        <p>
          <b>View PDF: </b>
          <a href={'https://computablepublishing.com/Composition/' + fhirJson.id}
            target='_blank' rel='noopener noreferrer'>View or download PDF</a>
        </p>
        <br />
      </div>
      <br />
      <h3 id="table-view">Table View</h3>
      {(tableData?.Labels && tableData.Data && Object.keys(tableData.Labels).length > 0) ?
        <Table>
          <Table.Header>
            <Table.Row>
              {Object.keys(tableData.Labels).map((label, labelIndex) => {
                return <Table.HeaderCell key={labelIndex}>
                  <DisplayFromFHIR xhtml={label} />
                </Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(tableData.Data).map((entry, entryIndex) => {
              return <Table.Row key={entryIndex}>{
                Object.keys(tableData.Data[entry]).map((item, itemIndex) => {
                  return <Table.Cell key={itemIndex}>
                    <DisplayFromFHIR xhtml={tableData.Data[entry][item]} />
                  </Table.Cell>
                })
              }</Table.Row>
            })}
          </Table.Body>
          <>{netEffectPointEstimate &&
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell className="netEffectCell" textAlign='right'
                  colSpan={Object.keys(tableData.Labels).length}>
                  <b>Net Effect Point Estimate = <DisplayFromFHIR xhtml={netEffectPointEstimate} /></b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          }</>
          <>{netEffectEstimate &&
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell className="netEffectCell" textAlign='right'
                  colSpan={Object.keys(tableData.Labels).length}>
                  <b>Net Effect Estimate = <DisplayFromFHIR xhtml={netEffectEstimate} /></b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          }</>
        </Table>
        :
        <>No tabular data.</>
      }
      <h3 id="section-detail">Section Detail</h3>
      {(fhirJson.section && fhirJson.section.length > 0) ?
        <div style={{ marginLeft: "24px" }}>
          {fhirJson.section.map((sect, sectIndex) => {
            return <span key={sectIndex}>
              <SectionDisplay sect={sect} sectIndex={sectIndex} />
            </span>;
          })}
        </div>
        :
        <>No section data.</>
      }
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
    </div>
  </div>
}

export default CompositionDisplay;