import { getStringFromFHIR, DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React, { useState, useEffect, useContext } from 'react';
import { getJsonFromIdentifier } from './ResourceDictionaryFunctions';
import FevirContext from './FevirContext';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const GroupEndUnitSectionDisplay = ({ sectionTitle, sectionSummary, sectionReference, sectionEmptyReason, groupJson }) => {
  let groupCombinationMethod = "";
  if (groupJson?.combinationMethod) {
    groupCombinationMethod = groupJson.combinationMethod;
    if (groupJson.combinationThreshold) {
      groupCombinationMethod += " " + groupJson.combinationThreshold.toString();
    }
  }
  return <div>
    <h4>{sectionTitle}</h4>
    <div style={{ marginLeft: "24px" }}>
      {(sectionSummary || sectionReference) ? <>
        {sectionSummary && <div><br /><DisplayFromFHIR xhtml={sectionSummary} /><br /><br /></div>}
        {groupJson?.quantity && <div><br /><b>Quantity: </b>{groupJson?.quantity}</div>}
        {groupCombinationMethod && <div><b>Combination Method: </b>{groupCombinationMethod}</div>}
        {groupJson?.characteristic &&
          <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={groupJson} />}
        {sectionReference && <div><br /><b>Reference to {sectionReference?.type && sectionReference.type + " "} Resource: </b><DisplayFromFHIR reference={sectionReference} /></div>}
      </>
        :
        <><b>Empty Reason: </b>
          {sectionEmptyReason ?
            <DisplayFromFHIR codeableConcept={sectionEmptyReason} /> :
            <>Not stated.</>}
        </>}
    </div>
  </div>
}

const PopulationSectionDisplay = ({ populationSection, resourceDictionary }) => {
  let studyGroupJson = getJsonFromIdentifier(resourceDictionary, populationSection.entry, "Group");
  let populationQuantity;
  let populationCombinationMethod;
  if (studyGroupJson) {
    if (studyGroupJson.quantity) {
      populationQuantity = studyGroupJson.quantity;
    }
    if (studyGroupJson.combinationMethod) {
      populationCombinationMethod = studyGroupJson.combinationMethod;
      if (studyGroupJson.combinationThreshold) {
        populationCombinationMethod += " " + studyGroupJson.combinationThreshold.toString();
      }
    }
  }
  let populationSummary = populationSection.text?.div || studyGroupJson?.description;
  let populationReference;
  if (populationSection.entry?.length > 0) {
    populationReference = populationSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(populationSection.author) && populationSection.author.length > 0) {
    sectionAuthor = populationSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(populationSummary || populationReference) ? <>
      {populationSummary && <div><br /><DisplayFromFHIR xhtml={populationSummary} /><br /><br /></div>}
      {populationQuantity && <div><br /><b>Quantity: </b>{populationQuantity}</div>}
      {populationCombinationMethod && <div><b>Combination Method: </b>{populationCombinationMethod}</div>}
      {studyGroupJson?.characteristic &&
        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={studyGroupJson} />}
      {populationReference && <div>
        <br />
        <b>Reference to {populationReference?.type && populationReference.type + " "}Resource: </b>
        <DisplayFromFHIR reference={populationReference} />
      </div>}
      {sectionAuthor && sectionAuthor}
      {populationSection.section?.length && <div>
        {populationSection.section.map((section, sectionIndex) => {
          return <div key={sectionIndex} >
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </div>}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={populationSection.emptyReason} />
      </>}
  </div>
}

const InterventionSectionDisplay = ({ interventionSection, resourceDictionary }) => {
  let interventionDefinitionJson;
  let interventionGroupJson;

  if (Array.isArray(interventionSection.section)) {
    for (const subsection of interventionSection.section) {
      if (subsection.code?.text === "Intervention Description" || subsection.code?.coding?.[0]?.code === "intervention-description") {
        interventionDefinitionJson = getJsonFromIdentifier(resourceDictionary, subsection.entry, "Group");
      } else if (subsection.code?.text === "Intervention Group" || subsection.code?.coding?.[0]?.code === "intervention-group") {
        interventionGroupJson = getJsonFromIdentifier(resourceDictionary, subsection.entry, "Group");
      }
    }
  }

  let interventionDescriptionSummary;
  let interventionGroupSummary;
  let interventionDescriptionReference;
  let interventionGroupReference;
  let interventionDescriptionEmptyReason;
  let interventionGroupEmptyReason;
  let interventionSubpopulationsSection;
  if (interventionSection?.section?.length > 0) {
    for (const section of interventionSection.section) {
      if (section.code?.text === "Intervention Description" || section.code?.coding?.[0]?.code === "intervention-description") {
        interventionDescriptionSummary = section.text?.div || interventionDefinitionJson?.description;
        if (section.entry?.length > 0) {
          interventionDescriptionReference = section.entry[0];
        }
        if (!interventionDescriptionSummary) {
          interventionDescriptionEmptyReason = section.emptyReason;
        }
      }
      if (section.code?.text === "Intervention Group" || section.code?.coding?.[0]?.code === "intervention-group") {
        interventionGroupSummary = section.text?.div || interventionGroupJson?.description;
        if (section.entry?.length > 0) {
          interventionGroupReference = section.entry[0];
        }
        if (!interventionGroupSummary) {
          interventionGroupEmptyReason = section.emptyReason;
        }
      }
      if (section.code?.text === "Intervention Groups for Population Subgroups" || section.code?.coding?.[0]?.code === "subgroup") {
        interventionSubpopulationsSection = section;
      }
    }
  }
  let interventionSummary = interventionSection.text?.div;
  let interventionReference;
  if (interventionSection.entry?.length > 0) {
    interventionReference = interventionSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(interventionSection.author) && interventionSection.author.length > 0) {
    sectionAuthor = interventionSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {interventionSummary && <div><br /><DisplayFromFHIR xhtml={interventionSummary} /></div>}
    {interventionReference && <div><br /><DisplayFromFHIR reference={interventionReference} /></div>}
    {sectionAuthor && sectionAuthor}
    <GroupEndUnitSectionDisplay sectionTitle={"Intervention Description"} sectionSummary={interventionDescriptionSummary}
      groupJson={interventionDefinitionJson}
      sectionReference={interventionDescriptionReference} sectionEmptyReason={interventionDescriptionEmptyReason} />
    <br />
    <GroupEndUnitSectionDisplay sectionTitle={"Intervention Group"} sectionSummary={interventionGroupSummary}
      groupJson={interventionGroupJson}
      sectionReference={interventionGroupReference} sectionEmptyReason={interventionGroupEmptyReason} />
    {interventionSubpopulationsSection && <div>
      <div>
        <p><b>Intervention Groups for Population Subgroups:</b></p>
        <div style={{ marginLeft: "24px" }}>
          {(interventionSubpopulationsSection.text && Object.keys(interventionSubpopulationsSection.text).length > 0) && <div>
            {interventionSubpopulationsSection.text.status === "empty" ?
              <></>
              :
              <div>
                <b>Narrative Summary: </b>
                <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                  <DisplayFromFHIR xhtml={interventionSubpopulationsSection.text.div} />
                </div>
              </div>}
            <br />
          </div>}
          {interventionSubpopulationsSection.orderedBy && <span>
            <b>Ordered by: </b>
            <DisplayFromFHIR codeableConcept={interventionSubpopulationsSection.orderedBy} /><br />
          </span>}
          {(Array.isArray(interventionSubpopulationsSection.entry) && interventionSubpopulationsSection.entry.length > 0) &&
            interventionSubpopulationsSection.entry.map((entry, entryIndex) => {
              return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
            })}
          {interventionSubpopulationsSection.emptyReason && <span>
            <b>Empty Reason: </b>
            <DisplayFromFHIR codeableConcept={interventionSubpopulationsSection.emptyReason} /><br />
          </span>}
        </div>
      </div>
    </div>}
  </div>
}

const ComparatorSectionDisplay = ({ comparatorSection, resourceDictionary }) => {
  let comparatorDefinitionJson;
  let comparatorGroupJson;
  let groupAssignmentJson;

  let interventionSubpopulationsSection;
  if (Array.isArray(comparatorSection.section)) {
    for (const subsection of comparatorSection.section) {
      if (subsection.code?.text === "Comparator Description" || subsection.code?.coding?.[0]?.code === "comparator-description") {
        comparatorDefinitionJson = getJsonFromIdentifier(resourceDictionary, subsection.entry, "Group");
      } else if (subsection.code?.text === "Comparator Group" || subsection.code?.coding?.[0]?.code === "comparator-group") {
        comparatorGroupJson = getJsonFromIdentifier(resourceDictionary, subsection.entry, "Group");
      } else if (subsection.code?.text === "Group Assignment" || subsection.code?.coding?.[0]?.code === "GroupAssignment") {
        groupAssignmentJson = getJsonFromIdentifier(resourceDictionary, subsection.entry, "EvidenceVariable");
      }
    }
  }

  let comparatorDescriptionSummary;
  let comparatorGroupSummary;
  let comparatorDescriptionReference;
  let comparatorGroupReference;
  let comparatorDescriptionEmptyReason;
  let comparatorGroupEmptyReason;
  let groupAssignmentReference;
  let groupAssignmentEmptyReason;
  let groupAssignmentSummary;
  if (comparatorSection?.section?.length > 0) {
    for (const section of comparatorSection.section) {
      if (section.code?.text === "Comparator Description" || section.code?.coding?.[0]?.code === "comparator-description") {
        comparatorDescriptionSummary = section.text?.div || comparatorDefinitionJson?.description;
        if (section.entry?.length > 0) {
          comparatorDescriptionReference = section.entry[0];
        }
        if (!comparatorDescriptionSummary) {
          comparatorDescriptionEmptyReason = section.emptyReason;
        }
      } else if (section.code?.text === "Comparator Group" || section.code?.coding?.[0]?.code === "comparator-group") {
        comparatorGroupSummary = section.text?.div || comparatorGroupJson?.description;
        if (section.entry?.length > 0) {
          comparatorGroupReference = section.entry[0];
        }
        if (!comparatorGroupSummary) {
          comparatorGroupEmptyReason = section.emptyReason;
        }
      } else if (section.code?.text === "Comparator Groups for Population Subgroups" || section.code?.coding?.[0]?.code === "subgroup") {
        interventionSubpopulationsSection = section;
      } else if (section.code?.text === "Group Assignment" || section.code?.coding?.[0]?.code === "GroupAssignment") {
        groupAssignmentSummary = section.text?.div || groupAssignmentJson?.description;
        if (section.entry?.length > 0) {
          groupAssignmentReference = section.entry[0];
        }
        if (!groupAssignmentSummary) {
          groupAssignmentEmptyReason = section.emptyReason;
        }
      }
    }
  }
  let comparatorSummary = comparatorSection.text?.div;
  let comparatorReference;
  if (comparatorSection.entry?.length > 0) {
    comparatorReference = comparatorSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(comparatorSection.author) && comparatorSection.author.length > 0) {
    sectionAuthor = comparatorSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {comparatorSummary && <div><br /><DisplayFromFHIR xhtml={comparatorSummary} /></div>}
    {comparatorReference && <div><br /><DisplayFromFHIR reference={comparatorReference} /></div>}
    {sectionAuthor && sectionAuthor}
    <GroupEndUnitSectionDisplay sectionTitle={"Comparator Description"} sectionSummary={comparatorDescriptionSummary}
      groupJson={comparatorDefinitionJson}
      sectionReference={comparatorDescriptionReference} sectionEmptyReason={comparatorDescriptionEmptyReason} />
    <br />
    <GroupEndUnitSectionDisplay sectionTitle={"Comparator Group"} sectionSummary={comparatorGroupSummary}
      groupJson={comparatorGroupJson}
      sectionReference={comparatorGroupReference} sectionEmptyReason={comparatorGroupEmptyReason} />
    {groupAssignmentSummary && <div>
      <h4>Group Assignment (Intervention vs. Comparator)</h4>
      <div style={{ marginLeft: "24px" }}><br /><DisplayFromFHIR xhtml={groupAssignmentSummary} /></div>
      {groupAssignmentReference && <div style={{ marginLeft: "24px" }}><br /><DisplayFromFHIR reference={groupAssignmentReference} /></div>}
    </div>}
    {interventionSubpopulationsSection && <div>
      <div>
        <p><b>Comparator Groups for Population Subgroups:</b></p>
        <div style={{ marginLeft: "24px" }}>
          {(interventionSubpopulationsSection.text && Object.keys(interventionSubpopulationsSection.text).length > 0) && <div>
            {interventionSubpopulationsSection.text.status === "empty" ?
              <></>
              :
              <div>
                <b>Narrative Summary: </b>
                <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                  <DisplayFromFHIR xhtml={interventionSubpopulationsSection.text.div} />
                </div>
              </div>}
            <br />
          </div>}
          {interventionSubpopulationsSection.orderedBy && <span>
            <b>Ordered by: </b>
            <DisplayFromFHIR codeableConcept={interventionSubpopulationsSection.orderedBy} /><br />
          </span>}
          {(Array.isArray(interventionSubpopulationsSection.entry) && interventionSubpopulationsSection.entry.length > 0) &&
            interventionSubpopulationsSection.entry.map((entry, entryIndex) => {
              return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
            })}
          {interventionSubpopulationsSection.emptyReason && <span>
            <b>Empty Reason: </b>
            <DisplayFromFHIR codeableConcept={interventionSubpopulationsSection.emptyReason} /><br />
          </span>}
        </div>
      </div>
    </div>}
  </div>
}

const ResearchStudySectionDisplay = ({ researchStudySection, resourceDictionary }) => {
  let studyCitationJson;
  let studyResearchStudyJson;
  if (Array.isArray(researchStudySection.entry) && researchStudySection.entry.length > 0 &&
    researchStudySection.entry[0].type) {
    if (researchStudySection.entry[0].type === "Citation") {
      studyCitationJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry, "Citation");
    } else if (researchStudySection.entry[0].type === "ResearchStudy") {
      studyResearchStudyJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry, "ResearchStudy");
    }
  } else {
    let tempJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry);
    if (tempJson?.resourceType === "ResearchStudy") {
      studyResearchStudyJson = tempJson;
    } else if (tempJson?.resourceType === "Citation") {
      studyCitationJson = tempJson;
    }
  }

  let researchStudySummary = researchStudySection.text?.div;
  if (!researchStudySummary) {
    if (studyCitationJson?.title) {
      researchStudySummary = "Research Study available: " + studyCitationJson.title;
    }
    if (studyResearchStudyJson) {
      let title = studyResearchStudyJson.title || studyResearchStudyJson.name || "";
      let pTitle = "";
      if (title) {
        pTitle = "<p><b>Title:</b> " + title + "</p>";
      }
      let description = studyResearchStudyJson.descriptionSummary || studyResearchStudyJson.description || "";
      let pDescription = "";
      if (description) {
        pDescription = "<br/><p><b>Description:</b> " + description + "</p>";
      }
      let phase = "";
      let pPhase = "";
      if (studyResearchStudyJson.phase) {
        phase = getStringFromFHIR.CodeableConcept(studyResearchStudyJson.phase);
      }
      if (phase) {
        pPhase = "<br/><p><b>Phase:</b> " + phase + "</p>";
      }
      let studyDesign = "";
      let pStudyDesign = "";
      if (studyResearchStudyJson.studyDesign) {
        studyDesign = studyResearchStudyJson.studyDesign.map(item => { return getStringFromFHIR.CodeableConcept(item) }).join("; ");
      }
      if (studyDesign) {
        pStudyDesign = "<br/><p><b>Study Design:</b> " + studyDesign + "</p>";
      }
      let combinedPstrings = pTitle + pDescription + pPhase + pStudyDesign;
      let narrative = "";
      if (combinedPstrings) {
        narrative = "<div>" + combinedPstrings + "</div>";
      }
      if (narrative) {
        researchStudySummary = narrative;
      }
    }
  }
  let researchStudyReference;
  if (researchStudySection.entry?.length > 0) {
    researchStudyReference = researchStudySection.entry;
  }
  let sectionAuthor;
  if (Array.isArray(researchStudySection.author) && researchStudySection.author.length > 0) {
    sectionAuthor = researchStudySection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(researchStudySummary || researchStudyReference) ? <>
      {researchStudySummary && <div><br /><DisplayFromFHIR xhtml={researchStudySummary} /><br /><br /></div>}
      {researchStudyReference && researchStudyReference.map((reference, index) => {
        return <div key={index}>
          <br /><b>Reference to {reference?.type && reference.type + " "} Resource: </b>
          <DisplayFromFHIR reference={reference} />
        </div>
      })}
      {sectionAuthor && sectionAuthor}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={researchStudySection.emptyReason} />
      </>}
  </div>
}

const StudyDesignSectionDisplay = ({ studyDesignSection, resourceDictionary }) => {
  let studyDesignClassificationJson;
  let studyDesignResearchStudyJson;
  let studyDesignClassificationFoi;
  let studyDesignResearchStudyFoi;
  if (Array.isArray(studyDesignSection.entry) && studyDesignSection.entry.length > 0 &&
    studyDesignSection.entry[0].reference?.split("/").length === 2) {
    if (studyDesignSection.entry[0].reference?.split("/")[0] === "ArtifactAssessment") {
      studyDesignClassificationFoi = studyDesignSection.entry[0].reference?.split("/")[1];
    }
    if (studyDesignSection.entry[0].reference?.split("/")[0] === "ResearchStudy") {
      studyDesignResearchStudyFoi = studyDesignSection.entry[0].reference?.split("/")[1];
    }
  }
  if (studyDesignClassificationFoi && resourceDictionary && resourceDictionary[studyDesignClassificationFoi]) {
    studyDesignClassificationJson = resourceDictionary[studyDesignClassificationFoi];
  }
  if (studyDesignResearchStudyFoi && resourceDictionary && resourceDictionary[studyDesignResearchStudyFoi]) {
    studyDesignResearchStudyJson = resourceDictionary[studyDesignResearchStudyFoi];
  }
  let studyDesignSummary = studyDesignSection.text?.div;
  if (!studyDesignSummary) {
    if (studyDesignClassificationJson) {
      let classifierArray = [];
      for (const content of studyDesignClassificationJson.content) {
        if (content.classifier) {
          classifierArray.push(content.classifier.map((classifier) => {
            return getStringFromFHIR.CodeableConcept(classifier)
          }).join('; '));
        }
      }
      if (classifierArray.length > 0) {
        studyDesignSummary = classifierArray.join('; ');
      }
    }
    if (studyDesignResearchStudyJson?.studyDesign) {
      studyDesignSummary = studyDesignResearchStudyJson.studyDesign.map((classifier) => {
        return getStringFromFHIR.CodeableConcept(classifier)
      }).join('; ')
    }
  }
  let studyDesignReference;
  if (studyDesignSection.entry?.length > 0) {
    studyDesignReference = studyDesignSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(studyDesignSection.author) && studyDesignSection.author.length > 0) {
    sectionAuthor = studyDesignSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(studyDesignSummary || studyDesignReference) ? <>
      {studyDesignSummary && <div><br /><DisplayFromFHIR xhtml={studyDesignSummary} /><br /><br /></div>}
      {studyDesignReference && <div><b>Reference to {studyDesignReference?.type && studyDesignReference.type + " "} Resource: </b><br /><DisplayFromFHIR reference={studyDesignReference} /></div>}
      {sectionAuthor && sectionAuthor}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={studyDesignSection.emptyReason} />
      </>}
  </div>
}

const MeasureReportSubgroupsDisplay = ({ subgroupSection, sectionCode }) => {
  return <div>
    <p><b>{sectionCode} Reports for Population Subgroups:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(subgroupSection.text && Object.keys(subgroupSection.text).length > 0) && <div>
        {subgroupSection.text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={subgroupSection.text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {subgroupSection.orderedBy && <span>
        <b>Ordered by: </b>
        <DisplayFromFHIR codeableConcept={subgroupSection.orderedBy} /><br />
      </span>}
      {(Array.isArray(subgroupSection.entry) && subgroupSection.entry.length > 0) &&
        subgroupSection.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {subgroupSection.emptyReason && <span>
        <b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={subgroupSection.emptyReason} /><br />
      </span>}
    </div>
  </div>
}

const BaselineMeasuresSectionDisplay = ({ baselineMeasuresSection, resourceDictionary }) => {
  if (!baselineMeasuresSection) {
    return <div><p>No Baseline Measures section in the JSON.</p></div>
  }
  let baselineMeasuresSummary = baselineMeasuresSection.text?.div;
  let measureReportReferenceList = [];
  let variablesListReferenceList = [];
  if (baselineMeasuresSection.entry?.length > 0) {
    for (const entry of baselineMeasuresSection.entry) {
      if (entry.type === "Composition") {
        measureReportReferenceList.push(entry);
      } else if (entry.type === "List") {
        variablesListReferenceList.push(entry);
      }
    }
  }
  let sectionAuthor;
  if (Array.isArray(baselineMeasuresSection.author) && baselineMeasuresSection.author.length > 0) {
    sectionAuthor = baselineMeasuresSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {baselineMeasuresSummary && <div><br /><DisplayFromFHIR xhtml={baselineMeasuresSummary} /></div>}
    {variablesListReferenceList.length > 0 && <div>
      <br />
      {variablesListReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {measureReportReferenceList.length > 0 && <div>
      <br />
      {measureReportReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {sectionAuthor && sectionAuthor}
    {baselineMeasuresSection.section?.[0] &&
      <MeasureReportSubgroupsDisplay subgroupSection={baselineMeasuresSection.section[0]}
        sectionCode="Baseline Measure" />}
    {((!baselineMeasuresSummary || baselineMeasuresSection.text.status === "empty") &&
      !baselineMeasuresSection.section && baselineMeasuresSection.emptyReason) &&
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={baselineMeasuresSection.emptyReason} />
      </>}
  </div>
}

const ParticipantFlowSectionDisplay = ({ participantFlowSection, resourceDictionary }) => {
  if (!participantFlowSection) {
    return <div><p>No Participant Flow section in the JSON.</p></div>
  }
  let participantFlowSummary = participantFlowSection.text?.div;
  let measureReportReferenceList = [];
  let variablesListReferenceList = [];
  if (participantFlowSection.entry?.length > 0) {
    for (const entry of participantFlowSection.entry) {
      if (entry.type === "Composition") {
        measureReportReferenceList.push(entry);
      } else if (entry.type === "List") {
        variablesListReferenceList.push(entry);
      }
    }
  }
  let sectionAuthor;
  if (Array.isArray(participantFlowSection.author) && participantFlowSection.author.length > 0) {
    sectionAuthor = participantFlowSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {participantFlowSummary && <div><br /><DisplayFromFHIR xhtml={participantFlowSummary} /></div>}
    {variablesListReferenceList.length > 0 && <div>
      <br />
      {variablesListReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {measureReportReferenceList.length > 0 && <div>
      <br />
      {measureReportReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {sectionAuthor && sectionAuthor}
    {participantFlowSection.section?.[0] &&
      <MeasureReportSubgroupsDisplay subgroupSection={participantFlowSection.section[0]}
        sectionCode="Participant Flow" />}
    {((!participantFlowSummary || participantFlowSection.text.status === "empty") &&
      !participantFlowSection.section && participantFlowSection.emptyReason) &&
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={participantFlowSection.emptyReason} />
      </>}
  </div>
}

const OutcomesSectionDisplay = ({ outcomesSection, resourceDictionary }) => {
  if (!outcomesSection) {
    return <div><p>No Outcomes section in the JSON.</p></div>
  }
  let outcomesSectionSummary = outcomesSection.text?.div;
  let measureReportReferenceList = [];
  let variablesListReferenceList = [];
  if (outcomesSection.entry?.length > 0) {
    for (const entry of outcomesSection.entry) {
      if (entry.type === "Composition") {
        measureReportReferenceList.push(entry);
      } else if (entry.type === "List") {
        variablesListReferenceList.push(entry);
      }
    }
  }
  let sectionAuthor;
  if (Array.isArray(outcomesSection.author) && outcomesSection.author.length > 0) {
    sectionAuthor = outcomesSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {outcomesSectionSummary && <div><br /><DisplayFromFHIR xhtml={outcomesSectionSummary} /></div>}
    {variablesListReferenceList.length > 0 && <div>
      <br />
      {variablesListReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {measureReportReferenceList.length > 0 && <div>
      <br />
      {measureReportReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {sectionAuthor && sectionAuthor}
    {outcomesSection.section?.[0] &&
      <MeasureReportSubgroupsDisplay subgroupSection={outcomesSection.section[0]}
        sectionCode="Outcome Measure" />}
    {((!outcomesSectionSummary || outcomesSection.text.status === "empty") &&
      !outcomesSection.section && outcomesSection.emptyReason) &&
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={outcomesSection.emptyReason} />
      </>}
  </div>
}

const SummaryOfFindingsSectionDisplay = ({ summaryOfFindingsSection, resourceDictionary }) => {
  if (!summaryOfFindingsSection) {
    return <div><p>No Summary of Findings section in the JSON.</p></div>
  }
  let summaryOFFindingsSectionSummary = summaryOfFindingsSection.text?.div;
  let summaryOfFindingsReportReferenceList = [];
  let summaryOfFindingsVariablesListReferenceList = [];
  if (summaryOfFindingsSection.entry?.length > 0) {
    for (const entry of summaryOfFindingsSection.entry) {
      if (entry.type === "Composition") {
        summaryOfFindingsReportReferenceList.push(entry);
      } else if (entry.type === "List") {
        summaryOfFindingsVariablesListReferenceList.push(entry);
      }
    }
  }
  let sectionAuthor;
  if (Array.isArray(summaryOfFindingsSection.author) && summaryOfFindingsSection.author.length > 0) {
    sectionAuthor = summaryOfFindingsSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }
  return <div>
    {summaryOFFindingsSectionSummary && <div><br /><DisplayFromFHIR xhtml={summaryOFFindingsSectionSummary} /></div>}
    {summaryOfFindingsVariablesListReferenceList.length > 0 && <div>
      <br />
      {summaryOfFindingsVariablesListReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {summaryOfFindingsReportReferenceList.length > 0 && <div>
      <br />
      {summaryOfFindingsReportReferenceList.map((entry, index) => {
        return <div key={index}>
          <DisplayFromFHIR reference={entry} /><br />
        </div>
      })}
    </div>}
    {sectionAuthor && sectionAuthor}
    {summaryOfFindingsSection.section?.[0] &&
      <MeasureReportSubgroupsDisplay subgroupSection={summaryOfFindingsSection.section[0]}
        sectionCode="Summary of Findings" />}
    {((!summaryOFFindingsSectionSummary || summaryOfFindingsSection.text.status === "empty") &&
      !summaryOfFindingsSection.section && summaryOfFindingsSection.emptyReason) &&
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={summaryOfFindingsSection.emptyReason} />
      </>}
  </div>
}

const ComparativeEvidenceReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }
  const globalContext = useContext(FevirContext);
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(fhirJson, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  let populationSection = {};
  let interventionSection = {};
  let comparatorSection = {};
  let researchStudySection = {};
  let studyDesignSection = "";
  let baselineMeasuresSection = {};
  let participantFlowSection = {};
  let outcomesSection = {};
  let summaryOfFindingsSection = {};
  let introductionSection = {};
  let discussionSection = {};
  let methodsSection = {};
  let referencesSection = {};
  let competingInterestsSection = {};
  let acknowlegementsSection = {};
  let appendicesSection = {};
  let additionalSections = [];

  if (Array.isArray(fhirJson.section)) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "population" || sectionCode === "Population") {
        populationSection = section;
      } else if (sectionCode === "intervention" || sectionCode === "Intervention") {
        interventionSection = section;
      } else if (sectionCode === "comparator" || sectionCode === "Comparator") {
        comparatorSection = section;
      } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
        researchStudySection = section;
      } else if (sectionCode === "study-design" || sectionCode === "Study Design") {
        studyDesignSection = section;
      } else if (sectionCode === "baseline-measures" || sectionCode === "Baseline Measures") {
        baselineMeasuresSection = section;
      } else if (sectionCode === "participant-flow" || sectionCode === "Participant Flow") {
        participantFlowSection = section;
      } else if (sectionCode === "outcome-measures" || sectionCode === "Outcomes") {
        outcomesSection = section;
      } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of Findings") {
        summaryOfFindingsSection = section;
      } else if (sectionCode === "introduction" || sectionCode === "Introduction") {
        introductionSection = section;
      } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
        discussionSection = section;
      } else if (sectionCode === "methods" || sectionCode === "Methods") {
        methodsSection = section;
      } else if (sectionCode === "references" || sectionCode === "References") {
        referencesSection = section;
      } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
        competingInterestsSection = section;
      } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
        acknowlegementsSection = section;
      } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
        appendicesSection = section;
      } else {
        additionalSections.push(section);
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="introduction">Introduction</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Comparative Evidence Report Title: </b>
          {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
        </p>
        {description &&
          <div><b>Comparative Evidence Report Description: </b><DisplayFromFHIR markdown={description} />
          </div>}
        {Array.isArray(fhirJson.subject) &&
          fhirJson.subject.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
          })}
        {Array.isArray(fhirJson.category) &&
          fhirJson.category.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
          })}
        <p>
          <b>View PDF: </b>
          <a href={'https://computablepublishing.com/Composition/' + fhirJson.id}
            target='_blank' rel='noopener noreferrer'>View or download PDF</a>
        </p>
        <EvidenceReportPackageSectionDisplay topSection={introductionSection} />
      </div>
      <h3 id="population">Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <PopulationSectionDisplay populationSection={populationSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="intervention">Intervention</h3>
      <div style={{ marginLeft: "24px" }}>
        <InterventionSectionDisplay interventionSection={interventionSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="comparator">Comparator</h3>
      <div style={{ marginLeft: "24px" }}>
        <ComparatorSectionDisplay comparatorSection={comparatorSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="research-study">Research Study</h3>
      <div style={{ marginLeft: "24px" }}>
        <ResearchStudySectionDisplay researchStudySection={researchStudySection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
        {studyDesignSection && <StudyDesignSectionDisplay studyDesignSection={studyDesignSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />}
      </div>
      <h3 id="methods">Methods</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={methodsSection} />
      </div>
      <h3 id="baseline-measures">Baseline Measures</h3>
      <div style={{ marginLeft: "24px" }}>
        <BaselineMeasuresSectionDisplay baselineMeasuresSection={baselineMeasuresSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="participant-flow">Participant Flow</h3>
      <div style={{ marginLeft: "24px" }}>
        <ParticipantFlowSectionDisplay participantFlowSection={participantFlowSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="outcomes">Outcomes</h3>
      <div style={{ marginLeft: "24px" }}>
        <OutcomesSectionDisplay outcomesSection={outcomesSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="summary-of-findings">Summary of Findings</h3>
      <div style={{ marginLeft: "24px" }}>
        <SummaryOfFindingsSectionDisplay summaryOfFindingsSection={summaryOfFindingsSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="discussion">Discussion</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={discussionSection} />
        {additionalSections?.length > 0 && additionalSections.map((section, sectionIndex) => {
          return <div key={sectionIndex} >
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </div>
      <h3 id="references">References</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={referencesSection} />
      </div>
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={competingInterestsSection} />
      </div>
      <h3 id="acknowledgements">Acknowledgements</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={acknowlegementsSection} />
      </div>
      <h3 id="appendices">Appendices</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={appendicesSection} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(sourceJsonState.howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={sourceJsonState.howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.category) && fhirJson.category.length > 0) && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {(Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0) && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ComparativeEvidenceReportViewerTool;