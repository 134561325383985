import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import ResourceBottomDisplay from './ResourceBottomDisplay';

const PlanDefinitionDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  if (fhirJson.citeAs) {
    howToCite = fhirJson.citeAs;
  }
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/cite-as" && extension.valueMarkdown) {
        howToCite = extension.valueMarkdown;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.type && <p><b>Type: </b><DisplayFromFHIR codeableConcept={fhirJson.type} /></p>}
      </div>
      <h3 id="goal">Goal</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.goal) && fhirJson.goal.length > 0)
          ?
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.goal.map((goal, goalIndex) => {
              return <div key={goalIndex}>
                <p><b>Goal entry {goalIndex + 1}</b></p>
                <div style={{ marginLeft: "24px" }}>
                  {goal.category && <span><b>Category: </b><DisplayFromFHIR codeableConcept={goal.category} /></span>}
                  {goal.description && <span><br /><b>Description: </b><DisplayFromFHIR codeableConcept={goal.description} /></span>}
                  {goal.priority && <span><br /><b>Priority: </b><DisplayFromFHIR codeableConcept={goal.priority} /></span>}
                  {goal.start && <span><br /><b>Start: </b><DisplayFromFHIR codeableConcept={goal.start} /></span>}
                  {Array.isArray(goal.addresses) && goal.addresses.map((addresses, addressesIndex) => {
                    return <span key={addressesIndex}><br /><b>The goal addresses: </b><DisplayFromFHIR codeableConcept={addresses} /></span>
                  })}
                  {Array.isArray(goal.documentation) && goal.documentation.map((documentation, documentationIndex) => {
                    return <div key={documentationIndex}>
                      <p><b>Supporting documentation: </b></p>
                      <DisplayFromFHIR relatedArtifact={documentation} /></div>
                  })}
                  {Array.isArray(goal.target) && goal.target.map((target, targetIndex) => {
                    return <div key={targetIndex}>
                      <p><b>Goal Target {targetIndex + 1}</b></p>
                      <div style={{ marginLeft: "24px" }}>
                        {target.measure && <span><b>Measure: </b><DisplayFromFHIR codeableConcept={target.measure} /></span>}
                        {target.detailQuantity && <p><b>Value: </b>{getStringFromFHIR.Quantity(target.detailQuantity)}</p>}
                        {target.detailRange && <p><b>Value: </b>{getStringFromFHIR.Range(target.detailRange)}</p>}
                        {target.detailCodeableConcept && <span><br /><b>Value: </b><DisplayFromFHIR codeableConcept={target.detailCodeableConcept} /></span>}
                        {target.detailString && <p><b>Value: </b>{target.detailString}</p>}
                        {target.detailBoolean && <p><b>Value: </b>{getStringFromFHIR.boolean(target.detailBoolean)}</p>}
                        {target.detailInteger && <p><b>Value: </b>{target.detailInteger.toString()}</p>}
                        {target.detailRatio && <p><b>Value: </b>{getStringFromFHIR.Ratio(target.detailRatio)}</p>}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            })}
          </div>
          :
          <p>No goal data.</p>
        }
      </div>
      <h3 id="actor">Actor</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.actor) && fhirJson.actor.length > 0)
          ?
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.actor.map((actor, actorIndex) => {
              return <div key={actorIndex}>
                <p><b>Actor entry {actorIndex + 1}</b></p>
                <div style={{ marginLeft: "24px" }}>
                  {actor.title && <p><b>Actor title: </b>{actor.title}</p>}
                  {actor.description && <span><b>Actor description: </b><DisplayFromFHIR markdown={actor.description} /></span>}
                  {Array.isArray(actor.option) && actor.option.map((option, optionIndex) => {
                    return <div key={optionIndex}>
                      <p><b>Actor type {optionIndex + 1}</b></p>
                      <div style={{ marginLeft: "24px" }}>
                        {option.type && <p><b>Type: </b>{option.type}</p>}
                        {option.typeCanonical && <p><b>Type: </b><DisplayFromFHIR uri={option.typeCanonical} /></p>}
                        {option.typeReference && <p><b>Type: </b><DisplayFromFHIR reference={option.typeReference} /></p>}
                        {option.role && <span><b>Role: </b><DisplayFromFHIR codeableConcept={option.role} /></span>}

                      </div>
                    </div>
                  })}
                </div>
              </div>
            })}
          </div>
          :
          <p>No actor data.</p>
        }
        {fhirJson.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={fhirJson.subjectCodeableConcept} /></span>}
        {fhirJson.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subjectReference} /></p>}
        {fhirJson.subjectCanonical && <p><b>Subject: </b><DisplayFromFHIR uri={fhirJson.subjectCanonical} /></p>}
      </div>
      <h3 id="action">Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <div style={{ marginLeft: "24px" }}>
          {(Array.isArray(fhirJson.action) && fhirJson.action.length > 0)
            ?
            <div style={{ marginLeft: "24px" }}>
              {fhirJson.action.map((action, actionIndex) => {
                return <div key={actionIndex}>
                  <p><b>Action entry {actionIndex + 1}</b></p>
                  <div style={{ marginLeft: "24px" }}>
                    {action.linkId && <p><b>Action id: </b>{action.linkId}</p>}
                    {(action.prefix || action.title) && <p><b>Action title: </b>{action.prefix} {action.title}</p>}
                    {action.description && <span><b>Action description: </b><DisplayFromFHIR markdown={action.description} /></span>}
                    {action.textEquivalent && <span><b>Action text equivalent: </b><DisplayFromFHIR markdown={action.textEquivalent} /></span>}
                    {action.priority && <p><b>Action priority: </b>{action.priority}</p>}
                    {action.code && <p><b>Action meaning: </b><DisplayFromFHIR codeableConcept={action.code} /></p>}
                    {Array.isArray(action.reason) && action.reason.map((reason, reasonIndex) => {
                      return <span key={reasonIndex}><br /><b>Reason: </b><DisplayFromFHIR codeableConcept={reason} /></span>
                    })}
                    {Array.isArray(action.documentation) && action.documentation.map((documentation, documentationIndex) => {
                      return <div key={documentationIndex}>
                        <p><b>Supporting documentation: </b></p>
                        <DisplayFromFHIR relatedArtifact={documentation} /></div>
                    })}
                    {action.goalId?.length > 0 && <p><b>Goals: </b>{action.goalId.join(', ')}</p>}
                    {action.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={action.subjectCodeableConcept} /></span>}
                    {action.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={action.subjectReference} /></p>}
                    {action.subjectCanonical && <p><b>Subject: </b><DisplayFromFHIR uri={action.subjectCanonical} /></p>}
                    {Array.isArray(action.trigger) && action.trigger.map((trigger, triggerIndex) => {
                      return <div key={triggerIndex}>
                        <p><b>Trigger {triggerIndex + 1}</b></p>
                        <div style={{ marginLeft: "24px" }}>
                          {trigger.type && <p><b>Type: </b>{trigger.type}</p>}
                          {trigger.name && <p><b>Name: </b>{trigger.name}</p>}
                          {trigger.code && <span><b>Definition: </b><DisplayFromFHIR codeableConcept={trigger.code} /></span>}
                          {trigger.subscriptionTopic && <p><b>Event: </b><DisplayFromFHIR uri={trigger.subscriptionTopic} /></p>}
                          {trigger.timingTiming && <p><b>Timing: </b>{getStringFromFHIR.Timing(trigger.timingTiming)}</p>}
                          {trigger.timingReference && <p><b>Timing: </b><DisplayFromFHIR reference={trigger.timingReference} /></p>}
                          {trigger.timingDate && <p><b>Timing: </b>{getStringFromFHIR.date(trigger.timingDate)}</p>}
                          {trigger.timingDateTime && <p><b>Timing: </b>{getStringFromFHIR.dateTime(trigger.timingDateTime)}</p>}
                          {trigger.data && <p>JSON data includes triggering data requirement.</p>}
                          {trigger.condition && <p><b>Condition: </b><DisplayFromFHIR expression={trigger.condition} /></p>}
                        </div>
                      </div>
                    })}
                    {Array.isArray(action.condition) && action.condition.map((condition, conditionIndex) => {
                      return <div key={conditionIndex}>
                        <p><b>Condition {conditionIndex + 1}</b></p>
                        <div style={{ marginLeft: "24px" }}>
                          {condition.kind && <p><b>Kind: </b>{condition.kind}</p>}
                          {condition.expression && <p><b>Expression: </b><DisplayFromFHIR expression={condition.expression} /></p>}
                        </div>
                      </div>
                    })}
                    {Array.isArray(action.input) && action.input.map((input, inputIndex) => {
                      return <div key={inputIndex}>
                        <p><b>Input {inputIndex + 1}</b></p>
                        <div style={{ marginLeft: "24px" }}>
                          {input.title && <p><b>Title: </b>{input.title}</p>}
                          {input.requirement && <p>JSON data includes input data requirement.</p>}
                          {input.relatedData && <p><b>Data: </b>{input.relatedData}</p>}
                        </div>
                      </div>
                    })}
                    {Array.isArray(action.output) && action.output.map((output, outputIndex) => {
                      return <div key={outputIndex}>
                        <p><b>Output {outputIndex + 1}</b></p>
                        <div style={{ marginLeft: "24px" }}>
                          {output.title && <p><b>Title: </b>{output.title}</p>}
                          {output.requirement && <p>JSON data includes output data requirement.</p>}
                          {output.relatedData && <p><b>Data: </b>{output.relatedData}</p>}
                        </div>
                      </div>
                    })}
                    {Array.isArray(action.relatedAction) && action.relatedAction.map((relatedAction, relatedActionIndex) => {
                      return <div key={relatedActionIndex}>
                        <p><b>Related Action {relatedActionIndex + 1}</b></p>
                        <div style={{ marginLeft: "24px" }}>
                          {relatedAction.targetId && <p><b>Id: </b>{relatedAction.targetId}</p>}
                          {relatedAction.relationship && <p><b>Start timing: </b>{relatedAction.relationship}</p>}
                          {relatedAction.endRelationship && <p><b>End timing: </b>{relatedAction.endRelationship}</p>}
                          {relatedAction.offsetDuration && <p><b>Offset: </b><DisplayFromFHIR duration={relatedAction.offsetDuration} /></p>}
                          {relatedAction.offsetRange && <p><b>Offset: </b><DisplayFromFHIR range={relatedAction.offsetRange} /></p>}
                        </div>
                      </div>
                    })}
                    {action.timingTiming && <p><b>Timing: </b>{getStringFromFHIR.Timing(action.timingTiming)}</p>}
                    {action.timingAge && <p><b>Timing: </b>{getStringFromFHIR.Quantity(action.timingAge)}</p>}
                    {action.timingRange && <p><b>Timing: </b>{getStringFromFHIR.Range(action.timingRange)}</p>}
                    {action.timingDuration && <p><b>Timing: </b>{getStringFromFHIR.Quantity(action.timingDuration)}</p>}
                    {action.location && <p><b>Location: </b><DisplayFromFHIR codeableReference={action.location} /></p>}
                    {(Array.isArray(action.participant) && action.participant.length > 0) && <div>
                      <p>{action.participant.length === 1 ? <b>Participant: </b> : <b>Participants: </b>}</p>
                      <div style={{ marginLeft: "24px" }}>
                        {action.participant.map((participant, participantIndex) => {
                          return <div key={participantIndex}>
                            <p><b>Participant entry {participantIndex + 1}</b></p>
                            <div style={{ marginLeft: "24px" }}>
                              {participant.actorId && <p><b>Participant actor id: </b>{participant.actorId}</p>}
                              {participant.type && <p><b>Participant type: </b>{participant.type}</p>}
                              {participant.typeCanonical && <p><b>Participant type canonical: </b><DisplayFromFHIR uri={participant.typeCanonical} /></p>}
                              {participant.typeReference && <p><b>Participant type reference: </b><DisplayFromFHIR reference={participant.typeReference} /></p>}
                              {participant.role && <span><b>Participant role: </b><DisplayFromFHIR codeableConcept={participant.role} /></span>}
                              {participant.function && <span><b>Participant function: </b><DisplayFromFHIR codeableConcept={participant.function} /></span>}
                            </div>
                          </div>
                        })}
                      </div>
                    </div>
                    }
                    {action.type && <span><b>Action type: </b><DisplayFromFHIR codeableConcept={action.type} /></span>}
                    {action.groupingBehavior && <p><b>Grouping Behavior: </b>{action.groupingBehavior}</p>}
                    {action.selectionBehavior && <p><b>Selection Behavior: </b>{action.selectionBehavior}</p>}
                    {action.requiredBehavior && <p><b>Required Behavior: </b>{action.requiredBehavior}</p>}
                    {action.precheckBehavior && <p><b>Precheck Behavior: </b>{action.precheckBehavior}</p>}
                    {action.cardinalityBehavior && <p><b>Cardinality Behavior: </b>{action.cardinalityBehavior}</p>}
                    {action.definitionCanonical && <p><b>Action Definition: </b><DisplayFromFHIR uri={action.definitionCanonical} /></p>}
                    {action.definitionUri && <p><b>Action Definition: </b><DisplayFromFHIR uri={action.definitionUri} /></p>}
                    {action.transform && <p><b>Transform to apply: </b><DisplayFromFHIR uri={action.transform} /></p>}
                    {(Array.isArray(action.dynamicValue) && action.dynamicValue.length > 0) && <div>
                      <p>{action.dynamicValue.length === 1 ? <b>Dynamic Value: </b> : <b>Dynamic Values: </b>}</p>
                      <div style={{ marginLeft: "24px" }}>
                        {action.dynamicValue.map((dynamicValue, dynamicValueIndex) => {
                          return <div key={dynamicValueIndex}>
                            {dynamicValue.path && <p><b>Path: </b>{dynamicValue.path}</p>}
                            {dynamicValue.expression && <div><b>Expression: </b><DisplayFromFHIR expression={dynamicValue.expression} /></div>}
                            <br />
                          </div>
                        })}
                      </div>
                    </div>
                    }
                    {action.action && <p>JSON data includes sub-actions.</p>}
                  </div>
                </div>
              })}
            </div>
            :
            <p>No action data.</p>
          }
          {(fhirJson.asNeededBoolean !== undefined || fhirJson.asNeededCodeableConcept) && <p><b>As Needed:</b></p>}
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.asNeededBoolean !== undefined && <p><b>As needed: </b>{getStringFromFHIR.boolean(fhirJson.asNeededBoolean)}</p>}
            {fhirJson.asNeededCodeableConcept && <span><b>As needed for: </b><DisplayFromFHIR codeableConcept={fhirJson.asNeededCodeableConcept} /></span>}
          </div>
        </div>
      </div>
      <ResourceBottomDisplay howToCite={howToCite} fhirJson={fhirJson}
        classificationsLoadedState={classificationsLoadedState} classificationsArray={classificationsArrayState}
      />
    </div>
  </div>
}

export default PlanDefinitionDisplay;