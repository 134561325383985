import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { Table } from 'semantic-ui-react';

const TitleComponentDisplay = ({ component }) => {
  return <div>
    <br />
    <p><b>Title of this Adaptation Resource: </b>{component.summary}</p>
  </div>
};

const OriginalResourceContentComponentDisplay = ({ component, datatypeHandling }) => {
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    {component.summary && <DisplayFromFHIR markdown={component.summary} />}
    {component.component && <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>}
  </div>
};

const RationaleForChangeComponentDisplay = ({ component }) => {
  if (!component.summary && !component.classifier?.length > 0) {
    return <></>
  }
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    <DisplayFromFHIR markdown={component.summary} />
    <br />
    {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
      <div>
        <p><b>Is change suggested? </b>
          {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
          {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
        </p>
      </div>}
    {(Array.isArray(component.relatedArtifact) && component.relatedArtifact.length > 0) &&
      component.relatedArtifact.map((relatedItem, relatedItemIndex) => {
        return <div key={relatedItemIndex}>
          <p><b>Related Item {relatedItemIndex + 1}: </b></p>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR relatedArtifact={relatedItem} />
          </div>
        </div>
      })
    }
    {(Array.isArray(component.relatesTo) && component.relatesTo.length > 0) &&
      component.relatesTo.map((relatedItem, relatedItemIndex) => {
        return <div key={relatedItemIndex}>
          <p><b>Related Item {relatedItemIndex + 1}: </b></p>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR relatesTo={relatedItem} />
          </div>
        </div>
      })
    }
    {component.component && <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} />
      }
    </div>}
  </div>
};

const SuggestedChangeComponentDisplay = ({ component, datatypeHandling }) => {
  if (!component.summary) {
    return <></>
  }
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    {(component.summary && typeof (component.summary) === 'string') && <DisplayFromFHIR markdown={component.summary} />}
    {(component.summary && typeof (component.summary) === 'object' && datatypeHandling === 'manageAsCoding') && <>
      {Array.isArray(component.summary) ?
        component.summary.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ')
        :
        <DisplayFromFHIR coding={component.summary} />}
      <><br /><br /><p><b>JSON: </b>{JSON.stringify(component.summary)}</p></>
    </>
    }
    <br />
    {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
      <div>
        <p><b>Accept or reject? </b>
          {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
          {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
        </p>
      </div>}
  </div>
};

const PrimitiveComponentDisplay = ({ component }) => {
  return <div>
    <br />
    <p><b>Specific to: </b>{component.path?.toString()}</p>
    <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Datatype: </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} />
      }
    </div>
  </div>
};

const CodingComponentDisplay = ({ component }) => {
  return <div>
    <br />
    <p><b>Specific to: </b>{component.path?.toString()}</p>
    <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Coding Object or Array? </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling='manageAsCoding' />
      }
    </div>
  </div>
};

const ComplexComponentDisplay = ({ component, datatypeHandling }) => {
  if (Array.isArray(component.classifier) && component.classifier.length > 0 &&
    Array.isArray(component.classifier?.[0]?.coding) && component.classifier[0].coding.length > 0) {
    datatypeHandling = component.classifier[0].coding[0].code;
  }
  return <div>
    <br />
    <p><b>Specific to: </b>{component.path?.toString()}</p>
    <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Datatype: </b>
            {component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>
  </div>
};

const ComponentDisplay = ({ content, contentIndex, datatypeHandling }) => {
  if (!content || typeof (content) !== "object") {
    return <></>
  }
  let contentType;
  if (content.type) {
    if (Array.isArray(content.type.coding) && content.type.coding.length > 0) {
      contentType = content.type.coding[0].display || content.type.coding[0].code;
    } else if (content.type.text) {
      contentType = content.type.text;
    }
  }

  if (contentType === "Title of Adaptation Resource" || contentType === "ResourceTitle" || contentType === "Title for Adaptation Resource") {
    return <TitleComponentDisplay component={content} />
  }
  if (contentType === "Primitive Element" || contentType === "PrimitiveElement" || contentType === "Editable Resource Content Element") {
    return <PrimitiveComponentDisplay component={content} />
  }
  if (contentType === "Coding Element" || contentType === "CodingElement" || contentType === "Editable Resource Content Coding") {
    return <CodingComponentDisplay component={content} />
  }
  if (contentType === "Array Element" || contentType === "ArrayElement" || contentType === "Object Element" || contentType === "ObjectElement" || contentType === "Complex Resource Content Element") {
    return <ComplexComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Original Resource Content" || contentType === "OriginalResourceContent") {
    return <OriginalResourceContentComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Rationale for Change to Resource Content" || contentType === "RationaleForChange") {
    return <RationaleForChangeComponentDisplay component={content} />
  }
  if (contentType === "Suggested Change to Resource Content" || contentType === "SuggestedChange" ||
    contentType === "Revised Resource Content" || contentType === "RevisedChange") {
    return <SuggestedChangeComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Original Resource Content JSON" || contentType === "OriginalResourceContentJSON") {
    return <div style={{ marginLeft: "24px" }}>
      {datatypeHandling === 'manageAsCoding' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Coding(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsIdentifier' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Identifier(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Identifier(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsReference' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Reference(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Reference(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsQuantity' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Quantity(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Quantity(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsAddress' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Address(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Address(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsAnnotation' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Annotation(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Annotation(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsContactDetail' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}>
              <DisplayFromFHIR contactDetail={object} />
              <br /></span>
          })
          :
          <span>
            <DisplayFromFHIR contactDetail={JSON.parse(content.summary)} />
            <br />
          </span>
        }
      </>}
      {datatypeHandling === 'manageAsDosage' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Dosage(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Dosage(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsExpression' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Expression(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Expression(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsHumanName' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.HumanName(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.HumanName(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsPeriod' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Period(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Period(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsRange' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Range(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Range(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsRatio' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Ratio(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Ratio(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsTiming' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Timing(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Timing(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsCodeableConcept' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.CodeableConcept(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.CodeableConcept(JSON.parse(content.summary))}</p>
        }
      </>}
      {datatypeHandling === 'manageAsRelatedArtifact' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><DisplayFromFHIR relatedArtifact={object} /></span>
          })
          :
          <DisplayFromFHIR relatedArtifact={JSON.parse(content.summary)} />
        }
      </>}
      {datatypeHandling === 'manageAsRelatesTo' && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><DisplayFromFHIR relatesTo={object} /></span>
          })
          :
          <DisplayFromFHIR relatesTo={JSON.parse(content.summary)} />
        }
      </>}
      <br />
      <span>
        <p><b>Original Resource Content JSON:</b></p>
        {/* TODO show either 'collapse' or 'expand' inline here, but the word is clickable and toggles to the other word */}
      </span>
      {/* TODO show the content below only if 'collapse' is displayed in the item above */}
      <br />
      <span>{JSON.stringify(JSON.parse(content.summary), null, 2).replace('\n', '')}</span>
      <br />
    </div>
  }
  if (contentType === "Contained Element Adaptation" || contentType === "ContainedElementAdaptation") {
    if (!content.component) {
      return <></>
    }
    return <div style={{ marginLeft: "24px" }}>
      <br />
      {(Array.isArray(content.component) && content.component.length > 0) ?
        content.component.map((content, contentIndex) => {
          return <div key={contentIndex}>
            <ComponentDisplay content={content} />
          </div>
        })
        :
        <span></span>
      }
      <br />
    </div>
  }
  return <div>
    <p><b>Adaptation Component {contentIndex + 1}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      {content.type && <><p><b>Type: </b><DisplayFromFHIR codeableConcept={content.type} /></p></>}
      {content.path && <><p><b>Specific to: </b>{content.path.toString()}</p></>}
      {content.summary && <DisplayFromFHIR markdown={content.summary} />}
      {(Array.isArray(content.classifier) && content.classifier.length > 0) &&
        content.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
      {content.author && <><p><b>Adaptation Author: </b>{getStringFromFHIR.Reference(content.author)}</p></>}
      {content.freeToShare === true && <p>Free to share.</p>}
      {content.freeToShare === false && <p>NOT free to share.</p>}
      {(Array.isArray(content.relatedArtifact) && content.relatedArtifact.length > 0) &&
        content.relatedArtifact.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}>
            <p><b>Related Item {relatedItemIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR relatedArtifact={relatedItem} />
            </div>
          </div>
        })
      }
      {(Array.isArray(content.relatesTo) && content.relatesTo.length > 0) &&
        content.relatesTo.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}>
            <p><b>Related Item {relatedItemIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR relatesTo={relatedItem} />
            </div>
          </div>
        })
      }
      {(Array.isArray(content.component) && content.component.length > 0) && <div style={{ marginLeft: "24px" }}>
        {content.component.map((content, contentIndex) => {
          return <div key={contentIndex}>
            {content.path?.toString() && <p><b>{content.path?.toString()} Adaptation Component {contentIndex + 1}: </b></p>}
            <ComponentDisplay content={content} />
          </div>
        })}
      </div>}
    </div></div>
};

const ContentDisplay = ({ contentArray, datatypeHandling }) => {
  if (Array.isArray(contentArray) && contentArray.length > 0) {
    return <div>{contentArray.map((content, contentIndex) => {
      return <div key={contentIndex}>
        <ComponentDisplay content={content} contentIndex={contentIndex} datatypeHandling={datatypeHandling} />
      </div>
    })}</div>
  } else {
    return <></>
  }
};

const ContentTableDisplay = ({ contentArray }) => {
  if (Array.isArray(contentArray) && contentArray.length > 0) {
    return <div><Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ backgroundColor: "#E2DDEF" }}>Element</Table.HeaderCell>
          <Table.HeaderCell style={{ backgroundColor: "#E2DDEF" }}>Original Content</Table.HeaderCell>
          <Table.HeaderCell style={{ backgroundColor: "#E2DDEF" }}>Revised Content</Table.HeaderCell>
          <Table.HeaderCell style={{ backgroundColor: "#E2DDEF" }}>Rationale for Change</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {contentArray?.map((content, contentIndex) => {
          return <Table.Row key={contentIndex}>
            <Table.Cell>{content.path?.[0]}</Table.Cell>
            <Table.Cell>{content.component?.[0]?.summary}</Table.Cell>
            <Table.Cell>{content.component?.[1]?.summary}</Table.Cell>
            <Table.Cell>{content.component?.[2]?.summary}</Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
    </div>
  } else {
    return <></>
  }
}

const AdaptationDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  let howToCite;
  if (fhirJson.citeAsMarkdown) {
    howToCite = fhirJson.citeAsMarkdown;
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="adapted-item">Adapted Item</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.artifactReference && <DisplayFromFHIR reference={fhirJson.artifactReference} />}
        {fhirJson.artifactCanonical && <DisplayFromFHIR uri={fhirJson.artifactCanonical} />}
        {fhirJson.artifactUri && <DisplayFromFHIR uri={fhirJson.artifactUri} />}
      </div>
      <h3 id="table-view">Table View</h3>
      <ContentTableDisplay contentArray={fhirJson.content} />
      <h3 id="adaptations">Adaptations</h3>
      <ContentDisplay contentArray={fhirJson.content} />
      <h3 id="adaptation-status">Adaptation Status</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Workflow Status: </b>{fhirJson.workflowStatus}</p>
        <p><b>Disposition: </b>{fhirJson.disposition}</p>
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default AdaptationDisplay;