import React, { useState, useEffect, memo } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import { autoEditEmptyReason } from './CompositionDocumentBundleFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { getStringFromFHIR, DisplayFromFHIR } from './ResourceFunctions';
import {
  emptyReasonNotStarted,
  sectionCodeSummaryOfFindingsForSingleOutcome,
  sectionCodeEvidenceWithInterventionAlone, sectionCodeEvidenceWithComparatorAlone,
  sectionCodeInterventionGroup,
  sectionCodeComparatorGroup, emptyTextNoData,
  sectionCodeEvidenceForEnrolledGroup,
  sectionCodeEvidenceForScreenedGroup,
  sectionCodeEvidenceForAnalyzedGroup,
  sectionCodePopulation,
  sectionCodeComparativeResults
} from './CodeSystemLookup';
import { generateEvidenceSummary } from './GenerateNarrativeSummaryFunctions';
import EditTableCellModal from './EditTableCellModal';
import scrollTo from './ScrollTo';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } });
}

const InnerSectionDataEntry = memo(({ startingValue, elementName, setResourceState,
  entryHeader, entrySetProfile, variableDefinition, resourceDictionary, setSourceJsonState,
  researchStudyRelatedArtfifact, resourceState, suggestedEvidenceTitle,
  globalContext, statisticType, statisticUnit, knownDataCount,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode, sectionArrayIndex }) => {
  let sectionCode;
  if (adaptationReportState?.pathList && startingValue?.code) {
    if (sectionArrayIndex || sectionArrayIndex === 0) {
      if (startingValue.code.text) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section[" + sectionArrayIndex + "]:" + JSON.stringify(startingValue.code);
      }
    } else {
      if (startingValue.code.text) {
        sectionCode = "section:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section:" + JSON.stringify(startingValue.code);
      }
    }
    if (parentSectionCode) {
      sectionCode = parentSectionCode.split(":")[0] + "." + sectionCode;
    }
  }

  let compositionId = resourceState.id;
  let compositionTitle = resourceState.title || resourceState.name;

  let entryAddElementValues = {
    title: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.title || suggestedEvidenceTitle,
    relatesTo: researchStudyRelatedArtfifact || "",
    description: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.description || "",
    variableDefinition: variableDefinition || "",
    statistic: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.statistic || ""
  };
  if (!entryAddElementValues.relatesTo) {
    entryAddElementValues.relatesTo = [
      {
        "type": "part-of",
        "targetReference": {
          "reference": "Composition/" + compositionId,
          "type": "Composition",
          "display": compositionTitle
        }
      }
    ];
  } else {
    entryAddElementValues.relatesTo.push({
      "type": "part-of",
      "targetReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    });
  }

  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp },
    author: [], focus: "", text: emptyTextNoData, orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [endSectionState, setEndSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  const [openEditModalState, setOpenEditModalState] = useState({ "openModal": false });

  useEffect((() => {
    if (Object.keys(endSectionState).length > 0) {
      let newSection = {};
      if (endSectionState.extension) { newSection.extension = endSectionState.extension; }
      if (endSectionState.title) { newSection.title = endSectionState.title; }
      if (endSectionState.code) { newSection.code = endSectionState.code; }
      if (Array.isArray(endSectionState.author) && endSectionState.author.length > 0) {
        newSection.author = endSectionState.author;
      }
      if (endSectionState.focus) { newSection.focus = endSectionState.focus; }
      if (endSectionState.text) { newSection.text = endSectionState.text; }
      if (endSectionState.orderedBy) { newSection.orderedBy = endSectionState.orderedBy; }
      if (Array.isArray(endSectionState.entry) && endSectionState.entry.length > 0) {
        newSection.entry = endSectionState.entry;
      }
      if (endSectionState.emptyReason) { newSection.emptyReason = endSectionState.emptyReason; }
      if (Array.isArray(endSectionState.section) && endSectionState.section.length > 0) {
        newSection.section = endSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      handleChange(elementName, newSection, setResourceState);
    }
  }), [endSectionState]);

  if (!openEditModalState.openModal) {
    return <div>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingSection.text?.div &&
          <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
        {startingSection.entry?.length > 0 && <>
          {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
            :
            <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
        </>}
        <br />
      </div>}
      &nbsp;&nbsp;
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setOpenEditModalState({ openModal: true });
        }}>
        {startingValue ? <>✎ Edit</> : <>➕ Add</>}
      </span>
    </div>
  } else {
    return <EditTableCellModal endSectionState={endSectionState}
      entryStartingResourceType={"Evidence"} resourceDictionary={resourceDictionary}
      setOpenEditModalState={setOpenEditModalState} globalContext={globalContext}
      generateTextDivFunction={generateEvidenceSummary} sectionCode={parentSectionCode}
      setResourceState={setEndSectionState} setProfile={entrySetProfile}
      statisticType={statisticType} statisticUnit={statisticUnit}
      knownDataCount={knownDataCount} entryHeader={entryHeader}
      entryAddElementValues={entryAddElementValues}
      setSourceJsonState={setSourceJsonState} resourceState={resourceState}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState}
      singleEvidenceEntry={true} />
  }
});

const generateGroupNarrative = (newGroupJson) => {
  if (newGroupJson) {
    let narrative = "";
    if (newGroupJson.quantity) {
      narrative = newGroupJson.quantity.toString() + " participants";
    }
    let newText = JSON.parse(JSON.stringify(emptyTextNoData));
    if (narrative) {
      let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
      newText = { status: "generated", div: divContainedNarrative };
    }
    return newText;
  }
  return { status: "empty", div: "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>" };
}

const generateEvidenceNarrative = (newEvidenceJson) => {

  if (newEvidenceJson) {
    let description = "";
    let statistic = "";
    if (newEvidenceJson.statistic?.length === 1) {
      if (newEvidenceJson.statistic[0].statisticType && newEvidenceJson.statistic[0].quantity) {
        let statisticTypeDisplay = newEvidenceJson.statistic[0].statisticType.coding?.[0]?.display || newEvidenceJson.statistic[0].statisticType.text || getStringFromFHIR.CodeableConcept(newEvidenceJson.statistic[0].statisticType);
        statistic = statisticTypeDisplay + " " + getStringFromFHIR.Quantity(newEvidenceJson.statistic[0].quantity);
      } else if (newEvidenceJson.statistic[0].description) {
        statistic = newEvidenceJson.statistic[0].description;
      }
      if (newEvidenceJson.statistic[0].attributeEstimate?.[0]?.type) {
        let attributeEstimate = newEvidenceJson.statistic[0].attributeEstimate[0];
        let attribute = "";
        if (attributeEstimate.level) {
          attribute = (attributeEstimate.level * 100).toString() + "% ";
        }
        attribute += attributeEstimate.type.coding?.[0]?.display || attributeEstimate.type.text || getStringFromFHIR.CodeableConcept(attributeEstimate.type);
        if (attributeEstimate.quantity) {
          attribute += " " + getStringFromFHIR.Quantity(attributeEstimate.quantity);
        }
        if (attributeEstimate.range) {
          attribute += " " + getStringFromFHIR.Range(attributeEstimate.range);
        }
        statistic += " (" + attribute + ")";
      }
    }
    if (newEvidenceJson.statistic?.length > 1) {
      for (const stat of newEvidenceJson.statistic) {
        let statisticInstance = "";
        if (stat.category) {
          statisticInstance = stat.category + ": ";
        }
        if (stat.statisticType && stat.quantity) {
          let statisticTypeDisplay = stat.statisticType.coding?.[0]?.display || stat.statisticType.text || getStringFromFHIR.CodeableConcept(stat.statisticType);
          statisticInstance += statisticTypeDisplay + " " + getStringFromFHIR.Quantity(stat.quantity);
        } else if (stat.description) {
          statisticInstance += stat.description;
        }
        if (stat.attributeEstimate?.[0]?.type) {
          let attributeEstimate = stat.attributeEstimate[0];
          let attribute = "";
          if (attributeEstimate.level) {
            attribute = (attributeEstimate.level * 100).toString() + "% ";
          }
          attribute += attributeEstimate.type.coding?.[0]?.display || attributeEstimate.type.text || getStringFromFHIR.CodeableConcept(attributeEstimate.type);
          if (attributeEstimate.quantity) {
            attribute += " " + getStringFromFHIR.Quantity(attributeEstimate.quantity);
          }
          if (attributeEstimate.range) {
            attribute += " " + getStringFromFHIR.Range(attributeEstimate.range);
          }
          statisticInstance += " (" + attribute + ")";
        }
        if (statistic) {
          statistic += "; " + statisticInstance;
        } else {
          statistic = statisticInstance;
        }
      }
    }
    if (statistic) {
      description = statistic;
    }
    if (!description) {
      description = newEvidenceJson.description || "";
    }
    let narrative = description;
    let newText = JSON.parse(JSON.stringify(emptyTextNoData));
    if (narrative) {
      let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
      newText = { status: "generated", div: divContainedNarrative };
    }
    return newText;
  }
  return { status: "empty", div: "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>" };
}

//generate text.div from entry values for the table row sent as sections and returned as newRowSections
const generateTableRowContent = (sections, resourceDictionary = {}) => {

  let sourceReferences = {
    screenedGroupEvidence: sections?.screenedSection?.entry?.[0] || "",
    enrolledGroupEvidence: sections?.enrolledSection?.entry?.[0] || "",
    analyzedGroupEvidence: sections?.analyzedSection?.entry?.[0] || "",
    interventionOnlyEvidence: sections?.interventionSection?.entry?.[0] || "",
    comparatorOnlyEvidence: sections?.comparatorSection?.entry?.[0] || "",
    comparativeEvidence: sections?.comparativeSection?.entry?.[0] || ""
  };
  let newRowSections = JSON.parse(JSON.stringify(sections));
  if (Object.keys(newRowSections).length > 0) {
    let screenedGroupEvidenceJson;
    let enrolledGroupEvidenceJson;
    let analyzedGroupEvidenceJson;
    let comparatorOnlyEvidenceJson;
    let interventionOnlyEvidenceJson;
    let comparativeEvidenceJson;
    if (sourceReferences.enrolledGroupEvidence) {
      let groupEvidenceFoi = sourceReferences.enrolledGroupEvidence.reference?.split('/')[1] || "missing";
      if ((groupEvidenceFoi === "missing" || isNaN(groupEvidenceFoi)) &&
        sourceReferences.enrolledGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.enrolledGroupEvidence.identifier.value) {
        let fli = sourceReferences.enrolledGroupEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[fli]) {
          groupEvidenceFoi = resourceDictionary.fliToFoi[fli];
          sourceReferences.enrolledGroupEvidence.reference = "Evidence/" + groupEvidenceFoi;
        }
      }

      if (groupEvidenceFoi) {
        enrolledGroupEvidenceJson = resourceDictionary[groupEvidenceFoi];
      }
    }
    if (sourceReferences.screenedGroupEvidence) {
      let groupEvidenceFoi = sourceReferences.screenedGroupEvidence.reference?.split('/')[1] || "missing";
      if ((groupEvidenceFoi === "missing" || isNaN(groupEvidenceFoi)) &&
        sourceReferences.screenedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.screenedGroupEvidence.identifier.value) {
        let fli = sourceReferences.screenedGroupEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[fli]) {
          groupEvidenceFoi = resourceDictionary.fliToFoi[fli];
          sourceReferences.screenedGroupEvidence.reference = "Evidence/" + groupEvidenceFoi;
        }
      }

      if (groupEvidenceFoi) {
        screenedGroupEvidenceJson = resourceDictionary[groupEvidenceFoi];
      }
    }
    if (sourceReferences.analyzedGroupEvidence) {
      let groupEvidenceFoi = sourceReferences.analyzedGroupEvidence.reference?.split('/')[1] || "missing";
      if ((groupEvidenceFoi === "missing" || isNaN(groupEvidenceFoi)) &&
        sourceReferences.analyzedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.analyzedGroupEvidence.identifier.value) {
        let fli = sourceReferences.analyzedGroupEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[fli]) {
          groupEvidenceFoi = resourceDictionary.fliToFoi[fli];
          sourceReferences.analyzedGroupEvidence.reference = "Evidence/" + groupEvidenceFoi;
        }
      }

      if (groupEvidenceFoi) {
        analyzedGroupEvidenceJson = resourceDictionary[groupEvidenceFoi];
      }
    }
    if (sourceReferences.comparatorOnlyEvidence) {
      let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
        sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparatorOnlyEvidence.identifier.value) {
        let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
          comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
          sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
        }
      }
      if (comparatorOnlyEvidenceFoi) {
        comparatorOnlyEvidenceJson = resourceDictionary[comparatorOnlyEvidenceFoi];
      }
    }
    if (sourceReferences.interventionOnlyEvidence) {
      let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
        sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.interventionOnlyEvidence.identifier.value) {
        let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
          interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
          sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
        }
      }
      if (interventionOnlyEvidenceFoi) {
        interventionOnlyEvidenceJson = resourceDictionary[interventionOnlyEvidenceFoi];
      }
    }
    if (sourceReferences.comparativeEvidence) {
      let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
      if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
        sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparativeEvidence.identifier.value) {
        let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
          comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
          sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
        }
      }
      if (comparativeEvidenceFoi) {
        comparativeEvidenceJson = resourceDictionary[comparativeEvidenceFoi];
      }
    }

    if (screenedGroupEvidenceJson) {
      newRowSections.screenedSection.entry = [sourceReferences.screenedGroupEvidence];
      newRowSections.screenedSection.text = generateEvidenceNarrative(screenedGroupEvidenceJson);
    }
    if (enrolledGroupEvidenceJson) {
      newRowSections.enrolledSection.entry = [sourceReferences.enrolledGroupEvidence];
      newRowSections.enrolledSection.text = generateEvidenceNarrative(enrolledGroupEvidenceJson);
    }
    if (analyzedGroupEvidenceJson) {
      newRowSections.analyzedSection.entry = [sourceReferences.analyzedGroupEvidence];
      newRowSections.analyzedSection.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
    }
    if (comparatorOnlyEvidenceJson) {
      newRowSections.comparatorSection.entry = [sourceReferences.comparatorOnlyEvidence];
      newRowSections.comparatorSection.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
    }
    if (interventionOnlyEvidenceJson) {
      newRowSections.interventionSection.entry = [sourceReferences.interventionOnlyEvidence];
      newRowSections.interventionSection.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
    }
    if (comparativeEvidenceJson) {
      newRowSections.comparativeSection.entry = [sourceReferences.comparativeEvidence];
      newRowSections.comparativeSection.text = generateEvidenceNarrative(comparativeEvidenceJson);
    }
    return newRowSections;
  } else {
    console.log('newRowSections is not an object.')
    console.log(newRowSections);
  }
};

const generateResultsTableContent = (oldSectionSet, resourceDictionary = {}) => {
  let newSectionSet = [];
  for (let row of oldSectionSet) {
    let newRow = JSON.parse(JSON.stringify(row));
    let sourceReferences = {
      screenedGroupEvidence: "",
      enrolledGroupEvidence: "",
      analyzedGroupEvidence: "",
      interventionOnlyEvidence: "",
      comparatorOnlyEvidence: "",
      comparativeEvidence: "",
      populationGroup: "",
      interventionGroup: "",
      comparatorGroup: ""
    };
    for (let section of row.section) {
      let sectionCode;
      if (section?.code) {
        sectionCode = section.code.coding?.[0]?.code || section.code.text;
      }
      if (sectionCode === "evidence-for-enrolled-group" || sectionCode === "Evidence for Enrolled Group") {
        if (section.entry?.length > 0) {
          sourceReferences.enrolledGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "evidence-for-screened-group") {
        if (section.entry?.length > 0) {
          sourceReferences.screenedGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "evidence-for-analyzed-group" || sectionCode === "Evidence for Analyzed Group" ||
        sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" ||
        sectionCode === "Evidence with total group") {
        if (section.entry?.length > 0) {
          sourceReferences.analyzedGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-comparator-alone" || sectionCode === "Control-group-alone-Evidence" ||
        sectionCode === "evidence-with-comparator-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.comparatorOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-intervention-alone" || sectionCode === "Intervention-group-alone-Evidence" ||
        sectionCode === "evidence-with-intervention-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.interventionOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "comparative-results" || sectionCode === "result-with-intervention-vs-comparator" ||
        sectionCode === "Intervention-vs-Control-Evidence" ||
        sectionCode === "evidence-with-intervention-vs-comparator") {
        if (section.entry?.length > 0) {
          sourceReferences.comparativeEvidence = section.entry[0];
        }
      }
      if (sectionCode === "population") {
        if (section.entry?.length > 0) {
          sourceReferences.populationGroup = section.entry[0];
        }
      }
      if (sectionCode === "intervention-group") {
        if (section.entry?.length > 0) {
          sourceReferences.interventionGroup = section.entry[0];
        }
      }
      if (sectionCode === "comparator-group") {
        if (section.entry?.length > 0) {
          sourceReferences.comparatorGroup = section.entry[0];
        }
      }
    }

    let newRowSections = newRow.section;
    if (Array.isArray(newRowSections) && newRowSections.length > 0) {
      let screenedGroupEvidenceJson;
      let enrolledGroupEvidenceJson;
      let analyzedGroupEvidenceJson;
      let comparatorOnlyEvidenceJson;
      let interventionOnlyEvidenceJson;
      let comparativeEvidenceJson;
      let populationGroupJson;
      let interventionGroupJson;
      let comparatorGroupJson;
      if (sourceReferences.enrolledGroupEvidence) {
        let enrolledGroupEvidenceFoi = sourceReferences.enrolledGroupEvidence.reference?.split('/')[1] || "missing";
        if ((enrolledGroupEvidenceFoi === "missing" || isNaN(enrolledGroupEvidenceFoi)) &&
          sourceReferences.enrolledGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.enrolledGroupEvidence.identifier.value) {
          let fli = sourceReferences.enrolledGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            enrolledGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.enrolledGroupEvidence.reference = "Evidence/" + enrolledGroupEvidenceFoi;
          }
        }

        if (resourceDictionary[enrolledGroupEvidenceFoi]) {
          enrolledGroupEvidenceJson = resourceDictionary[enrolledGroupEvidenceFoi];
        }
      }
      if (sourceReferences.screenedGroupEvidence) {
        let screenedGroupEvidenceFoi = sourceReferences.screenedGroupEvidence.reference?.split('/')[1] || "missing";
        if ((screenedGroupEvidenceFoi === "missing" || isNaN(screenedGroupEvidenceFoi)) &&
          sourceReferences.screenedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.screenedGroupEvidence.identifier.value) {
          let fli = sourceReferences.screenedGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            screenedGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.screenedGroupEvidence.reference = "Evidence/" + screenedGroupEvidenceFoi;
          }
        }
        if (resourceDictionary[screenedGroupEvidenceFoi]) {
          screenedGroupEvidenceJson = resourceDictionary[screenedGroupEvidenceFoi];
        }
      }
      if (sourceReferences.analyzedGroupEvidence) {
        let analyzedGroupEvidenceFoi = sourceReferences.analyzedGroupEvidence.reference?.split('/')[1] || "missing";
        if ((analyzedGroupEvidenceFoi === "missing" || isNaN(analyzedGroupEvidenceFoi)) &&
          sourceReferences.analyzedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.analyzedGroupEvidence.identifier.value) {
          let fli = sourceReferences.analyzedGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            analyzedGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.analyzedGroupEvidence.reference = "Evidence/" + analyzedGroupEvidenceFoi;
          }
        }

        if (resourceDictionary[analyzedGroupEvidenceFoi]) {
          analyzedGroupEvidenceJson = resourceDictionary[analyzedGroupEvidenceFoi];
        }
      }
      if (sourceReferences.comparatorOnlyEvidence) {
        let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
          sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparatorOnlyEvidence.identifier.value) {
          let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
            comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
            sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[comparatorOnlyEvidenceFoi]) {
          comparatorOnlyEvidenceJson = resourceDictionary[comparatorOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.interventionOnlyEvidence) {
        let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
          sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.interventionOnlyEvidence.identifier.value) {
          let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
            interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
            sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[interventionOnlyEvidenceFoi]) {
          interventionOnlyEvidenceJson = resourceDictionary[interventionOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.comparativeEvidence) {
        let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
        if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
          sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparativeEvidence.identifier.value) {
          let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
            comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
            sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
          }
        }
        if (resourceDictionary[comparativeEvidenceFoi]) {
          comparativeEvidenceJson = resourceDictionary[comparativeEvidenceFoi];
        }
      }
      if (sourceReferences.populationGroup) {
        let populationGroupFoi = sourceReferences.populationGroup.reference?.split('/')[1] || "missing";
        if ((populationGroupFoi === "missing" || isNaN(populationGroupFoi)) &&
          sourceReferences.populationGroup.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.populationGroup.identifier.value) {
          let fli = sourceReferences.populationGroup.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            populationGroupFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.populationGroup.reference = "Group/" + populationGroupFoi;
          }
        }

        if (resourceDictionary[populationGroupFoi]) {
          populationGroupJson = resourceDictionary[populationGroupFoi];
        }
      }
      if (sourceReferences.interventionGroup) {
        let interventionGroupFoi = sourceReferences.interventionGroup.reference?.split('/')[1] || "missing";
        if ((interventionGroupFoi === "missing" || isNaN(interventionGroupFoi)) &&
          sourceReferences.interventionGroup.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.interventionGroup.identifier.value) {
          let fli = sourceReferences.interventionGroup.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            interventionGroupFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.interventionGroup.reference = "Group/" + interventionGroupFoi;
          }
        }

        if (resourceDictionary[interventionGroupFoi]) {
          interventionGroupJson = resourceDictionary[interventionGroupFoi];
        }
      }
      if (sourceReferences.comparatorGroup) {
        let comparatorGroupFoi = sourceReferences.comparatorGroup.reference?.split('/')[1] || "missing";
        if ((comparatorGroupFoi === "missing" || isNaN(comparatorGroupFoi)) &&
          sourceReferences.comparatorGroup.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparatorGroup.identifier.value) {
          let fli = sourceReferences.comparatorGroup.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            comparatorGroupFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.comparatorGroup.reference = "Group/" + comparatorGroupFoi;
          }
        }

        if (resourceDictionary[comparatorGroupFoi]) {
          comparatorGroupJson = resourceDictionary[comparatorGroupFoi];
        }
      }

      for (let section of newRowSections) {
        if (section.code?.coding && section.code.coding[0].code) {
          switch (section.code.coding[0].code) {
            case "evidence-for-screened-group":
              if (screenedGroupEvidenceJson) {
                section.entry = [sourceReferences.screenedGroupEvidence];
                section.text = generateEvidenceNarrative(screenedGroupEvidenceJson);
              }
              break;
            case "evidence-for-enrolled-group":
              if (enrolledGroupEvidenceJson) {
                section.entry = [sourceReferences.enrolledGroupEvidence];
                section.text = generateEvidenceNarrative(enrolledGroupEvidenceJson);
              }
              break;
            case "evidence-for-analyzed-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.analyzedGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "evidence-with-total-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.analyzedGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "result-with-total-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "Evidence with total group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "result-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "evidence-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "Control-group-alone-Evidence":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "result-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "evidence-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "Intervention-group-alone-Evidence":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "comparative-results":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "result-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "evidence-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "Intervention-vs-Control-Evidence":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "population":
              if (populationGroupJson) {
                section.entry = [sourceReferences.populationGroup];
                section.text = generateGroupNarrative(populationGroupJson);
              }
              break;
            case "intervention-group":
              if (interventionGroupJson) {
                section.entry = [sourceReferences.interventionGroup];
                section.text = generateGroupNarrative(interventionGroupJson);
              }
              break;
            case "comparator-group":
              if (comparatorGroupJson) {
                section.entry = [sourceReferences.comparatorGroup];
                section.text = generateGroupNarrative(comparatorGroupJson);
              }
              break;
            default:
              console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
          }
        }
      }
    } else {
      console.log('newRowSections is not an array.')
      console.log(newRowSections);
    }
    newSectionSet.push(newRow);
  }
  return newSectionSet;
}

const InnerSectionArrayDataEntry = memo(({ startingValue, setResourceState,
  interventionGroupReference, setSourceJsonState, sampleSizeDefaultState,
  analyzedGroupReference, enrolledGroupReference, screenedGroupReference,
  populationSynthesisGroupReference, interventionSynthesisGroupReference, comparatorSynthesisGroupReference,
  comparatorGroupReference, groupAssignmentReference, comparatorCategory, resourceDictionary,
  researchStudyRelatedArtfifact, resourceState, globalContext,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode }) => {

  let analyzedSection = null;
  let enrolledSection = null;
  let screenedSection = null;
  let populationSynthesisGroupSection = null;
  let interventionSynthesisGroupSection = null;
  let comparatorSynthesisGroupSection = null;
  let comparatorSection = null;
  let interventionSection = null;
  let comparativeSection = null;
  let startingSectionStateValues = {};
  let defaultStatisticType = "";
  let defaultStatisticUnits = "";
  if (startingValue) {
    for (let itemIndex in startingValue.section) {
      let subsection = startingValue.section[itemIndex];
      let subsectionCode = subsection?.code?.coding?.[0]?.code;
      if (subsectionCode === "evidence-with-comparator-alone") {
        comparatorSection = subsection;
        startingSectionStateValues["comparatorSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-intervention-alone") {
        interventionSection = subsection;
        startingSectionStateValues["interventionSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "population") {
        populationSynthesisGroupSection = subsection;
        startingSectionStateValues["populationSynthesisGroupSection"] = subsection;
      } else if (subsectionCode === "intervention-group") {
        interventionSynthesisGroupSection = subsection;
        startingSectionStateValues["interventionSynthesisGroupSection"] = subsection;
      } else if (subsectionCode === "comparator-group") {
        comparatorSynthesisGroupSection = subsection;
        startingSectionStateValues["comparatorSynthesisGroupSection"] = subsection;
      } else if (subsectionCode === "comparative-results") {
        comparativeSection = subsection;
        startingSectionStateValues["comparativeSection"] = subsection;
      } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
        comparativeSection = subsection;
        startingSectionStateValues["comparativeSection"] = subsection;
      } else if (subsectionCode === "evidence-for-screened-group") {
        screenedSection = subsection;
        startingSectionStateValues["screenedSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-for-enrolled-group") {
        enrolledSection = subsection;
        startingSectionStateValues["enrolledSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-for-analyzed-group") {
        analyzedSection = subsection;
        startingSectionStateValues["analyzedSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      }
    }
  }
  let screenedVariableDefinition = [
    {
      "variableRole": "population",
      "observed": screenedGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedScreenedGroupEvidenceTitle = startingValue.focus.display + " for screened group";
  let analyzedVariableDefinition = [
    {
      "variableRole": "population",
      "observed": analyzedGroupReference || populationSynthesisGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedAnalyzedGroupEvidenceTitle = startingValue.focus.display + " for analyzed group";
  let enrolledVariableDefinition = [
    {
      "variableRole": "population",
      "observed": enrolledGroupReference || populationSynthesisGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedEnrolledGroupEvidenceTitle = startingValue.focus.display + " for enrolled group";
  let comparatorVariableDefinition = [
    {
      "variableRole": "population",
      "observed": comparatorGroupReference || comparatorSynthesisGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparatorEvidenceTitle = startingValue.focus.display + " with comparator";
  let interventionVariableDefinition = [
    {
      "variableRole": "population",
      "observed": interventionGroupReference || interventionSynthesisGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedInterventionEvidenceTitle = startingValue.focus.display + " with intervention";
  let comparativeVariableDefinition = [
    {
      "variableRole": "population",
      "observed": analyzedGroupReference || enrolledGroupReference || populationSynthesisGroupReference
    },
    {
      "variableRole": "exposure",
      "comparatorCategory": comparatorCategory || "",
      "observed": groupAssignmentReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparativeEvidenceTitle = startingValue.focus.display + " comparing intervention vs. comparator";

  const [sectionState, setSectionState] = useState(JSON.parse(JSON.stringify(startingSectionStateValues || null)));
  const [statisticDefaultsState, setStatisticDefaultsState] = useState({
    "statisticType": "", "units": ""
  });

  useEffect(() => {
    let newSection = [];
    for (const key in sectionState) {
      newSection.push(sectionState[key]);
    }
    if (newSection && Object.keys(newSection).length) {
      setResourceState(prevState => { return { ...prevState, "section": newSection } });
    }
  }, [sectionState]);

  return <>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {startingValue.title || startingValue.focus?.display}
      <p><span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setStatisticDefaultsState({
            "statisticType": defaultStatisticType || "",
            "units": defaultStatisticUnits || ""
          })
        }}>
        {(defaultStatisticType && !statisticDefaultsState.statisticType) &&
          <>➕ Add Default Statistic Type {getStringFromFHIR.CodeableConcept(defaultStatisticType)} and Units {defaultStatisticUnits || "none"}</>}
      </span></p>
      <br />
      <span>
        <div><br />
          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
            className="formButton"
            positive
            content={"Regenerate Table Row"}
            onClick={() => {
              let newRowSections = generateTableRowContent(sectionState, resourceDictionary);
              setSectionState(newRowSections);
            }}
          />
        </div>
      </span>
    </Table.Cell>
    {populationSynthesisGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {populationSynthesisGroupSection && <InnerSectionDataEntry startingValue={populationSynthesisGroupSection}
        elementName={"populationSynthesisGroupSection"} setResourceState={setSectionState}
        entryHeader="population" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={analyzedVariableDefinition}
        suggestedEvidenceTitle={suggestedAnalyzedGroupEvidenceTitle}
        resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.analyzedKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={6} />}
    </Table.Cell>}
    {interventionSynthesisGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {interventionSynthesisGroupSection && <InnerSectionDataEntry startingValue={interventionSynthesisGroupSection}
        elementName={"interventionSynthesisGroupSection"} setResourceState={setSectionState}
        entryHeader="intervention group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={interventionVariableDefinition}
        suggestedEvidenceTitle={suggestedInterventionEvidenceTitle}
        resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.interventionKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={7} />}
    </Table.Cell>}
    {comparatorSynthesisGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {comparatorSynthesisGroupSection && <InnerSectionDataEntry startingValue={comparatorSynthesisGroupSection}
        elementName={"comparatorSynthesisGroupSection"} setResourceState={setSectionState}
        entryHeader="comparator group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={comparatorVariableDefinition}
        suggestedEvidenceTitle={suggestedComparatorEvidenceTitle}
        resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.comparatorKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={8} />}
    </Table.Cell>}
    {screenedGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {screenedSection && <InnerSectionDataEntry startingValue={screenedSection}
        elementName={"screenedSection"} setResourceState={setSectionState}
        entryHeader="screened group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={screenedVariableDefinition}
        suggestedEvidenceTitle={suggestedScreenedGroupEvidenceTitle}
        resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.screenedKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={0} />}
    </Table.Cell>}
    {enrolledGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {enrolledSection && <InnerSectionDataEntry startingValue={enrolledSection}
        elementName={"enrolledSection"} setResourceState={setSectionState}
        entryHeader="enrolled group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={enrolledVariableDefinition}
        suggestedEvidenceTitle={suggestedEnrolledGroupEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.enrolledKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={1} />}
    </Table.Cell>}
    {analyzedGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {analyzedSection && <>
        <InnerSectionDataEntry startingValue={analyzedSection}
          elementName={"analyzedSection"} setResourceState={setSectionState}
          entryHeader="total (analyzed) group" entrySetProfile={"NoncomparativeEvidence"}
          variableDefinition={analyzedVariableDefinition}
          suggestedEvidenceTitle={suggestedAnalyzedGroupEvidenceTitle}
          resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          statisticType={statisticDefaultsState.statisticType}
          statisticUnit={statisticDefaultsState.units}
          knownDataCount={sampleSizeDefaultState.analyzedKnownDataCount}
          resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={2} />
      </>}
    </Table.Cell>}
    {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {interventionSection && <InnerSectionDataEntry startingValue={interventionSection}
        elementName={"interventionSection"} setResourceState={setSectionState}
        entryHeader="intervention group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={interventionVariableDefinition}
        suggestedEvidenceTitle={suggestedInterventionEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.interventionKnownDataCount}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={3} />}
    </Table.Cell>}
    {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {comparatorSection && <InnerSectionDataEntry startingValue={comparatorSection}
        elementName={"comparatorSection"} setResourceState={setSectionState}
        entryHeader="comparator group" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={comparatorVariableDefinition}
        suggestedEvidenceTitle={suggestedComparatorEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        knownDataCount={sampleSizeDefaultState.comparatorKnownDataCount} resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={4} />}
    </Table.Cell>}
    {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
      {comparativeSection && <InnerSectionDataEntry startingValue={comparativeSection}
        elementName={"comparativeSection"} setResourceState={setSectionState}
        entryHeader="comparison (intevention vs. comparator)" entrySetProfile={"ComparativeEvidence"}
        variableDefinition={comparativeVariableDefinition}
        suggestedEvidenceTitle={suggestedComparativeEvidenceTitle} resourceDictionary={resourceDictionary}
        researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
        knownDataCount={sampleSizeDefaultState.comparativeKnownDataCount} resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={5} />}
    </Table.Cell>}
  </>
});

const TableRowEdit = memo(({ startingValue, setResourceState, sectionArrayIndex,
  setSourceJsonState, sampleSizeDefaultState,
  interventionGroupReference, comparatorGroupReference, groupAssignmentReference,
  screenedGroupReference, enrolledGroupReference, analyzedGroupReference,
  populationSynthesisGroupReference, interventionSynthesisGroupReference, comparatorSynthesisGroupReference,
  comparatorGroupDisplay, resourceDictionary, globalContext, researchStudyRelatedArtfifact,
  resourceState, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let parentSectionCode = "section[" + sectionArrayIndex + "]:";

  let startingSection = {
    title: "", code: sectionCodeSummaryOfFindingsForSingleOutcome,
    author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (startingValue) {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [tableRowSectionState, setTableRowSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  /*
    useEffect(() => {
      if (startingValue.title !== tableRowSectionState.title) {
      let startingSection = {
      title: "", code: "", author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
        }
    if (startingValue) {
          if (startingValue.extension) {startingSection.extension = startingValue.extension; }
    if (startingValue.title) {startingSection.title = startingValue.title; }
    if (startingValue.code) {startingSection.code = startingValue.code; }
    if (startingValue.author) {startingSection.author = startingValue.author; }
    if (startingValue.focus) {startingSection.focus = startingValue.focus; }
    if (startingValue.text) {startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) {startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) {startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) {startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {startingSection.section = startingValue.section; }
        }
    setTableRowSectionState(JSON.parse(JSON.stringify(startingSection)));
      }
    }, [startingValue]);
    */
  useEffect((() => {
    if (Object.keys(tableRowSectionState).length > 0) {
      let newSection = {};
      if (tableRowSectionState.extension) { newSection.extension = tableRowSectionState.extension; }
      if (tableRowSectionState.title) { newSection.title = tableRowSectionState.title; }
      if (tableRowSectionState.code) { newSection.code = tableRowSectionState.code; }
      if (Array.isArray(tableRowSectionState.author) && tableRowSectionState.author.length > 0) {
        newSection.author = tableRowSectionState.author;
      }
      if (tableRowSectionState.focus) { newSection.focus = tableRowSectionState.focus; }
      if (tableRowSectionState.text) { newSection.text = tableRowSectionState.text; }
      if (tableRowSectionState.orderedBy) { newSection.orderedBy = tableRowSectionState.orderedBy; }
      if (Array.isArray(tableRowSectionState.entry) && tableRowSectionState.entry.length > 0) {
        newSection.entry = tableRowSectionState.entry;
      }
      if (tableRowSectionState.emptyReason) { newSection.emptyReason = tableRowSectionState.emptyReason; }
      if (Array.isArray(tableRowSectionState.section) && tableRowSectionState.section.length > 0) {
        newSection.section = tableRowSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0 || (Object.keys(newSection).length === 1 && newSection.code)) {
        newSection = null;
      }
      newSection = JSON.parse(JSON.stringify(newSection));
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          return { ...prevState, [sectionArrayIndex]: newSection };
        });
      }
    }
    //}
  }), [tableRowSectionState]);

  return <InnerSectionArrayDataEntry startingValue={startingSection}
    setResourceState={setTableRowSectionState}
    populationSynthesisGroupReference={populationSynthesisGroupReference}
    interventionSynthesisGroupReference={interventionSynthesisGroupReference}
    comparatorSynthesisGroupReference={comparatorSynthesisGroupReference}
    interventionGroupReference={interventionGroupReference}
    comparatorGroupReference={comparatorGroupReference}
    groupAssignmentReference={groupAssignmentReference}
    screenedGroupReference={screenedGroupReference}
    enrolledGroupReference={enrolledGroupReference}
    analyzedGroupReference={analyzedGroupReference}
    comparatorCategory={comparatorGroupDisplay}
    resourceDictionary={resourceDictionary}
    sampleSizeDefaultState={sampleSizeDefaultState}
    researchStudyRelatedArtfifact={researchStudyRelatedArtfifact} resourceState={resourceState}
    globalContext={globalContext} setSourceJsonState={setSourceJsonState}
    adaptationReportState={adaptationReportState}
    setAdaptationReportState={setAdaptationReportState}
    setChangeAvailableToSaveState={setChangeAvailableToSaveState}
    parentSectionCode={parentSectionCode} />
});

const createStartingArrayAsObject = (resultsSections, variablesList, notStartedSection) => {
  let usableSection = JSON.parse(JSON.stringify(resultsSections));
  if (!usableSection) {
    let variablesDictionary = {};
    let variableReferencesList = [];
    if (variablesList?.length) {
      for (const entry of variablesList) {
        let variableFocus = entry.display || entry.reference;
        if (variableFocus) {
          variablesDictionary[variableFocus] = {
            "title": variableFocus,
            "text": emptyTextNoData,
            "focus": entry,
            "section": notStartedSection
          };
          variableReferencesList.push(variableFocus);
        }
      }
    }

    let includedRowFocusList = [];
    let starterSection = resultsSections || [];
    usableSection = starterSection.filter((row) => {
      let rowFocus = row.focus?.display || row.focus?.reference || row.title;
      let match = false;
      if (rowFocus && variableReferencesList.includes(rowFocus)) {
        match = true;
        includedRowFocusList.push(rowFocus);
      }
      return match;
    });
    for (const rowFocus of variableReferencesList) {
      if (!includedRowFocusList.includes(rowFocus)) {
        let starterSection = variablesDictionary[rowFocus];
        usableSection.push(starterSection);
      }
    }
  }

  let startingArrayAsObject = {};
  if (usableSection) {
    for (let itemIndex in usableSection) {
      startingArrayAsObject[itemIndex] = usableSection[itemIndex];
    }
  }
  return startingArrayAsObject;
}

const EvidenceTableEdit = ({ resultsSections, setAllSectionsState, setSourceJsonState,
  groupsState, groupAssignmentState, variablesState, reportType,
  resourceDictionary, globalContext, resourceState }) => {

  let screenedGroupReference = null;
  let enrolledGroupReference = null;
  let analyzedGroupReference = null;
  let interventionGroupReference = null;
  let comparatorGroupReference = null;
  let groupAssignmentReference = null;
  let researchStudyRelatedArtfifact;
  let screenedKnownDataCount = "";
  let enrolledKnownDataCount = "";
  let analyzedKnownDataCount = "";
  let interventionKnownDataCount = "";
  let comparatorKnownDataCount = "";
  let comparativeKnownDataCount = "";

  if (groupsState) {
    if (groupsState.interventionGroup) {
      interventionGroupReference = groupsState.interventionGroup;
      if (interventionGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[interventionGroupReference.reference.split("/")[1]]) {
        let groupJson = resourceDictionary[interventionGroupReference.reference.split("/")[1]];
        if (groupJson.quantity) {
          interventionKnownDataCount = groupJson.quantity;
        }
      }
    }
    if (groupsState.comparatorGroup) {
      comparatorGroupReference = groupsState.comparatorGroup;
      if (comparatorGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[comparatorGroupReference.reference.split("/")[1]]) {
        let groupJson = resourceDictionary[comparatorGroupReference.reference.split("/")[1]];
        if (groupJson.quantity) {
          comparatorKnownDataCount = groupJson.quantity;
        }
      }
    }
    if (groupsState.screenedGroup) {
      screenedGroupReference = groupsState.screenedGroup;
      if (screenedGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[screenedGroupReference.reference.split("/")[1]]) {
        let groupJson = resourceDictionary[screenedGroupReference.reference.split("/")[1]];
        if (groupJson.quantity) {
          screenedKnownDataCount = groupJson.quantity;
        }
      }
    }
    if (groupsState.enrolledGroup) {
      enrolledGroupReference = groupsState.enrolledGroup;
      if (enrolledGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[enrolledGroupReference.reference.split("/")[1]]) {
        let groupJson = resourceDictionary[enrolledGroupReference.reference.split("/")[1]];
        if (groupJson.quantity) {
          enrolledKnownDataCount = groupJson.quantity;
        }
      }
    }
    if (groupsState.analyzedGroup) {
      analyzedGroupReference = groupsState.analyzedGroup;
      if (analyzedGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[analyzedGroupReference.reference.split("/")[1]]) {
        let groupJson = resourceDictionary[analyzedGroupReference.reference.split("/")[1]];
        if (groupJson.quantity) {
          analyzedKnownDataCount = groupJson.quantity;
        }
      }
    }
  }
  if (groupAssignmentState?.groupAssignment) {
    groupAssignmentReference = groupAssignmentState.groupAssignment;
  }
  if (interventionKnownDataCount && comparatorKnownDataCount) {
    comparativeKnownDataCount = interventionKnownDataCount + comparatorKnownDataCount;
  } else if (analyzedKnownDataCount) {
    comparativeKnownDataCount = analyzedKnownDataCount;
  } else if (enrolledKnownDataCount) {
    comparativeKnownDataCount = enrolledKnownDataCount;
  }

  const [arrayState, setArrayState] = useState(null);
  const [startingArrayState, setStartingArrayState] = useState(null);

  const [sampleSizeDefaultState, setSampleSizeDefaultState] = useState({});

  let notStartedSection = [
    {
      "code": sectionCodeEvidenceForScreenedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceForEnrolledGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceForAnalyzedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithInterventionAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithComparatorAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeComparativeResults,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    }
  ];

  if (reportType === "Outcome Meaasure") {
    notStartedSection.push({
      "code": sectionCodePopulation,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    });
    notStartedSection.push({
      "code": sectionCodeInterventionGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    });
    notStartedSection.push({
      "code": sectionCodeComparatorGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    });
  }

  useEffect(() => {
    if (variablesState?.variablesList) {
      let startingArrayAsObject = createStartingArrayAsObject(resultsSections, variablesState.variablesList, notStartedSection);
      setArrayState(startingArrayAsObject);
      setStartingArrayState(startingArrayAsObject);
    }
  }, [resultsSections, variablesState]);

  useEffect((() => {
    if (arrayState && Object.keys(arrayState).length > 0 && startingArrayState &&
      JSON.stringify(arrayState) !== JSON.stringify(startingArrayState)) {
      let variablesDictionary = {};
      let variablesList = variablesState?.variablesList || [];
      if (variablesList?.length) {
        for (const entry of variablesList) {
          let variableFocus = entry.display || entry.reference;
          if (variableFocus) {
            variablesDictionary[variableFocus] = {
              "title": variableFocus,
              "text": emptyTextNoData,
              "focus": entry,
              "section": notStartedSection
            };
          }
        }
      }
      let oldArrayStateDictionary = {};
      for (const index in arrayState) {
        let focus = arrayState[index].focus?.display || arrayState[index].focus?.reference || arrayState[index].title;
        oldArrayStateDictionary[focus] = JSON.parse(JSON.stringify(arrayState[index]));
      }
      let newArrayStateDictionary = {};
      for (const focus of Object.keys(oldArrayStateDictionary)) {
        newArrayStateDictionary[focus] = oldArrayStateDictionary[focus];
      }
      for (const focus of Object.keys(variablesDictionary)) {
        if (!oldArrayStateDictionary[focus]) {
          newArrayStateDictionary[focus] = variablesDictionary[focus];
        }
      }
      if (Object.keys(newArrayStateDictionary).length > 0) {
        let newArray = [];
        for (let key of Object.keys(newArrayStateDictionary)) {
          if (typeof newArrayStateDictionary[key] === "object" && Object.keys(newArrayStateDictionary[key]).length > 0) {
            newArray.push(newArrayStateDictionary[key]);
          }
        }
        if (newArray.length > 0) {
          setAllSectionsState(prevState => { return { ...prevState, resultsSections: newArray } });
        }
      }

    }
  }), [arrayState]);

  return <>{arrayState && <>
    {(Object.keys(arrayState).length > 0) && <>
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
        className="formButton"
        positive
        content={"Regenerate Table Content"}
        onClick={() => {
          if (variablesState?.variablesList?.length) {
            let oldSectionSet = JSON.parse(JSON.stringify(resultsSections));
            let newSectionSet = generateResultsTableContent(oldSectionSet, resourceDictionary);
            if (newSectionSet.length) {
              setAllSectionsState(prevState => {
                return { ...prevState, "resultsSections": newSectionSet }
              });
            } else {
              alert("problem creating newSectionSet");
            }
          } else {
            alert("missing variablesState.variablesList.length");
          }
        }}
      />
    </>}
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {groupsState.populationSynthesisGroup &&
            <Table.HeaderCell>Population</Table.HeaderCell>}
          {groupsState.interventionSynthesisGroup &&
            <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {groupsState.comparatorSynthesisGroup &&
            <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {groupsState.screenedGroup &&
            <Table.HeaderCell>Screened Group</Table.HeaderCell>}
          {groupsState.enrolledGroup &&
            <Table.HeaderCell>Enrolled Group</Table.HeaderCell>}
          {groupsState.analyzedGroup &&
            <Table.HeaderCell>Analyzed Group</Table.HeaderCell>}
          {groupsState.interventionGroup &&
            <Table.HeaderCell>With Intervention</Table.HeaderCell>}
          {groupsState.comparatorGroup &&
            <Table.HeaderCell>With Comparator</Table.HeaderCell>}
          {groupAssignmentState.groupAssignment &&
            <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {groupsState.populationSynthesisGroup &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {groupsState.populationSynthesisGroup.display || groupsState.populationSynthesisGroup.reference}
            </Table.Cell>}
          {groupsState.interventionSynthesisGroup &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {groupsState.interventionSynthesisGroup.display || groupsState.interventionSynthesisGroup.reference}
            </Table.Cell>}
          {groupsState.comparatorSynthesisGroup &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {groupsState.comparatorSynthesisGroup.display || groupsState.comparatorSynthesisGroup.reference}
            </Table.Cell>}
          {groupsState.screenedGroup && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupsState.screenedGroup.display || groupsState.screenedGroup.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "screenedKnownDataCount": screenedKnownDataCount,
                    "screenedKnownDataCountSelected": true
                  }
                })
              }}>
              {(screenedKnownDataCount && !sampleSizeDefaultState.screenedKnownDataCountSelected) &&
                <>➕ Add {screenedKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupsState.enrolledGroup && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupsState.enrolledGroup.display || groupsState.enrolledGroup.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "enrolledKnownDataCount": enrolledKnownDataCount,
                    "enrolledKnownDataCountSelected": true
                  }
                })
              }}>
              {(enrolledKnownDataCount && !sampleSizeDefaultState.enrolledKnownDataCountSelected) &&
                <>➕ Add {enrolledKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {(groupsState.analyzedGroup) && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupsState.analyzedGroup.display || groupsState.analyzedGroup.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "analyzedKnownDataCount": analyzedKnownDataCount,
                    "analyzedKnownDataCountSelected": true
                  }
                })
              }}>
              {((analyzedKnownDataCount) && !sampleSizeDefaultState.analyzedKnownDataCountSelected) &&
                <>➕ Add {(analyzedKnownDataCount)} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupsState.interventionGroup && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupsState.interventionGroup.display || groupsState.interventionGroup.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "interventionKnownDataCount": interventionKnownDataCount,
                    "interventionKnownDataCountSelected": true
                  }
                })
              }}>
              {(interventionKnownDataCount && !sampleSizeDefaultState.interventionKnownDataCountSelected) &&
                <>➕ Add {interventionKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupsState.comparatorGroup && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupsState.comparatorGroup.display || groupsState.comparatorGroup.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparatorKnownDataCount": comparatorKnownDataCount,
                    "comparatorKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparatorKnownDataCount && !sampleSizeDefaultState.comparatorKnownDataCountSelected) &&
                <>➕ Add {comparatorKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupAssignmentState.groupAssignment && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupAssignmentState.groupAssignment.display || groupAssignmentState.groupAssignment.reference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparativeKnownDataCount": comparativeKnownDataCount,
                    "comparativeKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparativeKnownDataCount && !sampleSizeDefaultState.comparativeKnownDataCountSelected) &&
                <>➕ Add {comparativeKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
        </Table.Row>
        {(arrayState && Object.keys(arrayState).length > 0) &&
          Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
            return <Table.Row key={keyValuePairIndex}>
              <TableRowEdit startingValue={keyValuePair[1]}
                setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                screenedGroupReference={screenedGroupReference}
                enrolledGroupReference={enrolledGroupReference}
                analyzedGroupReference={analyzedGroupReference}
                interventionGroupReference={interventionGroupReference}
                comparatorGroupReference={comparatorGroupReference}
                groupAssignmentReference={groupAssignmentReference}
                populationSynthesisGroupReference={groupsState.populationSynthesisGroup}
                interventionSynthesisGroupReference={groupsState.interventionSynthesisGroup}
                comparatorSynthesisGroupReference={groupsState.comparatorSynthesisGroup}
                comparatorGroupDisplay={groupsState.comparatorGroup?.display || groupsState.comparatorGroup?.reference}
                resourceDictionary={resourceDictionary}
                researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
                sampleSizeDefaultState={sampleSizeDefaultState} resourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
            </Table.Row>
          })}
      </Table.Body>
    </Table>
  </>}
  </>
};

const GroupsDataEntry = ({ groupsState, setGroupsState, reportType }) => {

  return <div style={{ marginLeft: "24px" }}>
    <h3>Screened Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the Group Resource for the Screened Group.</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='screenedGroup'
          fieldLabel={'Group Resource (for the Screened Group)'}
          startingValue={groupsState.screenedGroup || null} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          startCollapsed enableCreation={true}
          setResourceState={setGroupsState} />
      </div>
    </div>
    <h3>Enrolled Group (Study Population)</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the Group Resource for the Enrolled Group (Study Population).</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='enrolledGroup'
          fieldLabel={'Group Resource for the Enrolled Group (Study Population)'}
          startingValue={groupsState.enrolledGroup} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          startCollapsed enableCreation={true}
          setResourceState={setGroupsState} />
      </div>
    </div>
    <h3>Analyzed Group (Study Population)</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the Group Resource for the Analyzed Group (Study Population).</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='analyzedGroup'
          fieldLabel={'Group Resource for the Analyzed Group (Study Population)'}
          startingValue={groupsState.analyzedGroup} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          startCollapsed enableCreation={true}
          setResourceState={setGroupsState} />
      </div>
    </div>
    {reportType === "Outcome Measure" && <div>
      <h3>Population Definition (Eligibility Criteria)</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>Create or identify the Group Resource for the Population Definition (Study Eligibility Criteria) for synthesis use.</p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='populationSynthesisGroup'
            fieldLabel={'Group Resource for the Population Definition (Eligibility Criteria)'}
            startingValue={groupsState.populationSynthesisGroup} referencedResourceTypes={['Group']}
            startingResourceType="Group"
            selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
            startCollapsed enableCreation={true}
            setResourceState={setGroupsState} />
        </div>
      </div>
      <h3>Intervention Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Intervention Description.</p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='interventionSynthesisGroup'
            fieldLabel={'Group Resource (for the Intervention Description)'}
            startingValue={groupsState.interventionSynthesisGroup} referencedResourceTypes={['Group']}
            startingResourceType="Group"
            selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
            startCollapsed enableCreation={true}
            setResourceState={setGroupsState} />
        </div>
      </div>
      <h3>Comparator Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Comparator Description.</p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='comparatorSynthesisGroup'
            fieldLabel={'Group Resource (for the Comparator Description)'}
            startingValue={groupsState.comparatorSynthesisGroup} referencedResourceTypes={['Group']}
            startingResourceType="Group"
            selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
            startCollapsed enableCreation={true}
            setResourceState={setGroupsState} />
        </div>
      </div>
    </div>}
    <h3>Intervention Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the Group Resource for the Intervention Group.</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='interventionGroup'
          fieldLabel={'Group Resource (for the Intervention Group'}
          startingValue={groupsState.interventionGroup} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          startCollapsed enableCreation={true}
          setResourceState={setGroupsState} />
      </div>
    </div>
    <h3>Comparator Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the Group Resource for the Comparator Group.</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='comparatorGroup'
          fieldLabel={'Group Resource (for the Comparator Group'}
          startingValue={groupsState.comparatorGroup} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          startCollapsed enableCreation={true}
          setResourceState={setGroupsState} />
      </div>
    </div>
  </div>
}

const GroupAssignmentDataEntry = ({ groupAssignmentState, setGroupAssignmentState }) => {

  return <div style={{ marginLeft: "24px" }}>
    <h3>Group Assignment (for Comparison)</h3>
    <div style={{ marginLeft: "24px" }}>
      <p>Create or identify the EvidenceVariable Resource (GroupAssignment Profile) for the key comparison.</p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='groupAssignment'
          fieldLabel={'GroupAssignment EvidenceVariable Resource'}
          startingValue={groupAssignmentState.groupAssignment} referencedResourceTypes={['EvidenceVariable']}
          startingResourceType="EvidenceVariable"
          setProfile="GroupAssignment"
          startCollapsed enableCreation={true}
          setResourceState={setGroupAssignmentState} />
      </div>
    </div>
  </div>
}

const VariablesSectionDataEntry = ({ variablesState, setVariablesState, reportType }) => {

  return <div style={{ marginLeft: "24px" }}>
    <p>Create or identify the EvidenceVariable Resource(s) to organize the Evidence Table.</p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='Reference' elementName='variablesList'
        fieldLabel={'EvidenceVariable Resource (for the ' + reportType}
        startingValue={variablesState.variablesList} referencedResourceTypes={['EvidenceVariable']}
        startingResourceType="EvidenceVariable"
        setProfile={reportType === "Participant Flow" ? "ParticipantFlowEvidenceVariable" : "VariableDefinition"}
        startCollapsed startEmptyArrayClosed enableCreation={true}
        setResourceState={setVariablesState} />
    </div>
  </div>
}

const reorderResultsSections = (resultsSection) => {
  let originalResultsSectionString = JSON.stringify(resultsSection);
  let reorderedResults = resultsSection.map(result => {
    if (!result.code) {
      result.code = sectionCodeSummaryOfFindingsForSingleOutcome;
    }
    let emptyScreenedEvidence = {
      "code": sectionCodeEvidenceForScreenedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyEnrolledEvidence = {
      "code": sectionCodeEvidenceForEnrolledGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyAnalyzedEvidence = {
      "code": sectionCodeEvidenceForAnalyzedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyInterventionEvidence = {
      "code": sectionCodeEvidenceWithInterventionAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyComparatorEvidence = {
      "code": sectionCodeEvidenceWithComparatorAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyEffectEstimate = {
      "code": sectionCodeComparativeResults,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyPopulationSynthesis = {
      "code": sectionCodePopulation,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyInterventionSynthesis = {
      "code": sectionCodeInterventionGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyComparatorSynthesis = {
      "code": sectionCodeComparatorGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let resultDictionary = {
      screened: emptyScreenedEvidence,
      enrolled: emptyEnrolledEvidence,
      analyzed: emptyAnalyzedEvidence,
      intervention: emptyInterventionEvidence,
      comparator: emptyComparatorEvidence,
      effect: emptyEffectEstimate,
      populationSynthesis: emptyPopulationSynthesis,
      interventionSynthesis: emptyInterventionSynthesis,
      comparatorSynthesis: emptyComparatorSynthesis,
      additionalSections: []
    };
    for (const section of result.section) {
      let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
      if (sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" || sectionCode === "Evidence with total group" ||
        sectionCode === "evidence-for-analyzed-group" || sectionCode === "Evidence for Analyzed Group"
      ) {
        if (sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" || sectionCode === "Evidence with total group") {
          section.code = sectionCodeEvidenceForAnalyzedGroup;
        }
        resultDictionary.analyzed = section;
      } else if (sectionCode === "evidence-with-intervention-alone" || sectionCode === "Evidence with intervention alone") {
        resultDictionary.intervention = section;
      } else if (sectionCode === "evidence-with-comparator-alone" || sectionCode === "Evidence with comparator alone") {
        resultDictionary.comparator = section;
      } else if (sectionCode === "comparative-results" || sectionCode === "evidence-with-intervention-vs-comparator" ||
        sectionCode === "Evidence with intervention vs. comparator") {
        resultDictionary.effect = section;
      } else if (sectionCode === "evidence-for-screened-group" || sectionCode === "Evidence for Screened Group") {
        resultDictionary.screened = section;
      } else if (sectionCode === "evidence-for-enrolled-group" || sectionCode === "Evidence for Enrolled Group") {
        resultDictionary.enrolled = section;
      } else if (sectionCode === "population") {
        resultDictionary.populationSynthesis = section;
      } else if (sectionCode === "intervention-group") {
        resultDictionary.interventionSynthesis = section;
      } else if (sectionCode === "comparator-group") {
        resultDictionary.comparatorSynthesis = section;
      } else if (section) {
        console.log("unrecognized sectionCode: " + sectionCode);
        resultDictionary.additionalSections.push(section);
      }
    }
    let reorderedResultArray = [
      resultDictionary.screened,
      resultDictionary.enrolled,
      resultDictionary.analyzed,
      resultDictionary.intervention,
      resultDictionary.comparator,
      resultDictionary.effect,
      resultDictionary.populationSynthesis,
      resultDictionary.interventionSynthesis,
      resultDictionary.comparatorSynthesis
    ].concat(resultDictionary.additionalSections);
    result.section = reorderedResultArray;
    return result;
  });
  if (originalResultsSectionString !== JSON.stringify(reorderedResults)) {
    resultsSection = reorderedResults;
  }
}

const EvidenceTableReportPackageAuthor = ({ resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState, profile }) => {

  let reportType;
  if (profile === "OutcomeMeasureReport") {
    reportType = "Outcome Measure"
  } else if (profile === "BaselineMeasureReport") {
    reportType = "Baseline Measure"
  } else if (profile === "ParticipantFlowReport") {
    reportType = "Participant Flow"
  }

  const [allSectionsState, setAllSectionsState] = useState({ "resultsSections": [], "additionalSections": [] });
  const [startingAllSectionsState, setStartingAllSectionsState] = useState({ "resultsSections": [], "additionalSections": [] });
  const [groupsState, setGroupsState] = useState({
    screenedGroup: null, enrolledGroup: null, analyzedGroup: null,
    interventionGroup: null, comparatorGroup: null,
    populationSynthesisGroup: null, interventionSynthesisGroup: null, comparatorSynthesisGroup: null
  });
  const [groupAssignmentState, setGroupAssignmentState] = useState({ groupAssignment: null });
  const [variablesState, setVariablesState] = useState({ variablesList: [] });

  useEffect(() => {
    if (resourceState?.section?.length) {
      let resultsSections = [];
      let additionalSections = [];
      const allSections = JSON.parse(JSON.stringify(resourceState.section));
      for (const section of allSections) {
        if (section?.code?.coding?.[0]?.code === "results") {
          resultsSections.push(section);
        } else {
          additionalSections.push(section);
        }
      }
      reorderResultsSections(resultsSections);
      setAllSectionsState({ "resultsSections": resultsSections, "additionalSections": additionalSections });
      setStartingAllSectionsState({ "resultsSections": resultsSections, "additionalSections": additionalSections });
      let variablesList = [];
      let screenedGroup = null;
      let enrolledGroup = null;
      let analyzedGroup = null;
      let interventionGroup = null;
      let comparatorGroup = null;
      let populationSynthesisGroup = null;
      let interventionSynthesisGroup = null;
      let comparatorSynthesisGroup = null;
      let groupAssignment = null;
      for (const results of resultsSections) {
        if (results.focus) {
          variablesList.push(results.focus);
          if (results.section?.length) {
            for (const result of results.section) {
              let code = result.code?.coding?.[0]?.code;
              switch (code) {
                case "evidence-for-screened-group":
                  if (result.focus && !screenedGroup) {
                    screenedGroup = result.focus;
                  }
                  break;
                case "evidence-for-enrolled-group":
                  if (result.focus && !enrolledGroup) {
                    enrolledGroup = result.focus;
                  }
                  break;
                case "evidence-for-analyzed-group":
                  if (result.focus && !analyzedGroup) {
                    analyzedGroup = result.focus;
                  }
                  break;
                case "evidence-with-intervention-alone":
                  if (result.focus && !interventionGroup) {
                    interventionGroup = result.focus;
                  }
                  break;
                case "evidence-with-comparator-alone":
                  if (result.focus && !comparatorGroup) {
                    comparatorGroup = result.focus;
                  }
                  break;
                case "comparative-results":
                  if (result.focus && !groupAssignment) {
                    groupAssignment = result.focus;
                  }
                  break;
                case "population":
                  if (result.focus && !populationSynthesisGroup) {
                    populationSynthesisGroup = result.focus;
                  }
                  break;
                case "intervention-group":
                  if (result.focus && !interventionSynthesisGroup) {
                    interventionSynthesisGroup = result.focus;
                  }
                  break;
                case "comparator-group":
                  if (result.focus && !comparatorSynthesisGroup) {
                    comparatorSynthesisGroup = result.focus;
                  }
                  break;
                default:
                //
              }
            }
          }
        }
      }
      setGroupsState({
        screenedGroup: screenedGroup, enrolledGroup: enrolledGroup, analyzedGroup: analyzedGroup,
        interventionGroup: interventionGroup, comparatorGroup: comparatorGroup,
        populationSynthesisGroup: populationSynthesisGroup, interventionSynthesisGroup: interventionSynthesisGroup,
        comparatorSynthesisGroup: comparatorSynthesisGroup
      });
      setGroupAssignmentState({ groupAssignment: groupAssignment });
      setVariablesState({ variablesList: variablesList });
    }
  }, []);

  useEffect(() => {
    if (allSectionsState && startingAllSectionsState) {
      if (
        (JSON.stringify(allSectionsState.resultsSections) !==
          JSON.stringify(startingAllSectionsState.resultsSections)) ||
        (JSON.stringify(allSectionsState.additionalSections) !==
          JSON.stringify(startingAllSectionsState.additionalSections))
      ) {
        setResourceState(prevState => {
          return {
            ...prevState,
            section: allSectionsState.resultsSections.concat(allSectionsState.additionalSections)
          };
        });
      }
    }
  }, [allSectionsState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded && resourceState?.section?.length) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    } else if (sourceJsonState?.scrollTo) {
      scrollTo("resourceTextViewSegment", sourceJsonState.scrollTo, true);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  const [addedSectionState, setAddedSectionState] = useState({ "title": "" });

  useEffect(() => {
    if (addedSectionState.addSection && addedSectionState.title) {
      let sectionToBeAdded = { "title": addedSectionState.title };
      setAllSectionsState(prevState => {
        let newSection = prevState.additionalSections || [];
        newSection.push(sectionToBeAdded);
        return { ...prevState, "additionalSections": newSection };
      });
      setAddedSectionState({ "title": "" });
    }
  }, [addedSectionState]);

  return <>{(sourceJsonState.loaded && resourceState.section?.length) ?
    <div style={{ marginTop: "12px" }}>
      <div>
        <h3 id="groups">Groups</h3>
        <div style={{ marginLeft: "24px" }}>
          <GroupsDataEntry groupsState={groupsState} setGroupsState={setGroupsState}
            reportType={reportType}
          />
          <br />
          <GroupAssignmentDataEntry groupAssignmentState={groupAssignmentState}
            setGroupAssignmentState={setGroupAssignmentState}
          />
        </div>
        {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
        {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
        {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
        <div style={{ marginLeft: "24px" }}>
          <VariablesSectionDataEntry reportType={reportType}
            variablesState={variablesState} setVariablesState={setVariablesState} />
        </div>
        <h3 id="evidence">Evidence</h3>
        <EvidenceTableEdit resultsSections={allSectionsState.resultsSections}
          groupsState={groupsState} groupAssignmentState={groupAssignmentState}
          variablesState={variablesState} reportType={reportType}
          resourceDictionary={sourceJsonState.resourceDictionary}
          setSourceJsonState={setSourceJsonState} resourceState={resourceState}
          setAllSectionsState={setAllSectionsState} globalContext={globalContext}
        />
        <br />
        <h3 id="discussion">Discussion</h3>
        {allSectionsState?.additionalSections?.length ?
          <>
            {allSectionsState.additionalSections.map((section, sectionIndex) => {
              return <div key={sectionIndex}>
                <EvidenceReportPackageSectionDisplay topSection={section} />
              </div>
            })}
            <div style={{ marginLeft: "24px" }}>
              <p>Use Section Detail tab to edit section content.</p>
              {(Array.isArray(allSectionsState.additionalSections) && allSectionsState.additionalSections.length > 0) &&
                <div>
                  <p>Top-level sections include:</p>
                  <div style={{ marginLeft: "24px" }}>
                    {allSectionsState.additionalSections.map((subsection, subsectionIndex) => {
                      let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code
                      return <span key={subsectionIndex}>
                        <p><b>Section: </b>{sectionName}</p>
                      </span>;
                    })}
                  </div>
                </div>}
              <div>
                <p>Enter a title then click Add Section.</p>
                <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                  startingValue={addedSectionState.title}
                  setResourceState={setAddedSectionState} />
                <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                  content={"Add Section"}
                  onClick={() => {
                    setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
                  }}
                  disabled={!addedSectionState.title} />
              </div>
            </div>
          </>
          :
          <>
            <div>
              <p>Enter a title then click Add Section.</p>
              <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                startingValue={addedSectionState.title}
                setResourceState={setAddedSectionState} />
              <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                content={"Add Section"}
                onClick={() => {
                  setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
                }}
                disabled={!addedSectionState.title} />
            </div>
          </>}
        <br />
      </div>
    </div>
    :
    <div>
      <p>Loading ...</p>
    </div>}</>
};

export { EvidenceTableReportPackageAuthor, generateEvidenceNarrative };