import { emptyTextNoData } from './CodeSystemLookup';
import { getStringFromFHIR } from './ResourceFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import {
  checkIfStatisticAbsoluteOrRelative, codeMatchesRelativeRisk, extractControlEventRateFromStatistic
} from './SummaryOfFindingsAuthoringTool';

const generateCompositionSummary = async (compositionFoi, resourceDictionary, globalContext) => {
  let newCompositionJson;
  if (compositionFoi) {
    if (resourceDictionary && resourceDictionary[compositionFoi]) {
      newCompositionJson = resourceDictionary[compositionFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': compositionFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newCompositionJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newCompositionJson?.text?.div) {
      return newCompositionJson.text.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No Narrative summary found in the Composition Resource.]</div>";
};

const generateEvidenceSummary = async (evidenceFoi, resourceDictionary, globalContext) => {
  let newEvidenceJson;
  if (evidenceFoi) {
    if (resourceDictionary && resourceDictionary[evidenceFoi]) {
      newEvidenceJson = resourceDictionary[evidenceFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': evidenceFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newEvidenceJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newEvidenceJson) {
      let description = "";
      let statistic = "";
      if (newEvidenceJson.statistic && newEvidenceJson.statistic[0]) {
        if (newEvidenceJson.statistic[0].statisticType && newEvidenceJson.statistic[0].quantity) {
          let statisticTypeDisplay = newEvidenceJson.statistic[0].statisticType.coding?.[0]?.display || newEvidenceJson.statistic[0].statisticType.text || getStringFromFHIR.CodeableConcept(newEvidenceJson.statistic[0].statisticType);
          statistic = statisticTypeDisplay + " " + getStringFromFHIR.Quantity(newEvidenceJson.statistic[0].quantity);
        } else if (newEvidenceJson.statistic[0].description) {
          statistic = newEvidenceJson.statistic[0].description;
        }
      }
      if (statistic) {
        if (newEvidenceJson.statistic[0].attributeEstimate?.[0]?.type) {
          let attributeEstimate = newEvidenceJson.statistic[0].attributeEstimate[0];
          let attribute = "";
          if (attributeEstimate.level) {
            attribute = (attributeEstimate.level * 100).toString() + "% ";
          }
          attribute += attributeEstimate.type.coding?.[0]?.display || attributeEstimate.type.text || getStringFromFHIR.CodeableConcept(attributeEstimate.type);
          if (attributeEstimate.quantity) {
            attribute += " " + getStringFromFHIR.Quantity(attributeEstimate.quantity);
          }
          if (attributeEstimate.range) {
            attribute += " " + getStringFromFHIR.Range(attributeEstimate.range);
          }
          statistic += " (" + attribute + ")";
        }
        description = statistic;
      }
      if (!description) {
        description = newEvidenceJson.description || "";
      }
      let narrative = description;
      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateCalculatedInterventionRateSummary = async (foiList, resourceDictionary, globalContext) => {
  let generatedTextDiv = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
  let evidence1Foi = foiList[0];
  let evidence2Foi = foiList[1];
  let evidence1Json;
  let evidence2Json;
  if (evidence1Foi && evidence2Foi) {
    if (resourceDictionary && resourceDictionary[evidence1Foi]) {
      evidence1Json = resourceDictionary[evidence1Foi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': evidence1Foi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        evidence1Json = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (resourceDictionary && resourceDictionary[evidence2Foi]) {
      evidence2Json = resourceDictionary[evidence2Foi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': evidence2Foi
      };
      let responseB = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseB.success) {
        evidence2Json = JSON.parse(responseB.fhirjsonstring);
      };
    }
    if (evidence1Json && evidence2Json) {
      let controlEventRate = NaN;
      let riskDifference = NaN;
      let interventionResult = NaN;
      let relativeRisk = NaN;

      if (evidence1Json.statistic) {
        controlEventRate = extractControlEventRateFromStatistic(evidence1Json.statistic);
        for (const stat of evidence1Json.statistic) {
          if (stat.statisticType?.coding?.[0]?.code &&
            checkIfStatisticAbsoluteOrRelative(stat.statisticType.coding[0].code) === "absolute") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              riskDifference = stat.quantity.value;
            }
          }
          if (stat.statisticType?.coding?.[0]?.code &&
            codeMatchesRelativeRisk(stat.statisticType.coding[0].code)) {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              relativeRisk = stat.quantity.value;
              break;
            }
          }
          if (stat.statisticType?.coding?.[0]?.code &&
            checkIfStatisticAbsoluteOrRelative(stat.statisticType.coding[0].code) === "relative") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              relativeRisk = stat.quantity.value;
            }
          }
        }
      }

      if (evidence2Json.statistic) {
        if (isNaN(controlEventRate)) {
          controlEventRate = extractControlEventRateFromStatistic(evidence2Json.statistic);
        }
        for (const stat of evidence2Json.statistic) {
          if (stat.statisticType?.coding?.[0]?.code &&
            checkIfStatisticAbsoluteOrRelative(stat.statisticType.coding[0].code) === "absolute") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              riskDifference = stat.quantity.value;
            }
          }
          if (stat.statisticType?.coding?.[0]?.code &&
            codeMatchesRelativeRisk(stat.statisticType.coding[0].code)) {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              relativeRisk = stat.quantity.value;
              break;
            }
          }
          if (stat.statisticType?.coding?.[0]?.code &&
            checkIfStatisticAbsoluteOrRelative(stat.statisticType.coding[0].code) === "relative") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              relativeRisk = stat.quantity.value;
            }
          }
        }
      }

      if (!isNaN(relativeRisk)) {
        let interventionEventRate = controlEventRate * relativeRisk;
        riskDifference = interventionEventRate - controlEventRate;
      }

      interventionResult = (controlEventRate + riskDifference) * 100;

      if (isNaN(interventionResult)) {
        generatedTextDiv = "<div xmlns=\"http://www.w3.org/1999/xhtml\">[Not a proportion.]</div>";
      } else {
        generatedTextDiv = "<div xmlns=\"http://www.w3.org/1999/xhtml\">" +
          parseFloat(interventionResult.toFixed(2)) + '%' + "</div>";
      }
    }
  }
  return generatedTextDiv;
}

const generateEvidenceVariableSummary = async (evidenceVariableFoi, resourceDictionary, globalContext) => {
  let newEvidenceVariableJson;
  if (evidenceVariableFoi) {
    if (resourceDictionary && resourceDictionary[evidenceVariableFoi]) {
      newEvidenceVariableJson = resourceDictionary[evidenceVariableFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': evidenceVariableFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newEvidenceVariableJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newEvidenceVariableJson) {
      let title = newEvidenceVariableJson.title || newEvidenceVariableJson.name || "";
      let boldedTitle = "";
      if (title) {
        boldedTitle = "<b>" + title + "</b>";
      }
      let description = "";
      if (newEvidenceVariableJson.description) {
        description = " " + newEvidenceVariableJson.description;
      }
      let handling = "";
      if (newEvidenceVariableJson.handling) {
        handling = " (" + newEvidenceVariableJson.handling + " variable)";
      }
      let narrative = boldedTitle + description + handling;
      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateGroupSummary = async (groupFoi, resourceDictionary, globalContext) => {
  let newGroupJson;
  if (groupFoi) {
    if (resourceDictionary && resourceDictionary[groupFoi]) {
      newGroupJson = resourceDictionary[groupFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': groupFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newGroupJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newGroupJson) {
      let title = newGroupJson.title || newGroupJson.name || "";
      let boldedTitle = "";
      if (title) {
        boldedTitle = "<b>" + title + "</b>";
      }
      let description = "";
      if (newGroupJson.description) {
        description = " " + newGroupJson.description;
      }
      let quantity = "";
      if (newGroupJson.quantity) {
        quantity = " (" + newGroupJson.quantity + " participants)";
      }
      let narrative = boldedTitle + description + quantity;
      let newText = { status: "empty", div: "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>" };
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateSubgroupsSummary = async (groupFoiList, resourceDictionary, globalContext) => {
  let subgroupSummary = "";
  for (const groupFoi of groupFoiList) {
    let newGroupJson;
    if (groupFoi) {
      if (resourceDictionary && resourceDictionary[groupFoi]) {
        newGroupJson = resourceDictionary[groupFoi];
      } else {
        const bodyA = {
          'functionid': "getfhirresource",
          'idToken': "",
          'resourceid': groupFoi
        };
        let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
        if (responseA.success) {
          newGroupJson = JSON.parse(responseA.fhirjsonstring);
        };
      }
      if (newGroupJson) {
        let title = newGroupJson.title || newGroupJson.name || "";
        let boldedTitle = "";
        if (title) {
          boldedTitle = "<b>" + title + "</b>";
        }
        let description = "";
        if (newGroupJson.description) {
          description = " " + newGroupJson.description;
        }
        let quantity = "";
        if (newGroupJson.quantity) {
          quantity = " (" + newGroupJson.quantity + " participants)";
        }
        let narrative = boldedTitle + description + quantity;
        if (subgroupSummary) {
          subgroupSummary += "; ";
        }
        subgroupSummary += narrative;
      }
    }
  }
  if (!subgroupSummary) {
    subgroupSummary = "[No data.]";
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + subgroupSummary + "</p></div>";
}

const generateResearchStudySummary = async (researchStudyFoi, resourceDictionary, globalContext) => {
  let newResearchStudyJson;
  if (researchStudyFoi) {
    if (resourceDictionary && resourceDictionary[researchStudyFoi]) {
      newResearchStudyJson = resourceDictionary[researchStudyFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': researchStudyFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newResearchStudyJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newResearchStudyJson) {
      let title = newResearchStudyJson.title || newResearchStudyJson.name || "";
      let pTitle = "";
      if (title) {
        pTitle = "<p><b>Title:</b> " + title + "</p>";
      }
      let description = newResearchStudyJson.descriptionSummary || newResearchStudyJson.description || "";
      let pDescription = "";
      if (description) {
        pDescription = "<br/><p><b>Description:</b> " + description + "</p>";
      }
      let phase = "";
      let pPhase = "";
      if (newResearchStudyJson.phase) {
        phase = getStringFromFHIR.CodeableConcept(newResearchStudyJson.phase);
      }
      if (phase) {
        pPhase = "<br/><p><b>Phase:</b> " + phase + "</p>";
      }
      let studyDesign = "";
      let pStudyDesign = "";
      if (newResearchStudyJson.studyDesign) {
        studyDesign = newResearchStudyJson.studyDesign.map(item => { return getStringFromFHIR.CodeableConcept(item) }).join("; ");
      }
      if (studyDesign) {
        pStudyDesign = "<br/><p><b>Study Design:</b> " + studyDesign + "</p>";
      }
      let pExtensions = "";
      let extensions = newResearchStudyJson.extension || [];
      if (extensions.length > 0) {
        for (const extension of extensions) {
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-search-strategy") {
            let searchStrategy = getStringFromFHIR.Reference(extension.valueReference);
            pExtensions += "<br/><p><b>Search Strategy:</b> " + searchStrategy + "</p>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-systematic-review-eligibility-criteria") {
            let reviewEligCrit = getStringFromFHIR.Reference(extension.valueReference);
            pExtensions += "<br/><p><b>Systematic Review Eligibility Criteria:</b> " + reviewEligCrit + "</p>";
          }
        }
      }
      let combinedPstrings = pTitle + pDescription + pPhase + pStudyDesign + pExtensions;
      let narrative = "";
      if (combinedPstrings) {
        narrative = "<div>" + combinedPstrings + "</div>";
      }
      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + narrative + "</div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateM11TitlePageFromResearchStudySummary = async (researchStudyFoi, resourceDictionary, globalContext) => {
  let newResearchStudyJson;
  if (researchStudyFoi) {
    if (resourceDictionary && resourceDictionary[researchStudyFoi]) {
      newResearchStudyJson = resourceDictionary[researchStudyFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': researchStudyFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newResearchStudyJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newResearchStudyJson) {
      let fullTitle = newResearchStudyJson.title || newResearchStudyJson.name || "";
      let sponsorProtocolIdentifier = "";
      let confidentialityStatement = "";
      let originalProtocol = "Yes";
      let versionNumber = newResearchStudyJson.version || "";
      let versionDate = "";
      let amendmentIdentifier = "Not applicable";
      let amendmentScope = "Not applicable";
      let compoundCodes = "";
      let compoundNames = "";
      if (newResearchStudyJson.focus) {
        compoundCodes = newResearchStudyJson.focus.map(item => {
          if (item.concept) {
            if (item.concept.coding) {
              return item.concept.coding.map(coding => { return coding.code }).join(", ");
            } else { return item.concept.text || ""; }
          } else { return item.reference?.display || ""; }
        }).join("; ");
        compoundNames = newResearchStudyJson.focus.map(item => {
          if (item.concept) {
            if (item.concept.coding) {
              return item.concept.coding.map(coding => { return coding.display }).join(", ");
            } else { return item.concept.text || ""; }
          } else { return item.reference?.display || ""; }
        }).join("; ");
      }
      let trialPhase = "[No data.]";
      if (newResearchStudyJson.phase) {
        trialPhase = getStringFromFHIR.CodeableConcept(newResearchStudyJson.phase);
      }
      let shortTitle = fullTitle;
      let trialAcronym = "";
      if (newResearchStudyJson.label) {
        for (const label of newResearchStudyJson.label) {
          if (label.type?.coding?.[0]?.code === "short-title") {
            shortTitle = label.value || "";
          }
          if (label.type?.coding?.[0]?.code === "acronym") {
            trialAcronym = label.value || "";
          }
        }
      }
      let sponsorName = "[No data.]";
      let manufacturerName = "";
      let regulatoryIdentifiers = "";
      if (newResearchStudyJson.identifier) {
        let regulatoryIdentifierStrings = []
        for (const identifier of newResearchStudyJson.identifier) {
          if (identifier.type?.text === "Sponsor Protocol Identifier") {
            sponsorProtocolIdentifier = identifier.value;
          } else if (identifier.type?.text === "Amendment Identifier") {
            amendmentIdentifier = identifier.value;
          } else if (identifier.system !== "https://fevir.net") {
            regulatoryIdentifierStrings.push(getStringFromFHIR.Identifier(identifier));
          }
        }
        if (regulatoryIdentifierStrings.length > 0) {
          regulatoryIdentifiers = regulatoryIdentifierStrings.join("; ");
        }
      }
      let sponsorApprovalDate = "";
      if (newResearchStudyJson.progressStatus) {
        for (const progress of newResearchStudyJson.progressStatus) {
          if (progress.state?.text === "Version Date") {
            versionDate = progress.period?.start || progress.period?.end || "";
          }
          if (progress.state?.text === "Sponsor Approval") {
            sponsorApprovalDate = progress.period?.start || progress.period?.end || "";
          }
        }
      }
      let sponsorSignatory = "[{<Enter sponsor signature block (name and title of sponsor signatory and signature date)>} or {This protocol was approved via <describe method>}].";
      let medicalExpertContact = "";
      if (newResearchStudyJson.associatedParty) {
        for (const party of newResearchStudyJson.associatedParty) {
          let roleCode = party.role?.coding?.[0]?.code;
          if (roleCode === "sponsor" || roleCode === "lead-sponsor") {
            sponsorName = party.name;
          } else if (roleCode === "general-contact" && party.role.text === "Medical Expert") {
            medicalExpertContact = party.name;
          }
          if (party.role.text === "Device Manufacturer") {
            manufacturerName = party.name;
          }
        }
      }
      let saeReportingMethod = "";
      let amendmentDetails = "Not applicable. This protocol has not been amended";
      let amendmentRationale = "";
      let percentEnrolled = "";
      let primaryReason = "";
      let amendmentSummary = "";
      let substantialImpactSafety = "";
      let substantialImpactSafetyBoolean;
      let substantialImpactSafetyComment = "";
      let substantialImpactReliability = "";
      let substantialImpactReliabilityBoolean;
      let substantialImpactReliabilityComment = "";
      let amendmentChangesTable = "";
      let extensions = newResearchStudyJson.extension || [];
      if (extensions.length > 0) {
        for (const ext of extensions) {
          if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sponsor-confidentiality-statement" &&
            ext.valueString) {
            confidentialityStatement = ext.valueString;
          } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sae-reporting-method" &&
            ext.valueString) {
            saeReportingMethod = ext.valueString;
          } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment" &&
            ext.extension) {
            originalProtocol = "No";
            for (const extension of ext.extension) {
              if (extension.url === "details") {
                amendmentDetails = extension.valueString;
              } else if (extension.url === "scope") {
                amendmentScope = extension.valueString;
              } else if (extension.url === "description") {
                amendmentSummary = extension.valueString;
              } else if (extension.url === "rationale") {
                amendmentRationale = extension.valueString;
              } else if (extension.url === "substantialImpactSafety") {
                substantialImpactSafetyBoolean = extension.valueBoolean;
              } else if (extension.url === "substantialImpactSafetyComment") {
                substantialImpactSafetyComment = extension.valueString;
              } else if (extension.url === "substantialImpactReliability") {
                substantialImpactReliabilityBoolean = extension.valueBoolean;
              } else if (extension.url === "substantialImpactReliabilityComment") {
                substantialImpactReliabilityComment = extension.valueString;
              } else if (extension.url === "primaryReason") {
                primaryReason = getStringFromFHIR.CodeableConcept(extension.valueCodeableConcept);
              }
            }
          }
        }
      }
      if (substantialImpactSafetyBoolean === true) {
        substantialImpactSafety = "Yes"
      }
      if (substantialImpactSafetyBoolean === false) {
        substantialImpactSafety = "No"
      }
      if (substantialImpactSafetyComment) {
        substantialImpactSafety += ", " + substantialImpactSafetyComment;
      }
      if (substantialImpactReliabilityBoolean === true) {
        substantialImpactReliability = "Yes"
      }
      if (substantialImpactReliabilityBoolean === false) {
        substantialImpactReliability = "No"
      }
      if (substantialImpactReliabilityComment) {
        substantialImpactReliability += ", " + substantialImpactReliabilityComment;
      }

      let titlePageSummary = "<table><tbody>";
      if (confidentialityStatement) {
        titlePageSummary += "<tr><td><b>Sponsor Confidentiality Statement:</b></td><td>" + confidentialityStatement + "</td></tr>";
      }
      titlePageSummary += "<tr><td><b>Full Title:</b></td><td>" + fullTitle + "</td></tr>";
      if (trialAcronym) {
        titlePageSummary += "<tr><td><b>Trial Acronym:</b></td><td>" + trialAcronym + "</td></tr>";
      }
      titlePageSummary += "<tr><td><b>Sponsor Protocol Identifier:</b></td><td>" + sponsorProtocolIdentifier +
        "</td></tr><tr><td><b>Original Protocol:</b></td><td>" + originalProtocol +
        "</td></tr><tr><td><b>Version Number:</b></td><td>" + versionNumber +
        "</td></tr><tr><td><b>Version Date:</b></td><td>" + versionDate +
        "</td></tr><tr><td><b>Amendment Identifier:</b></td><td>" + amendmentIdentifier +
        "</td></tr><tr><td><b>Amendment Scope:</b></td><td>" + amendmentScope +
        "</td></tr><tr><td><b>Sponsor's Investigational Product Code(s):</b></td><td>" + compoundCodes +
        "</td></tr><tr><td><b>Investigational Product Name(s):</b></td><td>" + compoundNames +
        "</td></tr><tr><td><b>Trial Phase:</b></td><td>" + trialPhase +
        "</td></tr><tr><td><b>Short Title:</b></td><td>" + shortTitle +
        "</td></tr><tr><td><b>Sponsor Name and Address:</b></td><td>" + sponsorName;
      if (manufacturerName) {
        titlePageSummary += "</td></tr><tr><td><b>Device Manufacturer Name and Address:</b></td><td>" + manufacturerName;
      }
      titlePageSummary += "</td></tr><tr><td><b>Regulatory or Clincial Trial Identifier(s):</b></td><td>" + regulatoryIdentifiers +
        "</td></tr><tr><td><b>Sponsor Approval:</b></td><td>" + sponsorApprovalDate +
        "</td></tr><tr><td><b>Sponsor Signatory:</b></td><td>" + sponsorSignatory +
        "</td></tr><tr><td><b>Medical Expert Contact:</b></td><td>" + medicalExpertContact +
        "</td></tr><tr><td><b>Amendment Details:</b></td><td>" + amendmentDetails +
        "</td></tr></tbody></table><br/>";
      if (amendmentDetails !== "Not applicable. This protocol has not been amended") {
        if (amendmentRationale) {
          titlePageSummary += "<p><b>Overall Rationale for the Amendment:</b></p><p><i>" + amendmentRationale +
            "</i></p>";
        }
        titlePageSummary += "<p>The table below describes the current amendment.</p><table><tbody><tr><td><b>Approximate % Enrolled at time of Sponsor Approval:</b></td><td>" + percentEnrolled +
          "</td></tr><tr><td><b>Reason(s) for Amendment:</b></td><td>Primary: " + primaryReason +
          ", Secondary: NA</td></tr><tr><td><b>Amendment Summary:</b></td><td>" + amendmentSummary +
          "</td></tr><tr><td>Is this amendment likely to have a substantial impact on the safety or rights of the participants?</td><td>" + substantialImpactSafety +
          "</td></tr><tr><td>Is this amendment likely to have a substantial impact on the reliability and robustness of the data generated in the clinical trial?</td><td>" + substantialImpactReliability +
          "</td></tr></tbody></table><br/><p><b>Overview of Changes in the Current Amendment:</b></p>" + amendmentChangesTable;
      }


      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (titlePageSummary) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + titlePageSummary + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateM11InclusionCriteriaFromGroupSummary = async (groupFoi, resourceDictionary, globalContext) => {
  let newGroupJson;
  if (groupFoi) {
    if (resourceDictionary && resourceDictionary[groupFoi]) {
      newGroupJson = resourceDictionary[groupFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': groupFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newGroupJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newGroupJson) {

      let groupInclusionCharacteristics = [];
      let groupExclusionCharacteristics = [];
      if (newGroupJson.characteristic?.length > 0) {
        for (const characteristic of newGroupJson.characteristic) {
          if (characteristic?.description || characteristic?.code) {
            if (characteristic.exclude) {
              groupExclusionCharacteristics.push(characteristic);
            } else {
              groupInclusionCharacteristics.push(characteristic);
            }
          }
        }
      }

      let narrative = "<p>To be eligible to participate in this trial, an individual must meet all the following criteria:</p><ol>";
      for (const characteristic of groupInclusionCharacteristics) {
        if (characteristic.description) {
          narrative += "<li>" + characteristic.description + "</li>";
        } else {
          let characteristicCode = getStringFromFHIR.CodeableConcept(characteristic.code);
          let characteristicValue = "[Data did not convert.]";
          if (characteristic.valueCodeableConcept) {
            characteristicValue = getStringFromFHIR.CodeableConcept(characteristic.valueCodeableConcept);
          } else if (characteristic.valueReference) {
            characteristicValue = getStringFromFHIR.Reference(characteristic.valueReference);
          } else if (characteristic.valueRange) {
            characteristicValue = getStringFromFHIR.Range(characteristic.valueRange);
          } else if (characteristic.valueQuantity) {
            characteristicValue = getStringFromFHIR.Quantity(characteristic.valueQuantity);
          } else if (characteristic.valueBoolean) {
            characteristicValue = "True";
          }
          narrative += "<li>" + characteristicCode + " MATCHING " + characteristicValue + "</li>";
        }
      }
      narrative += "</ol>";

      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateM11ExclusionCriteriaFromGroupSummary = async (groupFoi, resourceDictionary, globalContext) => {
  let newGroupJson;
  if (groupFoi) {
    if (resourceDictionary && resourceDictionary[groupFoi]) {
      newGroupJson = resourceDictionary[groupFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': groupFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newGroupJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newGroupJson) {
      let groupInclusionCharacteristics = [];
      let groupExclusionCharacteristics = [];
      if (newGroupJson.characteristic?.length > 0) {
        for (const characteristic of newGroupJson.characteristic) {
          if (characteristic?.description || characteristic?.code) {
            if (characteristic.exclude) {
              groupExclusionCharacteristics.push(characteristic);
            } else {
              groupInclusionCharacteristics.push(characteristic);
            }
          }
        }
      }

      let narrative = "<p>An individual who meets any of the following criteria will be excluded from participation in this trial:</p><ol>";
      for (const characteristic of groupExclusionCharacteristics) {
        if (characteristic.description) {
          narrative += "<li>" + characteristic.description + "</li>";
        } else {
          let characteristicCode = getStringFromFHIR.CodeableConcept(characteristic.code);
          let characteristicValue = "[Data did not convert.]";
          if (characteristic.valueCodeableConcept) {
            characteristicValue = getStringFromFHIR.CodeableConcept(characteristic.valueCodeableConcept);
          } else if (characteristic.valueReference) {
            characteristicValue = getStringFromFHIR.Reference(characteristic.valueReference);
          } else if (characteristic.valueRange) {
            characteristicValue = getStringFromFHIR.Range(characteristic.valueRange);
          } else if (characteristic.valueQuantity) {
            characteristicValue = getStringFromFHIR.Quantity(characteristic.valueQuantity);
          } else if (characteristic.valueBoolean) {
            characteristicValue = "True";
          }
          narrative += "<li>" + characteristicCode + " MATCHING " + characteristicValue + "</li>";
        }
      }
      narrative += "</ol>";

      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (narrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateObjectivesNarrative = (objectives, comparisonGroupDictionary) => {
  let objectivesDetail = "";
  for (let objective of objectives) {
    let objectiveDetail = "";
    if (objective.name) {
      objectiveDetail += "<p><b>Name: </b>" + objective.name + "</p>";
    }
    if (objective.description) {
      objectiveDetail += "<p><b>Description </b>" + objective.description + "</p>";
    }
    if (objective.outcomeMeasure?.length > 0) {
      objectiveDetail += "<p><b>Outcome Measures:</b></p>";
      for (let outcome of objective.outcomeMeasure) {
        let label = outcome.name || "Unnamed outcome measure";
        let type = outcome.type?.coding?.[0]?.code || "";
        let description = outcome.description || "";
        let endpoint = "Undefined endpoint";
        if (outcome.endpoint) {
          endpoint = getStringFromFHIR.Reference(outcome.endpoint);
        }
        let population = "";
        if (outcome.population) {
          population = getStringFromFHIR.Reference(outcome.population);
        }
        let intervention = "";
        if (outcome.intervention) {
          intervention = getStringFromFHIR.Reference(outcome.intervention);
        }
        let comparator = "";
        if (outcome.comparator) {
          comparator = getStringFromFHIR.Reference(outcome.comparator);
        }
        let summaryMeasure = "";
        if (outcome.summaryMeasure) {
          summaryMeasure = getStringFromFHIR.CodeableConcept(outcome.summaryMeasure);
        }
        let eventHandling = "";
        let eventHandlingArray = outcome.eventHandling || [];
        if (eventHandlingArray.length > 0) {
          let eventHandlingEntries = [];
          for (let entry of eventHandlingArray) {
            let description = entry.description || "";
            let event = "";
            let group = "";
            let handling = "";
            if (entry.event) {
              event = getStringFromFHIR.CodeableConcept(entry.event);
            }
            if (entry.group) {
              group = getStringFromFHIR.CodeableConcept(entry.group);
            }
            if (entry.handling) {
              handling = getStringFromFHIR.CodeableConcept(entry.handling);
            }
            let eventHandlingString = "<li>";
            if (event) {
              eventHandlingString += "Event: " + event;
            }
            if (group) {
              if (eventHandlingString !== "<li>") {
                eventHandlingString += "; ";
              }
              eventHandlingString += "Group: " + group;
            }
            if (handling) {
              if (eventHandlingString !== "<li>") {
                eventHandlingString += "; ";
              }
              eventHandlingString += "Handling: " + handling;
            }
            if (description) {
              if (eventHandlingString !== "<li>") {
                eventHandlingString += "; ";
              }
              eventHandlingString += "Description: " + description;
            }
            eventHandlingString += "</li>";
            eventHandlingEntries.push(eventHandlingString);
          }
          eventHandling = "<ul>" + eventHandlingEntries.join("") + "</ul>";
        }
        let estimandDetail = "<p><b>" + label + "</b></p><table><tbody>";
        if (type) {
          estimandDetail += "<tr><td><b>Type</b></td><td>" + type + "</td></tr>";
        }
        if (description) {
          estimandDetail += "<tr><td><b>Description</b></td><td>" + description + "</td></tr>";
        }
        if (population) {
          estimandDetail += "<tr><td><b>Population</b></td><td>" + population + "</td></tr>";
        }
        if (intervention) {
          estimandDetail += "<tr><td><b>Intervention group</b></td><td>" + intervention + "</td></tr>";
        }
        if (comparator) {
          estimandDetail += "<tr><td><b>Control group</b></td><td>" + comparator + "</td></tr>";
        }
        estimandDetail += "<tr><td><b>Endpoint</b></td><td>" + endpoint + "</td></tr>";
        if (summaryMeasure) {
          estimandDetail += "<tr><td><b>Population-level summary</b></td><td>" + summaryMeasure + "</td></tr>";
        }
        if (eventHandling) {
          estimandDetail += "<tr><td><b>Handling of Intercurrent Events</b></td><td>" + eventHandling + "</td></tr>";
        }
        estimandDetail += "</tbody></table><br/>";
        objectiveDetail += estimandDetail;

      }
    }
    if (objective.extension?.length > 0) {
      objectiveDetail += "<p><b>Estimands:</b></p>";
      for (let extension of objective.extension) {
        if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand") {
          let label = "Unnamed estimand";
          let endpoint = "Undefined endpoint";
          let population = "Undefined population";
          let intervention = "Undefined intervention group";
          let comparator = "Undefined comparator group";
          let summaryMeasure = "Undefined summary measure";
          let eventHandling = "Undefined event handling";
          let eventHandlingArray = [];
          let extensions = extension.extension || [];
          if (extensions.length > 0) {
            for (const ext of extensions) {
              if (ext.url === "label" && ext.valueString) {
                label = ext.valueString;
              } else if (ext.url === "endpoint" && ext.valueReference) {
                endpoint = getStringFromFHIR.Reference(ext.valueReference);
              } else if (ext.url === "population" && ext.valueReference) {
                population = getStringFromFHIR.Reference(ext.valueReference);
              } else if ((ext.url === "interventionGroup" || ext.url === "intervention") && ext.valueId) {
                intervention = comparisonGroupDictionary[ext.valueId] || ext.valueId;
              } else if ((ext.url === "comparatorGroup" || ext.url === "comparator") && ext.valueId) {
                comparator = comparisonGroupDictionary[ext.valueId] || ext.valueId;
              } else if (ext.url === "summaryMeasure" && ext.valueCodeableConcept) {
                summaryMeasure = getStringFromFHIR.CodeableConcept(ext.valueCodeableConcept);
              } else if (ext.url === "eventHandling" && ext.extension) {
                eventHandlingArray.push(ext);
              }
            }
          }
          if (eventHandlingArray.length > 0) {
            let eventHandlingEntries = [];
            for (let entry of eventHandlingArray) {
              let description = "";
              let event = "";
              let group = "";
              let handling = "";
              if (entry.extension) {
                for (let extension of entry.extension) {
                  if (extension.url === "description" && extension.valueMarkdown) {
                    description = extension.valueMarkdown;
                  } else if (extension.url === "event" && extension.valueCodeableConcept) {
                    event = getStringFromFHIR.CodeableConcept(extension.valueCodeableConcept);
                  } else if (extension.url === "group" && extension.valueCodeableConcept) {
                    group = getStringFromFHIR.CodeableConcept(extension.valueCodeableConcept);
                  } else if (extension.url === "handling" && extension.valueCodeableConcept) {
                    handling = getStringFromFHIR.CodeableConcept(extension.valueCodeableConcept);
                  }
                }
              }
              let eventHandlingString = "<li>";
              if (event) {
                eventHandlingString += "Event: " + event;
              }
              if (group) {
                if (eventHandlingString !== "<li>") {
                  eventHandlingString += "; ";
                }
                eventHandlingString += "Group: " + group;
              }
              if (handling) {
                if (eventHandlingString !== "<li>") {
                  eventHandlingString += "; ";
                }
                eventHandlingString += "Handling: " + handling;
              }
              if (description) {
                if (eventHandlingString !== "<li>") {
                  eventHandlingString += "; ";
                }
                eventHandlingString += "Description: " + description;
              }
              eventHandlingString += "</li>";
              eventHandlingEntries.push(eventHandlingString);
            }
            eventHandling = "<ul>" + eventHandlingEntries.join("") + "</ul>";
          }
          let estimandDetail = "<p><b>" + label +
            "</b></p><table><tbody><tr><td><b>Population</b></td><td>" + population +
            "</td></tr><tr><td><b>Treatment</b></td><td>Intervention group: " + intervention +
            "; Control group: " + comparator +
            "</td></tr><tr><td><b>Endpoint</b></td><td>" + endpoint +
            "</td></tr><tr><td><b>Population-level summary</b></td><td>" + summaryMeasure +
            "</td></tr><tr><td><b>Handling of Intercurrent Events</b></td><td>" + eventHandling +
            "</td></tr></tbody></table><br/>";
          objectiveDetail += estimandDetail;
        }
      }
    }
    if (objectiveDetail) {
      objectivesDetail += objectiveDetail;
    }
  }
  if (!objectivesDetail) {
    objectivesDetail = "There was a problem in the data for these objectives."
  }
  return objectivesDetail;
}

const generateM11EstimandsSectionFromResearchStudySummary = async (researchStudyFoi, resourceDictionary, globalContext) => {
  let newResearchStudyJson;
  if (researchStudyFoi) {
    if (resourceDictionary && resourceDictionary[researchStudyFoi]) {
      newResearchStudyJson = resourceDictionary[researchStudyFoi];
    } else {
      const bodyA = {
        'functionid': "getfhirresource",
        'idToken': "",
        'resourceid': researchStudyFoi
      };
      let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
      if (responseA.success) {
        newResearchStudyJson = JSON.parse(responseA.fhirjsonstring);
      };
    }
    if (newResearchStudyJson) {
      let comparisonGroups = newResearchStudyJson.comparisonGroup || [];
      let comparisonGroupDictionary = {};
      for (let comparisonGroup of comparisonGroups) {
        if (comparisonGroup.linkId) {
          comparisonGroupDictionary[comparisonGroup.linkId] = comparisonGroup.name || comparisonGroup.linkId;
        } else if (comparisonGroup.name) {
          comparisonGroupDictionary[comparisonGroup.name] = comparisonGroup.name;
        }
      }
      let objectives = newResearchStudyJson.objective || [];
      let primaryObjectives = [];
      let secondaryObjectives = [];
      let exploratoryObjectives = [];
      let untypedObjectives = [];
      for (let objective of objectives) {
        if (objective.type?.coding?.[0]?.code === "primary") {
          primaryObjectives.push(objective);
        } else if (objective.type?.coding?.[0]?.code === "secondary") {
          secondaryObjectives.push(objective);
        } else if (objective.type?.coding?.[0]?.code === "exploratory") {
          exploratoryObjectives.push(objective);
        } else {
          untypedObjectives.push(objective);
        }
      }
      let primaryObjectivesNarrative = "";
      let secondaryObjectivesNarrative = "";
      let exploratoryObjectivesNarrative = "";
      let untypedObjectivesNarrative = "";
      if (primaryObjectives.length > 0) {
        let objectivesDetail = generateObjectivesNarrative(primaryObjectives, comparisonGroupDictionary);
        primaryObjectivesNarrative = "<div><h4>Prmary Objectives and Associated Estimands</h4>" + objectivesDetail + "</div>"
      }
      if (secondaryObjectives.length > 0) {
        let objectivesDetail = generateObjectivesNarrative(secondaryObjectives, comparisonGroupDictionary);
        secondaryObjectivesNarrative = "<div><h4>Secondary Objectives and Associated Estimands</h4>" + objectivesDetail + "</div>"
      }
      if (exploratoryObjectives.length > 0) {
        let objectivesDetail = generateObjectivesNarrative(exploratoryObjectives, comparisonGroupDictionary);
        exploratoryObjectivesNarrative = "<div><h4>Exploratory Objectives</h4>" + objectivesDetail + "</div>"
      }
      if (untypedObjectives.length > 0) {
        let objectivesDetail = generateObjectivesNarrative(untypedObjectives, comparisonGroupDictionary);
        untypedObjectivesNarrative = "<div><h4>Untyped Objectives</h4>" + objectivesDetail + "</div>"
      }
      let objectivesNarrative = primaryObjectivesNarrative + secondaryObjectivesNarrative +
        exploratoryObjectivesNarrative + untypedObjectivesNarrative;
      let newText = JSON.parse(JSON.stringify(emptyTextNoData));
      if (objectivesNarrative) {
        let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + objectivesNarrative + "</p></div>";
        newText = { status: "generated", div: divContainedNarrative };
      }
      return newText.div;
    }
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>";
}

const generateVariablesSummary = async (variableFoiList, resourceDictionary, globalContext) => {
  let variablesSummary = "";
  for (const variableFoi of variableFoiList) {
    let newEvidenceVariableJson;
    if (variableFoi) {
      if (resourceDictionary && resourceDictionary[variableFoi]) {
        newEvidenceVariableJson = resourceDictionary[variableFoi];
      } else {
        const bodyA = {
          'functionid': "getfhirresource",
          'idToken': "",
          'resourceid': variableFoi
        };
        let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
        if (responseA.success) {
          newEvidenceVariableJson = JSON.parse(responseA.fhirjsonstring);
        };
      }
      if (newEvidenceVariableJson) {
        let title = newEvidenceVariableJson.title || newEvidenceVariableJson.name || "";
        let boldedTitle = "";
        if (title) {
          boldedTitle = "<b>" + title + "</b>";
        }
        let description = "";
        if (newEvidenceVariableJson.description) {
          description = " " + newEvidenceVariableJson.description;
        }
        let narrative = boldedTitle + description;
        if (variablesSummary) {
          variablesSummary += "; ";
        }
        variablesSummary += narrative;
      }
    }
  }
  if (!variablesSummary) {
    variablesSummary = "[No data.]";
  }
  return "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + variablesSummary + "</p></div>";
}

export {
  generateCompositionSummary, generateEvidenceSummary, generateEvidenceVariableSummary,
  generateGroupSummary, generateResearchStudySummary, generateSubgroupsSummary,
  generateM11TitlePageFromResearchStudySummary, generateM11InclusionCriteriaFromGroupSummary,
  generateM11ExclusionCriteriaFromGroupSummary, generateM11EstimandsSectionFromResearchStudySummary,
  generateCalculatedInterventionRateSummary, generateVariablesSummary
};