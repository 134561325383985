import React, { useState, useEffect, useImperativeHandle } from 'react';
import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const CommentEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {

  let startingArtifactReferenceDatatype = 'none';
  if (fhirJson.artifactReference) { startingArtifactReferenceDatatype = 'Reference'; }
  if (fhirJson.artifactCanonical) { startingArtifactReferenceDatatype = 'canonical'; }
  if (fhirJson.artifactUri) { startingArtifactReferenceDatatype = 'uri'; }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "ArtifactAssessment", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser,
    "citeAs": fhirJson.citeAs, "relatesTo": fhirJson.relatesTo || fhirJson.relatedArtifact,
    "artifactReference": fhirJson.artifactReference, "artifactCanonical": fhirJson.artifactCanonical, "artifactUri": fhirJson.artifactUri,
    "content": fhirJson.content, "workflowStatus": fhirJson.workflowStatus, "disposition": fhirJson.disposition,
    "citeAsReference": fhirJson.citeAsReference, "citeAsMarkdown": fhirJson.citeAsMarkdown
  });
  const [artifactReferenceEditState, setArtifactReferenceEditState] = useState(false);
  const [artifactReferenceDatatypeState, setArtifactReferenceDatatypeState] = useState(startingArtifactReferenceDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    commentState: resourceState
  }), [resourceState]);
  useEffect((() => {
    if (artifactReferenceDatatypeState === 'uri') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'artifactUri': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactUri': null } })
    }
  }), [artifactReferenceDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="comment-on">Comment On</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title of this Comment Resource'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <br /><br />
        <div><b>The Resource Being Commented On: </b>
          <DisplayFromFHIR reference={resourceState.artifactReference} />
          <DisplayFromFHIR uri={resourceState.artifactUri} />
          <span className={"unselectable"} style={{ marginLeft: "24px", cursor: "pointer" }} onClick={() => setArtifactReferenceEditState(!artifactReferenceEditState)}>
            <i>Change the Resource Being Commented On</i>&nbsp;&nbsp;
            {artifactReferenceEditState ? <>▼</> : <>►</>}
          </span>
        </div>
        {artifactReferenceEditState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
            <DatatypeSelector elementXName='artifact[x]' allowedDatatypes={['Reference', 'canonical', 'uri']}
              datatypeState={artifactReferenceDatatypeState} setDatatypeState={setArtifactReferenceDatatypeState} />
            {(artifactReferenceDatatypeState === 'uri') &&
              <DataEntry datatype='uri' elementName='artifactUri'
                fieldLabel='The Resource Being Commented On (by URL)' startingValue={resourceState.artifactUri || null}
                setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'Reference') &&
              <DataEntry datatype='Reference' elementName='artifactReference' fieldLabel='The Resource Being Commented On'
                startingValue={resourceState.artifactReference || null} setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'canonical') &&
              <DataEntry datatype='uri' elementName='artifactCanonical' fieldLabel='The Resource Being Commented On (by Canonical URI)'
                startingValue={resourceState.artifactCanonical || null} setResourceState={setResourceState} />}
            <br />
          </div>
        }
      </div>
      <h3 id="comments">Comments</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Comment' elementName='content'
          fieldLabel='Comment' startingValue={resourceState.content} setResourceState={setResourceState} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CommentEdit;