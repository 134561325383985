import React, { useState, useContext } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { AssociatedResourcesDisplay, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { getStringFromFHIR } from './ResourceFunctions';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const SectionDisplay = ({ sect, sectIndex }) => {
  if (!sect || !Object.keys(sect).length) {
    return <div key={sectIndex} >
      <br />
      <p><b>{'Section ' + (sectIndex + 1).toString()}:</b></p>
      <>No content in this section.</>
    </div>

  }
  return <div key={sectIndex} >
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(sect.code && Object.keys(sect.code).length > 0) && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(sect.focus && Object.keys(sect.focus).length > 0) && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(sect.text && Object.keys(sect.text).length > 0) && <div>
        {sect.text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={sect.text.div} />
            </div>
          </div>
        }
        <br />
      </div>}
      {(sect.orderedBy && Object.keys(sect.orderedBy).length > 0) && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
        sect.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {(sect.emptyReason && Object.keys(sect.emptyReason).length > 0) && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex} /></span>;
        })}
    </div>
  </div>
}

const TopSectionDisplay = ({ topSection }) => {

  if (!topSection || !Object.keys(topSection).length) {
    return <>No content in this section.</>
  }

  let title;
  if (topSection.title) {
    title = topSection.title;
  }
  let code;
  if (topSection.code && Object.keys(topSection.code).length > 0) {
    code = topSection.code;
  }
  let author;
  if (Array.isArray(topSection.author) && topSection.author.length > 0) {
    author = topSection.author
  }
  let focus;
  if (topSection.focus && Object.keys(topSection.focus).length > 0) {
    focus = topSection.focus;
  }
  let text;
  if (topSection.text && Object.keys(topSection.text).length > 0) {
    text = topSection.text;
  }
  let orderedBy;
  if (topSection.orderedBy && Object.keys(topSection.orderedBy).length > 0) {
    orderedBy = topSection.orderedBy;
  }
  let entry;
  if (Array.isArray(topSection.entry) && topSection.entry.length > 0) {
    entry = topSection.entry
  }
  let emptyReason;
  if (topSection.emptyReason && Object.keys(topSection.emptyReason).length > 0) {
    emptyReason = topSection.emptyReason;
  }
  let section;
  if (Array.isArray(topSection.section) && topSection.section.length > 0) {
    section = topSection.section
  }
  let titleDisplay = title || getStringFromFHIR.CodeableConcept(code);

  return <div>
    <p><b>{titleDisplay}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {focus && <span><b>Focus: </b><DisplayFromFHIR reference={focus} /><br /></span>}
      {(Array.isArray(author) && author.length > 0) &&
        author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(text && Object.keys(text).length > 0) && <div>
        {text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={orderedBy} /><br /></span>}
      {(Array.isArray(entry) && entry.length > 0) &&
        entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={emptyReason} /><br /></span>}
      {(Array.isArray(section) && section.length > 0) &&
        section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}>
            <SectionDisplay sect={subsection} sectIndex={subsectionIndex} />
          </span>;
        })}
    </div>
  </div>
}

const M11ReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatesTo) && fhirJson.relatesTo.length > 0) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.targetMarkdown || relatedArtifactEntry.targetReference?.display || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }

  const globalContext = useContext(FevirContext);
  const [apiUrlState, setApiUrlState] = useState("");

  let section1 = {};
  let section2 = {};
  let section3 = {};
  let section4 = {};
  let section5 = {};
  let section6 = {};
  let section7 = {};
  let section8 = {};
  let section9 = {};
  let section10 = {};
  let section11 = {};
  let section12 = {};
  let section13 = {};
  let section14 = {};
  let section15 = {};
  if (Array.isArray(fhirJson?.section)) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "section1-protocol-summary") {
        section1 = section;
      } else if (sectionCode === "section2-introduction") {
        section2 = section;
      } else if (sectionCode === "section3-estimands") {
        section3 = section;
      } else if (sectionCode === "section4-trial-design") {
        section4 = section;
      } else if (sectionCode === "section5-trial-population") {
        section5 = section;
      } else if (sectionCode === "section6-trial-intervention") {
        section6 = section;
      } else if (sectionCode === "section7-participant-discontinuation") {
        section7 = section;
      } else if (sectionCode === "section8-assessments") {
        section8 = section;
      } else if (sectionCode === "section9-adverse-events") {
        section9 = section;
      } else if (sectionCode === "section10-statistics") {
        section10 = section;
      } else if (sectionCode === "section11-oversight") {
        section11 = section;
      } else if (sectionCode === "section12-supporting-details") {
        section12 = section;
      } else if (sectionCode === "section13-glossary") {
        section13 = section;
      } else if (sectionCode === "section14-references") {
        section14 = section;
      } else if (sectionCode === "section0-title-page") {
        section15 = section;
      }
    }
  }

  return <div>
    <br />
    <div style={{ marginLeft: "24px" }}>
      <Dropdown placeholder={"Pick a URL to send to"} closeOnChange selection clearable selectOnBlur={false}
        style={{ width: "280px" }}
        options={[{ "text": "https://fs-01.azurewebsites.net", "value": "https://fs-01.azurewebsites.net", "key": "https://fs-01.azurewebsites.net" }]}
        value={apiUrlState}
        onChange={(e, data) => { setApiUrlState(data.value) }}
      />
      &nbsp;&nbsp;&nbsp;
      <Button className="formButton" style={{ color: "#000000" }} content={"Send via API"}
        disabled={!apiUrlState}
        onClick={async () => {
          try {
            let bundleJson;
            let bundleFoi;
            let fli;
            try {
              let fliPeriod = howToCite.split("fevir.net/FLI/")[1];
              fli = fliPeriod.split(".")[0];
              if (fli) {
                const body = {
                  'functionid': 'getfoilistfromflilist',
                  'fliList': [fli],
                  'idToken': ""
                };
                let response = await submitToFevirServer(globalContext, 100000, body, true, false);
                if (response?.success && response.foilist?.[0]) {
                  let foi = response.foilist[0];
                  if (foi) {
                    bundleFoi = foi;
                  }
                } else {
                  if (fhirJson.meta.versionId == "1") {
                    globalContext.openAlert({ header: "Error", content: "A Bundle for this Composition could not be found. Please try clicking \"Edit M11 Report\" and then \"Update\" button." });
                  } else {
                    globalContext.openAlert({ header: "Error", content: "A Bundle for this Composition could not be found. Please contact the FEvIR administrators." });
                  }
                }
              }
            } catch { }
            if (bundleFoi) {
              const body = {
                'functionid': 'getfhirresource',
                'resourceid': bundleFoi,
                'resourcetype': "Bundle",
                'idToken': ""
              };
              let response = await submitToFevirServer(globalContext, 10000, body, true, false);
              if (response?.success && response.fhirjsonstring) {
                bundleJson = JSON.parse(response.fhirjsonstring);
                delete bundleJson.status;
                delete bundleJson.url;
                delete bundleJson.author;
              }
            }

            if (bundleJson) {
              let body = { functionid: "externalapicall", method: "PUT", timeout: 119000, url: apiUrlState + "/Bundle/" + bundleJson.id, data: JSON.stringify(bundleJson) };

              let response = await submitToFevirServer(globalContext, 120000, body, true, false);
              console.log(response);
              if (response?.error) {
                if (response.response) {
                  delete response.response.id;
                  delete response.response.meta;
                  globalContext.openAlert({ header: "ERROR (also see console)", content: JSON.stringify(response.response, null, 2) });
                } else {
                  globalContext.openAlert({ header: "ERROR (also see console)", content: JSON.stringify(response, null, 2) });
                }
              } else {
                globalContext.openAlert({ header: "Success", content: "Resource submitted via API!" });
              }
            }
          } catch (e) { console.log(e); }
        }} />
    </div>
    <br />
    <p>
      <b>View PDF: </b>
      <a href={'https://computablepublishing.com/Composition/' + fhirJson.id}
        target='_blank' rel='noopener noreferrer'>View or download PDF</a>
    </p>
    <div style={{ marginTop: "12px" }}>
      <h3 id="title-page">Title Page</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section15} />
      </div>
      <br />
      <h3 id="1-protocol-summary">1 Protocol Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section1} />
      </div>
      <br />
      <h3 id="2-introduction">2 Introduction</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section2} />
      </div>
      <br />
      <h3 id="3-estimands">3 Estimands</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section3} />
      </div>
      <br />
      <h3 id="4-trial-design">4 Trial Design</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section4} />
      </div>
      <br />
      <h3 id="5-trial-population">5 Trial Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section5} />
      </div>
      <br />
      <h3 id="6-trial-intervention">6 Trial Intervention</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section6} />
      </div>
      <br />
      <h3 id="7-participant-discontinuation">7 Participant Discontinuation</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section7} />
      </div>
      <br />
      <h3 id="8-trial-assessments">8 Trial Assessments</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section8} />
      </div>
      <br />
      <h3 id="9-adverse-events">9 Adverse Events</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section9} />
      </div>
      <br />
      <h3 id="10-statistics">10 Statistics</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section10} />
      </div>
      <br />
      <h3 id="11-oversight">11 Oversight</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section11} />
      </div>
      <br />
      <h3 id="12-supporting-details">12 Supporting Details</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section12} />
      </div>
      <br />
      <h3 id="13-glossary">13 Glossary</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section13} />
      </div>
      <br />
      <h3 id="14-references">14 References</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={section14} />
      </div>
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default M11ReportViewerTool;