import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Segment, TextArea, Dimmer, Loader, Tab } from 'semantic-ui-react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import "firebase/compat/auth";
import GenericToggleButtons from './GenericToggleButtons';
import NavigationSegment from './NavigationSegment';
import { SectionDetail, SectionDetailLeftSideDisplay } from './SectionDetail';
import SchemaElementDisplay from './SchemaElementDisplay';
import CitationDisplay from './CitationDisplay';
import { CodeSystemTermDetailTabRouting, TermDetailLeftSideDisplay } from './CodeSystemTermEdit';
import RobatTermDisplay from './RobatTermDisplay';
import { ComparisonDetailLeftSideDisplay, ComparisonDetailTabRouting } from './ComparisonDetailDisplay';
import { EvidenceVariableCharacteristicDetailLeftSideDisplay, EvidenceVariableCharacteristicDetailTabRouting } from './EvidenceVariableCharacteristicEdit';
import ResourceBuilderViewer from './ResourceBuilderViewer';
import QuestionnaireDisplay from './QuestionnaireDisplay';
import RecommendationDisplay from './RecommendationDisplay';
import RiskOfBiasAssessmentReader from './RiskOfBiasAssessmentReader';
import CodeSystemTermProgressTable from './CodeSystemTermProgressTable';
import submitToFevirServer from './SubmitToFevirServer';
import { loadResourceStateByType } from './ResourceBuilder';
import { submitNewAdaptedResource } from './AdaptingCompositionFunctions';

import {
  SimpleResourceFieldViewer, getResourceComments, loadUsageView,
  createInviteLink, revokeInviteLink, builderUpdateJson, submitUpdatedFhirResource,
  addToProject, ReorganizeSectionsModal
} from './ResourceFunctions';
import { loadProjectResourcesList } from './ProjectFunctions';
import { findConceptByCode, findRobatConceptByCode, getAlternativeTerms, getPropertyCodes, findConceptProperties } from './CodeSystemFunctions';
import { characteristicNavigationEntryDisplay, navigateToAnotherCharacteristic } from './NavigationCharacteristicSegment';
import server_config from './ServerConfiguration';

import { startingLanguageOptions } from './CodeSystemLookup';
import { SearchResourcesToAddToProjectModal } from './SearchModal';

import './App.css';
import FevirContext from './FevirContext';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { VersionDifferenceViewer } from './VersionDifferencesFunctions';

const ports = server_config["ports"];
const server_address = server_config["address"];

const handleDownload = (event, jsonString, resourceId) => {
  event.preventDefault();
  console.log(jsonString)
  const blob = new Blob([jsonString], { type: 'text/json' });
  const a = document.createElement('a');
  a.download = 'FOI' + resourceId + '.json';
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const ResourceTextDisplay = ({ resourceType, resourceId, formInputsStateRef, getResource, changeFhirEntryState,
  referencedResourceState, setReferencedResourceState, changeFormState, firebase,
  userState, setFhirEntryState, languageOptions, setLanguageOptions, fhirEntryState, history,
  setChangeAvailableToSaveState, update,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  if (fhirEntryState.fhirEntry && fhirEntryState.fhirEntryString && fhirEntryState.fhirEntryString !== "{}") {
    try {
      let fhirJson;
      if (fhirEntryState.fhirEntryString) {
        fhirJson = JSON.parse(fhirEntryState.fhirEntryString)
      } else {
        fhirJson = fhirEntryState.fhirEntry;
      }
      if (resourceType === "Citation") {
        return <CitationDisplay resourceId={resourceId} formInputsStateRef={formInputsStateRef}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          changeFhirEntryState={changeFhirEntryState} changeFormState={changeFormState}
          languageOptions={languageOptions} setLanguageOptions={setLanguageOptions}
          userName={userState.name} />
      } else if (resourceType === "Project") {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState} resourceState={resourceState}
          history={history} update={update}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState} />
      } else if (resourceType === "Recommendation") {
        return <RecommendationDisplay fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
        />
      } else if (resourceType === "Questionnaire") {
        return <QuestionnaireDisplay changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
          userState={userState}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
        />
      } else if (resourceType === "Evidence" || resourceType === "ActivityDefinition" || resourceType === "PlanDefinition" ||
        resourceType === "SoftwareScript" || resourceType === "Composition" ||
        resourceType === "Group" || resourceType === "Library" || resourceType === "List" || resourceType === "Bundle" ||
        resourceType === "Measure" || resourceType === "ResearchStudy" || resourceType === "ValueSet" ||
        resourceType === "EvidenceVariable" || resourceType === "CodeSystem") {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
          fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          resourceState={resourceState} setResourceState={setResourceState}
          sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
          changeFhirEntryState={changeFhirEntryState} history={history}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
        />
      } else if (resourceType === "ArtifactAssessment" && (fhirEntryState.classification ||
        fhirEntryState.rating || fhirEntryState.comment || fhirEntryState.adaptation ||
        fhirEntryState.comparison || fhirEntryState.recommendationJustification)) {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} resourceState={resourceState}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState} update={update}
        />
      } else if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {
        return <RiskOfBiasAssessmentReader fhirJson={fhirJson} changeFormState={changeFormState} />
      } else if (resourceType === "SchemaElement") {
        return <SchemaElementDisplay changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
          fhirJson={fhirJson} fhirEntryState={fhirEntryState}
        />
      } else {
        return <div>
          <div style={{ marginTop: "12px" }}>
          </div>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} startExpanded={true} />
        </div>
      }

    } catch (e) {
      return <>Invalid {resourceType} JSON. See JSON Tab to see the resource.</>
    }
  } else {
    return <></>
  }
}

const ResourceDisplayContent = ({ history, resourceEntryState, pageEditModeState, setPageEditModeState,
  claimedResourceType, resourceType, resourceTypeURL, resourceId, getResource, formState, setFormState,
  changeFormState, firebase, indexPath, jsonView }) => {
  const globalContext = useContext(FevirContext);
  let userState = globalContext.userState;

  const { addToast } = useToasts();

  const formInputsStateRef = useRef({});

  const [fhirEntryState, setFhirEntryState] = useState({
    "fhirEntry": {}, "fhirEntryString": "",
    "fhirEntryStringChange": false, "loadedFhirEntryString": "", "title": "", "loading": true, "found": null,
    "classification": false, "rating": false, "comment": false, "adaptation": false, "comparison": false,
    "riskOfBiasAssessmentToolResource": false, "recommendationJustification": false,
    "systematicReviewProfile": false,
    "compositionProfiles": [],
    "editMode": false, "projectChangeMode": false,
    "readpermission": false, "editpermission": true, "loggedin": false, "status": "", "totalviews": null,
    "totalusersviewed": null, "totaledits": null, "totalusersedited": null, "projectsChecked": {},
    "projectsList": [], "projectsContain": [], "projectsContainIds": [], "projectResources": [],
    "startingStatus": "draft", "profileName": undefined, "newChildConcepts": [], "conceptCommentsExpanded": false,
    "conceptJsonExpanded": false, "comments": [], "startingVersionId": "0",
    "activeIndex": 0, 'jsonView': resourceEntryState.jsonView, 'viewMyWorkModalOpen': resourceEntryState.viewMyWorkModalOpen
  });

  useEffect(() => {
    let loadedActiveIndex = 0;

    if (jsonView) {
      if (resourceTypeURL === "CodeSystem" || resourceTypeURL === "Composition") {
        loadedActiveIndex = 2;
      } else {
        loadedActiveIndex = 1;
      }
    }

    if (loadedActiveIndex) {
      setFhirEntryState(prevState => { return { ...prevState, "activeIndex": loadedActiveIndex }; });
    }
  }, []);

  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [codeSystemTermProgressState, setCodeSystemTermProgressState] = useState({ "loading": true, "termProgress": {} });

  const [referencedResourceState, setReferencedResourceState] = useState({
    "populationVariableDefinitionIndex": undefined, "referencedGroup": {}, "referencedGroupString": "", "groupInclusionCharacteristics": [], "groupExclusionCharacteristics": [], "groupCharacteristics": [], "referencedIntendedGroup": {}, "referencedIntendedGroupString": "", "intendedGroupInclusionCharacteristics": [], "intendedGroupExclusionCharacteristics": [], "intendedGroupCharacteristics": [],
    "exposureVariableDefinitionIndex": undefined, "referencedObservedExposure": {}, "referencedObservedExposureString": "", "observedExposureCharacteristicCombination": [], "observedExposureCharacteristics": [], "referencedIntendedExposure": {}, "referencedIntendedExposureString": "", "intendedExposureCharacteristicCombination": [], "intendedExposureCharacteristics": [],
    "referenceExposureVariableDefinitionIndex": undefined, "referencedObservedReferenceExposure": {}, "referencedObservedReferenceExposureString": "", "observedReferenceExposureCharacteristicCombination": [], "observedReferenceExposureCharacteristics": [], "referencedIntendedReferenceExposure": {}, "referencedIntendedReferenceExposureString": "", "intendedReferenceExposureCharacteristicCombination": [], "intendedReferenceExposureCharacteristics": [],
    "outcomeVariableDefinitionIndex": undefined, "referencedObservedOutcome": {}, "referencedObservedOutcomeString": "", "observedOutcomeCharacteristicCombination": [], "observedOutcomeCharacteristics": [], "referencedIntendedOutcome": {}, "referencedIntendedOutcomeString": "", "intendedOutcomeCharacteristicCombination": [], "intendedOutcomeCharacteristics": []
  });

  const [languageOptions, setLanguageOptions] = useState({ "options": startingLanguageOptions });

  const [locationKeys, setLocationKeys] = useState([]);
  const [searchToAddToProjectModalOpenState, setSearchToAddToProjectModalOpenState] = useState({});
  const [resourceState, setResourceState] = useState({});
  const [sourceJsonState, setSourceJsonState] = useState({});
  const [reorganizeConceptsModalState, setReorganizeConceptsModalState] = useState({});

  useEffect(() => {
    return history.listen(location => {
      /*
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ]);
      }
      */

      if (history.action === "POP") {
        setFhirEntryState(prevState => { return { ...prevState }; });
        /*
        if (locationKeys[1] === location.key) {
          // Handle back event
          setLocationKeys(([ _, ...keys ]) => keys);
  
        } else {
          // Handle forward event 
          setLocationKeys((keys) => [ location.key, ...keys ]);
        }
        */
      } else if (history.action === "PUSH") {
        //setFhirEntryState(prevState  => { return { ...prevState, conceptName: "" }; });
      }
    })
  }, [locationKeys]);

  const updateFhirEntryStateFunction = async () => {

    if (resourceEntryState !== undefined && resourceEntryState.found !== null) {
      let fhirJsonString = resourceEntryState["fhirResource"];
      let fhirJson;
      let status = resourceEntryState.status;
      let found = resourceEntryState.found;
      let loggedin = resourceEntryState.loggedin;
      let readpermission = resourceEntryState.readpermission;
      let editpermission = resourceEntryState.editpermission;
      let adminpermission = resourceEntryState.adminpermission;

      //let commentslist = resourceEntryState.commentslist;
      let commentsdict = resourceEntryState.commentsdict;
      //let voteslist = resourceEntryState.voteslist;
      let votesdict = resourceEntryState.votesdict;
      //let myvoteslist = resourceEntryState.myvoteslist;
      let myvotesdict = resourceEntryState.myvotesdict;

      let fhirEntryTitle = fhirEntryState.title;

      let fhirEntryStatus = fhirEntryState.status;
      let fhirEntryEditMode = fhirEntryState.editMode;
      let fhirEntryStartingVersionId = fhirEntryState.startingVersionId;
      let fhirEntryStartingStatus = fhirEntryState.startingStatus;

      let riskOfBiasAssessmentToolResource = fhirEntryState.riskOfBiasAssessmentToolResource;
      let recommendationJustification = fhirEntryState.recommendationJustification;
      let systematicReviewProfile = fhirEntryState.systematicReviewProfile;
      let compositionProfiles = fhirEntryState.compositionProfiles;
      let classification = fhirEntryState.classification;
      let rating = fhirEntryState.rating;
      let comment = fhirEntryState.comment;
      let adaptation = fhirEntryState.adaptation;
      let comparison = fhirEntryState.comparison;

      let profileName = fhirEntryState.profileName;

      if (found && readpermission && fhirJsonString) {
        found = true;
        fhirJson = JSON.parse(fhirJsonString);
        fhirEntryTitle = resourceEntryState["title"];
        changeFormState(fhirEntryTitle, "tempResourceTitle");
        changeFormState(resourceId, "tempResourceId");

        if (resourceType === "ArtifactAssessment" && !riskOfBiasAssessmentToolResource) { //Checking to see if riskOfBiasAssessmentToolResource is not yet set
          for (let useContextIndex in fhirJson.useContext) {
            let useContext = fhirJson.useContext[useContextIndex];
            if (useContext.code?.display === "to be viewed by this tool" && useContext.valueCodeableConcept?.text === "Computable Publishing: Risk of Bias Assessment Tool") {
              riskOfBiasAssessmentToolResource = true;
              break;
            }
          }
          if (profileName) {

          } else {
            if (riskOfBiasAssessmentToolResource) {
              for (let contentIndex in fhirJson.content) {
                let content = fhirJson.content[contentIndex];
                if (content.type?.text === "Profile") {
                  for (let classifierIndex in content.classifier) {
                    let classifier = content.classifier[classifierIndex];
                    if (classifier.text) {
                      profileName = classifier.text;
                      break;
                    }
                  }
                }
                if (profileName) {
                  break;
                }
              }
            }
          }
        }
        if (resourceType === "ArtifactAssessment") {
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification") ||
            fhirJson.meta?.profile?.includes("https://fevir.net/Profile/RecommendationJustification")) {
            recommendationJustification = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/classification") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/classification")) {
            classification = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/rating") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/rating")) {
            rating = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comment") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comment")) {
            comment = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation")) {
            adaptation = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison")) {
            comparison = true;
          }
        }

        if (resourceType === "ResearchStudy") {
          if (fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/systematic-review")) {
            systematicReviewProfile = true;
          }
        }

        if (resourceType === "Composition") {
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
            compositionProfiles.push("recommendationProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
            compositionProfiles.push("guidelineProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
            compositionProfiles.push("evidenceReportPackageProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
            compositionProfiles.push("m11reportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
            compositionProfiles.push("summaryOfFindingsProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
            compositionProfiles.push("comparativeEvidenceReportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report")) {
            compositionProfiles.push("comparativeEvidenceSynthesisReportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
            compositionProfiles.push("baselineMeasureReportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
            compositionProfiles.push("participantFlowReportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
            compositionProfiles.push("outcomeMeasureReportProfile");
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report")) {
            compositionProfiles.push("outcomeMeasureSynthesisReportProfile");
          }
        }

        if (status) {
        } else if (fhirJson.status && fhirJsonString) {
          status = JSON.parse(fhirJsonString)["status"];
        }
      }
      if (resourceEntryState.startEditMode && editpermission) {
        fhirEntryEditMode = true;
      }

      if (pageEditModeState === resourceId && resourceType === "ArtifactAssessment" && riskOfBiasAssessmentToolResource) {
        fhirEntryEditMode = true;
      }

      fhirEntryStatus = status;
      if (fhirJson?.meta?.versionId) {
        fhirEntryStartingVersionId = fhirJson.meta.versionId;
      }
      if (fhirJson?.status) {
        fhirEntryStartingStatus = fhirJson.status;
      }

      setFhirEntryState(prevState => {
        return {
          ...prevState,
          found: found,
          loggedin: loggedin,
          readpermission: readpermission,
          editpermission: editpermission,
          adminpermission: adminpermission,
          title: fhirEntryTitle,
          status: fhirEntryStatus,
          startingStatus: fhirEntryStartingStatus,
          startingVersionId: fhirEntryStartingVersionId,
          loadedFhirEntryString: fhirJsonString,
          fhirEntryString: fhirJsonString,
          classification: classification,
          rating: rating,
          comment: comment,
          adaptation: adaptation,
          comparison: comparison,
          riskOfBiasAssessmentToolResource: riskOfBiasAssessmentToolResource,
          recommendationJustification: recommendationJustification,
          systematicReviewProfile: systematicReviewProfile,
          compositionProfiles: compositionProfiles,
          profileName: profileName,
          //commentslist: commentslist,
          commentsdict: commentsdict,
          //voteslist: voteslist,
          votesdict: votesdict,
          //myvoteslist: myvoteslist,
          myvotesdict: myvotesdict,
          editMode: fhirEntryEditMode,
          loading: false
        };
      });

      if (resourceType === "Project") {
        loadProjectResourcesList(setFhirEntryState, resourceType, resourceId, false, globalContext);
      }
    }
  };

  useEffect(() => {
    updateFhirEntryStateFunction();
  }, [resourceEntryState]);

  const associateResourceWithProjectFunction = async () => {
    if (searchToAddToProjectModalOpenState.selectedResourceReference) {
      let targetfoi = searchToAddToProjectModalOpenState.selectedResourceReference;
      let projectfoi = resourceId;
      //setReferenceState(selectResourceFromSearchState.selectedResourceReference); REPLACE WITH ACTION
      try {
        const associateProjectBody = {
          'functionid': 'associateresourcewithproject',
          'idToken': '',
          'targetfoi': targetfoi,
          'projectfoi': projectfoi
        };
        let response = {};
        response = await submitToFevirServer(globalContext, 10000, associateProjectBody, true, false);
        if (!response.success) {
          alert("Problem associating the Resource with the Project");
        }
      } catch (e) { alert("Problem associating the Resource with the Project"); }
    }
  };

  useEffect((() => {
    associateResourceWithProjectFunction();
  }), [searchToAddToProjectModalOpenState.selectedResourceReference]);

  const updateFhirEntryStateFunctionAgain = async () => {

    if (fhirEntryState.loading && pageEditModeState && indexPath.length > 0) {
      if ((resourceType === "ArtifactAssessment" || claimedResourceType === "ArtifactAssessment") || (resourceType === "EvidenceVariable" || claimedResourceType === "EvidenceVariable")) {
        if (fhirEntryState.activeIndex !== 1) {
          setFhirEntryState(prevState => { return { ...prevState, activeIndex: 1 }; });
        }
      }
    }

    if (resourceType !== "Citation" && resourceType !== "Project" && resourceType !== "Evidence" &&
      resourceType !== "EvidenceVariable" && resourceType !== "Group" && resourceType !== "Composition" &&
      resourceType !== "PlanDefinition" && resourceType !== "Library" && resourceType !== "List" && resourceType !== "Measure" &&
      resourceType !== "ActivityDefinition" && resourceType !== "SoftwareScript" &&
      resourceType !== "ArtifactAssessment" && resourceType !== "CodeSystem" &&
      resourceType !== "ResearchStudy" && resourceType !== "ValueSet" && resourceType !== "SchemaElement" && resourceType !== "Bundle") {

      if (claimedResourceType === "ResearchStudy") {
        changeFormState("FEvIR®: ResearchStudy Viewer", "pageTitle");
      } else if (claimedResourceType !== "ArtifactAssessment" && claimedResourceType !== "EvidenceVariable") {
        changeFormState("FEvIR®: Resource Viewer", "pageTitle");
      }
    } else if (resourceType === "CodeSystem") {
      let stateChanged = false;
      let comments;
      let votes;
      let myvotes;
      if (fhirEntryState.editMode) {
        changeFormState("FEvIR®: CodeSystem Builder", "pageTitle");
      } else {
        changeFormState("FEvIR®: CodeSystem Viewer", "pageTitle");
      }
      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          if (fhirEntryState.hasApprovalProperty == undefined) {
            if (json.property) {
              for (let property of json.property) {
                if (property.code && property.code == "approval") {
                  setFhirEntryState(prevState => { return { ...prevState, hasApprovalProperty: true }; });
                  break;
                }
              }
            }
          }
          if (fhirEntryState.conceptCode && (fhirEntryState.conceptCommentsExpanded || fhirEntryState.conceptVotesExpanded || fhirEntryState.conceptMyVotesExpanded)) {
            if ((fhirEntryState.comments === undefined || fhirEntryState.comments.length === 0 || fhirEntryState.votes === undefined || fhirEntryState.votes.length === 0 || fhirEntryState.myvotes === undefined || fhirEntryState.myvotes.length === 0)) {
              let commentsvotesresponse = await getResourceComments(globalContext, fhirEntryState, resourceId);
              comments = commentsvotesresponse.comments;
              votes = commentsvotesresponse.votes;
              myvotes = commentsvotesresponse.myvotes;
            }
          } else {
            comments = [];
            votes = [];
            myvotes = [];
          }

          if ((indexPath?.length > 0 || (fhirEntryState.conceptPath === undefined || fhirEntryState.conceptPath?.length === 0)) && (fhirEntryState.fhirEntryString && Object.keys(JSON.parse(fhirEntryState.fhirEntryString)).length > 0)) {
            if (fhirEntryState.submittingToServer) {
              let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
                setFhirEntryState, globalContext, history, changeFormState, false, undefined);
            } else {

              let concept = json;
              let conceptCode = indexPath[0];
              let conceptPath = [];
              let indexPathIndexes = indexPath.slice(1, indexPath.length);
              let found = false;
              if (indexPathIndexes.length > 0) {
                for (let indexPathIndex in indexPathIndexes) {
                  let currentIndex = indexPathIndexes[indexPathIndex];
                  if (concept) {
                    concept = concept.concept;
                    if (concept && concept[currentIndex]) {
                      concept = concept[currentIndex];
                      conceptPath.push(concept.display);
                    }
                  }
                }
                if (concept && (conceptCode === concept.code || conceptCode === "undefined")) {
                  found = true;
                }
              }

              if (found === false) {
                let conceptFound = findConceptByCode(json, conceptCode, [], []);
                if (conceptFound?.length > 0) {
                  concept = conceptFound[0];
                  conceptPath = conceptFound[1];
                  indexPathIndexes = conceptFound[2];
                }
                if (concept && (conceptCode === concept.code || conceptCode === "undefined")) {
                  found = true;
                }
              }

              if (found && fhirEntryState.conceptCode !== concept.code && fhirEntryState.conceptName !== concept.display && fhirEntryState.termChanged !== true && !(fhirEntryState.newChildConcepts?.length > 0)) {

                let propertyProperties = getPropertyCodes(json);
                let propertyCodes = propertyProperties["propertyCodes"];
                let conceptProperties = findConceptProperties(concept, propertyCodes);
                let conceptOpenForVoting = false;
                let conceptPropertyComment = "";
                if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                  conceptOpenForVoting = true;
                }
                if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                  conceptPropertyComment = conceptProperties["comment"];
                }
                let propertyDescriptions = propertyProperties["propertyDescriptions"];
                stateChanged = true;
                setFhirEntryState(prevState => {
                  return {
                    ...prevState,
                    submittingToServer: false,
                    conceptName: concept.display,
                    conceptCode: concept.code,
                    conceptSystem: undefined,
                    conceptDefinition: concept.definition,
                    conceptPath: conceptPath,
                    conceptPathIndexes: indexPathIndexes,
                    conceptPathString: conceptPath.join(": "),
                    conceptSelected: concept,
                    alternativeTerms: getAlternativeTerms(concept),
                    propertyDescriptions: propertyDescriptions,
                    propertyCodes: propertyCodes,
                    conceptProperties: conceptProperties,
                    conceptOpenForVoting: conceptOpenForVoting,
                    conceptPropertyComment: conceptPropertyComment,
                    newChildConcepts: [],
                    comments: comments,
                    votes: votes,
                    myvotes: myvotes
                  };
                });
              }
            }
          }
        }

      } catch (e) { }

      if (!formState.hashLoaded && indexPath?.length > 0) {
        if (fhirEntryState.activeIndex !== tabPanesLookup["Term Detail"]) {
          changeResourceViewTab(tabPanesLookup["Term Detail"], "Term Detail", true);
          changeFormState(true, "hashLoaded");
        }
      }
      if ((indexPath === undefined || indexPath.length === 0) && fhirEntryState.activeIndex === 1 && !(fhirEntryState.conceptCode === undefined || fhirEntryState.conceptCode === "")) {
        stateChanged = true;
        setFhirEntryState(prevState => {
          return {
            ...prevState,
            characteristicSelected: undefined, conceptName: "", conceptCode: "", conceptSystem: "",
            conceptDefinition: undefined, conceptPath: undefined, conceptPathIndexes: undefined,
            conceptPathString: undefined, conceptSelected: undefined, newChildConcepts: [],
            comments: comments, votes: [], myvotes: []
          };
        });
      }
      if (stateChanged === false && ((comments && fhirEntryState.comments && fhirEntryState.comments.length !== comments.length) || (votes && fhirEntryState.votes && fhirEntryState.votes.length !== votes.length) || (myvotes && fhirEntryState.myvotes && fhirEntryState.myvotes.length !== myvotes.length))) {
        setFhirEntryState(prevState => { return { ...prevState, comments: comments, votes: votes, myvotes: myvotes }; });
      }
    } else if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {

      let stateChanged = false;

      if (fhirEntryState.activeIndex !== tabPanesLookup["Term Detail"] && resourceType === "ArtifactAssessment" && (pageEditModeState || fhirEntryState.editMode)) {
        changeResourceViewTab(tabPanesLookup["Term Detail"], "Term Detail", true);
        changeFormState(true, "hashLoaded");
      }

      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          let concept = json;
          let jsonConceptCode;
          let jsonConceptDisplay;
          let jsonConceptSystem;
          let conceptCode = indexPath[0];
          let conceptPath = [];
          let indexPathIndexes = indexPath.slice(1, indexPath.length);
          let found = false;

          let conceptFound = findRobatConceptByCode(json, conceptCode, [], []);
          if (conceptFound?.length > 0) {
            concept = conceptFound[0];
            conceptPath = conceptFound[1];
            indexPathIndexes = conceptFound[2];
            if (concept.type) {
              for (let codingIndex in concept.type.coding) {
                let coding = concept.type.coding[codingIndex];
                if (coding.code) {
                  jsonConceptCode = coding.code;
                  jsonConceptSystem = coding.system;
                  jsonConceptDisplay = coding.display;
                  break;
                }
              }
            }
          }
          if (concept && (conceptCode === jsonConceptCode || conceptCode === "undefined")) {
            found = true;
          }

          if (found && fhirEntryState.conceptCode !== jsonConceptCode && fhirEntryState.conceptName !== jsonConceptDisplay) {
            let propertyProperties = getPropertyCodes(json);
            let propertyCodes = propertyProperties["propertyCodes"];
            let conceptProperties = findConceptProperties(concept, propertyCodes);
            let propertyDescriptions = propertyProperties["propertyDescriptions"];
            stateChanged = true;
            setFhirEntryState(prevState => {
              return {
                ...prevState,
                submittingToServer: false,
                conceptName: jsonConceptDisplay,
                conceptCode: jsonConceptCode,
                conceptSystem: jsonConceptSystem,
                conceptDefinition: concept.definition,
                conceptPath: conceptPath,
                conceptPathIndexes: indexPathIndexes,
                conceptPathString: conceptPath.join(": "),
                conceptSelected: concept,
                alternativeTerms: getAlternativeTerms(concept),
                propertyDescriptions: propertyDescriptions,
                propertyCodes: propertyCodes,
                conceptProperties: conceptProperties
              };
            });
          }
        }
      } catch (e) { }
    } else if (resourceType === "ArtifactAssessment" && fhirEntryState.adaptation && false) {
      //for later use if there is a need to reach the page by URL
      if (fhirEntryState.activeIndex !== tabPanesLookup["Adaptation Detail"] && resourceType === "ArtifactAssessment" && (pageEditModeState || fhirEntryState.editMode)) {
        changeResourceViewTab(tabPanesLookup["Adaptation Detail"], "Adaptation Detail", true);
        changeFormState(true, "hashLoaded");
      }
      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          //todo
        }
      } catch (e) { }
    } else if (resourceType === "EvidenceVariable") {
      if (fhirEntryState.fhirEntryString && indexPath?.length > 0 && JSON.stringify(indexPath) !== JSON.stringify(fhirEntryState.conceptPathIndexes)) {

        if (fhirEntryState.activeIndex !== tabPanesLookup["Characteristic Detail"]) {
          changeResourceViewTab(tabPanesLookup["Characteristic Detail"], "Characteristic Detail", true);
        }
        if (formState.hashLoaded === false) {
          changeFormState(true, "hashLoaded");
        }
        let json = JSON.parse(fhirEntryState.fhirEntryString);
        let characteristic = json;
        let characteristicPath = [];
        let found = false;
        if (indexPath.length > 0) {
          for (let indexPathIndex in indexPath) {
            let currentIndex = indexPath[indexPathIndex];
            if (characteristic) {
              if (characteristic.characteristic) {
                characteristic = characteristic.characteristic;
              } else if (characteristic.definitionByCombination?.characteristic?.length > 0) {
                characteristic = characteristic.definitionByCombination.characteristic;
              }
              if (characteristic && characteristic[currentIndex]) {
                characteristic = characteristic[currentIndex];
                characteristicPath.push(characteristic.display);
              }
            }
          }
          if (characteristic) {
            found = true;
          }
        }
        if (found) {
          let tempPathString = characteristicNavigationEntryDisplay(characteristic);
          navigateToAnotherCharacteristic("Characteristic", history, fhirEntryState, setFhirEntryState, 'EvidenceVariable', resourceId,
            json, characteristic, tempPathString[1], characteristicPath, indexPath, characteristicPath.join(": "), formInputsStateRef,
            changeFhirEntryState, submitUpdatedFhirResource, globalContext, changeFormState, addToast);
        }
      }
    } else if (resourceType === "Composition") {

      let stateChanged = false;
      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);

          if ((indexPath?.length > 0 || (fhirEntryState.sectionPath === undefined || fhirEntryState.sectionPath?.length === 0)) && (fhirEntryState.fhirEntryString && Object.keys(JSON.parse(fhirEntryState.fhirEntryString)).length > 0)) {
            if (fhirEntryState.submittingToServer) {
              let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
                setFhirEntryState, globalContext, history, changeFormState, false, undefined);
            } else if (!fhirEntryState.sectionSelected) {
              let section = json;
              let sectionPath = [];
              let indexPathIndexes = indexPath;
              let found = false;
              if (indexPathIndexes.length > 0) {
                for (let indexPathIndex in indexPathIndexes) {
                  let currentIndex = indexPathIndexes[indexPathIndex];
                  if (section) {
                    section = section.section;
                    if (section && section[currentIndex]) {
                      section = section[currentIndex];
                      sectionPath.push(section.title);  //Probably should get the section code too
                      if (parseInt(indexPathIndex) + 1 === indexPathIndexes.length) {
                        found = true;
                      }
                    }
                  }
                }
              }
              if (fhirEntryState.sectionChanged !== true && !(fhirEntryState.newChildSections?.length > 0)) {
                if (found) {
                  stateChanged = true;
                  setFhirEntryState(prevState => {
                    return {
                      ...prevState,
                      submittingToServer: false,
                      sectionName: section.title || section.code?.text || section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code,
                      sectionCode: section.code,
                      sectionSystem: undefined,
                      sectionDefinition: section.definition,
                      sectionPath: sectionPath,
                      sectionPathIndexes: indexPathIndexes,
                      sectionPathString: sectionPath.join(": "),
                      sectionSelected: section,
                      newChildsections: []
                    };
                  });
                } else {
                  history.push(`/resources/${resourceType}/${resourceId}`);
                }
              } else {
                setFhirEntryState(prevState => {
                  return {
                    ...prevState,
                    sectionSelected: {}
                  };
                });

              }
            }
          }
        }

      } catch (e) { }

      if (!formState.hashLoaded && indexPath?.length > 0) {
        if (fhirEntryState.activeIndex !== tabPanesLookup["Section Detail"]) {
          changeResourceViewTab(tabPanesLookup["Section Detail"], "Section Detail", true);
          changeFormState(true, "hashLoaded");
        }
      }
      if ((indexPath === undefined || indexPath.length === 0) && fhirEntryState.activeIndex === 1 && !(fhirEntryState.sectionCode === undefined || fhirEntryState.sectionCode === "")) {
        stateChanged = true;
        setFhirEntryState(prevState => {
          return {
            ...prevState,
            characteristicSelected: undefined, sectionName: "", sectionCode: "", sectionSystem: "",
            sectionDefinition: undefined, sectionPath: undefined, sectionPathIndexes: undefined,
            sectionPathString: undefined, sectionSelected: undefined, newChildSections: []
          };
        });
      }
    }
  };

  useEffect(() => {
    try {
      if (fhirEntryState.fhirEntryString && !resourceState.resourceJson) {
        let fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
        let starterResourceStateValues = loadResourceStateByType(fhirJson);
        if (starterResourceStateValues) {
          setResourceState(starterResourceStateValues);
        }
      }
      updateFhirEntryStateFunctionAgain();
    } catch { }
  }, [fhirEntryState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    if (resourceType === "Evidence" || resourceType === "EvidenceVariable" || resourceType === "Group" || resourceType === "Composition") {
      loadSourceJson();
    }
  }, [sourceJsonState]);

  let robatTermLoad = false;
  if (fhirEntryState.loading && pageEditModeState && indexPath.length > 0 && (resourceType === "ArtifactAssessment" || claimedResourceType === "ArtifactAssessment")) {
    robatTermLoad = true;
  }

  let evidenceVariableCriteriaLoad = false;
  if (resourceType === "EvidenceVariable" || claimedResourceType === "EvidenceVariable") {

    if (resourceEntryState !== undefined && resourceEntryState.found !== null) {
      let fhirJsonString = resourceEntryState["fhirResource"];
      let fhirJson;
      let found = resourceEntryState.found;
      let readpermission = resourceEntryState.readpermission;
      if (found && readpermission && fhirJsonString) {
        fhirJson = JSON.parse(fhirJsonString);
        if (fhirJson.characteristic) {
          evidenceVariableCriteriaLoad = true;
        }
      }
    }
  }

  let tabPanesLookup;
  if (fhirEntryState.adaptOn) {
    if (resourceType === "Composition") {
      tabPanesLookup = { 'Track Changes': null };
    } else {
      tabPanesLookup = { 'Track Changes (NOT CURRENTLY FUNCTIONAL)': null };
    }
  } else if (resourceType === "CodeSystem") {
    tabPanesLookup = { 'Text View': null, 'Term Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (robatTermLoad || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && (pageEditModeState || fhirEntryState.editMode))) {
    tabPanesLookup = { 'Text View': null, 'Term Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (resourceType === "ArtifactAssessment" && fhirEntryState.comparison) {
    tabPanesLookup = { 'Text View': null, 'Comparison Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (evidenceVariableCriteriaLoad) {
    tabPanesLookup = { 'Text View': null, 'Characteristic Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (resourceType === "Composition") {
    if (fhirEntryState.editMode) {
      tabPanesLookup = { 'Metadata': null, 'Section Detail': null, 'JSON View': null, 'Usage View': null };
    } else {
      tabPanesLookup = { 'Text View': null, 'Section Detail': null, 'JSON View': null, 'Usage View': null };
    }
  } else {
    tabPanesLookup = { 'Text View': null, 'JSON View': null, 'Usage View': null };
  }
  let tabPanes = Object.keys(tabPanesLookup).map((tab, index) => {
    tabPanesLookup[tab] = index;
    let tabObj = {
      menuItem: tab
    };
    return tabObj;
  });

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const changeResourceViewTab = async (tabIndex, tabLabel, automated) => {
    let allowTabChange = true;
    if (resourceType === "CodeSystem") {
      if (tabIndex == 1 && fhirEntryState.editMode) {
        if (window.confirm("You can't go to the Term Detail tab until you save or discard changes that you've made to the Resource. Click 'OK' to save your changes and then navigate away. Click 'Cancel' and then refresh the browser to discard the changes.")) {
          setLoadingSubmit(true);
          let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
            setFhirEntryState, globalContext, history, changeFormState, false, undefined);
          setLoadingSubmit(false);
        } else {
          allowTabChange = false;
        }
      }
      if (tabIndex != 1) {
        if (fhirEntryState.editMode && (fhirEntryState.termChanged || fhirEntryState.newChildConcepts?.length > 0)) {
          if (window.confirm("You can't leave this tab until you save or discard changes that you've made to the term. Click 'OK' to save your changes and then navigate away. Click 'Cancel' and then refresh the browser to discard the changes.")) {
            setLoadingSubmit(true);
            let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
            setLoadingSubmit(false);
            allowTabChange = false;
          } else {
            allowTabChange = false;
          }
        }
      }
      if (tabIndex == 3 && fhirEntryState.fhirEntryString) {
        let fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
        if (fhirJson.version === "pre-release") {
          let termProgress;
          try {
            const usersBody = {
              'functionid': 'gettermcountincodesystem',
              'idToken': '',
              'resourceid': resourceId
            };
            let response;
            response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
            if (response.success) {
              termProgress = response.termcountinfo;
            }
          } catch (e) { }
          setCodeSystemTermProgressState(prevState => {
            return {
              ...prevState,
              termProgress: termProgress,
              loading: false
            };
          });
        }
      }
    } else if (fhirEntryState.editMode && resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && tabIndex != 1) {
      changeFhirEntryState(false, "editMode");
      setPageEditModeState("");
      if (automated) {

      } else {
        let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
        if (updateResult) {
          alert("Saved your rating change to the server, taking you out of edit mode and bringing you to the Text View tab.");
        } else {
          tabIndex = 0;
          tabLabel = "Text View";
          alert("Taking you out of edit mode and bringing you to the Text View tab.");
        }
      }
    } else if (fhirEntryState.editMode && resourceType === "EvidenceVariable" && tabIndex != 1 && formInputsStateRef?.current?.characteristicState?.termChanged) {
      //changeFhirEntryState(false, "editMode");
      setPageEditModeState("");
      if (automated) {

      } else {
        let doYouWantToSave = false;
        if (window.confirm("Do you want to save your change to criteria to the server? If not, please refresh this page to change tabs.")) {
          doYouWantToSave = true;
        }
        if (doYouWantToSave) {
          let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, addToast);
          if (updateResult) {
            alert("Saved your criteria change to the server, taking you out of edit mode and bringing you to the Text View tab.");
          } else {
            tabIndex = 0;
            tabLabel = "Text View";
            alert("Taking you out of edit mode and bringing you to the Text View tab.");
          }
        } else {
          allowTabChange = false;
        }
      }
    } else if (fhirEntryState.editMode && resourceType === "Composition" && tabIndex != 1 && (fhirEntryState.sectionChanged || fhirEntryState.newChildSections?.length > 0)) {
      //changeFhirEntryState(false, "editMode");
      setPageEditModeState("");
      if (automated) {

      } else {
        let doYouWantToSave = false;
        if (window.confirm("Do you want to save your change to the section to the server? If not, please refresh this page to change tabs.")) {
          doYouWantToSave = true;
        }
        if (doYouWantToSave) {
          let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, addToast);
          if (updateResult) {
            alert("Saved your section change to the server, taking you out of edit mode and bringing you to the Text View tab.");
          } else {
            tabIndex = 0;
            tabLabel = "Text View";
            alert("Taking you out of edit mode and bringing you to the Text View tab.");
          }
        } else {
          allowTabChange = false;
        }
      }
    }
    if (allowTabChange) {

      if (tabLabel === "Text View" || tabLabel === "Metadata") {
        loadProjectResourcesList(setFhirEntryState, resourceType, resourceId, false, globalContext);
      } else if (tabLabel === "JSON View") {
        await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext);
      } else if (tabLabel === "Usage View") {
        await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext);
        loadUsageView(resourceType, resourceId, globalContext, fhirEntryState, setFhirEntryState);
      } else if (tabLabel === "Term Detail") {
        if (resourceType === "CodeSystem") {
          if (fhirEntryState.conceptCode) {
            history.push(`/resources/CodeSystem/${resourceId}#${fhirEntryState.conceptCode}`);
          }
        }
      } else if (tabLabel === "Characteristic Detail") {
        if (resourceType === "EvidenceVariable") {
          if (fhirEntryState.pathIndexes?.length > 0) {
            history.push(`/resources/EvidenceVariable/${resourceId}#${fhirEntryState.pathIndexes.toString()}`);
          }
        }
      } else if (tabLabel === "Section Detail") {
        if (resourceType === "Composition") {
          if (fhirEntryState.conceptCode) {
            history.push(`/resources/Composition/${resourceId}#${fhirEntryState.sectionIndexPath}`);
          }
        }
      }
      if (tabIndex != 1 && (resourceType === "CodeSystem" || resourceType === "EvidenceVariable" || resourceType === "Composition")) {
        history.push(`/resources/${resourceType}/${resourceId}`);
      }
      changeFhirEntryState(tabIndex, "activeIndex");
    }
  };

  const ViewTabPane = () => (<Tab className="viewTabPane"
    menu={{ secondary: true, pointing: true }}
    panes={tabPanes}
    activeIndex={fhirEntryState.activeIndex}
    onTabChange={(e, data) => {
      changeResourceViewTab(data.activeIndex, tabPanes[data.activeIndex]["menuItem"], false);
    }}
  />);

  const editProjects = async () => {
    changeFhirEntryState(true, "projectChangeMode");
    let body = {
      'functionid': 'getprojectslist',
      'idToken': ""
    };

    let response = await submitToFevirServer(globalContext, 10000, body, true, false);
    if (response?.success) {
      setFhirEntryState(prevState => {
        return {
          ...prevState,
          projectsList: response.projectslist
        };
      });
    }
  }

  const ResourceStatusRadioButton = () => {

    let values = ["active", "draft"];
    let labels = ["Publish (anyone can view)", "Draft (only editors can view)"];

    if (userState.name === "Khalid Shahin" || userState.name === "Brian S. Alper" || userState.name === "Joanne Dehnbostel" || userState.name === "Kevin O'Brien") {
      values.push("retired");
      labels.push("Retired");
    }

    return <>

      <GenericToggleButtons thin={true} boxes={true} togglable={false}
        values={values} labels={labels} fieldLabel={"status"}
        elementName={"status"} changeElementName={"fhirEntryStringChange"}
        startingValue={fhirEntryState.status} setter={setFhirEntryState}
      />
      <br />
      <br />
    </>
  }

  const CantDisplayResource = () => {
    let displayMessage = "";
    if (fhirEntryState.found === true) {
      if (fhirEntryState.loggedin) {
        displayMessage = "You do not have permission to view this resource.";
      } else {
        displayMessage = "Please log in to view resource.";
      }
    } else if (fhirEntryState.found === false) {
      displayMessage = "Resource not found";
    }
    return <span><h1>{displayMessage}<br /><br /></h1></span>
  }

  const JSONViewResourceButtons = () => {
    return <>
      {
        fhirEntryState.editMode
          ?
          <>
            {userState.id ?
              <>
                &nbsp;&nbsp;&nbsp;
                {fhirEntryState.startingStatus !== "draft" &&
                  <>
                    <Button className="formButton" style={{ color: "#000000" }}
                      content="Edit Resource in JSON Tree Writer"
                      onClick={() => { window.open("https://khalid-shahin.github.io/JSON-Tree-Writer/?server=" + encodeURIComponent(server_address) + "&port=" + ports["master_server"] + "&extrarequestheader=functionid&extrarequestinfo=getfhirresource&responseobjectname=fhirjsonstring&string=true&resourcetype=" + resourceType + "&resourceid=" + resourceId, '_blank'); }} disabled={fhirEntryState.previousVersionLoaded} />
                    &nbsp;&nbsp;&nbsp;
                  </>
                }
                <Button className="formButton" style={{ color: "#000000" }} content="Submit Updated Resource"
                  onClick={async () => {
                    setLoadingSubmit(true);
                    await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
                      setFhirEntryState, globalContext, history, changeFormState, false, undefined);
                    setLoadingSubmit(false);
                  }}
                  disabled={!fhirEntryState.fhirEntryStringChange || fhirEntryState.previousVersionLoaded}
                />
              </>
              :
              <>&nbsp;&nbsp;&nbsp;Please log back in.</>
            }
          </>
          :
          <>
            {userState.id && fhirEntryState.readpermission && fhirEntryState.editpermission ?
              <>
                &nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }}
                  content="Edit Resource"
                  onClick={() => {
                    changeFhirEntryState(true, "editMode");
                    setPageEditModeState(resourceId);
                  }}
                  disabled={fhirEntryState.previousVersionLoaded} />
                {fhirEntryState.status === "draft" &&
                  <>
                    &nbsp;&nbsp;&nbsp;
                    <Button className="formButton" style={{ color: "#000000" }} content="Publish"
                      onClick={() => {
                        changeFhirEntryState("active", "status");
                        submitUpdatedFhirResource("active", resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
                      }}
                      disabled={fhirEntryState.previousVersionLoaded}
                    />
                  </>
                }
              </>
              :
              <></>
            }
            {fhirEntryState.found &&
              <>
                &nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }} content="Clone Resource"
                  onClick={() => {
                    history.push({
                      "pathname": "/create/" + resourceType + "/" + resourceId,
                      "resourceType": resourceType,
                      "resourceId": resourceId
                    });
                  }}
                  disabled={fhirEntryState.previousVersionLoaded} />
              </>
            }
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }} content="Add New Resource"
              onClick={() => { history.push("/create"); }} />
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }} content="Download JSON"
              onClick={event => handleDownload(event, fhirEntryState.fhirEntryString || '', resourceId)} />
          </>
      }</>
  }

  let tempResourceType = claimedResourceType;
  if (resourceType) {
    tempResourceType = resourceType;
  }
  let resourceTypeDisplay = tempResourceType || "";

  let navigationElements = [];
  if (tempResourceType === "Project") {
    navigationElements = ["Project Title", "Project Description", "Project Actions", "Project Details", "Associated Links", "Associated Documents", "Associated Resources", "Software Code", "Reference List", "How to Cite", "Metadata", "Classifiers"];
  } else if (tempResourceType === "Citation") {
    navigationElements = ["Citation Summary", "Identifiers", "Title", "Abstract", "Publication Form", "URLs", "Contributors", "Classifiers", "MeSH Headings", "Third-Party Classifiers", "Status", "Modifiers", "Related Articles", "Citation Metadata", "JSON Outline"];
  } else if (tempResourceType === "ActivityDefinition") {
    navigationElements = ["Summary", "Title", "Activity Definition", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Library") {
    navigationElements = ["Summary", "Title", "Subject", "Content", "Parameters", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "List") {
    navigationElements = ["Summary", "Description", "List Entries", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Bundle") {
    navigationElements = ["Bundle Metadata", "Bundle Entries", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "PlanDefinition") {
    navigationElements = ["Summary", "Title", "Goal", "Actor", "Action", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ResearchStudy") {
    navigationElements = ["Summary", "Identification", "Status", "Subject", "Protocol", "Organizations", "Recruitment", "Variables", "Results", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "SoftwareScript") {
    navigationElements = ["Title", "Detail", "Code String", "Files/Documents", "How to Cite", "Metadata", "Classifiers"];
  } else if (tempResourceType === "Recommendation") {
    navigationElements = ["Summary", "Population", "Action", "Implementation", "Evidence", "Justification", "Classifiers", "Metadata"];
  } else if (tempResourceType === "Evidence") {
    navigationElements = ["Summary", "Population", "Exposures", "Outcome", "Study Design", "Statistics", "Certainty", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    if (fhirEntryState.editMode) {
      navigationElements = ["Summary", "Variable Definitions", "Study Design", "Statistics", "Certainty", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
  } else if (tempResourceType === "Group") {
    navigationElements = ["Summary", "Characteristic Combination", "Inclusion Criteria", "Exclusion Criteria", "Members", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Composition") {
    navigationElements = ["Summary", "Table View", "Section Detail", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    if (fhirEntryState.editMode) {
      navigationElements = ["Reorder Sections", "Edit Metadata", "Summary", "Table View", "Section Definition", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.evidenceReportPackageProfile) {
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Summary", "Introduction", "Discussion", "Methods", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      } else {
        navigationElements = ["Introduction", "Discussion", "Methods", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("recommendationProfile")) {
      navigationElements = ["Summary", "Introduction", "Recommendation", "Justification", "Considerations", "Methods", "Evidence", "Discussion", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Summary", "Introduction", "Recommendation", "Justification", "Considerations", "Methods", "Evidence", "Discussion", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("guidelineProfile")) {
      navigationElements = ["Summary", "Introduction", "Methods", "Recommendations", "Discussion", "References",
        "Competing Interests", "Acknowledgements", "Appendices",
        "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata",
          "Summary", "Introduction", "Methods", "Recommendations", "Discussion", "References",
          "Competing Interests", "Acknowledgements", "Appendices",
          "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("m11reportProfile")) {
      navigationElements = ["Title Page", "1 Protocol Summary", "2 Introduction", "3 Estimands", "4 Trial Design",
        "5 Trial Population", "6 Trial Intervention", "7 Participant Discontinuation",
        "8 Trial Assessments", "9 Adverse Events", "10 Statistics", "11 Oversight",
        "12 Supporting Details", "13 Glossary", "14 References",
        "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Title Page", "1 Protocol Summary",
          "2 Introduction", "3 Estimands", "4 Trial Design",
          "5 Trial Population", "6 Trial Intervention", "7 Participant Discontinuation",
          "8 Trial Assessments", "9 Adverse Events", "10 Statistics", "11 Oversight",
          "12 Supporting Details", "13 Glossary", "14 References",
          "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("summaryOfFindingsProfile")) {
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Summary", "Customization", "Table View", "Column Definition", "Section Definition", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      } else {
        navigationElements = ["Summary", "Customization", "Table View", "Section Detail", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceReportProfile")) {
      navigationElements = ["Introduction", "Population", "Intervention", "Comparator", "Research Study", "Methods",
        "Baseline Measures", "Participant Flow", "Outcomes", "Summary of Findings", "Discussion", "References",
        "Competing Interests", "Acknowledgements", "Appendices",
        "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Introduction", "Population", "Intervention", "Comparator", "Research Study", "Methods",
          "Baseline Measures", "Participant Flow", "Outcomes", "Summary of Findings", "Discussion", "References",
          "Competing Interests", "Acknowledgements", "Appendices",
          "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceSynthesisReportProfile")) {
      navigationElements = ["Introduction", "Population", "Intervention", "Comparator", "Research Study", "Methods",
        "Outcome Measures", "Results", "Summary of Findings", "Discussion", "References",
        "Competing Interests", "Acknowledgements", "Appendices",
        "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Introduction", "Population", "Intervention", "Comparator", "Research Study", "Methods",
          "Outcome Measures", "Results", "Summary of Findings", "Discussion", "References",
          "Competing Interests", "Acknowledgements", "Appendices",
          "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("baselineMeasureReportProfile")) {
      navigationElements = ["Evidence", "Discussion", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Groups", "Baseline Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("participantFlowReportProfile")) {
      navigationElements = ["Evidence", "Discussion", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Groups", "Participant Flow Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];

      }
    }
    if (fhirEntryState.compositionProfiles.includes("outcomeMeasureReportProfile")) {
      navigationElements = ["Evidence", "Discussion", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Groups", "Outcome Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.compositionProfiles.includes("outcomeMeasureSynthesisReportProfile")) {
      navigationElements = ["Evidence", "Discussion", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      if (fhirEntryState.editMode) {
        navigationElements = ["Reorder Sections", "Edit Metadata", "Groups", "Outcome Measures", "Results", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
  } else if (tempResourceType === "Measure") {
    navigationElements = ["Summary", "Title", "Measure Definition", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "EvidenceReport") {
    navigationElements = ["Summary", "Table View", "Populations", "Exposures", "Results", "Risk of Bias", "Metadata"];
  } else if (tempResourceType === "EvidenceVariable") {
    navigationElements = ["Summary", "Definition", "Inclusion Criteria", "Exclusion Criteria", "Data Handling", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.classification) {
    navigationElements = ["Classified Item", "Classifications", "Classification Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.rating) {
    navigationElements = ["Rated Item", "Ratings", "Rating Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.comment) {
    navigationElements = ["Comment On", "Comments", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.adaptation) {
    navigationElements = ["Adapted Item", "Table View", "Adaptations", "Adaptation Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.comparison) {
    navigationElements = ["Compared Items", "Comparisons", "Adaptation Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {
    navigationElements = ["Top", "Risk of Bias Assessment", "Details", "Metadata"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.recommendationJustification) {
    navigationElements = ["Summary", "Recommendation", "Evidence", "Net Effect", "Judgments", "Competing Interests", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "CodeSystem") {
    navigationElements = ["Description", "Properties", "Concepts", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Questionnaire") {
    navigationElements = ["Summary", "Questionnaire", "Metadata"];
  } else if (tempResourceType === "ValueSet") {
    navigationElements = ["Description", "Content", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "SchemaElement") {
    navigationElements = ["Identification", "Details", "Mapping"];
  }

  let editDisplay = "Edit " + resourceTypeDisplay;
  if (fhirEntryState.riskOfBiasAssessmentToolResource) {
    editDisplay = "Edit ROB Assessment";
  } else if (fhirEntryState.recommendationJustification) {
    editDisplay = "Edit Recommendation Justification";
    resourceTypeDisplay = "Recommendation Justification";
  } else if (fhirEntryState.systematicReviewProfile) {
    editDisplay = "Edit Systematic Review";
    resourceTypeDisplay = "Systematic Review";
  } else if (fhirEntryState.compositionProfiles.includes("recommendationProfile")) {
    editDisplay = "Edit Recommendation";
    resourceTypeDisplay = "Recommendation";
  } else if (fhirEntryState.compositionProfiles.includes("guidelineProfile")) {
    editDisplay = "Edit Guideline";
    resourceTypeDisplay = "Guideline";
  } else if (fhirEntryState.compositionProfiles.includes("evidenceReportPackageProfile")) {
    editDisplay = "Edit Evidence Report Package";
    resourceTypeDisplay = "EvidenceReportPackage";
  } else if (fhirEntryState.compositionProfiles.includes("m11reportProfile")) {
    editDisplay = "Edit M11 Report";
    resourceTypeDisplay = "M11 Report";
  } else if (fhirEntryState.compositionProfiles.includes("summaryOfFindingsProfile")) {
    editDisplay = "Edit Summary of Findings";
    resourceTypeDisplay = "Summary of Findings";
  } else if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceReportProfile")) {
    editDisplay = "Edit Comparative Evidence Report";
    resourceTypeDisplay = "Comparative Evidence Report";
  } else if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceSynthesisReportProfile")) {
    editDisplay = "Edit Comparative Evidence Synthesis Report";
    resourceTypeDisplay = "Comparative Evidence Synthesis Report";
  } else if (fhirEntryState.compositionProfiles.includes("baselineMeasureReportProfile")) {
    editDisplay = "Edit Baseline Measure Report";
    resourceTypeDisplay = "Baseline Measure Report";
  } else if (fhirEntryState.compositionProfiles.includes("participantFlowReportProfile")) {
    editDisplay = "Edit Participant Flow Report";
    resourceTypeDisplay = "Participant Flow Report";
  } else if (fhirEntryState.compositionProfiles.includes("outcomeMeasureReportProfile")) {
    editDisplay = "Edit Outcome Measure Report";
    resourceTypeDisplay = "Outcome Measure Report";
  } else if (fhirEntryState.compositionProfiles.includes("outcomeMeasureSynthesisReportProfile")) {
    editDisplay = "Edit Outcome Measure Synthesis Report";
    resourceTypeDisplay = "Outcome Measure Synthesis Report";
  }

  let inviteText = "";
  if (fhirEntryState.inviteCode) {
    inviteText = `You're invited by ${userState.name} to become an editor for the ${fhirEntryState.title} resource FOI ${resourceId}\nYou can accept this invite by going to this URL, be logged in, and then click the Accept button.\nhttps://fevir.net/invite/${fhirEntryState.inviteCode}`;
  }

  const submitNewClassification = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newClassification = {};
    let title = "Classification of " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newClassification = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newClassification),
      'title': title,
      'status': "active",
    };
    if (resourceType === 'Citation') {
      try {
        let citationJson = JSON.parse(fhirEntryState.fhirEntryString);
        if (Array.isArray(citationJson.useContext)) {
          for (const useContext of citationJson.useContext) {
            if (useContext.code?.code === "fevir-platform-use" && Array.isArray(useContext.valueCodeableConcept?.coding) &&
              (useContext.valueCodeableConcept.coding[0]?.code === "fhir-resource" || useContext.valueCodeableConcept.coding[0]?.code === "project-specific")) {
              let citedArtifactFoi;
              if (Array.isArray(citationJson.citedArtifact?.identifier)) {
                for (let identifier of citationJson.citedArtifact.identifier) {
                  if (identifier.type?.text === "FEvIR Object Identifier" && (identifier.system === "urn:ietf:rfc:3986" || identifier.system === "https://fevir.net")) {
                    citedArtifactFoi = parseInt(identifier.value);
                  }
                }
              }
              if (citedArtifactFoi && typeof citedArtifactFoi === "number") {
                body.aboutformstateid = citedArtifactFoi;
                if (Array.isArray(citationJson.citedArtifact.title) && citationJson.citedArtifact.title[0]?.text) {
                  body.targettitle = citationJson.citedArtifact.title[0].text;
                }
                let citedResourceType = useContext.valueCodeableConcept.text || "Resource";
                newClassification.artifactReference = {
                  "reference": citedResourceType + "/" + citedArtifactFoi,
                  "type": citedResourceType,
                  "display": body.targettitle
                }
                if (citedResourceType === "Resource") {
                  delete newClassification.artifactReference.type;
                }
                body.fhirEntry = JSON.stringify(newClassification);
              }
            }
          }
        }
      } catch {
        alert('failure changing body.targettitle (This should only occur when Classify applied to a Citation of a FHIR Resource on the FEvIR Platform.');
      }
    }
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  const submitNewRating = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newRating = {};
    let title = "Rating of " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newRating = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/rating"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newRating),
      'title': title,
      'status': "active",
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  const submitNewComment = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newComment = {};
    let title = "Comment on " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newComment = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/comment"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newComment),
      'title': title,
      'status': "active",
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  let loading = (globalContext.userState.loading || fhirEntryState.loading || loadingSubmit);

  const turnAdaptOn = () => {
    setFhirEntryState(prevState => { return { ...prevState, editMode: true, adaptOn: true }; })
  };

  const updateCodeSystemTerm = async () => {
    if (fhirEntryState.editMode) {
      setLoadingSubmit(true);
      await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
        setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      setLoadingSubmit(false);
    }
  }

  const update = async () => {
    if (fhirEntryState.editMode) {
      setLoadingSubmit(true);
      if (globalContext.unsavedChangesInReferencedResourceState.unsavedChanges) {
        globalContext.openConfirmation({
          header: globalContext.unsavedChangesInReferencedResourceState.modalHeader || "",
          content: globalContext.unsavedChangesInReferencedResourceState.message || "",
          runFunction: globalContext.unsavedChangesInReferencedResourceState.runFunction || null
        });
      }
      await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      setLoadingSubmit(false);
    } else {
      changeFhirEntryState(true, "editMode");
      setPageEditModeState(resourceId);
    }
  }

  const ClassifyButton = () => {
    return <Button className="formButton subCommunicateButtonWider"
      style={{ margin: "0px", color: "#000000" }}
      content="Classify" title="Create a classification of this resource."
      onClick={() => {
        !userState.loading && userState.id
          ?
          submitNewClassification(resourceType, resourceId, fhirEntryState, history)
          :
          alert("Please log in to classify.")
      }}
      disabled={fhirEntryState.previousVersionLoaded} />;
  };

  const RateButton = () => {
    return <Button className="formButton subCommunicateButtonWider"
      style={{ margin: "0px", color: "#000000" }}
      content={resourceType === "Citation" || resourceType === "Evidence" ||
        resourceType === "Composition" || resourceType === "EvidenceReport" ? "RoBAT" : "Rate"}
      title={resourceType === "Citation" || resourceType === "Evidence" ||
        resourceType === "Composition" || resourceType === "EvidenceReport" ?
        "Create a Risk of Bias Assessment" : "Rate this resource"}
      onClick={() => {
        if (!globalContext.userState.loading && globalContext.userState.id) {
          if (resourceType === "Citation" || resourceType === "Evidence" ||
            resourceType === "Composition" || resourceType === "EvidenceReport") {
            history.push({
              "pathname": "/createriskofbiasassessment/" + resourceType + "/" + resourceId + "/",
              "resourceType": resourceType,
              "resourceId": resourceId
            });
          } else {
            submitNewRating(resourceType, resourceId, fhirEntryState, history);
          }
        } else {
          alert("Please log in to rate.");
        }
      }}
      disabled={fhirEntryState.previousVersionLoaded}
    />;
  };

  const CommentButton = () => {
    return <Button className="formButton subCommunicateButtonWider" style={{ margin: "0px", color: "#000000" }}
      content="Comment" title="Comment on this resource."
      onClick={() => {
        !globalContext.userState.loading && globalContext.userState.id
          ?
          submitNewComment(resourceType, resourceId, fhirEntryState, history)
          :
          alert("Please log in to comment.")
      }}
      disabled={fhirEntryState.previousVersionLoaded} />;
  };

  const EditUpdateButton = () => {
    return <Button className="formButton navigationButton"
      style={{ color: "#000000", width: "97%", margin: "0px" }}
      content={fhirEntryState.editMode ? "Update" : editDisplay}
      onClick={update}
      disabled={(!loadingSubmit && globalContext.userState.id &&
        fhirEntryState.readpermission && fhirEntryState.editpermission &&
        resourceType !== "Recommendation" && !fhirEntryState.adaptOn) ? false : true}
    />;
  };

  const CloneButton = () => {
    return <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }}
      content={"Clone " + resourceTypeDisplay}
      onClick={() => {
        let redirectUrl = "/create";
        if (resourceType) {
          redirectUrl += "/" + resourceType;
        }
        redirectUrl += "/" + resourceId;
        history.push({ "pathname": redirectUrl, "resourceType": resourceType, "resourceId": resourceId });
      }}
      disabled={fhirEntryState.previousVersionLoaded} />;
  };

  const AdaptButton = () => {
    return <Button className="formButton navigationButton"
      style={{ color: "#000000", width: "100%", margin: "0px" }}
      content={"Adapt " + resourceTypeDisplay}
      onClick={() => {
        !globalContext.userState.loading && globalContext.userState.id
          ?
          turnAdaptOn()
          :
          alert("Please log in to adapt this resource.")
      }}
      disabled={fhirEntryState.editMode || fhirEntryState.previousVersionLoaded || fhirEntryState.adaptOn}
    />;
  };

  const ViewEditJSONButton = () => {
    return <Button className="formButton navigationButton"
      style={{ color: "#000000", width: "97%", margin: "0px" }}
      content={fhirEntryState.editMode ? "Edit JSON" : "View JSON"}
      onClick={() => { changeResourceViewTab(tabPanesLookup["JSON View"], "JSON View", true); }} />;
  };

  const PublishButton = () => {
    return <Button className="formButton navigationButton"
      style={{ color: "#000000", width: "100%", margin: "0px" }}
      content={"Publish"}
      onClick={() => {
        changeFhirEntryState("active", "status");
        submitUpdatedFhirResource("active", resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      }}
    />;
  };

  return <div>
    <div style={{ display: "flex", width: "100%" }}>
      {(tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View" ||
        tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Metadata" ||
        tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Track Changes") &&
        <div style={{ float: "left", minWidth: "290px", marginRight: "18px" }}>
          <div style={{ position: "absolute", width: "290px" }}>
            {loading &&
              <Dimmer className={"loadingDimmer"} active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>}
            <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
              {fhirEntryState.title ?
                <>{fhirEntryState.title}</>
                :
                <>{formState.tempResourceId === resourceId ?
                  <>{formState.tempResourceTitle}</>
                  :
                  <><br /></>}</>}
            </h3>
            <NavigationSegment elements={navigationElements} resourceId={resourceId} loading={loading} />
            <div style={{ marginTop: "14px" }} />
            <div>
              <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                <ClassifyButton />
                <RateButton />
                <CommentButton />
              </div>
              <div style={{ marginTop: "10px" }} />
              <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                {fhirEntryState.previousVersionLoaded ?
                  <>{/*
                    <div style={{ width: "100%", textAlign: "center", width: "100%", textAlign: "left" }}>
                      <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }}
                        content="Revert to this Version"
                        disabled={true}
                      />
                    </div>*/}
                  </>
                  :
                  <>
                    <div style={{ width: "50%", textAlign: "left", float: "left" }}>
                      {changeAvailableToSaveState === true ?
                        <>
                          <Button style={{ color: "#FFFFFF", width: "97%", margin: "0px" }}
                            className="formButton"
                            content="Submit New (Adapted) Resource" positive
                            onClick={() => { submitNewAdaptedResource(fhirEntryState, globalContext, history); }}
                          />
                        </>
                        :
                        <>
                          <EditUpdateButton />
                        </>}
                    </div>
                    <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                      <CloneButton />
                    </div>
                  </>
                }
              </div>
              <div style={{ marginTop: "10px" }} />
              <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                <AdaptButton />
              </div>
              <div style={{ marginTop: "10px" }} />
              <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                <div style={{ width: "50%", float: "left", textAlign: "left" }}>
                  <Button className="formButton navigationButton"
                    style={{ color: "#000000", width: "97%", margin: "0px" }}
                    content={(resourceType === "Project" || !userState.name) ? "View Usage" : "Add to Project"}
                    onClick={() => {
                      changeResourceViewTab(tabPanesLookup["Usage View"], "Usage View", true);
                      editProjects();
                    }}
                    disabled={fhirEntryState.previousVersionLoaded}
                  />
                </div>
                <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                  <ViewEditJSONButton />
                </div>
              </div>
              {(!fhirEntryState.editMode && fhirEntryState.status === "draft" && userState.name &&
                fhirEntryState.readpermission && fhirEntryState.editpermission) &&
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                    <div style={{ marginTop: "10px" }} />
                    <PublishButton />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }

      {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Term Detail" &&
        <>
          <TermDetailLeftSideDisplay firebase={firebase} loading={loading} robatTermLoad={robatTermLoad}
            setLoadingSubmit={setLoadingSubmit}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formState={formState}
            resourceId={resourceId} resourceType={resourceType} formInputsStateRef={formInputsStateRef}
            changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
            changeFormState={changeFormState} addToast={addToast} globalContext={globalContext}
            history={history} setPageEditModeState={setPageEditModeState} setFormState={setFormState}
            changeResourceViewTab={changeResourceViewTab} />
        </>
      }
      {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Section Detail" &&
        <>
          <SectionDetailLeftSideDisplay loading={loading} setLoadingSubmit={setLoadingSubmit}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formState={formState}
            resourceId={resourceId} resourceType={resourceType} formInputsStateRef={formInputsStateRef}
            changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
            changeFormState={changeFormState} addToast={addToast} globalContext={globalContext}
            history={history} setPageEditModeState={setPageEditModeState} />
        </>
      }
      {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Comparison Detail" &&
        <>
          <ComparisonDetailLeftSideDisplay loading={loading} setLoadingSubmit={setLoadingSubmit}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formState={formState}
            resourceId={resourceId} resourceType={resourceType} formInputsStateRef={formInputsStateRef}
            changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
            changeFormState={changeFormState} addToast={addToast} globalContext={globalContext}
            history={history} setPageEditModeState={setPageEditModeState} />
        </>
      }
      {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Characteristic Detail" &&
        <>
          <EvidenceVariableCharacteristicDetailLeftSideDisplay loading={loading} setLoadingSubmit={setLoadingSubmit}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formState={formState}
            resourceId={resourceId} resourceType={resourceType} formInputsStateRef={formInputsStateRef}
            changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
            changeFormState={changeFormState} addToast={addToast} globalContext={globalContext}
            history={history} setPageEditModeState={setPageEditModeState} />
        </>
      }
      <div style={{ float: "right", width: "100%" }}>
        <Segment id="resourceTextViewSegment" name="resourceTextViewSegment"
          className={`containerSegment remainderOfPageSegment ${fhirEntryState.editMode && "editSegment"} ${fhirEntryState.previousVersionLoaded && "historySegment"}`}
          raised>
          <>
            {(fhirEntryState.loading) &&
              <Dimmer className={"loadingDimmer"} active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            }
          </>
          <ViewTabPane />

          {fhirEntryState.loading || (fhirEntryState.found && fhirEntryState.readpermission) ?
            <></>
            :
            <CantDisplayResource />
          }
          {(resourceType && resourceType !== "" && tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Track Changes") &&
            <ResourceTextDisplay resourceType={resourceType} resourceId={resourceId} getResource={getResource}
              formInputsStateRef={formInputsStateRef}
              setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
              firebase={firebase} userState={userState}
              changeFormState={changeFormState}
              languageOptions={languageOptions}
              setLanguageOptions={setLanguageOptions}
              fhirEntryState={fhirEntryState} history={history} key="resourceTextDisplay"
              referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          }
          {(resourceType && resourceType !== "" && tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Metadata") &&
            <ResourceTextDisplay resourceType={resourceType} resourceId={resourceId} getResource={getResource}
              formInputsStateRef={formInputsStateRef}
              setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
              firebase={firebase} userState={userState}
              changeFormState={changeFormState}
              languageOptions={languageOptions}
              setLanguageOptions={setLanguageOptions}
              fhirEntryState={fhirEntryState} history={history} key="resourceTextDisplay"
              referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          }
          {(resourceType && resourceType !== "" && tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View") &&
            <>{resourceType === "CodeSystem" ?
              <>
                {fhirEntryState.editMode && <>
                  {reorganizeConceptsModalState.modalOpen &&
                    <ReorganizeSectionsModal resourceState={resourceState} setResourceState={setResourceState}
                      reorganizeSectionsModalState={reorganizeConceptsModalState}
                      setReorganizeSectionsModalState={setReorganizeConceptsModalState}
                      update={updateCodeSystemTerm} elementName={"concept"} />}
                  <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }}
                    className="formButton sectionReorganizeButton"
                    content="Reorganize Concepts"
                    onClick={() => { setReorganizeConceptsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
                  <br />
                </>}
                <ResourceTextDisplay resourceType={resourceType} resourceId={resourceId} getResource={getResource}
                  formInputsStateRef={formInputsStateRef}
                  setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
                  firebase={firebase} userState={userState}
                  changeFormState={changeFormState}
                  languageOptions={languageOptions}
                  setLanguageOptions={setLanguageOptions}
                  fhirEntryState={fhirEntryState} history={history} key="resourceTextDisplay"
                  referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
                  resourceState={resourceState} setResourceState={setResourceState}
                  sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
              </>
              :
              <>
                <ResourceTextDisplay resourceType={resourceType} resourceId={resourceId} getResource={getResource}
                  formInputsStateRef={formInputsStateRef}
                  setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
                  firebase={firebase} userState={userState}
                  changeFormState={changeFormState}
                  languageOptions={languageOptions}
                  setLanguageOptions={setLanguageOptions}
                  fhirEntryState={fhirEntryState} history={history} key="resourceTextDisplay"
                  referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
                  resourceState={resourceState} setResourceState={setResourceState}
                  sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
              </>
            }
            </>
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Term Detail" &&
            <>{(resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) ?
              <RobatTermDisplay changeFormState={changeFormState}
                formInputsStateRef={formInputsStateRef} resourceId={resourceId} fhirEntryState={fhirEntryState}
                setFhirEntryState={setFhirEntryState} history={history} />
              :
              <CodeSystemTermDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
              />
            }</>
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Comparison Detail" &&
            <>{resourceType === "ArtifactAssessment" &&
              <ComparisonDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
            }</>
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Characteristic Detail" &&
            <>{resourceType === "EvidenceVariable" &&
              <EvidenceVariableCharacteristicDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
                changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
                changeFormState={changeFormState} addToast={addToast} />
            }</>
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Section Detail" &&
            <SectionDetail formInputsStateRef={formInputsStateRef} resourceId={resourceId}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
              fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "JSON View" &&
            <>
              {(fhirEntryState.found && fhirEntryState.readpermission) &&
                <>
                  <h2 style={{ marginTop: "6px", marginBottom: "12px" }}>{fhirEntryState.title}</h2>
                  <TextArea style={{ minHeight: "400px", width: "65%" }}
                    value={fhirEntryState.fhirEntryString || ''}
                    onChange={(e) => {
                      changeFhirEntryState(e.target.value, "fhirEntryString");
                      changeFhirEntryState(true, "fhirEntryStringChange");
                    }}
                    readOnly={!fhirEntryState.editMode} />
                  <br /><br />
                  {fhirEntryState.editMode && <ResourceStatusRadioButton />}
                </>
              }
              <JSONViewResourceButtons />
              <br /><br /><br />
            </>
          }
          {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Usage View" &&
            <>
              {(fhirEntryState.found && fhirEntryState.readpermission) &&
                <>
                  <h2 style={{ marginTop: "6px", marginBottom: "12px" }}>{fhirEntryState.title}</h2>
                  <br />
                  <p>This resource has been accessed <b>{fhirEntryState.totalviews}</b> times by at least <b>{fhirEntryState.totalusersviewed}</b> users.</p>
                  {(fhirEntryState.totaledits > 0) && <p>This resource has been modified <b>{fhirEntryState.totaledits}</b> times by at least <b>{fhirEntryState.totalusersedited}</b> users.</p>}
                  <br />
                  {((fhirEntryState.editpermission && fhirEntryState.startingVersionId > 1) ||
                  resourceId === "293778") &&
                    <VersionDifferenceViewer resourceId={resourceId} globalContext={globalContext}
                      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
                  }
                  {fhirEntryState.adminpermission &&
                    <>
                      <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>Co-editors</span></div></div>
                      {fhirEntryState.editors?.map((editor, editorIndex) => {
                        return <h4 key={editorIndex} style={{ margin: "0px" }}>{editor.name}</h4>
                      })}
                      <br />
                      {fhirEntryState.inviteCode &&
                        <>
                          {/*<b>Invite collaborators with this link</b>: <a href={`https://fevir.net/invite/${fhirEntryState.inviteCode}`} target="_blank" rel="noopener noreferrer">https://fevir.net/invite/{fhirEntryState.inviteCode}</a>*/}
                          <div style={{ width: "100%", padding: "6px", background: "#FFFFFF", border: "1px solid #000000", whiteSpace: "pre-wrap" }}>{inviteText}</div>
                          <br />
                          <Button className="formButton" style={{ color: "#000000" }} content="Copy Invite to Clipboard" onClick={() => { navigator.clipboard.writeText(inviteText) }} />
                          <br /><br />
                          <Button className="formButton" style={{ color: "#000000" }} content="Disable Invite-to-Edit Link" onClick={() => {
                            if (window.confirm("Are you sure you want to disable this invite link to no longer add additional co-editors to this resource?")) {
                              revokeInviteLink(resourceId, fhirEntryState.inviteCode, globalContext, setFhirEntryState);
                            }
                          }}
                            disabled={fhirEntryState.previousVersionLoaded}
                          />
                          {/*BUTTON TO COPY TO CLIPBOARD*/}
                        </>
                      }
                      {fhirEntryState.inviteNotCreatedYet &&
                        <><Button className="formButton" style={{ color: "#000000" }} content="Create Invite-to-Edit Link" onClick={() => { createInviteLink(resourceId, globalContext, setFhirEntryState); }} disabled={fhirEntryState.creatingInvite || fhirEntryState.previousVersionLoaded} /></>
                      }
                    </>
                  }
                  {resourceType !== "Project" ?
                    <>
                      {fhirEntryState.projectChangeMode ?
                        <>
                          <div className="resourceListHeader" style={{ cursor: "default" }}>
                            <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
                              <span>Projects</span>
                            </div>
                          </div>
                          <Button className="formButton" style={{ color: "#000000" }} content="Apply Changes"
                            disabled={userState.id ? false : true}
                            onClick={async () => {
                              await addToProject(globalContext, resourceId, fhirEntryState.projectsChecked);
                              await loadUsageView(resourceType, resourceId, globalContext, fhirEntryState, setFhirEntryState);
                              changeFhirEntryState(false, "projectChangeMode");
                            }}
                          />
                          <br />
                          <br />
                          {fhirEntryState.projectsList?.map((project, projectIndex) => {
                            return <div key={projectIndex}>
                              {project.canedit &&
                                <FormControlLabel
                                  disabled={!project.canedit}
                                  control={<Checkbox style={{ pointerEvents: "auto" }} name="projectCheckBox" inputProps={{ 'aria-label': project.name }} color="primary" checked={fhirEntryState.projectsChecked[project.value] || false}
                                    onChange={(e) => {
                                      let projectsChecked = fhirEntryState.projectsChecked;
                                      projectsChecked[project.value] = e.target.checked;
                                      changeFhirEntryState(projectsChecked, "projectsChecked");
                                    }} />}
                                  label={<span>{project.label}</span>}
                                />
                              }
                            </div>
                          })}
                        </>
                        :
                        <>
                          <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>This resource is used in the following projects:</span></div></div>
                          <Button className="formButton" style={{ color: "#000000" }} content="Add/Remove from Projects"
                            disabled={userState.id && fhirEntryState.status !== "retired" ? false : true}
                            onClick={editProjects}
                          />
                          <br />
                          <br />
                          {fhirEntryState.projectsContain?.map((project, projectIndex) => {
                            return <p key={projectIndex}><a href={"/resources/Project/" + project.projectresourceid} target="_blank" rel="noopener noreferrer">{project.projectname}</a></p>
                          })}
                          {resourceType === "CodeSystem" && <>
                            <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>CodeSystem Development Progress</span></div></div>
                            <CodeSystemTermProgressTable termProgress={codeSystemTermProgressState.termProgress} tableHeaderStyle={{}} tableCellStyle={{}} totalText={"TOTAL"} />
                          </>}
                        </>
                      }
                    </>
                    :
                    <>
                      <div className="resourceListHeader" style={{ cursor: "default" }}>
                        <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
                          <span>Resources in Project</span>
                        </div>
                      </div>
                      {fhirEntryState.adminpermission &&
                        <>
                          <Button className="formButton" style={{ color: "#000000" }}
                            content="Search to Add Resources"
                            disabled={userState.id ? false : true}
                            onClick={() => { setSearchToAddToProjectModalOpenState({ "modalOpen": true }); }}
                          />
                          {searchToAddToProjectModalOpenState.modalOpen &&
                            <SearchResourcesToAddToProjectModal globalContext={globalContext}
                              setSelectResourceFromSearchState={setSearchToAddToProjectModalOpenState} />}
                          <br />
                          <br />
                        </>}
                      {fhirEntryState.projectResources?.map((resource, resourceIndex) => {
                        return <div key={resourceIndex}>
                          <a href={"/resources/" + resource.resourcetype + "/" + resource.id}>{resource.title}</a>
                          {/*<Link to={"/resources/"+resource.resourcetype+"/"+resource.id}>{resource.title}</Link>*/}
                        </div>
                      })}
                    </>
                  }
                </>
              }
              <br /><br /><br />
            </>
          }
        </Segment>
      </div>
    </div>
  </div>;
};

export default ResourceDisplayContent;