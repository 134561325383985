import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import ResourceBottomDisplay from './ResourceBottomDisplay';

const CodeSystemDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  if (fhirJson.citeAs) {
    howToCite = fhirJson.citeAs;
  }
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/cite-as" && extension.valueMarkdown) {
        howToCite = extension.valueMarkdown;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="description">Description</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.version && <p><b>Version: </b>{fhirJson.version}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.valueSet && <div><b>Value set with entire code system: </b><DisplayFromFHIR uri={fhirJson.valueSet} /><br /><br /></div>}
      </div>
      <h3 id="properties">Properties</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.caseSensitive && <><b>Case sensitive: </b>{fhirJson.caseSensitive.toString()}<br /></>}
        {fhirJson.hierarchyMeaning && <><b>Hierarchy meaning: </b>{fhirJson.hierarchyMeaning}<br /></>}
        {fhirJson.compositional && <><b>Compositional: </b>{fhirJson.compositional.toString()}<br /></>}
        {fhirJson.versionNeeded && <><b>Version needed: </b>{fhirJson.versionNeeded.toString()}<br /></>}
        {fhirJson.content && <><b>Content: </b>{fhirJson.content}<br /></>}
        <b>Properties</b>
        <div style={{ marginLeft: "24px" }}>
          <SimpleResourceFieldViewer resource={fhirJson.property} parentElement={""} />
        </div>
      </div>
      <h3 id="concepts">Concepts</h3>
      <div style={{ marginLeft: "24px" }}>
        <SimpleResourceFieldViewer resource={fhirJson.concept} parentElement={""} />
      </div>
      <br /><br />
      <ResourceBottomDisplay howToCite={howToCite} fhirJson={fhirJson}
        classificationsLoadedState={classificationsLoadedState} classificationsArray={classificationsArrayState}
      />
    </div>
  </div>
}

export default CodeSystemDisplay;