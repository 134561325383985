import React, { useEffect, useState } from 'react';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import EvidenceVariableCriteriaDisplay from './EvidenceVariableCriteriaDisplay';
import { Button } from 'semantic-ui-react';

const assignLinkIdValues = (characteristic, starterValue) => {
  if (!starterValue) {
    starterValue = "item";
  }
  for (const i in characteristic) {
    characteristic[i].linkId = starterValue + i;
    if (characteristic[i].definitionByCombination &&
      Array.isArray(characteristic[i].definitionByCombination.characteristic)) {
      characteristic[i].definitionByCombination.characteristic = assignLinkIdValues(characteristic[i].definitionByCombination.characteristic, characteristic[i].linkId + "..")
    }
  }
  return characteristic;
}

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.shortTitle) {
      innerDiv += "<p><b>Short title: </b>" + resource.shortTitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.definition?.concept) {
      innerDiv += "<p><b>Definition: </b>" + getStringFromFHIR.CodeableConcept(resource.definition.concept) + "</p><br/>";
    }
    if (resource.definition?.reference) {
      innerDiv += "<p><b>Definition: </b>" + getStringFromFHIR.Reference(resource.definition.reference) + "</p><br/>";
    }
    if (resource.handling) {
      innerDiv += "<p><b>Handling: </b>" + resource.handling + "</p><br/>";
    }
    if (resource.category?.length > 0) {
      innerDiv += resource.category.map((item) => {
        let categoryName = item.name || "";
        let categoryValue = "";
        if (item.valueCodeableConcept) {
          categoryValue = getStringFromFHIR.CodeableConcept(item.valueCodeableConcept);
        } else if (item.valueQuantity) {
          categoryValue = getStringFromFHIR.Quantity(item.valueQuantity);
        } else if (item.valueRange) {
          categoryValue = getStringFromFHIR.Range(item.valueRange);
        } else if (item.valueReference) {
          categoryValue = getStringFromFHIR.Reference(item.valueReference);
        }
        if (categoryName && categoryValue) {
          return "<p><b>Category " + categoryName + ": </b>" + categoryValue + "</p><br/>"
        } else if (categoryName) {
          return "<p><b>Category: </b>" + categoryName + "</p><br/>"
        } else if (categoryValue) {
          return "<p><b>Category: </b>" + categoryValue + "</p><br/>"
        }
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const evidenceVariableDotDefinitionResourceTypes = ['Group'];
const evidenceVariableDotHandlingValues = ['boolean', 'continuous', 'dichotomous', 'ordinal', 'polychotomous', 'extension'];
const evidenceVariableDefinitionConceptSystemChoices = [
  { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' },
  { 'uri': 'http://hl7.org/fhir/sid/icd-10', 'display': 'ICD-10' },
  { 'uri': 'http://loinc.org', 'display': 'LOINC' },
  { 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' },
  { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' }
];
const EvidenceVariableBuilder = ({ resourceState, setResourceState, setSourceJsonState }) => {
  let fhirJson = resourceState.resourceJson;
  let handlingDetail;
  if (Array.isArray(fhirJson.extension)) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-variable-handling-detail") {
        handlingDetail = extension.valueCodeableConcept;
        break;
      }
    }
  }

  const [handlingDetailState, setHandlingDetailState] = useState({ "handlingDetail": handlingDetail });

  useEffect(() => {
    if (handlingDetailState.handlingDetail) {
      setResourceState(prevState => {
        let newExtension = prevState.extension || [];
        let handlingExtensionFound = false;
        for (let extension of newExtension) {
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-variable-handling-detail") {
            extension.valueCodeableConcept = handlingDetailState.handlingDetail;
            handlingExtensionFound = true;
            break;
          }
        }
        if (!handlingExtensionFound) {
          newExtension.push({
            "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-variable-handling-detail",
            "valueCodeableConcept": handlingDetailState.handlingDetail
          })
        }
        return { ...prevState, extension: newExtension };
      })
    }
  }, [handlingDetailState]);

  return <div style={{ marginTop: "12px" }}>
    <h3 id="summary">Summary</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='shortTitle' fieldLabel='Short title'
        startingValue={resourceState.shortTitle} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={resourceState.name} setResourceState={setResourceState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startCollapsed
        startingValue={resourceState.description} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.note} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='actual' fieldLabel='Actual'
        startingValue={resourceState.actual} setResourceState={setResourceState} />
      <h4>Narrative Summary</h4>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
    </div>
    <h3 id="definition">Definition</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableReference' elementName='definition' fieldLabel='Variable Definition'
        referencedResourceTypes={evidenceVariableDotDefinitionResourceTypes} startCollapsed
        enableCreation={true} systemChoices={evidenceVariableDefinitionConceptSystemChoices} systemChoicesOpen={true}
        fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
        startingValue={resourceState.definition} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='EvidenceVariableDefinitionModifier' elementName='definitionModifier'
        fieldLabel='Definition Modifier' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.definitionModifier} setResourceState={setResourceState} />
    </div>
    {fhirJson.characteristic && <><br />
      <Button style={{ color: "#000000" }} className="formButton" content="DELETE characteristic ELEMENT"
        onClick={() => {
          setResourceState(prevState => { return { ...prevState, "characteristic": "" }; });
          alert("The characteristic element will be deleted once you click Update.");
        }} />
      <br />
      <Button style={{ color: "#000000" }} className="formButton" content="Re-index Characteristics"
        onClick={() => {
          let newCharacteristic = assignLinkIdValues(resourceState.characteristic, "item");
          setResourceState(prevState => { return { ...prevState, "characteristic": newCharacteristic }; });
        }} />
      <EvidenceVariableCriteriaDisplay fhirJson={fhirJson} />
    </>}
    <h3 id="data-handling">Data Handling</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='handling' fieldLabel='Handling'
        startingValue={resourceState.handling} setResourceState={setResourceState}
        allowedValues={evidenceVariableDotHandlingValues} />
      {resourceState.handling === "extension" &&
        <DataEntry datatype='CodeableConcept' elementName='handlingDetail'
          fieldLabel='Handling Detail' startCollapsed={true}
          startingValue={handlingDetailState.handlingDetail} setResourceState={setHandlingDetailState} />}
      <DataEntry asArray={true} datatype='EvidenceVariableCategory' elementName='category'
        fieldLabel='Category (value option for categorical variables)' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.category} setResourceState={setResourceState} />
      <DataEntry datatype='Expression' elementName='conditional' fieldLabel='Conditional (measured if)'
        startingValue={resourceState.conditional} setResourceState={setResourceState}
        startCollapsed />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='classifier'
        fieldLabel='Classifier' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.classifier} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='EvidenceVariableDataStorage' elementName='dataStorage'
        fieldLabel='Data storage' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.dataStorage} setResourceState={setResourceState} />
      <DataEntry datatype='RelativeTime' elementName='timing' fieldLabel='Timing'
        startingValue={resourceState.timing} setResourceState={setResourceState}
        startCollapsed />
      <DataEntry datatype='Period' elementName='period' fieldLabel='Timing (Dates)'
        startingValue={resourceState.period} setResourceState={setResourceState}
        startCollapsed />
      <DataEntry asArray={true} datatype='EvidenceVariableConstraint' elementName='constraint'
        fieldLabel='Constraint' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.constraint} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='missingDataMeaning'
        fieldLabel='Missing data' startCollapsed={true}
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={resourceState.missingDataMeaning} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='unacceptableDataHandling'
        fieldLabel='Unacceptable data handling' startEmptyArrayClosed={true} deletableArray={true}
        startCollapsed={true}
        startingValue={resourceState.unacceptableDataHandling} setResourceState={setResourceState} />
    </div>
  </div>
}

export default EvidenceVariableBuilder;
