import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import "firebase/compat/auth";
import { DisplayFromFHIR, SimpleResourceFieldViewer, addResourceElementState, getStringFromFHIR, changeResourceElementState } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { getTypeAndValueDisplayValuesForTable, getCharacteristicByCombinationDisplay, getCharacteristicDefinitionDisplay } from './EvidenceVariableCharacteristicDisplay';

const EvidenceVariableCharacteristicTable = ({ criterias, criteriaType, matchReport }) => {

  let headerCellColor = "#F9FAFB";
  if (criteriaType) {
    if (criteriaType.toLowerCase() === "inclusion") {
      headerCellColor = "#EAFCEA";
    } else if (criteriaType.toLowerCase() === "exclusion") {
      headerCellColor = "#FCEAEA";
    }
  }

  return <Table>
    <Table.Header>
      <Table.Row>
        {matchReport && <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Match Result</Table.HeaderCell>}
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Definition</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Description</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Qualifiers (Timing)</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {criterias.map((criteria, criteriaIndex) => {
        let backgroundColor = "#FFFFFF";
        let fullRowPadding = '';
        let otherRowPadding = '';
        if (criteria.level !== 0) {
          fullRowPadding = `${60 * criteria.level}px`;
          otherRowPadding = `${(60 * criteria.level) - 30}px`;
          if (criteria.level === 1) {
            backgroundColor = "#EBEBEB";
          } else if (criteria.level === 2) {
            backgroundColor = "#D0D0D0";
          } else if (criteria.level === 3) {
            backgroundColor = "#CCCCCC";
          } else if (criteria.level >= 4) {
            backgroundColor = "#B1B1B1";
          }
        }
        return <Table.Row key={criteriaIndex} style={{ backgroundColor: backgroundColor }}>
          {criteria.fullRow ?
            <>
              {matchReport && <Table.Cell style={{ verticalAlign: "top", textAlign: "center" }}>{criteria.matchResult && criteria.matchResult}</Table.Cell>}
              <Table.Cell style={{ verticalAlign: "top", paddingLeft: fullRowPadding }}>
                {criteria.criteriaTypeDisplay ?
                  <b>{criteria.criteriaTypeDisplay}{criteria.criteriaValueDisplay !== undefined && <>: </>}</b>
                  :
                  <>{criteria.criteriaDefinitionByCombinationDisplay ?
                    <b>{criteria.criteriaDefinitionByCombinationDisplay}</b>
                    :
                    <>{criteria.criteriaDefinition && <b>Definition: </b>}</>
                  }</>
                }
                {criteria.criteriaValueDisplay !== undefined ?
                  criteria.criteriaValueDisplay
                  :
                  <>{criteria.criteriaDefinitionByCombinationDisplay ?
                    <></>
                    :
                    <>{criteria.criteriaDefinition}</>
                  }</>
                }
                {criteria.offset && <><br /><b>Offset:</b> {criteria.offset}</>}
                {criteria.typeMethodDisplay?.length > 0 && <><br /><b>Method:</b> {criteria.typeMethodDisplay.join("; ")}</>}
                {criteria.device && <><br /><br /><b>Device:</b> {JSON.stringify(criteria.device, null, 2)}</>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {criteria.description !== undefined && <span><b>Description: </b><DisplayFromFHIR markdown={criteria.description} /></span>}
                <br />
                {criteria.notes.length > 0 && <span><b>Notes: </b></span>}
                {criteria.notes.map((note, noteIndex) => {
                  return <div key={noteIndex}>{note}<br /></div>
                })}</Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {criteria.instancesQuantity && <><b>Number of instances:</b> {getStringFromFHIR.Quantity(criteria.instancesQuantity)}<br /></>}
                {criteria.instancesRange && <><b>Number of instances:</b> {getStringFromFHIR.Range(criteria.instancesRange)}<br /></>}
                {/*criteria.method && <><b>Method:</b> {criteria.method}</>*/}
                {Array.isArray(criteria.timeFromEvent) && criteria.timeFromEvent.map((timeFromEvent, timeFromEventIndex) => {
                  return <div key={timeFromEventIndex}>
                    <b>Time from event:</b>
                    <br />
                    <div style={{ paddingLeft: "32px" }}>
                      {timeFromEvent.description && <><b>Description: </b> <DisplayFromFHIR markdown={timeFromEvent.description} /><br /></>}
                      {(timeFromEvent.note?.length === 1 && timeFromEvent.note[0].text) && <><b>Note: </b> {timeFromEvent.note[0].text}<br /></>}
                      {timeFromEvent.note?.length > 1 && <><b>Notes: </b> {timeFromEvent.note.map((note, noteIndex) => { return <span key={noteIndex}>{note.text}{noteIndex + 1 < timeFromEvent.note.length && <>; </>}</span> })}<br /></>}
                      {timeFromEvent.eventCodeableConcept && <><b>Event Definition: </b> <DisplayFromFHIR codeableConcept={timeFromEvent.eventCodeableConcept} /><br /></>}
                      {timeFromEvent.eventReference && <><b>Event Definition: </b> {getStringFromFHIR.Reference(timeFromEvent.eventReference)}<br /></>}
                      {timeFromEvent.eventDateTime && <><b>Event Definition: </b> {timeFromEvent.eventDateTime}<br /></>}
                      {timeFromEvent.eventId && <><b>Event Definition: </b> {timeFromEvent.eventId}<br /></>}
                      {timeFromEvent.quantity && <><b>Timing: </b> {getStringFromFHIR.Quantity(timeFromEvent.quantity)}<br /></>}
                      {timeFromEvent.range && <><b>Timing: </b> {getStringFromFHIR.Range(timeFromEvent.range)}<br /></>}
                      <br /><br />
                    </div>
                  </div>
                })}
                {criteria.groupMeasure !== undefined && <><br /><br /><b>Group measure:</b> {criteria.groupMeasure}</>}
              </Table.Cell>
            </>
            :
            <Table.Cell style={{ verticalAlign: "top", paddingLeft: otherRowPadding }} colSpan={matchReport ? "4" : "3"}><b>{criteria.text}</b></Table.Cell>
          }
        </Table.Row>
      })}
    </Table.Body>
  </Table>
}

const getCriteriaData = (characteristic, level) => {
  let criteria = {};
  let criteriaDefinition = getCharacteristicDefinitionDisplay(characteristic);
  let criteriaTypeDisplay;
  let criteriaValueDisplay;
  if (characteristic.definitionByTypeAndValue) {
    [criteriaTypeDisplay, criteriaValueDisplay] = getTypeAndValueDisplayValuesForTable(characteristic);
  }
  let criteriaDefinitionByCombinationDisplay;
  if (characteristic.definitionByCombination) {
    criteriaDefinitionByCombinationDisplay = getCharacteristicByCombinationDisplay(characteristic);
  }
  criteria.criteriaTypeDisplay = criteriaTypeDisplay;
  criteria.criteriaValueDisplay = criteriaValueDisplay;
  criteria.criteriaDefinition = criteriaDefinition;
  criteria.criteriaDefinitionByCombinationDisplay = criteriaDefinitionByCombinationDisplay;
  criteria.description = characteristic.description;
  criteria.instancesQuantity = characteristic.instancesQuantity;
  criteria.instancesRange = characteristic.instancesRange;
  criteria.method = getStringFromFHIR.CodeableConcept(characteristic.method); /* July 19, 2022, delete this, this is the old structure */
  criteria.typeMethodDisplay = [];
  criteria.device = "";
  criteria.offset = "";
  if (characteristic.definitionByTypeAndValue) {
    criteria.offset = getStringFromFHIR.CodeableConcept(characteristic.definitionByTypeAndValue.offset);
    criteria.device = characteristic.definitionByTypeAndValue.device;
    for (let methodIndex in characteristic.definitionByTypeAndValue.method) {
      let method = characteristic.definitionByTypeAndValue.method[methodIndex];
      criteria.typeMethodDisplay.push(getStringFromFHIR.CodeableConcept(method));
    }
  } else if (characteristic.offset) { //TO DO todo, June 23, 2022 remove this else if and the contents inside, this is the OLD structure, no longer needed
    criteria.offset = getStringFromFHIR.CodeableConcept(characteristic.offset); //REMOVE
  }
  criteria.timeFromEvent = characteristic.timeFromEvent;
  if (criteria.timeFromEvent === undefined && characteristic.timeFromStart) {
    if (Array.isArray(characteristic.timeFromStart)) {
      criteria.timeFromEvent = characteristic.timeFromStart;
    } else {
      criteria.timeFromEvent = [characteristic.timeFromStart];
    }
  }
  criteria.groupMeasure = characteristic.groupMeasure; /* July 19, 2022, DELETE this is an old structure */
  criteria.notes = [];
  for (let noteIndex in characteristic.note) {
    if (characteristic.note[noteIndex].text) {
      criteria.notes.push(characteristic.note[noteIndex].text);
    }
  }
  criteria.level = level;
  criteria.fullRow = true;

  for (let extensionIndex in characteristic.extension) {
    let extension = characteristic.extension[extensionIndex];
    if (extension.valueCoding) {
      if (extension.valueCoding.system === "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992") {
        if (extension.valueCoding.code === "result_characteristic_match") {
          criteria.matchResult = "✅ Match";
        } else if (extension.valueCoding.code === "result_characteristic_no_match") {
          criteria.matchResult = "❌ No match";
        } else if (extension.valueCoding.display) {
          criteria.matchResult = extension.valueCoding.display;
        }
      }
    }
  }


  return criteria;
}

const subCharacteristicRecursion = (criteriaList, characteristic, level) => {
  if (characteristic.definitionByCombination?.characteristic?.length > 0) {
    let includingSubCharacteristicsPresent = false;
    let excludingSubCharacteristicsPresent = false;
    let includingSubCriteria = [];
    let excludingSubCriteria = [];
    for (let subCharacteristicIndex in characteristic.definitionByCombination.characteristic) {
      let subCharacteristic = characteristic.definitionByCombination.characteristic[subCharacteristicIndex];
      let subCriteria = getCriteriaData(subCharacteristic, level);
      if (subCharacteristic.exclude) {
        excludingSubCharacteristicsPresent = true;
        excludingSubCriteria.push(subCriteria);
        excludingSubCriteria = subCharacteristicRecursion(excludingSubCriteria, subCharacteristic, level + 1);
      } else {
        includingSubCharacteristicsPresent = true;
        includingSubCriteria.push(subCriteria);
        includingSubCriteria = subCharacteristicRecursion(includingSubCriteria, subCharacteristic, level + 1);
      }
    }
    if (includingSubCharacteristicsPresent) {
      criteriaList.push({ fullRow: false, level: level, text: "Including these characteristics:" });
      criteriaList = criteriaList.concat(includingSubCriteria);
    }
    if (excludingSubCharacteristicsPresent) {
      criteriaList.push({ fullRow: false, level: level, text: "Excluding these characteristics:" });
      criteriaList = criteriaList.concat(excludingSubCriteria);
    }
  }
  return criteriaList;
}

const EvidenceVariableDisplayForMatchReport = ({ changeFormState, formInputsStateRef, fhirJson, fhirEntryState, externalUse, classificationsArrayState, classificationsLoadedState, citationSummary, citationJson }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  if (fhirJson.citeAs) {
    howToCite = fhirJson.citeAs;
  }
  const projectBlankState = { "resourceJson": fhirJson, "newClassifications": null };
  let evidenceVariableStarting = projectBlankState;
  if (fhirJson) {
    if (fhirJson.extension) {
      evidenceVariableStarting.extension = fhirJson.extension;
    }
    evidenceVariableStarting.url = fhirJson.url;
    evidenceVariableStarting.identifier = fhirJson.identifier;
    if (fhirJson.version) {
      evidenceVariableStarting.version = fhirJson.version;
    }
    if (fhirJson.versionAlgorithmString) {
      evidenceVariableStarting.versionAlgorithmString = fhirJson.versionAlgorithmString;
    }
    if (fhirJson.versionAlgorithmCoding) {
      evidenceVariableStarting.versionAlgorithmCoding = fhirJson.versionAlgorithmCoding;
    }
    evidenceVariableStarting.name = fhirJson.name;
    evidenceVariableStarting.title = fhirJson.title;
    evidenceVariableStarting.shortTitle = fhirJson.shortTitle;
    evidenceVariableStarting.status = fhirJson.status || "unknown";
    evidenceVariableStarting.experimental = fhirJson.experimental;
    evidenceVariableStarting.date = fhirJson.date;
    evidenceVariableStarting.publisher = fhirJson.publisher;
    evidenceVariableStarting.contact = fhirJson.contact;
    evidenceVariableStarting.description = fhirJson.description;
    evidenceVariableStarting.useContext = fhirJson.useContext;
    evidenceVariableStarting.purpose = fhirJson.purpose;
    evidenceVariableStarting.copyright = fhirJson.copyright;
    evidenceVariableStarting.copyrightLabel = fhirJson.copyrightLabel;
    evidenceVariableStarting.approvalDate = fhirJson.approvalDate;
    evidenceVariableStarting.lastReviewDate = fhirJson.lastReviewDate;
    evidenceVariableStarting.effectivePeriod = fhirJson.effectivePeriod;
    evidenceVariableStarting.author = fhirJson.author;
    evidenceVariableStarting.editor = fhirJson.editor;
    evidenceVariableStarting.reviewer = fhirJson.reviewer;
    evidenceVariableStarting.endorser = fhirJson.endorser;
    evidenceVariableStarting.handling = fhirJson.handling;
    evidenceVariableStarting.relatedArtifact = fhirJson.relatedArtifact;
    evidenceVariableStarting.relatesTo = fhirJson.relatesTo;
    evidenceVariableStarting.categories = fhirJson.category || [];
    evidenceVariableStarting.notes = [];
    for (let noteIndex in fhirJson.note) {
      if (fhirJson.note[noteIndex].text !== undefined) {
        evidenceVariableStarting.notes.push(fhirJson.note[noteIndex].text);
      }
    }
    let inclusionCriteria = [];
    let exclusionCriteria = [];
    for (let characteristicIndex in fhirJson.characteristic) {
      let characteristic = fhirJson.characteristic[characteristicIndex];
      let criteria = getCriteriaData(characteristic, 0);
      let exclude = characteristic.exclude;
      let level = 1;
      if (exclude) {
        exclusionCriteria.push(criteria);
        exclusionCriteria = subCharacteristicRecursion(exclusionCriteria, characteristic, level);
      } else {
        inclusionCriteria.push(criteria);
        inclusionCriteria = subCharacteristicRecursion(inclusionCriteria, characteristic, level);
      }
    }

    evidenceVariableStarting.matchReport = false;
    for (let useContextIndex in fhirJson.useContext) {
      let useContext = fhirJson.useContext[useContextIndex];
      if (useContext.valueCodeableConcept?.text === "match report") {
        evidenceVariableStarting.matchReport = true;
      }
    }


    for (let extensionIndex in fhirJson.extension) {
      let extension = fhirJson.extension[extensionIndex];
      if (extension.valueCoding) {
        if (extension.valueCoding.system === "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992") {
          if (extension.valueCoding.code === "result_eligibility_criteria_match") {
            evidenceVariableStarting.matchResult = "✅ Match";
          } else if (extension.valueCoding.code === "result_eligibility_criteria_no_match") {
            evidenceVariableStarting.matchResult = "❌ No match";
          } else if (extension.valueCoding.display) {
            evidenceVariableStarting.matchResult = extension.valueCoding.display;
          }
        }
      }
    }

    evidenceVariableStarting.inclusionCriteria = inclusionCriteria;
    evidenceVariableStarting.exclusionCriteria = exclusionCriteria;

    //evidenceVariableStarting.actual = fhirJson.actual;
    if (fhirJson.actual !== undefined) {
      if (fhirJson.actual) {
        evidenceVariableStarting.actualRadioValue = "True";
      } else {
        evidenceVariableStarting.actualRadioValue = "False";
      }
    } else {
      evidenceVariableStarting.actualRadioValue = "Undefined";
    }

    evidenceVariableStarting.handlingRadioValue = fhirJson.handling || "Undefined";
  }

  const [evidenceVariableState, setEvidenceVariableState] = useState(evidenceVariableStarting);

  useEffect(() => {
    if (!externalUse) {
      if (fhirEntryState.editMode) {
        changeFormState("FEvIR®: EvidenceVariable Builder", "pageTitle");
      } else {
        changeFormState("FEvIR®: EvidenceVariable Viewer", "pageTitle");
      }
    }
    /*
    if (fhirEntryState.activeIndex === 0) {
        let resourceId = fhirJson.id;
        citationTextViewCalculations(fhirJson, resourceId, fhirEntryState, changeFhirEntryState, setCitationState, setCitationIndividualContributorsState,
            setCitationContributorsState, setCitationPublicationFormsState, citationBlankState, contributionInstanceTypeOptions,
            setContributionInstanceTypeOptions, setAffiliationsOptions, setContributionTypeOptions, setContributorRoleOptions, setLanguageOptions,
            setCitationProjectOptions, setCitationStatusTypeOptions, setCitedArtifactStatusTypeOptions, setArtifactClassifierTypeOptions,
            setPartTypeOptions, setSummaryTypeOptions, setSummarySourceOptions, setAbstractOptions, setArticleUrlTypeOptions,
            setClassificationTypeOptions, setClassifierOptions, setTitleTypeOptions, userState);
    }
    */
  }, [fhirEntryState]);

  useImperativeHandle(formInputsStateRef, () => ({
    evidenceVariableState
  }), [evidenceVariableState]);

  let editMode = fhirEntryState.editMode;
  if (!fhirJson) {
    return <></>
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      {!externalUse && <h3 id="summary">Summary</h3>}
      <div style={{ marginLeft: "24px" }}>
        {(editMode && !externalUse) ?
          <>
            <TextField style={{ width: "100%", marginTop: "6px" }} multiline className="inputField" type='text' label={'Title'} size="small" variant='outlined' value={evidenceVariableState.title || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "title": e.target.value } }) }} />
            <TextField style={{ width: "100%", marginTop: "16px" }} multiline className="inputField" type='text' label={'Title for machine-use'} size="small" variant='outlined' value={evidenceVariableState.name || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "name": e.target.value } }) }} />
            <TextField style={{ width: "100%", marginTop: "16px" }} multiline className="inputField" type='text' label={'Short Title'} size="small" variant='outlined' value={evidenceVariableState.shortTitle || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "shortTitle": e.target.value } }) }} />
            <TextField style={{ width: "100%", marginTop: "16px" }} multiline className="inputField" type='text' label={'Subtitle'} size="small" variant='outlined' value={evidenceVariableState.subtitle || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "subtitle": e.target.value } }) }} />
            <TextField style={{ width: "100%", marginTop: "16px" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={evidenceVariableState.description || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "description": e.target.value } }) }} />
            <div style={{ marginTop: "4px", marginBottom: "4px" }}><b style={{ fontSize: "1.1em" }}>Notes: </b>
              <div style={{ paddingLeft: "28px" }}>
                <div style={{ marginTop: "0px", marginBottom: "8px" }}>
                  {evidenceVariableState.notes.map((note, noteIndex) => {
                    return <><TextField style={{ marginTop: "12px", width: "100%" }} multiline className="inputField" type='text' label={'Note ' + (noteIndex + 1).toString()} size="small" variant='outlined' value={note} onChange={(e) => { changeResourceElementState(setEvidenceVariableState, e.target.value, undefined, undefined, "notes", noteIndex, undefined); }} /><br /></>
                  })}
                </div>
                <span><Button className="formButton" style={{ color: "#000000" }} content="+ Add Note" onClick={() => { addResourceElementState(setEvidenceVariableState, undefined, undefined, "notes", ""); }} /></span>
              </div>
            </div>

            <TextField style={{ width: "100%", marginTop: "16px" }} multiline className="inputField" type='text' label={'Copyright'} size="small" variant='outlined' value={evidenceVariableState.copyright || ""} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "copyright": e.target.value } }) }} />
            {/* RADIO BUTTONS to change the actual field*/}
            <div>
              <div style={{ float: "left", paddingRight: "16px", paddingTop: "10px" }}><b>Actual: </b></div> <RadioGroup row aria-label="actual" name="actual"
                value={evidenceVariableState.actualRadioValue} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "actualRadioValue": e.target.value } }); }}>
                <FormControlLabel
                  value="True"
                  control={<Radio color="primary" />}
                  name="radio-button-control"
                  color="default"
                  inputprops={{ 'aria-label': 'True' }}
                  labelPlacement="right"
                  label="True"
                />
                <FormControlLabel
                  value="False"
                  control={<Radio color="primary" />}
                  color="default"
                  name="radio-button-control"
                  inputprops={{ 'aria-label': 'False' }}
                  labelPlacement="right"
                  label="False"
                />
                <FormControlLabel
                  value="Undefined"
                  control={<Radio color="primary" />}
                  color="default"
                  name="radio-button-control"
                  inputprops={{ 'aria-label': 'Undefined' }}
                  labelPlacement="right"
                  label="Undefined"
                />
              </RadioGroup></div>
          </>
          :
          <>
            {(evidenceVariableState.matchReport && !externalUse) && <span><b>Match Report: </b><br /></span>}
            {(evidenceVariableState.matchReport && !externalUse) && <span style={{ paddingLeft: "32px" }}><b>Match Result: </b> {evidenceVariableState.matchResult && evidenceVariableState.matchResult}<br /><br /></span>}
            {!externalUse && <p><b>Title:</b> {evidenceVariableState.title}</p>}
            {(evidenceVariableState.name && !externalUse) && <p><b>Name:</b> {evidenceVariableState.name}</p>}
            {(evidenceVariableState.shortTitle && !externalUse) && <p><b>Short Title:</b> {evidenceVariableState.shortTitle}</p>}
            {(evidenceVariableState.subtitle && !externalUse) && <p><b>Subtitle:</b> {evidenceVariableState.subtitle}</p>}
            {!externalUse && <><b>Description:</b> <DisplayFromFHIR markdown={evidenceVariableState.description} /><br /><br /></>}
            {(evidenceVariableState.notes?.length > 0 && !externalUse) && <p><b>Notes:</b> <div style={{ paddingLeft: "28px" }}>{evidenceVariableState.notes.map((note, noteIndex) => {
              return <div key={noteIndex}>{note}</div>
            })}</div></p>}
            {!externalUse && <><b>Copyright:</b> <DisplayFromFHIR markdown={evidenceVariableState.copyright} /><br /><br /></>}
            {(evidenceVariableState.actualRadioValue !== "Undefined" && !externalUse) && <p><b>Actual:</b> {evidenceVariableState.actualRadioValue}</p>}
          </>
        }
      </div>
      <h3 id="inclusion-criteria">Inclusion Criteria</h3>
      <div style={{ marginLeft: "24px" }}>
        {evidenceVariableState.inclusionCriteria.length > 0 ?
          <EvidenceVariableCharacteristicTable criterias={evidenceVariableState.inclusionCriteria} criteriaType={"Inclusion"} matchReport={evidenceVariableState.matchReport} />
          :
          <div style={{ marginLeft: "24px" }}>None</div>
        }
      </div>
      <h3 id="exclusion-criteria">Exclusion Criteria</h3>

      <div style={{ marginLeft: "24px" }}>
        {evidenceVariableState.exclusionCriteria.length > 0 ?
          <EvidenceVariableCharacteristicTable criterias={evidenceVariableState.exclusionCriteria} criteriaType={"Exclusion"} matchReport={evidenceVariableState.matchReport} />
          :
          <div style={{ marginLeft: "24px" }}>None</div>
        }
      </div>
      {(!evidenceVariableState.matchReport && !externalUse) &&
        <>
          <h3 id="handling">Handling</h3>
          <div style={{ marginLeft: "24px" }}>
            <>
              <p>{editMode ?
                <>
                  <div style={{ float: "left", paddingRight: "16px", paddingTop: "10px" }}><b>Handling: </b></div>
                  <div>
                    <RadioGroup row aria-label="handling" name="handling"
                      value={evidenceVariableState.handlingRadioValue} onChange={(e) => { setEvidenceVariableState(prevState => { return { ...prevState, "handlingRadioValue": e.target.value } }); }}>
                      <FormControlLabel
                        value="continuous"
                        control={<Radio color="primary" />}
                        name="radio-button-control"
                        color="default"
                        inputprops={{ 'aria-label': 'Continuous' }}
                        labelPlacement="right"
                        label="Continuous"
                      />
                      <FormControlLabel
                        value="dichotomous"
                        control={<Radio color="primary" />}
                        color="default"
                        name="radio-button-control"
                        inputprops={{ 'aria-label': 'Dichotomous' }}
                        labelPlacement="right"
                        label="Dichotomous"
                      />
                      <FormControlLabel
                        value="ordinal"
                        control={<Radio color="primary" />}
                        color="default"
                        name="radio-button-control"
                        inputprops={{ 'aria-label': 'Ordinal' }}
                        labelPlacement="right"
                        label="Ordinal"
                      />
                      <FormControlLabel
                        value="polychotomous"
                        control={<Radio color="primary" />}
                        color="default"
                        name="radio-button-control"
                        inputprops={{ 'aria-label': 'Polychotomous' }}
                        labelPlacement="right"
                        label="Polychotomous"
                      />
                      <FormControlLabel
                        value="Undefined"
                        control={<Radio color="primary" />}
                        color="default"
                        name="radio-button-control"
                        inputprops={{ 'aria-label': 'Undefined' }}
                        labelPlacement="right"
                        label="Undefined"
                      />
                    </RadioGroup></div>
                </>
                :
                <><b>Handling: </b> {evidenceVariableState.handling}</>
              }
              </p>
              <div><b>Categories:</b> {evidenceVariableState.categories.length > 0 && <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {evidenceVariableState.categories.map((category, categoryIndex) => {
                    return <Table.Row key={categoryIndex}>
                      <Table.Cell style={{ verticalAlign: "top", width: "25%" }}>
                        {editMode ? <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Name'} size="small" variant='outlined' value={category.name} onChange={(e) => { changeResourceElementState(setEvidenceVariableState, e.target.value, undefined, undefined, "categories", categoryIndex, "name"); }} /> : <>{(category.name && typeof category.name === "string") && category.name}</>}
                      </Table.Cell>
                      <Table.Cell style={{ verticalAlign: "top" }}>
                        <>
                          {category.valueCodeableConcept && <DisplayFromFHIR codeableConcept={category.valueCodeableConcept} />}
                          {category.valueQuantity && getStringFromFHIR.Quantity(category.valueQuantity)}
                          {category.valueRange && getStringFromFHIR.Range(category.valueRange)}
                        </>
                      </Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>}
                {editMode && <span><Button className="formButton" style={{ color: "#000000" }} content="+ Add Category" onClick={() => { setEvidenceVariableState(prevState => { let categories = prevState.categories || []; categories.push({ "name": "" }); return { ...prevState, "categories": categories }; }); }} /></span>}
              </div>
            </>
          </div>
          <h3 id="how-to-cite">How to Cite</h3>
          {editMode ?
            <div style={{ marginLeft: "24px" }}>
              <DisplayHowToCite citationSummary={citationSummary}
                citationJson={citationJson} />
            </div>
            :
            <div style={{ marginLeft: "24px" }}>
              {(howToCite) ?
                <div>
                  <p><b>Citation Summary:</b></p>
                  <DisplayFromFHIR markdown={howToCite} />
                </div>
                :
                <p>Can be added upon editing.</p>
              }
            </div>
          }
          <h3 id="metadata">Metadata</h3>
          <div style={{ marginLeft: "24px" }}>
            {editMode ?
              <MetadataPatternEdit resourceState={evidenceVariableState} setResourceState={setEvidenceVariableState} />
              :
              <MetadataPatternDisplay fhirJson={fhirJson} />
            }
          </div>
          <h3 id="classifiers">Classifiers</h3>
          {editMode ?
            <div style={{ marginLeft: "24px" }}>
              <div>
                <p>Add Classifiers:</p>
                <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
                  fieldLabel='Classification' startingValue={evidenceVariableState.newClassifications} setResourceState={setEvidenceVariableState} />
              </div>
              {(classificationsArrayState) && <div>
                <p>Existing Classifiers:</p>
                <DisplayClassifiers classificationsArray={classificationsArrayState} />
              </div>}
            </div>
            :
            <>{classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }</>
          }
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
          <br /><br />
        </>
      }
    </div>
  </div>
}

export default EvidenceVariableDisplayForMatchReport;