import React, { useState, useContext, useEffect, useImperativeHandle } from 'react';
import { getStringFromFHIR, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import './App.css';
import { changeSectionDetailEditSettingsForEvidenceReportPackage } from './EvidenceReportPackageAuthoringTool';
import { changeSectionDetailEditSettingsForM11Report } from './M11ReportAuthoringTool';
import { changeSectionDetailEditSettingsForGuideline } from './GuidelineAuthoringTool';
import { changeSectionDetailEditSettingsForRecommendation } from './RecommendationAuthoringTool';
import { changeSectionDetailEditSettingsForComparativeEvidenceReport } from './ComparativeEvidenceReportAuthor';
import { changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport } from './ComparativeEvidenceSynthesisReportAuthor';
import FevirContext from './FevirContext';
import { useToasts } from 'react-toast-notifications';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { NavigationCompositionSectionSegment } from './NavigationCompositionSectionSegment';
import { emptyReasonNotStarted } from './CodeSystemLookup';

const SectionDetail = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, resourceState, setResourceState,
  sourceJsonState, setSourceJsonState }) => {
  if (fhirEntryState.editMode) {
    return <SectionDetailEdit formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  } else {
    return <SectionDetailDisplay fhirEntryState={fhirEntryState} />
  }
}

const SectionDetailDisplay = ({ fhirEntryState }) => {

  let section;
  let sectionWasSelected = fhirEntryState.sectionPathIndexes?.length > 0;
  if (sectionWasSelected) {
    section = fhirEntryState.sectionSelected;
  } else {
    section = JSON.parse(fhirEntryState.fhirEntryString).section; //this is the highest-level section array for the Resource
  }

  const [sectionJsonExpandedState, setSectionJsonExpandedState] = useState(false);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {sectionWasSelected ?
        <>
          <h2 style={{ margin: "0px" }}>{fhirEntryState.sectionName || section.title || section.code?.text ||
            section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code}</h2>
          <br />
          <span style={{ whiteSpace: "pre-wrap" }}>
            <div style={{ marginTop: "12px" }}>
              {section.focus && <div><b>Section Focus: </b><DisplayFromFHIR reference={section.focus} /><br /><br /></div>}
              {section.text && <div>
                <h3 id="narrative">Narrative</h3>
                <div style={{ marginLeft: "24px" }}>
                  <div>
                    <p><b>Narrative Status: </b>{section.text.status}</p>
                    <div>
                      <b>Narrative Summary: </b>
                      <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                        <DisplayFromFHIR xhtml={section.text.div} />
                      </div>
                    </div>
                    <br />
                  </div>
                  {(Array.isArray(section.author) && section.author.length > 0) &&
                    section.author.map((author, authorIndex) => {
                      return <div key={authorIndex}>
                        <b>Author: </b><DisplayFromFHIR reference={author} />
                      </div>;
                    })}
                </div>
              </div>}
              {(Array.isArray(section.entry) && section.entry.length > 0) && <>
                <h3 id="referenced-resources">Referenced Resources</h3>
                <div style={{ marginLeft: "24px" }}>
                  {section.entry.map((entry, entryIndex) => {
                    return <div key={entryIndex} style={{ marginTop: "8px" }}>
                      <b>{entry.type || "Resource"}: </b><DisplayFromFHIR reference={entry} />
                    </div>;
                  })}
                </div>
              </>}
              {(Array.isArray(section.section) && section.section.length > 0) && <>
                <h3 id="contained-sections">Contained Sections</h3>
                <div style={{ marginLeft: "24px" }}>
                  {section.section.map((subsection, subsectionIndex) => {
                    let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code
                    return <div key={subsectionIndex} style={{ marginTop: "8px" }}>
                      <p><b>Section: </b>{sectionName}</p>
                      <div style={{ marginLeft: "24px" }}><DisplayFromFHIR xhtml={subsection.text?.div} /></div>
                    </div>;
                  })}
                </div>
              </>
              }
              {(section.orderedBy || (section.emptyReason && (!section.section || section.section.length === 0) &&
                (!section.entry || section.entry.length === 0) &&
                (!section.text || section.text.status === "empty"))) && <div>
                  <h3 id="section-content">Section Content</h3>
                  <div style={{ marginLeft: "24px" }}>
                    {section.orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={section.orderedBy} /><br /></span>}
                    {(section.emptyReason && (!section.section || section.section.length === 0) &&
                      (!section.entry || section.entry.length === 0) &&
                      (!section.text || section.text.status === "empty")) && <div>
                        <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={section.emptyReason} /><br /></span>
                      </div>}
                  </div>
                </div>}
            </div>
            <br />
          </span>
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={section} parentElement={""} />
          <br /><br />
        </>
        :
        <>
          <span>Please select a section</span>
          <br />
        </>
      }
      <br />
      <div>
        <span>
          <b>JSON</b> <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => {
              setSectionJsonExpandedState(prevState => {
                return !prevState;
              })
            }}>{sectionJsonExpandedState ? <>▼</> : <>►</>}</span>
        </span>
        <br />
        {sectionJsonExpandedState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>{JSON.stringify(section, null, 2)}</div>}
      </div>
    </div>
  </div>
}

const submitCreateCitation = async (addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry) => {
  if (createCitationModalState.title) {
    const body = {
      'functionid': 'createcitationforreferencelist', //modeled from submitaddcitationtoproject
      'idToken': "",
      'title': createCitationModalState.title,
      'pmid': createCitationModalState.pmid,
      'description': createCitationModalState.description,
      'compositionFOI': compositionFOI,
      'cmpositionTitle': compositionTitle,
      'username': globalContext.userState.name
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        if (response.citationFOI) {
          addCitationToEntry(response.citationFOI, createCitationModalState.title);
        }
        addToast('Your Citation for this reference list has been submitted.', { appearance: 'success' });
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false, "title": "", "pmid": "", "description": "" }; });
        window.open("/resources/Citation/" + response.citationFOI, "_blank"); //opens newly created Citation resource in a new tab
      } else {
        alert("There was a problem with your submission. Perhaps the PMID you provided is non-existant");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    alert("A title is required.");
  }
};

const CreateCitationModal = ({ addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry }) => {
  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Create and Add Citation to Reference List</h3>
    <br />
    <TextField style={{ width: "580px" }} className="inputField" type='text' label={'Citation Title'}
      size="small" variant='outlined' value={createCitationModalState.title}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, title: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "280px" }} className="inputField" type='number' label={'PMID (optional)'}
      size="small" variant='outlined' value={createCitationModalState.pmid}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, pmid: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "580px" }} multiline className="inputField" type='text'
      label={'Citation Description (optional)'} size="small" variant='outlined'
      value={createCitationModalState.description}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, description: e.target.value }; });
      }} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Citation" positive
      onClick={() => {
        submitCreateCitation(addToast, globalContext, compositionFOI, compositionTitle,
          createCitationModalState, setCreateCitationModalState, addCitationToEntry);
      }}
    />
    <br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={createCitationModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const SectionDetailEdit = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, resourceState, setResourceState,
  sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionDetailEditSettings = {
    editCode: false, editCodeCodeableConceptLevelValueSet: "",
    editTextStatus: true, editTextDiv: true, textDeletable: true, emptyTextDivValue: "", editTextFunction: "",
    generateTextDivFunction: "", generateNarrativeFromSingleEntryFunction: "", generateNarrativeFromEntryArrayFunction: "",
    entryFoiList: [],
    editAuthor: true, editFocus: true, editFocusAddElementValues: "",
    entryEnableCreation: true,
    noEntry: false, entryHeader: "", entryStartingResourceType: "", entryReferencedResourceTypes: [],
    entrySetProfile: "", entrySelectProfile: null, entrySelectAnyProfile: false, entryAddElementValues: "",
    editOrderedBy: true, editEmptyReason: true, noSection: false, addSectionAllowed: true,
    adaptationReportState: "", setAdaptationReportState: "", setChangeAvailableToSaveState: "",
    createCitation: "", fixedTitle: "", compositionId: resourceId, compositionTitle: resourceState?.title || "",
    usesLoadSourceJson: false
  };

  let sectionWasSelected = fhirEntryState.sectionPathIndexes?.length > 0;
  if (sectionWasSelected) {
    let section = fhirEntryState.sectionSelected;
    sectionDetailEditSettings = changeSectionDetailEditSettingsForEvidenceReportPackage(sectionDetailEditSettings, section);
    if (fhirEntryState.compositionProfiles?.length) {
      if (fhirEntryState.compositionProfiles.includes("guidelineProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForGuideline(sectionDetailEditSettings, section);
      }
      if (fhirEntryState.compositionProfiles.includes("recommendationProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForRecommendation(sectionDetailEditSettings, section);
      }
      if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceReportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForComparativeEvidenceReport(sectionDetailEditSettings, section, resourceDictionary);
      }
      if (fhirEntryState.compositionProfiles.includes("comparativeEvidenceSynthesisReportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport(sectionDetailEditSettings, section, resourceDictionary);
      }
      if (fhirEntryState.compositionProfiles.includes("m11reportProfile")) {
        sectionDetailEditSettings = changeSectionDetailEditSettingsForM11Report(sectionDetailEditSettings, section);
      }
    }
  }

  let editCode = sectionDetailEditSettings.editCode;
  let editCodeCodeableConceptLevelValueSet = sectionDetailEditSettings.editCodeCodeableConceptLevelValueSet;
  let editTextStatus = sectionDetailEditSettings.editTextStatus;
  let editTextDiv = sectionDetailEditSettings.editTextDiv;
  let textDeletable = sectionDetailEditSettings.textDeletable;
  let emptyTextDivValue = sectionDetailEditSettings.emptyTextDivValue;
  let editTextFunction = sectionDetailEditSettings.editTextFunction;
  let generateTextDivFunction = sectionDetailEditSettings.generateTextDivFunction;
  let generateNarrativeFromSingleEntryFunction = sectionDetailEditSettings.generateNarrativeFromSingleEntryFunction;
  let generateNarrativeFromEntryArrayFunction = sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
  let entryFoiList = sectionDetailEditSettings.entryFoiList;
  let editAuthor = sectionDetailEditSettings.editAuthor;
  let editFocus = sectionDetailEditSettings.editFocus;
  let editFocusAddElementValues = sectionDetailEditSettings.editFocusAddElementValues;
  let noEntry = sectionDetailEditSettings.noEntry;
  let entryDeletable = sectionDetailEditSettings.entryDeletable;
  let entryInstanceDeletable = sectionDetailEditSettings.entryInstanceDeletable;
  let entryEnableCreation = sectionDetailEditSettings.entryEnableCreation;
  let entryHeader = sectionDetailEditSettings.entryHeader;
  let entryStartingResourceType = sectionDetailEditSettings.entryStartingResourceType;
  let entryReferencedResourceTypes = sectionDetailEditSettings.entryReferencedResourceTypes;
  let entrySetProfile = sectionDetailEditSettings.entrySetProfile;
  let entrySelectProfile = sectionDetailEditSettings.entrySelectProfile;
  let entrySelectAnyProfile = sectionDetailEditSettings.entrySelectAnyProfile;
  let entryAddElementValues = sectionDetailEditSettings.entryAddElementValues;
  let editOrderedBy = sectionDetailEditSettings.editOrderedBy;
  let noSection = sectionDetailEditSettings.noSection;
  let editEmptyReason = sectionDetailEditSettings.editEmptyReason;
  let adaptationReportState = sectionDetailEditSettings.adaptationReportState;
  let setAdaptationReportState = sectionDetailEditSettings.setAdaptationReportState;
  let setChangeAvailableToSaveState = sectionDetailEditSettings.setChangeAvailableToSaveState;
  let createCitation = sectionDetailEditSettings.createCitation;
  let fixedTitle = sectionDetailEditSettings.fixedTitle;
  let compositionId = sectionDetailEditSettings.compositionId;
  let compositionTitle = sectionDetailEditSettings.compositionTitle;
  let starterSection = sectionDetailEditSettings.starterSection;
  let addSectionAllowed = sectionDetailEditSettings.addSectionAllowed;
  let addSectionFixedCodeNoText = sectionDetailEditSettings.addSectionFixedCodeNoText;
  let addSingleSectionFixedTitleFixedCodeNoText = sectionDetailEditSettings.addSingleSectionFixedTitleFixedCodeNoText;
  let addNewOutcomeSectionsFunction = sectionDetailEditSettings.addNewOutcomeSectionsFunction;
  let editableSectionCodes = sectionDetailEditSettings.editableSectionCodes;
  let fixedSectionCode = sectionDetailEditSettings.fixedSectionCode;
  let createRecommendation = sectionDetailEditSettings.createRecommendation;
  let SummaryOfFindingsEdit = sectionDetailEditSettings.SummaryOfFindingsEdit;
  let RecommendationSpecificationEdit = sectionDetailEditSettings.RecommendationSpecificationEdit;
  let RecommendationConsiderationsEdit = sectionDetailEditSettings.RecommendationConsiderationsEdit;
  let usesLoadSourceJson = sectionDetailEditSettings.usesLoadSourceJson;

  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;
  const [sectionCodeState, setSectionCodeState] = useState("");
  const [sectionNameState, setSectionNameState] = useState("");
  //const [sectionDetailState, setSectionDetailState] = useState();

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "targetReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "targetReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }
  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let orderedByCodeableConceptLevelValueSet = [
    {
      "coding": [{ "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
      ]
    }
  ];
  let emptyReasonCodeableConceptLevelValueSet = [
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
    },
    {
      "coding":
        [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
    }
  ];
  let timestamp = new Date();

  const [sectionState, setSectionState] = useState();
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const { addToast } = useToasts();
  const [createCitationModalState, setCreateCitationModalState] = useState({
    "modalOpen": false,
    "title": "", "pmid": "", "description": ""
  });
  const [citationEntryRevisedState, setCitationEntryRevisedState] = useState(false);
  const [addedSectionState, setAddedSectionState] = useState({ "title": "", "textDiv": "" });
  const [firstRenderState, setFirstRenderState] = useState(true);

  const addCitationToEntry = (citationFOI, title) => {
    setSectionState(prevState => {
      let newEntry = prevState.entry || [];
      newEntry.push({
        "reference": "Citation/" + citationFOI,
        "type": "Citation", "display": title
      });
      return { ...prevState, "entry": newEntry };
    });
    setCitationEntryRevisedState(true);
  };

  useEffect(() => {
    if (citationEntryRevisedState) {
      alert("Citation added."); //This alert appears at the right time but does not re-render the component
      setCitationEntryRevisedState(false);
    }
  }, [citationEntryRevisedState]);

  useEffect(() => {
    if (addedSectionState.addSection && addedSectionState.title) {
      let sectionToBeAdded = { "title": addedSectionState.title };
      if (addedSectionState.textDiv) {
        sectionToBeAdded.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + addedSectionState.textDiv + "</div>"
        }
      }
      if (createRecommendation) {
        sectionToBeAdded.code = { "text": "recommendations" };
      } else if (createCitation) {
        sectionToBeAdded.code = { "text": "references-section" };
      }
      if (addedSectionState.code) {
        sectionToBeAdded.code = addedSectionState.code;
      }
      setSectionState(prevState => {
        let newSection = prevState.section || [];
        newSection.push(sectionToBeAdded);
        return { ...prevState, "section": newSection };
      });
      setAddedSectionState({ "title": "", "textDiv": "" });
    }
  }, [addedSectionState]);

  useEffect((() => {
    if (sectionWasSelected && sectionState && Object.keys(sectionState).length > 0) {
      let newEmptyReason = false;
      let deleteEmptyReason = false;
      if (sectionState.section?.length || sectionState.entry?.length ||
        (sectionState.text && sectionState.text.status !== "empty")) {
        if (sectionState.emptyReason) {
          deleteEmptyReason = true;
        }
      } else if (!sectionState.emptyReason) {
        newEmptyReason = emptyReasonNotStarted;
      }
      if (deleteEmptyReason) {
        setSectionState(prevState => {
          let newState = prevState;
          delete newState.emptyReason;
          return newState;
        });
      } else if (newEmptyReason) {
        setSectionState(prevState => {
          return { ...prevState, emptyReason: newEmptyReason };
        });
      }
      if (firstRenderState) {
        setFirstRenderState(false);
      } else {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);


  const loadSourceJson = () => {
    if (usesLoadSourceJson) {
      if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
        loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
      }
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState, resourceState, fhirEntryState.sectionChanged]);

  useEffect((() => {
    let section;
    if (sectionWasSelected) {
      section = fhirEntryState.sectionSelected;
    } else {
      section = JSON.parse(fhirEntryState.fhirEntryString).section; //this is the highest-level section array for the Resource
    }

    let startingSection = {
      title: "", code: { "text": "section created " + timestamp },
      author: [], focus: "", text: "", orderedBy: "",
      entry: [], emptyReason: "", section: [], sectionPathIndexes: sectionPathIndexes
    }
    if (!section) {
      section = "";
    } else {
      if (section.extension) { startingSection.extension = section.extension; }
      if (section.title) { startingSection.title = section.title; }
      if (section.code) { startingSection.code = section.code; }
      if (section.author) { startingSection.author = section.author; }
      if (section.focus) { startingSection.focus = section.focus; }
      if (section.text) { startingSection.text = section.text; }
      if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
      if (section.entry) { startingSection.entry = section.entry; }
      if (section.emptyReason) { startingSection.emptyReason = section.emptyReason; }
      if (section.section) { startingSection.section = section.section; } else if (starterSection) {
        startingSection.section = starterSection;
      }
    }
    setSectionState(startingSection);
    let sectionCode = getStringFromFHIR.CodeableConcept(section.code);
    let sectionName = section.title || section.code?.text || section.code?.coding?.[0]?.display || section.code?.coding?.[0]?.code;
    setSectionCodeState(sectionCode);
    setSectionNameState(sectionName);
  }), [fhirEntryState.sectionSelected]);

  if (SummaryOfFindingsEdit) {
    return <SummaryOfFindingsEdit section={sectionState}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  if (RecommendationSpecificationEdit) {
    return <RecommendationSpecificationEdit section={sectionState}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  if (RecommendationConsiderationsEdit) {
    return <RecommendationConsiderationsEdit section={sectionState}
      formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      resourceState={resourceState} setResourceState={setResourceState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      {sectionWasSelected ?
        <>{sectionState && <>
          <h3 style={{ margin: "0px" }}>{fhirEntryState.sectionName}</h3>
          {createCitation && <>
            <CreateCitationModal addToast={addToast} globalContext={globalContext}
              compositionFOI={resourceState.id} compositionTitle={resourceState.title}
              createCitationModalState={createCitationModalState}
              setCreateCitationModalState={setCreateCitationModalState}
              addCitationToEntry={addCitationToEntry} />
            <Button className="formButton" style={{ color: "#000000", width: "100%" }}
              content={"Create Citation"}
              onClick={async () => {
                setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
              }}
              disabled={fhirEntryState.previousVersionLoaded}
            />
            <br /></>}
          <>
            {sectionNameState && <p><b>{fixedTitle || sectionState.title || sectionNameState}: </b></p>}
            <div style={{ marginLeft: "24px" }}>
              {fixedTitle
                ?
                <p><b>Title: </b>{fixedTitle}</p>
                :
                <>
                  <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
                    startingValue={sectionState.title} setResourceState={setSectionState}
                    path={sectionCodeState + '.title'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </>}
              {editCode && <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
                startingValue={sectionState.code}
                systemChoicesOpen={true} startCollapsed={true}
                codeableConceptLevelValueSet={editCodeCodeableConceptLevelValueSet}
                setResourceState={setSectionState} />}
              <NarrativeEntry elementName='text' fieldLabel='Section Summary'
                editTextStatus={editTextStatus} editTextDiv={editTextDiv} emptyTextDivValue={emptyTextDivValue}
                editTextFunction={editTextFunction}
                generateSummaryFunction={generateTextDivFunction}
                generateNarrativeFromSingleEntryFunction={generateNarrativeFromSingleEntryFunction}
                generateNarrativeFromEntryArrayFunction={generateNarrativeFromEntryArrayFunction}
                entryFoi={sectionState.entry?.[0]?.reference?.split("/")[1]}
                entryFoiList={entryFoiList}
                resourceDictionary={resourceDictionary} globalContext={globalContext}
                startingValue={sectionState.text} setResourceState={setSectionState}
                path={sectionCodeState}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
              {editAuthor && <>
                <span className={"unselectable"} style={{ cursor: "pointer" }}
                  onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
                  {editAuthorCollapsedState ? <>
                    <b>Add/Edit Author(s) for the Section ►</b>
                  </> : <>
                    <b>Collapse Section Author Data Entry ▼</b>
                  </>}
                </span>
                <br />
                {!editAuthorCollapsedState && <>
                  <DataEntry asArray={true} datatype='Reference' elementName='author'
                    fieldLabel='Section Author'
                    startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
                    startCollapsed startEmptyArrayClosed
                    setResourceState={setSectionState}
                    path={sectionCodeState + '.author'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </>
                }
              </>}
              {editFocus && <>
                <h3>Create or identify the Resource defining the focus for this section.</h3>
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry datatype='Reference' elementName='focus'
                    fieldLabel={'EvidenceVariable Resource'}
                    startingValue={sectionState.focus} referencedResourceTypes={["EvidenceVariable"]}
                    startingResourceType={"EvidenceVariable"}
                    startCollapsed={true} enableCreation={true}
                    setResourceState={setSectionState} addElementValues={editFocusAddElementValues}
                    fullResourceState={resourceState}
                    globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
                </div>
              </>}
              {editOrderedBy && <>
                <h3>Ordering of Section Content</h3>
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
                    startingValue={sectionState.orderedBy} valueSet={orderedByValueSet}
                    startCollapsed={true}
                    codeableConceptLevelValueSet={orderedByCodeableConceptLevelValueSet}
                    setResourceState={setSectionState}
                    path={sectionCodeState + '.orderedBy'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </div>
              </>}
              {!noEntry && <>
                {entryHeader ?
                  <h3>{entryHeader}</h3>
                  :
                  <h3>{sectionNameState} Section Content (as Resource Entries)</h3>
                }
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel={sectionNameState + ' Content Entry'}
                    startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
                    startCollapsed={true}
                    startEmptyArrayClosed={true}
                    deletableArray={entryDeletable ?? true}
                    deletable={entryInstanceDeletable ?? true}
                    enableCreation={entryEnableCreation ?? true}
                    startingResourceType={entryStartingResourceType}
                    setProfile={entrySetProfile}
                    selectProfile={entrySelectProfile} selectAnyProfile={entrySelectAnyProfile}
                    addElementValues={entryAddElementValues}
                    setResourceState={setSectionState} fullResourceState={resourceState}
                    globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                    path={sectionCodeState + '.entry'}
                    adaptationReportState={adaptationReportState}
                    setAdaptationReportState={setAdaptationReportState}
                    setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                </div>
              </>}
              {!noSection && <p><b>Contained Sections (for text with subheadings):</b></p>}
              <div style={{ marginLeft: "24px" }}>
                {(Array.isArray(sectionState.section) && sectionState.section.length > 0) &&
                  sectionState.section.map((subsection, subsectionIndex) => {
                    let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
                    let sectionTextDiv = subsection.text?.div;
                    let sectionTextStatus = subsection.text?.status;
                    return <span key={subsectionIndex}>
                      <p><b>Section: </b>{sectionName}</p>
                      {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                        <p>Narrative status: {sectionTextStatus}</p>
                        <p>Narrative summary:</p>
                        <DisplayFromFHIR xhtml={sectionTextDiv} />
                      </div>}
                    </span>;
                  })}
                {(addSectionAllowed && !noSection) && <>
                  {addSectionFixedCodeNoText
                    ?
                    <div>
                      <p>Enter a title then click Save New Section.</p>
                      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                        startingValue={addedSectionState.title}
                        setResourceState={setAddedSectionState} />
                      {addedSectionState.title &&
                        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                          content={"Save New Section"}
                          onClick={() => {
                            setAddedSectionState(prevState => {
                              return {
                                ...prevState,
                                code: addSectionFixedCodeNoText,
                                addSection: true
                              };
                            });
                          }} />}
                    </div>
                    :
                    <>{addSingleSectionFixedTitleFixedCodeNoText
                      ?
                      <div>
                        <br />
                        {!sectionState.section?.length &&
                          <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                            content={"Add " + addSingleSectionFixedTitleFixedCodeNoText.title + " Section"}
                            onClick={() => {
                              setAddedSectionState(prevState => {
                                return {
                                  ...prevState,
                                  title: addSingleSectionFixedTitleFixedCodeNoText.title,
                                  code: addSingleSectionFixedTitleFixedCodeNoText.code,
                                  addSection: true
                                };
                              });
                            }} />}
                      </div>
                      :
                      <>{addNewOutcomeSectionsFunction
                        ?
                        <>
                          {sectionState.section?.length ?
                            <div>
                              <p>Current section content includes sections for:</p>
                              {sectionState.section.map((sect, sectIndex) => {
                                let sectionDisplay = sect.title || sect.focus?.display || sect.focus?.reference || "[Missing variable identifier.]";
                                return <div key={sectIndex} style={{ marginLeft: "24px" }}>
                                  <p>{sectionDisplay}</p>
                                </div>
                              })}
                            </div>
                            :
                            <></>}
                          {sectionState.entry?.length ?
                            <div>
                              <br />
                              <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
                                content="Add new Outcome (Result) sections" positive
                                onClick={() => {
                                  addNewOutcomeSectionsFunction(setSectionState);
                                }}
                              />
                            </div>
                            :
                            <></>}
                        </>
                        :
                        <div>
                          <p>Enter a title then click Save New Section.</p>
                          <DataEntry datatype='string' elementName='title' fieldLabel='Title'
                            startingValue={addedSectionState.title}
                            setResourceState={setAddedSectionState} />
                          <br />   <br />
                          <DataEntry datatype="xhtml" elementName='textDiv' fieldLabel='Narrative Summary'
                            startingValue={addedSectionState.textDiv}
                            setResourceState={setAddedSectionState} />
                          {addedSectionState.title &&
                            <Button className="formButton" style={{ color: "#000000", width: "100%" }}
                              content={"Save New Section"}
                              onClick={() => {
                                setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
                              }} />}
                        </div>
                      }
                      </>
                    }
                    </>
                  }</>}
              </div>
              {(editEmptyReason && (!sectionState.section || sectionState.section.length === 0) &&
                (!sectionState.entry || sectionState.entry.length === 0) &&
                (!sectionState.text || sectionState.text.status === "empty")) && <>
                  <h3>Explain why empty</h3>
                  <div style={{ marginLeft: "24px" }}>
                    <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                      startingValue={sectionState.emptyReason}
                      startCollapsed={true}
                      codeableConceptLevelValueSet={emptyReasonCodeableConceptLevelValueSet}
                      setResourceState={setSectionState}
                      path={sectionCodeState + '.emptyReason'}
                      adaptationReportState={adaptationReportState}
                      setAdaptationReportState={setAdaptationReportState}
                      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                  </div>
                </>}
            </div>
          </>
          <br /><br />
        </>}
        </>
        :
        <>
          <span>Please select a section</span>
          <br />
          <br />
        </>
      }
    </div>
  </div>
}

const SectionDetailLeftSideDisplay = ({ loading, setLoadingSubmit, fhirEntryState, setFhirEntryState, formState,
  resourceId, resourceType, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource, changeFormState,
  addToast, globalContext, history, setPageEditModeState
}) => {
  return <div style={{ float: "left", minWidth: "500px", marginRight: "18px" }}><div style={{ position: "fixed", width: "500px" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>}
    <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
      {fhirEntryState.title ?
        <>{fhirEntryState.title}</>
        :
        <>{formState.tempResourceId === resourceId ?
          <>{formState.tempResourceTitle}</>
          :
          <><br /></>}</>}
    </h3>
    <NavigationCompositionSectionSegment resourceId={resourceId} fhirEntryState={fhirEntryState}
      setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef}
      changeFormState={changeFormState} changeFhirEntryState={changeFhirEntryState} addToast={addToast} />
    <>
      {resourceType === "Composition" &&
        <div>
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "100%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton"
                style={{ color: "#000000", width: "100%", margin: "0px" }}
                content={fhirEntryState.editMode ? "Update" : "Edit Section"}
                onClick={async () => {
                  if (fhirEntryState.editMode) {
                    setLoadingSubmit(true);
                    await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef,
                      fhirEntryState, setFhirEntryState, globalContext, history, changeFormState,
                      false, undefined);
                    alert("Edit then Update in the Text View to apply changes to the Bundle for the corresponding document.");
                    setLoadingSubmit(false);
                  } else {
                    changeFhirEntryState(true, "editMode");
                    setPageEditModeState(resourceId);
                  }
                }}
                disabled={(!fhirEntryState.previousVersionLoaded && globalContext.userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission) ? false : true}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }} />
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "100%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%" }}
                content={"Copy from Existing Section"} disabled />
            </div>
          </div>
          <div style={{ marginTop: "10px" }} />
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "50%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton"
                style={{ color: "#000000", width: "98%", margin: "0px" }}
                content={"Create Section from JSON"} disabled />
            </div>
            <div style={{ width: "50%", float: "right", textAlign: "right" }}>
              <Button className="formButton navigationButton"
                style={{ color: "#000000", width: "98%", margin: "0px" }}
                content={"Delete This Section"} disabled />
            </div>
          </div>
        </div>
      }
    </>
  </div>
  </div>
}

export { SectionDetail, SectionDetailLeftSideDisplay };