import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';
import { Button, Dropdown, Dimmer, Loader, Table } from 'semantic-ui-react';
import { TextField, Select, MenuItem } from '@mui/material';
import "firebase/compat/auth";
import CreateHyperlink from './CreateHyperlink';
import DropDownWithAdditions from './DropDownWithAdditions';
import { addResourceElementState, DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer, changeResourceElementState, getIdentifier, generateIdentifierTable, generateIdentifierSystemCell, generateIdentifierValueCell, builderUpdateJson } from './ResourceFunctions';
import { CitationPublicationFormsTextDisplay, CitationContributorsTextDisplay, citationTextViewCalculations, citationTextViewSummaryCalculations, citationTextViewClassifierCalculations } from './CitationFunctions';
import { DataEntry, MetaProfileEntry } from './DataEntryFormFunctions';
import {
    unlimitedStyleTypes, customStyleNames, generatedSummaryTexts, identifierSystemLookup, titleTypeLookup, abstractTypeLookup,
    artifactRelationshipTypeLookup, artifactClassifierTypeLookup, partTypeLookup, classificationTypeLookup, articleUrlTypeLookup,
    citedArtifactStatusTypeLookup, citationStatusTypeLookup, languageLookup,
    identifierDisplayLookup, startingIdentifierTypeOptions, startingContributorIdentifierTypeOptions,
    startingTitleTypeOptions, startingAbstractTypeOptions,
    startingSummaryTypeOptions, startingArtifactRelationshipTypeOptions, startingArtifactClassifierTypeOptions, startingPartTypeOptions,
    startingSummarySourceOptions, startingContributorRoleOptions, startingContributionInstanceTypeOptions,
    startingClassificationTypeOptions, startingArticleUrlTypeOptions, startingContributionTypeOptions, startingCitedArtifactStatusTypeOptions,
    startingCitationStatusTypeOptions,
    languageCodeOptions
} from './CodeSystemLookup';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import ProfilesByResourceType from './ProfilesByResourceType';

const displayProfileName = (profile, resourceType) => {
    let profileName;
    const profileDictionary = ProfilesByResourceType[resourceType];
    for (const key in profileDictionary) {
        if (profileDictionary[key].url === profile) {
            profileName = key;
        }
    }
    return profileName || profile;
}

const CitationDisplay = React.forwardRef(({ resourceId, formInputsStateRef,
    fhirEntryState, setFhirEntryState, changeFhirEntryState, changeFormState,
    languageOptions, setLanguageOptions, userName }, ref) => {

    let fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
    const globalContext = useContext(FevirContext);

    const citationBlankState = { citationLoaded: false, invalid: false, classifications: [], extraClassifications: [], externalClassifications: [], classificationsLoaded: false, "summariesLoaded": false, "citationSummaryStyle": "computable-publishing", "otherSummaries": [], "displayedSummaryStyles": { "Title Only": false, "Identifier Only": false, "User Defined": false, "[Enter Style]": false } };

    const [citationState, setCitationState] = useState(citationBlankState);

    const [citationPublicationFormsState, setCitationPublicationFormsState] = useState({});
    const [citationContributorsState, setCitationContributorsState] = useState({});
    const [citationIndividualContributorsState, setCitationIndividualContributorsState] = useState({});

    const [partTypeOptions, setPartTypeOptions] = useState({ "options": startingPartTypeOptions });
    const [summaryTypeOptions, setSummaryTypeOptions] = useState({ "options": startingSummaryTypeOptions });
    const [summarySourceOptions, setSummarySourceOptions] = useState({ "options": startingSummarySourceOptions });
    const [contributionTypeOptions, setContributionTypeOptions] = useState({ "options": startingContributionTypeOptions });
    const [contributorRoleOptions, setContributorRoleOptions] = useState({ "options": startingContributorRoleOptions });
    const [contributorIdentifierTypeOptions, setContributorIdentifierTypeOptions] = useState({ "options": startingContributorIdentifierTypeOptions });
    const [titleTypeOptions, setTitleTypeOptions] = useState({ "options": startingTitleTypeOptions });
    const [identifierTypeOptions, setIdentifierTypeOptions] = useState({ "options": startingIdentifierTypeOptions });
    const [abstractTypeOptions, setAbstractOptions] = useState({ "options": startingAbstractTypeOptions });
    const [articleUrlTypeOptions, setArticleUrlTypeOptions] = useState({ "options": startingArticleUrlTypeOptions });
    const [classificationTypeOptions, setClassificationTypeOptions] = useState({ "options": startingClassificationTypeOptions });
    const [classifierOptions, setClassifierOptions] = useState({ "options": [] });
    const [citedArtifactStatusTypeOptions, setCitedArtifactStatusTypeOptions] = useState({ "options": startingCitedArtifactStatusTypeOptions });
    const [artifactClassifierTypeOptions, setArtifactClassifierTypeOptions] = useState({ "options": startingArtifactClassifierTypeOptions });
    const [contributionInstanceTypeOptions, setContributionInstanceTypeOptions] = useState({ "options": startingContributionInstanceTypeOptions });
    const [affiliationsOptions, setAffiliationsOptions] = useState({ "options": [] });
    const [citationStatusTypeOptions, setCitationStatusTypeOptions] = useState({ "options": startingCitationStatusTypeOptions });
    const [citationProjectOptions, setCitationProjectOptions] = useState({ "options": [{ key: "EBMonFHIR/COKA Demonstration", text: "EBMonFHIR/COKA Demonstration", value: "EBMonFHIR/COKA Demonstration" }, { key: "Steroids SMR Project", text: "Steroids SMR Project", value: "Steroids SMR Project" }, { key: "MCBK Example", text: "MCBK Example", value: "MCBK Example" }, { key: "ACTS Concept Demo", text: "ACTS Concept Demo", value: "ACTS Concept Demo" }] });

    const changeCitationState = (newValue, field) => {
        setCitationState(prevState => {
            return {
                ...prevState,
                [field]: newValue
            };
        });
    };

    const changeCitationElementState = (setter, newValue, parentField, parentIndex, field, index, subfield) => {
        if (parentField) {
            setter(prevState => {
                let prevField = prevState[parentField];
                if (parentIndex !== undefined && index !== undefined) {
                    if (subfield !== undefined) {
                        prevField[parentIndex][field][index][subfield] = newValue;
                    } else {
                        if (prevField[parentIndex][field][index] === undefined) {
                            prevField[parentIndex][field].push("");
                        }
                        prevField[parentIndex][field][index] = newValue;
                    }
                } else if (parentIndex !== undefined && index === undefined && subfield === undefined) {
                    prevField[parentIndex][field] = newValue;
                } else if (parentIndex !== undefined && index === undefined && subfield !== undefined) {
                    prevField[parentIndex][field][subfield] = newValue;
                } else if (parentIndex === undefined && index !== undefined && subfield === undefined) {
                    prevField[field][index] = newValue;
                } else if (parentIndex === undefined && index !== undefined && subfield !== undefined) {
                    prevField[field][index][subfield] = newValue;
                } else if (field && subfield) {
                    prevField[field][subfield] = newValue;
                }
                return {
                    ...prevState,
                    [parentField]: prevField
                };
            });
        } else if (index !== undefined && subfield !== undefined) {
            setter(prevState => {
                let prevField = prevState[field];
                prevField[index][subfield] = newValue;
                return {
                    ...prevState,
                    [field]: prevField
                };
            });
        } else if (field && subfield) {
            setter(prevState => {
                let prevField = prevState[field];
                prevField[subfield] = newValue;
                return {
                    ...prevState,
                    [field]: prevField
                };
            });
        } else if (field && index !== undefined) {
            setter(prevState => {
                let prevField = prevState[field];
                prevField[index] = newValue;
                return {
                    ...prevState,
                    [field]: prevField
                };
            });
        } else {
            setter(prevState => {
                return {
                    ...prevState,
                    [field]: newValue
                };
            });
        }
    }

    const addOrDeleteCitationSummary = async (key, add, fhirJson, globalContext) => {
        if (!(key in unlimitedStyleTypes)) {
            changeSummaryStyleVisibility(key, add);
        }

        if (add) {
            let isCustomStyle = key in customStyleNames;
            let isGeneratedTextAvailable = key in generatedSummaryTexts;
            let generatedSummaryText = "";
            if (isGeneratedTextAvailable) {
                const body = {
                    'functionid': 'generatetextsummary',
                    'citation': fhirJson,
                    'style': key,
                    'idToken': ''
                };
                let response;
                try {
                    response = await submitToFevirServer(globalContext, 8000, body, true, false);
                    if (response.success) {
                        generatedSummaryText = response.generatedSummary;
                    }
                } catch (e) { }
            }
            let summaryEntry = {
                "summaryJSONIndex": null,       //null means it was created just now in the Citation Builder and not from the JSON
                "hasCustomStyleName": isCustomStyle,
                "summaryCustomStyleName": "",
                "summaryText": "",
                "humanReadableStyle": key,
                "generatedSummaryTextAvailable": isGeneratedTextAvailable,
                "generatedSummaryText": generatedSummaryText,
                "hide": false
            };
            setCitationState(prevState => {
                prevState.otherSummaries.push(summaryEntry);
                return {
                    ...prevState,
                    otherSummaries: prevState.otherSummaries
                };
            });
        }
    };

    const changeSummaryStyleVisibility = (key, bool) => {
        setCitationState(prevState => {
            let displayedSummaryStyles = prevState.displayedSummaryStyles;
            displayedSummaryStyles[key] = bool;
            return {
                ...prevState,
                displayedSummaryStyles: displayedSummaryStyles
            };
        });
    };

    useEffect(async () => {
        if (fhirEntryState.editMode) {
            changeFormState("FEvIR®: Citation Builder", "pageTitle");
        } else {
            changeFormState("FEvIR®: Citation Viewer", "pageTitle");
        }
    }, [fhirEntryState]);

    useEffect(async () => {
        if (!globalContext.userState.loading && fhirEntryState.activeIndex == 0) {
            let classifications = [];
            let extraClassifications = [];
            let extraClassificationFoisProcessed = [];
            let fevirPlatformUseClassifierCode = await citationTextViewCalculations(fhirJson, resourceId, fhirEntryState, changeFhirEntryState, setCitationState, setCitationIndividualContributorsState,
                setCitationContributorsState, setCitationPublicationFormsState, citationBlankState, contributionInstanceTypeOptions,
                setContributionInstanceTypeOptions, setAffiliationsOptions, setContributionTypeOptions, setContributorRoleOptions, setLanguageOptions,
                setCitationProjectOptions, setCitationStatusTypeOptions, setCitedArtifactStatusTypeOptions, setArtifactClassifierTypeOptions,
                setPartTypeOptions, setSummaryTypeOptions, setSummarySourceOptions, setAbstractOptions, setArticleUrlTypeOptions,
                setClassificationTypeOptions, setClassifierOptions, setTitleTypeOptions,
                globalContext.userState, classifications, extraClassifications, extraClassificationFoisProcessed);
            citationTextViewSummaryCalculations(fhirJson, setCitationState, changeSummaryStyleVisibility, globalContext);
            citationTextViewClassifierCalculations(fhirJson, fevirPlatformUseClassifierCode, resourceId, setCitationState,
                globalContext, classifications, extraClassifications, extraClassificationFoisProcessed);
        }
    }, [globalContext.userState.loading, fhirEntryState.activeIndex]);

    useImperativeHandle(formInputsStateRef, () => ({
        citationState,
        citationPublicationFormsState,
        citationContributorsState,
        citationIndividualContributorsState
    }), [citationState, citationPublicationFormsState, citationContributorsState, citationIndividualContributorsState]);

    if (citationState.citationLoaded === false) {
        return <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
        </Dimmer>
    }

    if (citationState.invalid) {
        return <>Invalid Citation JSON. See JSON Tab to see the resource.</>
    } else {
        let computablePublishingSummary = citationState.computablePublishingSummary;
        let otherSummaries = citationState.otherSummaries;
        let identifiers = citationState.identifiers;
        let relatedIdentifiers = citationState.relatedIdentifiers;
        let titles = citationState.titles;
        let webLocations = citationState.webLocations;
        let classifications = citationState.classifications;
        let extraClassifications = citationState.extraClassifications;
        let externalClassifications = citationState.externalClassifications;
        let meshHeadingSet = citationState.meshHeadingSet;
        let abstracts = citationState.abstracts;
        let baseCitationReference = citationState.baseCitationReference;
        let baseCitationDisplay = citationState.baseCitationDisplay;
        let baseCitationIdentifier = citationState.baseCitationIdentifier;

        let editMode = fhirEntryState.editMode;

        let citationMetadataEditMode = citationState.citationMetadataEditMode && editMode;

        return <div>
            <h3 id="citation-summary">Citation Summary</h3>
            <div style={{ paddingLeft: "40px" }}>
                {citationState.summariesLoaded ?
                    <>{editMode ?
                        <>
                            <span key="default_computable_publishing">
                                <b style={{ fontSize: "1.1em" }}>Style: </b>Computable Publishing
                                <br />
                                <b style={{ fontSize: "1.1em" }}>Summary: </b>{computablePublishingSummary}
                            </span>
                            <br />
                            <br />
                            {otherSummaries.map((summary, index) => {
                                return <span key={index}>
                                    <b style={{ fontSize: "1.1em" }}>Style: </b>{summary.hasCustomStyleName === false ? summary.humanReadableStyle : <TextField style={{ marginTop: "10px", width: "540px", maxWidth: "80%" }} multiline className="inputField" type='text' label='Enter Name of Style' size="small" variant='outlined' value={summary.summaryCustomStyleName} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "otherSummaries", index, "summaryCustomStyleName"); }} />}
                                    <br />
                                    <b style={{ fontSize: "1.1em" }}>Summary: </b>{summary.generatedSummaryText ? <>{summary.generatedSummaryText}</>
                                        :
                                        <TextField style={{ marginTop: "10px", width: "540px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Citation Summary Text'} size="small" variant='outlined' value={summary.summaryText} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "otherSummaries", index, "summaryText"); }} />
                                    }
                                    <br />
                                    <br />
                                </span>
                            })}

                            <Dropdown
                                id="citation-summary-style"
                                text="Add Additional Style"
                                style={{ fontWeight: "bold", fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif", padding: "3px", background: "#e0e1e2 none", border: "1px solid", borderRadius: ".28571429rem" }}
                            >
                                <Dropdown.Menu>
                                    {Object.keys(citationState.displayedSummaryStyles).map((style, index) => {
                                        if (!citationState.displayedSummaryStyles[style]) {
                                            return <Dropdown.Item value={index} key={index}
                                                onClick={(e) => addOrDeleteCitationSummary(style, true, fhirJson, globalContext)}
                                                disabled={citationState.displayedSummaryStyles.style}>{style}</Dropdown.Item>
                                        }
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                            <br /><br />
                            <Button className="formButton" style={{ color: "#000000" }} content="⟳ Refresh Generated Summaries" onClick={async () => { await builderUpdateJson("Citation", resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, null); }} />
                        </>
                        :
                        <>
                            <span><b style={{ fontSize: "1.1em" }}>Style: </b>
                                <Select
                                    className="thinSelector"
                                    labelId="citation-summary-style-label"
                                    id="citation-summary-style"
                                    value={citationState.citationSummaryStyle}
                                    onChange={(e) => { changeCitationState(e.target.value, "citationSummaryStyle") }}
                                    disabled={otherSummaries.length == 0}
                                >
                                    <MenuItem value={"computable-publishing"} key="default_computable_publishing">Computable Publishing</MenuItem>
                                    {otherSummaries.map((summary, index) => {
                                        return <MenuItem value={index} key={index}>{summary.humanReadableStyle}</MenuItem>
                                    })}
                                </Select>
                            </span>
                            <br />
                            <span><b style={{ fontSize: "1.1em" }}>Summary: </b>
                                {citationState.citationSummaryStyle === "computable-publishing" ?
                                    <DisplayFromFHIR markdown={computablePublishingSummary} />
                                    :
                                    <DisplayFromFHIR markdown={otherSummaries[citationState.citationSummaryStyle].summaryText} />
                                }
                            </span>
                        </>
                    }</>
                    :
                    <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Loading...</>
                }
            </div>
            <h3 id="identifiers">{identifiers?.length === 1 ? <>Identifier</> : <>Identifiers</>}</h3>
            <div className={`CitationIdentifiersSubSection`} style={{ paddingLeft: "40px" }}>
                {identifiers?.length > 0 || relatedIdentifiers?.length > 0 ?
                    <>
                        {generateIdentifierTable(identifiers, undefined, undefined, "identifiers", editMode, identifierSystemLookup, identifierDisplayLookup, identifierTypeOptions, changeResourceElementState, setCitationState)}
                        <h4 style={{ marginBottom: "0px" }}><br />{identifiers?.length === 1 ? <>Related Identifier</> : <>Related Identifiers</>} <span style={{ fontWeight: "normal" }}>(not unique to the cited article)</span></h4>
                        {generateIdentifierTable(relatedIdentifiers, undefined, undefined, "relatedIdentifiers", editMode, identifierSystemLookup, identifierDisplayLookup, identifierTypeOptions, changeResourceElementState, setCitationState)}
                    </>
                    :
                    <span style={{ color: "#EE1111" }}>No data. <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Identifier" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "identifiers", {}); }} />}</span> <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Related Identifier" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "relatedIdentifiers", {}); }} />}</span></span>
                }
            </div>
            <h3 id="title">{fhirJson.citedArtifact?.title?.length <= 1 ? <>Title</> : <>Titles</>}</h3>
            <div className={`CitationTitlesSubSection`} style={{ paddingLeft: "40px" }}>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Title text</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Language</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {titles?.map((title, index) => {
                            return <Table.Row key={index}>
                                {editMode ?
                                    <>
                                        <Table.Cell style={{ verticalAlign: "top" }}><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Citation Title Text'} size="small" variant='outlined' value={title.text} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "titles", index, "text"); }} /></Table.Cell>
                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                            <DropDownWithAdditions currentValue={title.titleType}
                                                labelText={'Pick Title Type'}
                                                options={titleTypeOptions.options} optionsLookup={titleTypeLookup}
                                                setter={setTitleTypeOptions} stateSetter={setCitationState}
                                                changeElementState={changeResourceElementState} index={index}
                                                elementKeyEntry={"titles"} elementKeySubEntry={"titleType"} multiple={true} />
                                            {title.titleType?.length === 1 && titleTypeLookup[title.titleType[0]] && <><br /><b>Tool tip:</b> used for {titleTypeLookup[title.titleType[0]].definition} </>}
                                            {title.titleType?.length > 1 && <><br /><b>Tool tip:</b> you have two or more title types, you can remove any title type that you no longer wish to keep.</>}
                                        </Table.Cell>
                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                            <Dropdown placeholder={'Pick Language'} closeOnChange search selection
                                                selectOnBlur={false} multiple={false} clearable
                                                style={{ width: "50px" }}
                                                options={languageCodeOptions}
                                                value={title.titleLanguage || ""}
                                                onChange={(e, data) => {
                                                    if (changeResourceElementState) {
                                                        changeResourceElementState(setCitationState, data.value, undefined, undefined, "titles", index,
                                                            "titleLanguage", undefined, undefined);
                                                    } else {
                                                        if (index !== undefined) {
                                                            setCitationState(prevState => {
                                                                if (Array.isArray(prevState)) {
                                                                    let newState = prevState;
                                                                    newState[index] = { ...prevState[index], titleLanguage: data.value }
                                                                    return [...newState];
                                                                } else {
                                                                    return { ...prevState, [index]: { ...prevState[index], titleLanguage: data.value } };
                                                                }
                                                            });
                                                        } else {
                                                            setCitationState(prevState => { return { ...prevState, titles: { ...prevState["titles"], titleLanguage: data.value } } });
                                                        }
                                                    }
                                                }}
                                            />
                                        </Table.Cell>
                                    </>
                                    :
                                    <>
                                        <Table.Cell style={{ verticalAlign: "top" }}><DisplayFromFHIR markdown={title.text} /></Table.Cell>
                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                            {title.titleType?.map((titleType) => {
                                                if (titleTypeLookup[titleType]) {
                                                    return " " + titleTypeLookup[titleType].display
                                                } else {
                                                    return " " + titleType
                                                }
                                            }).toString()}
                                        </Table.Cell>
                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                            {Array.isArray(title.titleLanguage)
                                                ?
                                                <>{title.titleLanguage.map((titleLanguage) => {
                                                    if (languageLookup[titleLanguage]) {
                                                        return " " + languageLookup[titleLanguage].display
                                                    } else { return " " + titleLanguage }
                                                }).toString()}</>
                                                :
                                                <>{languageLookup[title.titleLanguage]
                                                    ?
                                                    <>{" " + languageLookup[title.titleLanguage].display}</>
                                                    :
                                                    <>Unrecognized language: {title.titleLanguage}</>
                                                }</>}
                                        </Table.Cell>
                                    </>
                                }
                            </Table.Row>;
                        })
                        }
                    </Table.Body>
                </Table>
                <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Title" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "titles", {}); }} />}</span>
            </div>
            <h3 id="abstract">{fhirJson.citedArtifact?.abstract?.length <= 1 ? <>Abstract</> : <>Abstracts</>}</h3>
            <div className={`CitationAbstractsSubSection`} style={{ paddingLeft: "40px" }}>
                {abstracts?.length >= 1 ?
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ minWidth: "440px" }}>Abstract text</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Language</Table.HeaderCell>
                                <Table.HeaderCell>Abstract copyright</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {abstracts.map((abstract, index) => {
                                return <Table.Row key={index}>
                                    {editMode ?
                                        <>
                                            <Table.Cell style={{ verticalAlign: "top" }}><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Citation Abstract Text'} size="small" variant='outlined' value={abstract.text} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "abstracts", index, "text"); }} /></Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}>
                                                <DropDownWithAdditions currentValue={abstract.abstractTypeArray}
                                                    labelText={'Pick Abstract Type'}
                                                    options={abstractTypeOptions.options} optionsLookup={abstractTypeLookup}
                                                    setter={setAbstractOptions} stateSetter={setCitationState}
                                                    changeElementState={changeResourceElementState} index={index}
                                                    elementKeyEntry={"abstracts"} elementKeySubEntry={"abstractTypeArray"}
                                                    multiple={true} />
                                                {abstract.abstractTypeArray?.length === 1 && abstractTypeLookup[abstract.abstractTypeArray[0]] && <><br /><b>Tool tip:</b> used for {abstractTypeLookup[abstract.abstractTypeArray[0]].definition} </>}
                                                {abstract.abstractTypeArray?.length > 1 && <><br /><b>Tool tip:</b> you have two or more abstract types, you can remove any abstract type that you no longer wish to keep.</>}
                                            </Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}>
                                                <Dropdown placeholder={'Pick Language'}
                                                    closeOnChange search selection
                                                    selectOnBlur={false} multiple={false} clearable
                                                    style={{ width: "50px" }}
                                                    options={languageCodeOptions}
                                                    value={abstract.abstractLanguage || ""}
                                                    onChange={(e, data) => {
                                                        if (changeResourceElementState) {
                                                            changeResourceElementState(setCitationState, data.value, undefined, undefined, "abstracts", index,
                                                                "abstractLanguage", undefined, undefined);
                                                        } else {
                                                            if (index !== undefined) {
                                                                setCitationState(prevState => {
                                                                    if (Array.isArray(prevState)) {
                                                                        let newState = prevState;
                                                                        newState[index] = { ...prevState[index], abstractLanguage: data.value }
                                                                        return [...newState];
                                                                    } else {
                                                                        return { ...prevState, [index]: { ...prevState[index], abstractLanguage: data.value } };
                                                                    }
                                                                });
                                                            } else {
                                                                setCitationState(prevState => { return { ...prevState, abstracts: { ...prevState["abstracts"], abstractLanguage: data.value } } });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Abstract Copyright'} size="small" variant='outlined' value={abstract.abstractCopyright} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "abstracts", index, "abstractCopyright"); }} /></Table.Cell>
                                        </>
                                        :
                                        <>
                                            <Table.Cell style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}><DisplayFromFHIR abstractMarkdown={abstract.text} /></Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}>
                                                {abstract.abstractTypeArray?.map((abstractTypeArray) => {
                                                    if (abstractTypeLookup[abstractTypeArray]) {
                                                        return " " + abstractTypeLookup[abstractTypeArray].display
                                                    } else {
                                                        return " " + abstractTypeArray
                                                    }
                                                }).toString()}
                                            </Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}>
                                                {Array.isArray(abstract.abstractLanguage)
                                                    ?
                                                    <>{abstract.abstractLanguage.map((abstractLanguage) => {
                                                        if (languageLookup[abstractLanguage]) {
                                                            return " " + languageLookup[abstractLanguage].display
                                                        } else { return " " + abstractLanguage }
                                                    }).toString()}</>
                                                    :
                                                    <>{languageLookup[abstract.abstractLanguage]
                                                        ?
                                                        <>{" " + languageLookup[abstract.abstractLanguage].display}</>
                                                        :
                                                        <>Unrecognized language: {abstract.abstractLanguage}</>
                                                    }</>}
                                            </Table.Cell>
                                            <Table.Cell style={{ verticalAlign: "top" }}><DisplayFromFHIR markdown={abstract.abstractCopyright} /></Table.Cell>
                                        </>
                                    }
                                </Table.Row>;
                            })
                            }
                        </Table.Body>
                    </Table>
                    :
                    <span style={{ color: "#EE1111" }}>No data.</span>
                }
                <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Abstract" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "abstracts", {}); }} />}</span>
            </div>
            <br />
            <CitationPublicationFormsTextDisplay citationPublicationFormsState={citationPublicationFormsState}
                setCitationPublicationFormsState={setCitationPublicationFormsState}
                languageOptions={languageOptions} setLanguageOptions={setLanguageOptions}
                changeCitationState={changeCitationState} changeResourceElementState={changeResourceElementState}
                changeCitationElementState={changeCitationElementState}
                identifierTypeOptions={identifierTypeOptions} setIdentifierTypeOptions={setIdentifierTypeOptions}
                editMode={editMode} getIdentifier={getIdentifier}
                key="citationPublicationFormsTextDisplay1" />
            <h3 id="urls">{webLocations.length === 1 ? <>URL</> : <>URLs</>}</h3>
            <div className={`CitationWebLocationSubSection`} style={{ paddingLeft: "40px" }}>
                {webLocations.length >= 1 ?
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>URL</Table.HeaderCell>
                                <Table.HeaderCell style={{ width: "50%" }}>Type</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {webLocations.map((webLocation, index) => {
                                return <Table.Row key={index}>
                                    {editMode ?
                                        <>
                                            <Table.Cell style={{ verticalAlign: "top" }}><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'URL'} size="small" variant='outlined' value={webLocation.url || ''} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "webLocations", index, "url"); }} /></Table.Cell>
                                            <Table.Cell>
                                                <DropDownWithAdditions currentValue={webLocation.classifier} labelText={'Pick URL Type'} options={articleUrlTypeOptions.options} optionsLookup={articleUrlTypeLookup} setter={setArticleUrlTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"webLocations"} elementKeySubEntry={"classifier"} multiple={true} />
                                                {webLocation.classifier?.length === 1 && articleUrlTypeLookup[webLocation.classifier[0]] && <><br /><b>Tool tip:</b> used for {articleUrlTypeLookup[webLocation.classifier[0]].definition} </>}
                                                {webLocation.classifier?.length > 1 && <><br /><b>Tool tip:</b> you have two or more URL types, you can remove any URL type that you no longer wish to keep.</>}
                                            </Table.Cell>
                                        </>
                                        :
                                        <>
                                            <Table.Cell><a href={webLocation.url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: "break-all" }}>{webLocation.url}</a></Table.Cell>
                                            <Table.Cell>
                                                {webLocation.classifier &&
                                                    webLocation.classifier.map((classifier) => { if (articleUrlTypeLookup[classifier]) { return " " + articleUrlTypeLookup[classifier].display } else { return " " + classifier } }).toString()
                                                }
                                            </Table.Cell>
                                        </>
                                    }
                                </Table.Row>;
                            })
                            }
                        </Table.Body>
                    </Table>
                    :
                    <span style={{ color: "#EE1111" }}>No data.</span>
                }
                <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content={"+ Add URL"} onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "webLocations", { "type": [], "typeText": "", "computableResource": false }); }} />}</span>
            </div>
            <CitationContributorsTextDisplay citationState={citationState} setCitationContributorsState={setCitationContributorsState} citationIndividualContributorsState={citationIndividualContributorsState} setCitationIndividualContributorsState={setCitationIndividualContributorsState} changeResourceElementState={changeResourceElementState} changeCitationElementState={changeCitationElementState} contributorIdentifierTypeOptions={contributorIdentifierTypeOptions} summaryTypeOptions={summaryTypeOptions} summarySourceOptions={summarySourceOptions} setSummaryTypeOptions={setSummaryTypeOptions} setSummarySourceOptions={setSummarySourceOptions} contributorRoleOptions={contributorRoleOptions} setContributorRoleOptions={setContributorRoleOptions} contributionTypeOptions={contributionTypeOptions} setContributionTypeOptions={setContributionTypeOptions} contributionInstanceTypeOptions={contributionInstanceTypeOptions} setContributionInstanceTypeOptions={setContributionInstanceTypeOptions} affiliationsOptions={affiliationsOptions} setAffiliationsOptions={setAffiliationsOptions} editMode={editMode} citationContributorsState={citationContributorsState} key="citationContributorsTextDisplay1" />
            <h3 id="classifiers">{classifications.length === 1 ? <>Classifier</> : <>Classifiers</>}</h3>
            <div className={`CitationClassifiersSubSection`} style={{ paddingLeft: "40px" }}>
                {citationState.classificationsLoaded ?
                    <>
                        {classifications.length >= 1 ?
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Classifiers</Table.HeaderCell>
                                        {/*<Table.HeaderCell>Who classified</Table.HeaderCell>*/}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {classifications.map((classificationEntry, index) => {

                                        if (editMode) {
                                            return <Table.Row key={index}>
                                                <Table.Cell style={{ verticalAlign: "top" }}>
                                                    <DropDownWithAdditions currentValue={classificationEntry.classificationType} labelText={'Pick Classification Type'} options={classificationTypeOptions.options} optionsLookup={classificationTypeLookup} setter={setClassificationTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"classifications"} elementKeySubEntry={"classificationType"} multiple={false} />
                                                </Table.Cell>
                                                <Table.Cell style={{ verticalAlign: "top" }}>
                                                    {classificationEntry.classifiersCodedDisplay}
                                                    <br />
                                                    <DropDownWithAdditions currentValue={classificationEntry.classifiersTexts} labelText={'Add Classifiers'} options={classifierOptions.options} optionsLookup={{}} setter={setClassifierOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"classifications"} elementKeySubEntry={"classifiersTexts"} multiple={true} />
                                                    {/*classificationEntry.classifier?.map((classifierEntry, classifierIndex) => { return <span key={classifierIndex}>{classifierEntry.coding ? classifierEntry.coding[0].display : classifierEntry.text}, </span> })*/}
                                                </Table.Cell>
                                            </Table.Row>
                                        } else {
                                            let classificationTypeDisplay = classificationEntry.classificationType;
                                            if (classificationTypeDisplay) {
                                                let lookedupType = classificationTypeLookup[classificationEntry.classificationType];
                                                if (lookedupType) {
                                                    classificationTypeDisplay = lookedupType.display;
                                                }
                                            }
                                            return <Table.Row key={index}>
                                                <Table.Cell style={{ verticalAlign: "top" }}>{classificationTypeDisplay && classificationTypeDisplay}</Table.Cell>
                                                <Table.Cell style={{ verticalAlign: "top" }}>
                                                    {classificationEntry.classifiersAllDisplay}
                                                </Table.Cell>
                                                {/*<Table.Cell style={{verticalAlign: "top"}}>
                                {classificationEntry.whoClassifiedPerson && <><b>Person: </b>{classificationEntry.whoClassifiedPerson}<br /></> }
                                {classificationEntry.whoClassifiedOrganization && <><b>Organization: </b>{classificationEntry.whoClassifiedOrganization}<br /></> }
                                {classificationEntry.whoClassifiedPublisher && <><b>Publisher: </b>{classificationEntry.whoClassifiedPublisher}<br /></> }
                                {classificationEntry.whoClassifiedCopyright && <><b>Classifier copyright: </b>{classificationEntry.whoClassifiedCopyright}<br /></> }
                                {classificationEntry.whoClassifiedFreeToShare !== undefined && classificationEntry.whoClassifiedFreeToShare !== "[Undeclared]" && <><b>Free to Share: </b>{classificationEntry.whoClassifiedFreeToShare}<br /></>}
                            </Table.Cell>*/}
                                            </Table.Row>
                                        }
                                    })
                                    }
                                </Table.Body>
                                {/*
                    <Table.Body>
                        {citation.citedArtifact.classification.map((classificationEntry, index) => {
                        return <Table.Row key={index}>
                            <Table.Cell>{classificationEntry.type.coding ? classificationEntry.type.coding[0].display : classificationEntry.type.text}</Table.Cell>
                            <Table.Cell>
                            {classificationEntry.classifier?.map((classifierEntry, classifierIndex) => { return <span key={classifierIndex}>{classifierEntry.coding ? classifierEntry.coding[0].display : classifierEntry.text}, </span> })}
                            </Table.Cell>
                            <Table.Cell>{classificationEntry.whoClassified?.publisher ? classificationEntry.whoClassified.publisher.display : <></>}</Table.Cell>
                        </Table.Row>;
                        })
                        }
                    </Table.Body>*/}
                            </Table>
                            :
                            <span style={{ color: "#EE1111" }}>No data.</span>
                        }
                        <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Classifier" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "classifications", { "originalJson": {}, "classifiers": [], "whoClassifiedFreeToShare": true, "whoClassifiedPerson": userName }); }} />}</span>
                    </>
                    :
                    <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Loading...</>
                }
            </div>
            <h3 id="mesh-headings">MeSH Headings</h3>
            <div className={`CitationMeshHeadingsClassifiersSubSection`} style={{ paddingLeft: "40px" }}>
                {meshHeadingSet.length > 0 && (meshHeadingSet[0].metaTags.length > 0 || meshHeadingSet[0].classifiersAllDisplay) ?
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Classifiers</Table.HeaderCell>
                                <Table.HeaderCell>Qualifiers</Table.HeaderCell>
                                <Table.HeaderCell>Attribution</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {meshHeadingSet[0].classifiersAllDisplay &&
                                <Table.Row>
                                    <Table.Cell style={{ verticalAlign: "top" }}>{meshHeadingSet[0].classifiersAllDisplay}</Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                </Table.Row>
                            }
                            {meshHeadingSet[0].metaTags.length > 0 && meshHeadingSet[0].metaTags[0].contents.map((content, contentIndex) => {
                                return <Table.Row key={contentIndex}>
                                    <Table.Cell style={{ verticalAlign: "top" }}>{content.classifiersDisplayString}</Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}>{content.qualifiersDisplay}</Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}>
                                        {content.authorDisplay && <><>Created by: </>{content.authorDisplay}<br /></>}
                                        {content.freeToShare ?
                                            <>Free to share</>
                                            :
                                            <>{content.freeToShare === false && <>Not free to share</>}</>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                    </Table>
                    :
                    <span style={{ color: "#EE1111" }}>No data.</span>
                }
            </div>
            <h3 id="third-party-classifiers">Third-Party Classifiers</h3>

            <div className={`CitationThirdPartyClassifiersSubSection`} style={{ paddingLeft: "40px" }}>
                {citationState.classificationsLoaded ?
                    <>
                        <>{extraClassifications.map((extraClassification, extraClassificationIndex) => {
                            let classificationTypeDisplay = extraClassification.classificationType;
                            if (classificationTypeDisplay) {
                                let lookedupType = classificationTypeLookup[extraClassification.classificationType];
                                if (lookedupType) {
                                    classificationTypeDisplay = lookedupType.display;
                                }
                            }
                            return <div key={extraClassificationIndex}>
                                {extraClassification.metaTags.map((metaTag, metaTagIndex) => {
                                    return <div key={metaTagIndex}>
                                        <h4>{metaTag.display ? metaTag.display : <>[Undeclared Title]</>}</h4>
                                        {metaTag.copyright && <>Copyright: {metaTag.copyright}</>}
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Classifiers</Table.HeaderCell>
                                                    <Table.HeaderCell>Qualifiers</Table.HeaderCell>
                                                    <Table.HeaderCell>Attribution</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {extraClassification.classifiersAllDisplay &&
                                                    <Table.Row>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{classificationTypeDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{extraClassification.classifiersAllDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                                    </Table.Row>
                                                }
                                                {metaTag.contents.map((content, contentIndex) => {
                                                    return <Table.Row key={contentIndex}>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.typeDisplay ? content.typeDisplay : extraClassification.classificationType}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.classifiersDisplayString}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.qualifiersDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                                            {content.authorDisplay && <><>Created by: </>{content.authorDisplay}<br /></>}
                                                            {content.freeToShare ?
                                                                <>Free to share</>
                                                                :
                                                                <>{content.freeToShare === false && <>Not free to share</>}</>
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                })
                                                }
                                            </Table.Body>
                                        </Table>
                                        <br />
                                    </div>
                                })}
                            </div>
                        })}</>
                        <>{externalClassifications.map((externalClassification, externalClassificationIndex) => {
                            let classificationTypeDisplay = externalClassification.classificationType;
                            if (classificationTypeDisplay) {
                                let lookedupType = classificationTypeLookup[externalClassification.classificationType];
                                if (lookedupType) {
                                    classificationTypeDisplay = lookedupType.display;
                                }
                            }
                            return <div key={externalClassificationIndex}>
                                {externalClassification.metaTags.map((metaTag, metaTagIndex) => {
                                    return <div key={metaTagIndex}>
                                        <h4>{metaTag.display ? metaTag.display : <>[Undeclared Title]</>}</h4>
                                        {metaTag.copyright && <>Copyright: {metaTag.copyright}</>}
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Classifiers</Table.HeaderCell>
                                                    <Table.HeaderCell>Qualifiers</Table.HeaderCell>
                                                    <Table.HeaderCell>Attribution</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {externalClassification.classifiersAllDisplay &&
                                                    <Table.Row>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{classificationTypeDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{externalClassification.classifiersAllDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
                                                    </Table.Row>
                                                }
                                                {metaTag.contents.map((content, contentIndex) => {
                                                    return <Table.Row key={contentIndex}>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.typeDisplay ? content.typeDisplay : externalClassification.classificationType}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.classifiersDisplayString}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>{content.qualifiersDisplay}</Table.Cell>
                                                        <Table.Cell style={{ verticalAlign: "top" }}>
                                                            {content.authorDisplay && <><>Created by: </>{content.authorDisplay}<br /></>}
                                                            {content.freeToShare ?
                                                                <>Free to share</>
                                                                :
                                                                <>{content.freeToShare === false && <>Not free to share</>}</>
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                })
                                                }
                                            </Table.Body>
                                        </Table>
                                        <br />
                                    </div>
                                })}
                            </div>
                        })}</>
                        {extraClassifications.length + externalClassifications.length === 0 && <span style={{ color: "#EE1111" }}>No data.</span>}
                    </>
                    :
                    <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Loading...</>
                }
            </div>
            <h3 id="status">Status</h3>
            <div className={`CitationStatusSubSection`} style={{ paddingLeft: "40px" }}>
                <span><b style={{ fontSize: "1.1em" }}>Current state: </b>
                    {editMode ?
                        <DropDownWithAdditions currentValue={citationState.currentStates} labelText={'Pick Current State'} options={citedArtifactStatusTypeOptions.options} optionsLookup={citedArtifactStatusTypeLookup} setter={setCitedArtifactStatusTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={undefined} elementKeyEntry={"currentStates"} elementKeySubEntry={undefined} width={"50%"} multiple={true} />
                        :
                        <>{citationState.currentStates.toString()}</>
                    }
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Status dates: </b>
                </span>
                {citationState.statusDates.length >= 1 ?
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>State</Table.HeaderCell>
                                <Table.HeaderCell>Actual/Expected</Table.HeaderCell>
                                <Table.HeaderCell>Start</Table.HeaderCell>
                                <Table.HeaderCell>End</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {citationState.statusDates.map((statusDateEntry, index) => {
                                return <Table.Row key={index}>
                                    <Table.Cell>{editMode ?
                                        <DropDownWithAdditions currentValue={statusDateEntry.activity} labelText={'Pick Activity'} options={citedArtifactStatusTypeOptions.options} optionsLookup={citedArtifactStatusTypeLookup} setter={setCitedArtifactStatusTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"statusDates"} elementKeySubEntry={"activity"} multiple={false} />
                                        :
                                        <>{statusDateEntry.activity.toString()}</>
                                    }</Table.Cell>
                                    <Table.Cell>
                                        {statusDateEntry.actual !== undefined && statusDateEntry.actual !== null ? <></> : <></>}
                                        {editMode ?
                                            <fieldset style={{ width: "300px", display: "inline", border: "0px", verticalAlign: "bottom", padding: "0px" }}>
                                                &nbsp;&nbsp;&nbsp;
                                                <input type="radio" name={"statusDataActualExpected" + index.toString()} value="true" checked={statusDateEntry.actual} onChange={(e) => { changeResourceElementState(setCitationState, e.target.checked, undefined, undefined, "statusDates", index, "actual"); }} />
                                                <label> Actual</label>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" name={"statusDataActualExpected" + index.toString()} value="false" checked={statusDateEntry.actualProvided && !statusDateEntry.actual} onChange={(e) => { changeResourceElementState(setCitationState, !e.target.checked, undefined, undefined, "statusDates", index, "actual"); changeResourceElementState(setCitationState, true, undefined, undefined, "statusDates", index, "actualProvided"); }} />
                                                <label> Expected</label>
                                            </fieldset>
                                            :
                                            <>{statusDateEntry.actual ? <>Actual</> : statusDateEntry.actualProvided ? <>Expected</> : <></>}</>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{editMode ?
                                        <TextField style={{ width: "200px" }} multiline className="inputField" type='text' label={'Start Date (YYYY-MM-DD)'} size="small" variant='outlined' value={statusDateEntry.periodStart} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "statusDates", index, "periodStart"); }} />
                                        :
                                        statusDateEntry.periodStart
                                    }</Table.Cell>
                                    <Table.Cell>{editMode ?
                                        <TextField style={{ width: "200px" }} multiline className="inputField" type='text' label={'End Date (YYYY-MM-DD)'} size="small" variant='outlined' value={statusDateEntry.periodEnd} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "statusDates", index, "periodEnd"); }} />
                                        :
                                        statusDateEntry.periodEnd
                                    }</Table.Cell>
                                </Table.Row>;
                            })
                            }
                        </Table.Body>
                    </Table>
                    :
                    <span style={{ color: "#EE1111" }}>No data.</span>
                }
                <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Status Date" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "statusDates", { "actual": false, "actualProvided": false }); }} />}</span>
            </div>
            <h3 id="modifiers">Modifiers</h3>
            <div className={`CitationModifiersSubSection`} style={{ paddingLeft: "40px" }}>
                <span><b style={{ fontSize: "1.1em" }}>Date accessed: </b>{editMode ? <TextField style={{ width: "280px" }} multiline className="inputField" type='text' label={'Date accessed (YYYY-MM-DD)'} size="small" variant='outlined' value={citationState.dateAccessed} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "dateAccessed", undefined, undefined); }} /> : citationState.dateAccessed}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Version: </b>
                    {editMode ?
                        <TextField style={{ width: "280px" }} multiline className="inputField" type='text' label={'Version'} size="small"
                            variant='outlined' value={citationState.versionValue}
                            onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "versionValue", undefined, undefined); }}
                        /> : citationState.versionValue}
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Part: </b></span>
                {(editMode || citationState.partTypes.length > 0 || citationState.partValue) &&
                    <>
                        {(editMode || citationState.partTypes.length > 0) &&
                            <>
                                <br />
                                <span style={{ paddingLeft: "40px" }}><b style={{ fontSize: "1.1em" }}>Type: </b>
                                    {editMode ?
                                        <DropDownWithAdditions currentValue={citationState.partTypes} labelText={'Pick Part Type'} options={partTypeOptions.options} optionsLookup={partTypeLookup} setter={setPartTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={undefined} elementKeyEntry={"partTypes"} elementKeySubEntry={undefined} width={"50%"} multiple={true} />
                                        :
                                        <>{citationState.partTypes.toString()}</>
                                    }
                                </span>
                            </>}
                        <br />
                        <span style={{ paddingLeft: "40px" }}>
                            <b style={{ fontSize: "1.1em" }}>Value: </b>
                            {editMode ? <TextField style={{ width: "280px" }} multiline className="inputField" type='text'
                                label={'Part value'} size="small" variant='outlined' value={citationState.partValue}
                                onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "partValue", undefined, undefined); }}
                            /> : citationState.partValue}
                        </span>
                    </>
                }
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Base citation: </b>
                    {(editMode || citationState.baseCitationReference) &&
                        <>
                            <br />
                            <span style={{ paddingLeft: "40px" }}><b style={{ fontSize: "1.1em" }}>Reference URL: </b>
                                {editMode ?
                                    <TextField style={{ width: "280px" }} multiline className="inputField" type='text' label={'Base citation reference URL'} size="small" variant='outlined' value={citationState.baseCitationReference} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "baseCitationReference", undefined, undefined); }} />
                                    :
                                    <a href={citationState.baseCitationReference} target="_blank" rel="noopener noreferrer">{citationState.baseCitationReference}</a>
                                }
                            </span>
                        </>
                    }
                    {editMode ?
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr className={"subTableRow"} style={{ width: "100%" }}>
                                    <td style={{ paddingLeft: "40px" }}><b style={{ fontSize: "1.1em" }}>Identifier: </b></td>
                                    {generateIdentifierSystemCell(baseCitationIdentifier, undefined, undefined, undefined, "baseCitationIdentifier", editMode, identifierSystemLookup, identifierDisplayLookup, identifierTypeOptions, changeResourceElementState, setCitationState)}
                                    {generateIdentifierValueCell(baseCitationIdentifier, undefined, undefined, undefined, "baseCitationIdentifier", editMode, changeResourceElementState, setCitationState)}
                                </tr>
                            </tbody>
                        </table>
                        :
                        baseCitationReference || baseCitationDisplay || baseCitationIdentifier.value ?
                            baseCitationIdentifier.value
                                ?
                                <><br /><span style={{ paddingLeft: "80px" }}><b style={{ fontSize: "1.1em" }}>Identifier:</b> {<>{baseCitationIdentifier.system && identifierSystemLookup[baseCitationIdentifier.system] ? identifierSystemLookup[baseCitationIdentifier.system].display : baseCitationIdentifier.typeText ? baseCitationIdentifier.typeText : baseCitationIdentifier.system ? baseCitationIdentifier.system : baseCitationIdentifier.assignerDisplay}</>}&nbsp;&nbsp;&nbsp;{baseCitationIdentifier.value}</span></>
                                :
                                <></>
                            :
                            <span style={{ color: "#EE1111" }}>No data.</span>
                    }
                    {(editMode || citationState.baseCitationDisplay) &&
                        <>
                            <br />
                            <span style={{ paddingLeft: "40px" }}><b style={{ fontSize: "1.1em" }}>Display: </b>
                                {editMode ?
                                    <TextField style={{ width: "280px" }} multiline className="inputField" type='text' label={'Base citation display'} size="small" variant='outlined' value={citationState.baseCitationDisplay} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "baseCitationDisplay", undefined, undefined); }} />
                                    :
                                    <>{citationState.baseCitationDisplay}</>
                                }
                            </span>
                        </>
                    }
                </span>
                <br />
                <br />
                {(editMode || citationState.notes.length > 0) &&
                    <span><b style={{ fontSize: "1.1em" }}>Notes: </b>{citationState.notes.map((note, noteIndex) => {
                        if (editMode) {
                            return <><br /><TextField style={{ width: "380px" }} multiline className="inputField" type='text' label={'Note ' + (noteIndex + 1).toString()} size="small" variant='outlined' value={citationState.notes[noteIndex]} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "notes", noteIndex, undefined); }} /><br /></>
                        } else {
                            return <span style={noteIndex > 0 ? { paddingLeft: "40px" } : { paddingLeft: "0px" }}>{note}<br /></span>
                        }
                    })}</span>}
                <span>{editMode && <><br /><Button className="formButton" style={{ color: "#000000" }} content="+ Add Note" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "notes", ""); }} /></>}</span>
            </div>
            <h3 id="related-articles">{citationState.relatesToSet.length === 1 ? <>Related Article</> : <>Related Articles</>}</h3>
            <div className={`CitationRelatedArticlesSubSection`} style={{ paddingLeft: "40px" }}>
                {citationState.relatesToSet.length >= 1 ?
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Relation Type</Table.HeaderCell>
                                <Table.HeaderCell>Target Classifier</Table.HeaderCell>
                                <Table.HeaderCell>Target</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {citationState.relatesToSet.map((relatesToEntry, index) => {
                                return <Table.Row key={index}>
                                    <Table.Cell style={{ verticalAlign: "top" }}>
                                        <>
                                            {editMode && relatesToEntry.editing ?
                                                <Dropdown
                                                    name="relationTypeDropdown"
                                                    placeholder="Relation Type"
                                                    closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                                    options={startingArtifactRelationshipTypeOptions}
                                                    value={relatesToEntry.type || ''}
                                                    onChange={(e, data) => {
                                                        changeResourceElementState(setCitationState, data.value, undefined, undefined, "relatesToSet", index, "type");
                                                    }}
                                                />
                                                :
                                                <>{relatesToEntry.type && artifactRelationshipTypeLookup[relatesToEntry.type] ? artifactRelationshipTypeLookup[relatesToEntry.type].display : relatesToEntry.type}</>
                                            }
                                            {editMode && !relatesToEntry.editing &&
                                                <>
                                                    <br />
                                                    <Button className="formButton" style={{ color: "#000000" }} content="Edit Related Article" onClick={() => { setCitationState(prevState => { let newArray = prevState.relatesToSet; newArray[index].editing = true; return { ...prevState, relatesToSet: newArray } }) }} />
                                                </>
                                            }
                                        </>
                                    </Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}>
                                        {editMode && relatesToEntry.editing ?
                                            <>
                                                {relatesToEntry.classifiersNonEditableDisplay}
                                                <DropDownWithAdditions currentValue={relatesToEntry.classifiersEditable} labelText={'Add Classifiers'} options={artifactClassifierTypeOptions.options} optionsLookup={artifactClassifierTypeLookup} setter={setArtifactClassifierTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"relatesToSet"} elementKeySubEntry={"classifiersEditable"} multiple={true} />
                                                {/*classificationEntry.classifier && classificationEntry.classifier.map((classifierEntry, classifierIndex) => { return <span key={classifierIndex}>{classifierEntry.coding ? classifierEntry.coding[0].display : classifierEntry.text}, </span> })*/}
                                            </>
                                            :
                                            relatesToEntry.classifiersAllDisplay
                                        }
                                    </Table.Cell>
                                    <Table.Cell style={{ verticalAlign: "top" }}>
                                        {editMode && relatesToEntry.editing ?
                                            <>
                                                <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={"Target Description"} size="small" variant='outlined' value={relatesToEntry.display} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "relatesToSet", index, "display"); }} />
                                                <br /><br />
                                                <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={"Target Citation"} size="small" variant='outlined' value={relatesToEntry.citation} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "relatesToSet", index, "citation"); }} />
                                                <br /><br />
                                                <TextField style={{ minWidth: "100%" }} multiline className="inputField" type='text' label={"Target URL"} size="small" variant='outlined' value={relatesToEntry.targetUri || relatesToEntry.documentURL} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "relatesToSet", index, "targetUri"); }} />
                                                <br /><br />
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr><b>Identifier:</b></tr>
                                                        <tr className={"subTableRow"} style={{ width: "100%" }}>
                                                            {generateIdentifierSystemCell(relatesToEntry.targetReferenceIdentifier || relatesToEntry.resourceReferenceIdentifier, undefined, "relatesToSet", index, "targetReferenceIdentifier", true, identifierSystemLookup, identifierDisplayLookup, identifierTypeOptions, changeResourceElementState, setCitationState)}
                                                            {generateIdentifierValueCell(relatesToEntry.targetReferenceIdentifier || relatesToEntry.resourceReferenceIdentifier, undefined, "relatesToSet", index, "targetReferenceIdentifier", true, changeResourceElementState, setCitationState)}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                            :
                                            <>{relatesToEntry.display}{(relatesToEntry.display && relatesToEntry.citation) && <br />}<DisplayFromFHIR markdown={relatesToEntry.citation} />
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <b>{((relatesToEntry.targetReferenceIdentifier?.system &&
                                                        identifierSystemLookup[relatesToEntry.targetReferenceIdentifier.system] &&
                                                        (relatesToEntry.targetReferenceIdentifier.value || relatesToEntry.targetUri)) ||
                                                        (relatesToEntry.resourceReferenceIdentifier?.system &&
                                                            identifierSystemLookup[relatesToEntry.resourceReferenceIdentifier.system] &&
                                                            (relatesToEntry.resourceReferenceIdentifier.value || relatesToEntry.documentURL))) ?
                                                        <>{identifierSystemLookup[relatesToEntry.targetReferenceIdentifier?.system || relatesToEntry.resourceReferenceIdentifier?.system].display + ": "}</>
                                                        :
                                                        (relatesToEntry.targetReferenceIdentifier?.typeText || relatesToEntry.resourceReferenceIdentifier?.typeText) ?
                                                            <>{relatesToEntry.targetReferenceIdentifier?.typeText || relatesToEntry.resourceReferenceIdentifier.typeText + ": "}</>
                                                            /*
                                                        :
                                                        relatesToEntry.resourceReferenceIdentifier.system ?
                                                        <>{relatesToEntry.resourceReferenceIdentifier.system + ": "}</>
                                                        */
                                                            :
                                                            (relatesToEntry.targetReferenceIdentifier?.value || relatesToEntry.resourceReferenceIdentifier?.value || relatesToEntry.documentURL || relatesToEntry.targetUri) &&
                                                            "URL: "
                                                    } </b>
                                                    {relatesToEntry.documentURL ?
                                                        <a href={relatesToEntry.documentURL} target="_blank" rel="noopener noreferrer">
                                                            {relatesToEntry.resourceReferenceIdentifier.value ?
                                                                relatesToEntry.resourceReferenceIdentifier.value
                                                                :
                                                                relatesToEntry.documentURL
                                                            }
                                                        </a>

                                                        :
                                                        <span>{relatesToEntry.resourceReferenceIdentifier?.value}</span>
                                                    }
                                                    {relatesToEntry.targetUri ?
                                                        <a href={relatesToEntry.targetUri} target="_blank" rel="noopener noreferrer">
                                                            {relatesToEntry.targetReferenceIdentifier.value ?
                                                                relatesToEntry.targetReferenceIdentifier.value
                                                                :
                                                                relatesToEntry.targetUri
                                                            }
                                                        </a>

                                                        :
                                                        <span>{relatesToEntry.targetReferenceIdentifier?.value}</span>
                                                    }
                                                </>
                                                {/*
                            <a href={
                            relatesToEntry.resourceReferenceReference ?
                                relatesToEntry.resourceReferenceReference
                            :
                                relatesToEntry.resourceReferenceIdentifier.system.slice(-1) === "/" ?
                                relatesToEntry.resourceReferenceIdentifier.system+relatesToEntry.resourceReferenceIdentifier.value+"/"
                                :
                                relatesToEntry.resourceReferenceIdentifier.system+"/"+relatesToEntry.resourceReferenceIdentifier.value+"/"
                            } target="_blank" rel="noopener noreferrer">
                            {relatesToEntry.resourceReferenceIdentifier.value}
                            </a>
                            */}
                                            </>
                                        }
                                    </Table.Cell>
                                </Table.Row>;
                            })
                            }
                        </Table.Body>
                    </Table>
                    :
                    <span style={{ color: "#EE1111" }}>No data.</span>
                }
                <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Related Article" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "relatesToSet", { type: "", target: "", classifiersCoded: [], classifiersEditable: [], classifiersCodedDisplay: "", classifiersNonEditableJson: [], classifiersNonEditableDisplay: "", classifiersTexts: [], classifiersAllDisplay: "", targetReferenceType: "", citation: "", display: "", targetReferenceIdentifier: {}, targetUri: "", isTargetFhirResource: false, editing: true }); }} />}</span>
            </div>
            <h3 id="citation-metadata">Citation Metadata
                &nbsp;&nbsp;&nbsp;
                {editMode && <Button className="formButton" style={{ color: "#000000" }} content="Edit Metadata" onClick={() => { setCitationState(prevState => { return { ...prevState, citationMetadataEditMode: true }; }); }} />}
            </h3>
            {citationMetadataEditMode && <br />}
            <div className={`CitationWebLocationSubSection`} style={{ paddingLeft: "40px" }}>
                <span><b style={{ fontSize: "1.1em" }}>Title of the Citation: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ minWidth: "520px" }} multiline className="inputField" type='text' label={"Title of the Citation"} size="small" variant='outlined' value={citationState.citationTitle || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationTitle: e.target.value }; }); }} />
                        :
                        citationState.citationTitle
                    }
                </span>
                <br />
                {citationMetadataEditMode && <br />}
                <span>
                    <b style={{ fontSize: "1.1em" }}>URL: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ width: "280px" }} multiline className="inputField" type='text' label={"Citation URL"} size="small" variant='outlined' value={citationState.citationURL || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationURL: e.target.value }; }); }} disabled />
                        :
                        <CreateHyperlink url={citationState.citationURL} openInNewTab={true} />
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <>
                        <b style={{ fontSize: "1.1em" }}>FOI: </b>{citationState.citationFOI}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                    <b style={{ fontSize: "1.1em" }}>Version: </b>
                    {false && citationMetadataEditMode ?
                        <TextField style={{ width: "80px" }} multiline className="inputField" type='text' label={"Version"} size="small" variant='outlined' value={citationState.citationVersion || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationVersion: e.target.value }; }); }} />
                        :
                        citationState.citationVersion
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{ fontSize: "1.1em" }}>Date last changed: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ width: "250px" }} multiline className="inputField" type='text'
                            label={"Date last changed (YYYY-MM-DD)"} size="small" variant='outlined'
                            value={citationState.citationDate || ''}
                            onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationDate: e.target.value }; }); }} />
                        :
                        citationState.citationDate
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {citationMetadataEditMode && <br />}
                    <b style={{ fontSize: "1.1em" }}>Status: </b>
                    {citationMetadataEditMode ?
                        <Dropdown
                            name="citationStatus"
                            placeholder="Status"
                            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                            style={{ width: "100px" }}
                            options={[{ key: "active", text: "active", value: "active" }, { key: "draft", text: "draft", value: "draft" }, { key: "retired", text: "retired", value: "retired" }]}
                            value={citationState.status || fhirEntryState.status || ''}
                            onChange={(e, data) => {
                                setCitationState(prevState => {
                                    return { ...prevState, status: data.value };
                                });
                            }
                                //{changeResourceElementState(setFhirEntryState, data.value, undefined, undefined, "status", undefined, undefined);}
                            }
                        />
                        :
                        fhirEntryState.status
                    }
                </span>
                <br />
                {citationMetadataEditMode ?
                    <>
                        <MetaProfileEntry resourceType="Citation" startingValue={citationState.meta}
                            setResourceState={setCitationState} />
                    </>
                    :
                    <>{Array.isArray(citationState.meta?.profile) && <>
                        {(citationState.meta.profile.length === 1) && <p><b>Profile: </b>{displayProfileName(citationState.meta.profile[0], "Citation")}</p>}
                        {(citationState.meta.profile.length > 1) && <p><b>Profiles: </b>{citationState.meta.profile.map((profileInstance, profileIndex) => displayProfileName(profileInstance, "Citation")).join(', ')}</p>}
                    </>}
                    </>}
                {(citationMetadataEditMode || citationState.citationResourceIdentifiers?.length > 1 || (citationState.citationResourceIdentifiers?.length === 1 && citationState.citationFOI === "")) &&
                    <>
                        <br />
                        <b style={{ fontSize: "1.1em" }}>Identifiers: </b>
                        {generateIdentifierTable(citationState.citationResourceIdentifiers, undefined, undefined, "citationResourceIdentifiers", citationMetadataEditMode, identifierSystemLookup, identifierDisplayLookup, identifierTypeOptions, changeResourceElementState, setCitationState, ["https://fevir.net"])}
                        <br />
                    </>
                }
                {citationMetadataEditMode && <br />}
                <span><b style={{ fontSize: "1.1em" }}>Publisher: </b>
                    {citationMetadataEditMode ?
                        <>
                            <TextField style={{ width: "250px" }} multiline className="inputField" type='text' label={"Publisher"} size="small" variant='outlined' value={citationState.citationPublisher || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationPublisher: e.target.value }; }); }} disabled={citationState.citationPublisher === "Computable Publishing LLC" ? true : false} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Dropdown
                                name="citationContactSystem"
                                placeholder="Contact System"
                                closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                style={{ width: "100px" }}
                                disabled={citationState.citationPublisher === "Computable Publishing LLC" ? true : false}
                                options={[{ key: "phone", text: "phone", value: "phone" }, { key: "fax", text: "fax", value: "fax" }, { key: "email", text: "email", value: "email" }, { key: "pager", text: "pager", value: "pager" }, { key: "url", text: "url", value: "url" }, { key: "sms", text: "sms", value: "sms" }, { key: "other", text: "other", value: "other" }]}
                                value={citationState.citationPublisher === "Computable Publishing LLC" ? "email" : citationState.bestContactSystem ? citationState.bestContactSystem : ''}
                                onChange={(e, data) => {
                                    changeResourceElementState(setCitationState, data.value, undefined, undefined, "bestContactSystem", undefined, undefined);
                                }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField style={{ width: "300px" }} multiline className="inputField" type='text' label={"Contact Value"} size="small" variant='outlined' value={citationState.citationPublisher === "Computable Publishing LLC" ? "support@computablepublishing.com" : citationState.bestContact ? citationState.bestContact : ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, bestContact: e.target.value }; }); }} disabled={citationState.citationPublisher === "Computable Publishing LLC" ? true : false} />
                        </>
                        :
                        <>{citationState.citationPublisher} {citationState.bestContact && <>{citationState.bestContactSystem === "email" ? <>(<a href={"mailto:" + citationState.bestContact}>email</a>)</> : <>&nbsp;&nbsp;&nbsp;{citationState.bestContactSystem && <b>{citationState.bestContactSystem}: </b>} {citationState.bestContactSystem === "url" ? <CreateHyperlink url={citationState.bestContact} openInNewTab={true} /> : citationState.bestContact}</>}</>}</>
                    }
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>FEvIR Platform use: </b>{citationState.fevirPlatformUseClassifierDisplay}</span>
                <br />
                {citationMetadataEditMode && <br />}
                <span>
                    {citationMetadataEditMode ?
                        <DataEntry asArray={true} datatype='UsageContext' elementName='citationUseContext'
                            fieldLabel='Use Context' startCollapsed={true}
                            startEmptyArrayClosed={true} deletableArray={true}
                            startingValue={citationState.citationUseContext} setResourceState={setCitationState} />
                        :
                        (Array.isArray(citationState.citationUseContext) && citationState.citationUseContext.length > 0) &&
                        citationState.citationUseContext.map((useContext, useContextIndex) => {
                            return <div key={useContextIndex}>
                                <b>Use Context: </b>
                                <div style={{ marginLeft: "24px" }}><DisplayFromFHIR usageContext={useContext} /></div>
                                <br />
                            </div>
                        })
                    }
                </span>
                <br />
                {citationMetadataEditMode && <br />}
                <span><b style={{ fontSize: "1.1em" }}>Description: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ width: "540px" }} multiline className="inputField" type='text' label={"Description"} size="small" variant='outlined' value={citationState.citationDescription || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, citationDescription: e.target.value }; }); }} />
                        :
                        <DisplayFromFHIR markdown={citationState.citationDescription} />
                    }
                </span>
                <br />
                <span>
                    <b style={{ fontSize: "1.1em" }}>Contributors: </b>
                    {citationMetadataEditMode ?
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Contributor Name</Table.HeaderCell>
                                    <Table.HeaderCell>Contributor Role</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {citationState.citationResourceContributors.map((contributorEntry, index) => {
                                    return <Table.Row key={index}>
                                        <Table.Cell><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={"Name"} size="small" variant='outlined' value={contributorEntry.name || ''} onChange={(e) => { setCitationState(prevState => { let newArray = prevState.citationResourceContributors; newArray[index].name = e.target.value; return { ...prevState, citationResourceContributors: newArray }; }); }} /></Table.Cell>
                                        <Table.Cell>
                                            <Dropdown
                                                name="citationResourceContributorRole"
                                                placeholder="Role"
                                                closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                                style={{ width: "100px" }}
                                                options={[
                                                    { key: "Creator", text: "Creator", value: "Creator" },
                                                    { key: "Editor", text: "Editor", value: "Editor" },
                                                    { key: "Reviewer", text: "Reviewer", value: "Reviewer" },
                                                    { key: "Endorser", text: "Endorser", value: "Endorser" },
                                                    { key: "Recorder", text: "Recorder", value: "Recorder" }
                                                ]}
                                                value={contributorEntry.role || ''}
                                                onChange={(e, data) => {
                                                    setCitationState(prevState => { let newArray = prevState.citationResourceContributors; newArray[index].role = data.value; return { ...prevState, citationResourceContributors: newArray }; });
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>;
                                })
                                }
                            </Table.Body>
                        </Table>
                        :
                        citationState.citationResourceContributorsDisplayString
                    }
                </span>
                <span>{citationMetadataEditMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Contributor" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "citationResourceContributors", { "role": "", "name": "" }); }} />}</span>
                <br />
                {citationMetadataEditMode && <br />}
                <span>
                    <b style={{ fontSize: "1.1em" }}>Approval date: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ width: "260px" }} multiline className="inputField"
                            type='text' label={"Approval Date (YYYY-MM-DD)"}
                            size="small" variant='outlined'
                            value={citationState.approvalDate || citationState.dateApproved || ''}
                            onChange={(e) => {
                                setCitationState(prevState => {
                                    return { ...prevState, approvalDate: e.target.value };
                                });
                            }} />
                        :
                        <>{citationState.approvalDate ?
                            citationState.approvalDate
                            :
                            citationState.dateApproved
                        }</>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{ fontSize: "1.1em" }}>Last review date: </b>
                    {citationMetadataEditMode ?
                        <TextField style={{ width: "260px" }} multiline className="inputField" type='text' label={"Last Review Date (YYYY-MM-DD)"} size="small" variant='outlined' value={citationState.lastReviewDate || ''} onChange={(e) => { setCitationState(prevState => { return { ...prevState, lastReviewDate: e.target.value }; }); }} />
                        :
                        citationState.lastReviewDate
                    }
                </span>
                <br />
                {citationMetadataEditMode && <br />}
                <span>
                    <b style={{ fontSize: "1.1em" }}>Current state: </b>
                    {citationMetadataEditMode ?
                        <DropDownWithAdditions currentValue={citationState.citationCurrentStates} labelText={'Add Current State'} options={citationStatusTypeOptions.options} optionsLookup={citationStatusTypeLookup} setter={setCitationStatusTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} elementKeyEntry={"citationCurrentStates"} multiple={true} width={"760px"} />
                        :
                        <>
                            {citationState.citationCurrentStates.map((currentStateEntry, index) => {
                                return <span key={index}>{currentStateEntry && citationStatusTypeLookup[currentStateEntry] ? citationStatusTypeLookup[currentStateEntry].display : currentStateEntry}{index !== citationState.citationCurrentStates.length - 1 && <>,</>} </span>
                            })}
                        </>
                    }
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Status dates: </b>
                    {citationState.citationStatusDates.length >= 1 ?
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>State</Table.HeaderCell>
                                    <Table.HeaderCell>Actual/Expected</Table.HeaderCell>
                                    <Table.HeaderCell>Start</Table.HeaderCell>
                                    <Table.HeaderCell>End</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {citationState.citationStatusDates.map((statusDateEntry, index) => {
                                    return <Table.Row key={index}>
                                        <Table.Cell>{citationMetadataEditMode ?
                                            <DropDownWithAdditions currentValue={statusDateEntry.activity} labelText={'Pick Activity'} options={citationStatusTypeOptions.options} optionsLookup={citationStatusTypeLookup} setter={setCitationStatusTypeOptions} stateSetter={setCitationState} changeElementState={changeResourceElementState} index={index} elementKeyEntry={"citationStatusDates"} elementKeySubEntry={"activity"} multiple={false} />
                                            :
                                            <>{statusDateEntry.activity.toString()}</>
                                        }</Table.Cell>
                                        <Table.Cell>
                                            {statusDateEntry.actual !== undefined && statusDateEntry.actual !== null ? <></> : <></>}
                                            {citationMetadataEditMode ?
                                                <fieldset style={{ width: "300px", display: "inline", border: "0px", verticalAlign: "bottom", padding: "0px" }}>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <input type="radio" name={"citationStatusDataActualExpected" + index.toString()} value="true" checked={statusDateEntry.actual} onChange={(e) => { changeResourceElementState(setCitationState, e.target.checked, undefined, undefined, "citationStatusDates", index, "actual"); }} />
                                                    <label> Actual</label>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="radio" name={"citationStatusDataActualExpected" + index.toString()} value="false" checked={statusDateEntry.actualProvided && !statusDateEntry.actual} onChange={(e) => { changeResourceElementState(setCitationState, !e.target.checked, undefined, undefined, "citationStatusDates", index, "actual"); changeResourceElementState(setCitationState, true, undefined, undefined, "citationStatusDates", index, "actualProvided"); }} />
                                                    <label> Expected</label>
                                                </fieldset>
                                                :
                                                <>{statusDateEntry.actual ? <>Actual</> : statusDateEntry.actualProvided ? <>Expected</> : <></>}</>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>{citationMetadataEditMode ?
                                            <TextField style={{ width: "200px" }} multiline className="inputField" type='text' label={'Start Date (YYYY-MM-DD)'} size="small" variant='outlined' value={statusDateEntry.periodStart} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "citationStatusDates", index, "periodStart"); }} />
                                            :
                                            statusDateEntry.periodStart
                                        }</Table.Cell>
                                        <Table.Cell>{citationMetadataEditMode ?
                                            <TextField style={{ width: "200px" }} multiline className="inputField" type='text' label={'End Date (YYYY-MM-DD)'} size="small" variant='outlined' value={statusDateEntry.periodEnd} onChange={(e) => { changeResourceElementState(setCitationState, e.target.value, undefined, undefined, "citationStatusDates", index, "periodEnd"); }} />
                                            :
                                            statusDateEntry.periodEnd
                                        }</Table.Cell>
                                    </Table.Row>;
                                })
                                }
                            </Table.Body>
                        </Table>
                        :
                        <span style={{ color: "#EE1111" }}>No data.</span>
                    }
                    <span>{citationMetadataEditMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Status Date" onClick={() => { addResourceElementState(setCitationState, undefined, undefined, "citationStatusDates", { "actual": false, "actualProvided": false }); }} />}</span>
                </span>
                <div>
                    {citationMetadataEditMode
                        ?
                        <>
                            <DataEntry datatype='markdown' elementName='citationPurpose' fieldLabel='Purpose'
                                startingValue={citationState.citationPurpose} setResourceState={setCitationState} />
                            <DataEntry datatype='markdown' elementName='citationCopyright' fieldLabel='Copyright'
                                startingValue={citationState.citationCopyright} setResourceState={setCitationState} />
                            <DataEntry datatype='Period' elementName='citationEffectivePeriod' fieldLabel='Effective period'
                                startingValue={citationState.citationEffectivePeriod} setResourceState={setCitationState} />
                            <DataEntry asArray={true} datatype='RelatesTo' elementName='citationRelatedArtifact'
                                fieldLabel='Related Item' startCollapsed={true}
                                allowedTypeValues='FHIR' startEmptyArrayClosed={true} deletableArray={true}
                                startingValue={citationState.citationRelatedArtifact} setResourceState={setCitationState} />
                            <DataEntry asArray={true} datatype='Annotation' elementName='citationNote'
                                fieldLabel='Note' startCollapsed={true}
                                startEmptyArrayClosed={true} deletableArray={true}
                                startingValue={citationState.citationNote} setResourceState={setCitationState} />
                        </>
                        :
                        <>
                            {citationState.citationPurpose && <div><b>Purpose: </b><DisplayFromFHIR markdown={citationState.citationPurpose} /><br /><br /></div>}
                            {citationState.citationCopyright && <div><b>Copyright: </b><DisplayFromFHIR markdown={citationState.citationCopyright} /><br /><br /></div>}
                            {citationState.citationEffectivePeriod && <p><b>Effective period: </b>{getStringFromFHIR.Period(citationState.citationEffectivePeriod)}</p>}
                            {(Array.isArray(citationState.citationRelatedArtifact) && citationState.citationRelatedArtifact.length > 0) &&
                                citationState.citationRelatedArtifact.map((relatedItem, relatedItemIndex) => {
                                    return <div key={relatedItemIndex}>
                                        <b>Related Item: </b>
                                        <div style={{ marginLeft: "24px" }}><DisplayFromFHIR relatesTo={relatedItem} /></div>
                                    </div>
                                })}
                            {(Array.isArray(citationState.citationNote) && citationState.citationNote.length > 0) &&
                                citationState.citationNote.map((note, noteIndex) => {
                                    return <div key={noteIndex}>
                                        <b>Note: </b>
                                        <div style={{ marginLeft: "24px" }}><DisplayFromFHIR annotation={note} /></div>
                                    </div>
                                })}
                        </>
                    }
                </div>
            </div>
            <h3 id="json-outline">JSON Outline</h3>
            <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
            <br /><br />
        </div>
    }
});
export default CitationDisplay;