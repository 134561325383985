import { DisplayFromFHIR } from './ResourceFunctions';
import ResourceBottomDisplay from './ResourceBottomDisplay';

const LibraryDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  if (fhirJson.citeAs) {
    howToCite = fhirJson.citeAs;
  }
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/cite-as" && extension.valueMarkdown) {
        howToCite = extension.valueMarkdown;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
      </div>
      <h3 id="subject">Subject</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={fhirJson.type} /></span>}
        {fhirJson.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={fhirJson.subjectCodeableConcept} /></span>}
        {fhirJson.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subjectReference} /></p>}
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.relatedArtifact) &&
          fhirJson.relatedArtifact.map((relatedArtifact, relatedArtifactIndex) => {
            return <div key={relatedArtifactIndex}><b>Item: </b><DisplayFromFHIR relatedArtifact={relatedArtifact} /></div>
          })}
        {Array.isArray(fhirJson.content) &&
          fhirJson.content.map((content, contentIndex) => {
            return <div key={contentIndex}><b>Attachment: </b><DisplayFromFHIR attachment={content} /></div>
          })}
      </div>
      <h3 id="parameters">Parameters</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.parameter) &&
          fhirJson.parameter.map((parameter, parameterIndex) => {
            return <div key={parameterIndex}><b>Parameter: </b><DisplayFromFHIR parameterDefinition={parameter} /></div>
          })}
        {Array.isArray(fhirJson.dataRequirement) &&
          fhirJson.dataRequirement.map((dataRequirement, dataRequirementIndex) => {
            return <div key={dataRequirementIndex}><b>Data Requirement: </b><DisplayFromFHIR dataRequirement={dataRequirement} /></div>
          })}
      </div>
      <ResourceBottomDisplay howToCite={howToCite} fhirJson={fhirJson}
        classificationsLoadedState={classificationsLoadedState} classificationsArray={classificationsArrayState}
      />
    </div>
  </div>
}

export default LibraryDisplay;