import { Button, Dropdown, Table } from 'semantic-ui-react';
import { TextField, Checkbox } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';
import addOption from './AddOptionFunction';
import DropDownWithAdditions from './DropDownWithAdditions';
import ProfilesByResourceType from './ProfilesByResourceType';
import {
  clearState, addResourceElementState, DisplayFromFHIR, codesToCodeableConcept,
  changeResourceElementState, identifierTextViewChangesToJson, generateIdentifierTable,
  generateIdentifierSystemCell, generateIdentifierValueCell, jsonErrorCheck, getCodeableConceptsDisplay,
  getContainedResources
} from './ResourceFunctions';

import {
  customStyleNames, generatedSummaryTexts, identifierSystemLookup, publicationFormIdentifierSystemLookup,
  titleTypeLookup, abstractTypeLookup, summaryTypeLookup, artifactClassifierTypeLookup, partTypeLookup,
  summarySourceLookup, contributorRoleLookup, contributionInstanceTypeLookup, publishedInTypeLookup,
  journalIssueMediumLookup, classificationTypeLookup, articleUrlTypeLookup, contributionTypeLookup,
  citedArtifactStatusTypeLookup, citationStatusTypeLookup, languageLookup, contributorIdentifierSystemLookup,
  identifierDisplayLookup, contributorIdentifierDisplayLookup,
  publicationFormIdentifierDisplayLookup, startingPublicationFormIdentifierTypeOptions,
  startingPublishedInTypeOptions, startingJournalIssueMediumOptions, citationStatusTypeDisplayLookup
} from './CodeSystemLookup';
import { getResource } from './UserAccountFunctions';
import { deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const formatIdentifiersForState = (identifier, related) => {
  let identifierEntry = { "related": related };
  let identifierType = identifier.type;
  let identifierAssigner = identifier.assigner;
  let identifierSystem = identifier.system;
  identifierEntry["typeText"] = "";
  if (identifierType?.text) {
    identifierEntry["typeText"] = identifierType.text;
    //addOption(setIdentifierTypeOptions, identifierType.text);
  }
  if (identifierAssigner?.display) {
    identifierEntry["assignerDisplay"] = identifierAssigner.display;
  } else if (identifierSystem && identifierSystemLookup[identifierSystem]?.assignerDisplay) {
    identifierEntry["assignerDisplay"] = identifierSystemLookup[identifierSystem].assignerDisplay;
  } else if (identifierType && identifierDisplayLookup[identifierType]?.assignerDisplay) {
    identifierEntry["assignerDisplay"] = identifierDisplayLookup[identifierType].assignerDisplay;
  }
  if (identifierSystem) {
    identifierEntry["system"] = identifierSystem;
    if (identifierSystemLookup[identifierSystem]) {
      //identifierEntry["typeText"] = identifierSystemLookup[identifierSystem].display;
      if (identifierSystemLookup[identifierSystem].typeText !== undefined) {
        identifierEntry["typeText"] = identifierSystemLookup[identifierSystem].typeText;
      }
      if (identifierSystemLookup[identifierSystem].assignerDisplay !== undefined) {
        identifierEntry["assignerDisplay"] = identifierSystemLookup[identifierSystem].assignerDisplay;
      }
    }
  }
  identifierEntry["value"] = identifier.value;
  if (identifierEntry["value"] === undefined) {
    identifierEntry["value"] = "";
  }
  if (identifierEntry["system"] === undefined) {
    identifierEntry["system"] = "";
  }
  if (identifierEntry["typeText"] === undefined) {
    identifierEntry["typeText"] = "";
  }
  if (identifierEntry["assignerDisplay"] === undefined) {
    identifierEntry["assignerDisplay"] = "";
  }
  return identifierEntry;
}

const getWebLocation = (webLocationSet, code, withoutCode) => {
  let value;
  if (webLocationSet) {
    webLocationSet.some(webLocation => {
      let webLocationClassifier;
      if (webLocation.classifier) {
        webLocationClassifier = webLocation.classifier;
      } else if (webLocation.type) {
        webLocationClassifier = webLocation.type;
      }
      if (webLocationClassifier?.coding) {
        let valueWithoutCode;
        for (let codingIndex in webLocationClassifier.coding) {
          if (code) {
            if (webLocationClassifier.coding[codingIndex].code === code) {
              value = webLocation.url;
              return true;
            }
          } else if (withoutCode && webLocationClassifier.coding[codingIndex].code !== withoutCode) {
            valueWithoutCode = true;
          }
        }
        if (valueWithoutCode) {
          value = webLocation.url;
          return true;
        }
      }
    })
  }
  return value;
}

const CitationPublicationFormsTextDisplay = ({ setCitationPublicationFormsState, changeCitationElementState, editMode, citationPublicationFormsState, languageOptions, setLanguageOptions }) => {
  let publicationForms = citationPublicationFormsState.publicationForms;
  return <div id="publication-form">
    {publicationForms === undefined || publicationForms.length === 0 ? <div><h3 id="publication-form">Publication Form</h3><span style={{ color: "#EE1111", paddingLeft: "40px" }}>No data.</span></div> :
      <>
        {publicationForms?.map((pfEntry, index) => {
          if (publicationForms.length > -1) {
            return <div key={index}>
              <h3>Publication Form
                {publicationForms.length === 1 ? <></> :
                  <span> ({index + 1} of {publicationForms.length})</span>}
              </h3>
              <div className={`CitationAbstractsSubSection`} style={{ paddingLeft: "40px" }}>
                <span><b style={{ fontSize: "1.1em" }}>Published In:</b></span>
                <div className={`CitationAbstractsSubSection`} style={{ paddingLeft: "40px" }}>
                  <span><b style={{ fontSize: "1.1em" }}>Name: </b>{editMode ? <><TextField style={{ marginTop: "10px", width: "640px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Name'} size="small" variant='outlined' value={pfEntry.publishedIn?.title ? pfEntry.publishedIn.title : ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, "publicationForms", index, "publishedIn", undefined, "title"); }} /><br /></> : pfEntry.publishedIn?.title}</span>
                  <br />
                  <span><b style={{ fontSize: "1.1em" }}>Type: </b>{editMode ?
                    <Dropdown
                      name="publicationFormType"
                      placeholder="Type"
                      closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                      style={{ width: "25%" }}
                      options={startingPublishedInTypeOptions}
                      value={pfEntry.type || ''}
                      onChange={(e, data) => {
                        changeCitationElementState(setCitationPublicationFormsState, data.value, undefined, undefined, "publicationForms", index, "type");
                      }}
                    />
                    :
                    pfEntry.type ? publishedInTypeLookup[pfEntry.type].display : pfEntry.type}</span>
                  <br />
                  <span><b style={{ fontSize: "1.1em" }}>Identifiers:</b></span>
                  <div className={`CitationAbstractsSubSection`} style={{ paddingLeft: "40px" }}>
                    {pfEntry.identifiers && pfEntry.identifiers.length >= 1 ?
                      <>
                        {generateIdentifierTable(pfEntry.identifiers, "publicationForms", index, "identifiers", editMode, publicationFormIdentifierSystemLookup, publicationFormIdentifierDisplayLookup, { "options": startingPublicationFormIdentifierTypeOptions }, changeCitationElementState, setCitationPublicationFormsState)}
                        <span style={{ height: "8px" }} />
                      </>
                      :
                      <span style={{ color: "#EE1111" }}>No data. <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Identifier" onClick={() => { addResourceElementState(setCitationPublicationFormsState, "publicationForms", index, "identifiers", {}); }} />}</span></span>
                    }
                  </div>
                  <span><b style={{ fontSize: "1.1em" }}>Publisher: </b>{editMode ? <><TextField style={{ marginTop: "10px", width: "640px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.publishedIn?.publisher?.display || ''}
                    onChange={(e) => {
                      setCitationPublicationFormsState(prevState => {
                        let prevField = prevState["publicationForms"];
                        if (prevField[index]["publishedIn"] === undefined) { prevField[index]["publishedIn"] = {}; }
                        if (prevField[index]["publishedIn"]["publisher"] === undefined) { prevField[index]["publishedIn"]["publisher"] = {}; }
                        prevField[index]["publishedIn"]["publisher"]["display"] = e.target.value;
                        return {
                          ...prevState,
                          [publicationForms]: prevField
                        };
                      });
                    }} /><br /></> : pfEntry.publishedIn?.publisher?.display}<br /></span>

                  <span>
                    <b style={{ fontSize: "1.1em" }}>Publisher Location: </b>
                    {editMode ?
                      <>
                        <TextField style={{ width: "400px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.publishedIn && pfEntry.publishedIn.publisherLocation ? pfEntry.publishedIn.publisherLocation : ''}
                          onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, "publicationForms", index, "publishedIn", undefined, "publisherLocation"); }}
                        />
                        <br /></>
                      :
                      pfEntry.publishedIn.publisherLocation
                    }
                  </span>
                </div>
                <span><b style={{ fontSize: "1.1em" }}>Cited Medium: </b>{editMode ?
                  <><Dropdown
                    name="publicationFormType"
                    placeholder="Cited Medium"
                    closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                    style={{ width: "25%" }}
                    options={startingJournalIssueMediumOptions}
                    value={pfEntry.citedMediumDisplay || ''}
                    onChange={(e, data) => {
                      changeCitationElementState(setCitationPublicationFormsState, data.value, undefined, undefined, "publicationForms", index, "citedMediumDisplay");
                    }}
                  />
                    <br />
                  </>
                  :
                  pfEntry.citedMediumDisplay}
                  {/* pfEntry.citedMediumDisplay ? journalIssueMediumLookup[pfEntry.citedMediumDisplay].display : pfEntry.citedMediumDisplay} */}
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Volume: </b>{editMode ? <><TextField style={{ width: "400px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.volume || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, "publicationForms", index, "volume", undefined, undefined); }} /><br /></> : pfEntry.volume}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Issue: </b>{editMode ? <><TextField style={{ width: "400px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.issue || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, "publicationForms", index, "issue", undefined, undefined); }} /><br /></> : pfEntry.issue}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Date of Publication:</b></span>
                <div className={`CitationAbstractsSubSection`} style={{ paddingLeft: "40px" }}>

                  <span>
                    {!editMode && <>
                      {pfEntry.articleDate && <> <b>Article publication date:</b> {pfEntry.articleDate}</>}
                      {pfEntry.dateOfPublicationYear && <> <b>Year:</b> {pfEntry.dateOfPublicationYear}</>}
                      {pfEntry.dateOfPublicationMonth && <> <b>Month:</b> {pfEntry.dateOfPublicationMonth}</>}
                      {pfEntry.dateOfPublicationDay && <> <b>Day:</b> {pfEntry.dateOfPublicationDay}</>}
                      {pfEntry.dateOfPublicationSeason && <> <b>Season:</b> {pfEntry.dateOfPublicationSeason}</>}
                      {pfEntry.dateOfPublicationText && <> <b>Text:</b> {pfEntry.dateOfPublicationText}</>}
                    </>
                    }
                    {editMode && <><TextField style={{ width: "140px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'YYYY-MM-DD'} size="small" variant='outlined' value={pfEntry.articleDate || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "articleDate"); }} /></>}
                    &nbsp;&nbsp;&nbsp;
                    {editMode && <><TextField style={{ width: "90px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Year'} size="small" variant='outlined' value={pfEntry.dateOfPublicationYear || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "dateOfPublicationYear"); }} /></>}
                    &nbsp;&nbsp;&nbsp;
                    {editMode && <><TextField style={{ width: "80px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Month'} size="small" variant='outlined' value={pfEntry.dateOfPublicationMonth || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "dateOfPublicationMonth"); }} /></>}
                    &nbsp;&nbsp;&nbsp;
                    {editMode && <><TextField style={{ width: "60px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Day'} size="small" variant='outlined' value={pfEntry.dateOfPublicationDay || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "dateOfPublicationDay"); }} /></>}
                    &nbsp;&nbsp;&nbsp;
                    {editMode && <><TextField style={{ width: "180px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Season'} size="small" variant='outlined' value={pfEntry.dateOfPublicationSeason || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "dateOfPublicationSeason"); }} /></>}
                    &nbsp;&nbsp;&nbsp;
                    {editMode && <><TextField style={{ width: "240px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'Text'} size="small" variant='outlined' value={pfEntry.dateOfPublicationText || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "dateOfPublicationText"); }} /><br /></>}
                  </span>
                  <br />
                </div>
                <span><b style={{ fontSize: "1.1em" }}>Article last revision date: </b>{editMode ? <><TextField style={{ width: "260px", maxWidth: "80%" }} multiline className="inputField" type='text' label={'YYYY-MM-DD'} size="small" variant='outlined' value={pfEntry.lastRevisionDate || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "lastRevisionDate"); }} /><br /></> : pfEntry.lastRevisionDate}</span>
                <br />
                <span>
                  <b style={{ fontSize: "1.1em" }}>Language: </b>
                  {editMode ?
                    <DropDownWithAdditions currentValue={pfEntry.pfLanguage} labelText={'Pick Language'}
                      options={languageOptions.options} optionsLookup={languageLookup} setter={setLanguageOptions}
                      stateSetter={setCitationPublicationFormsState} changeElementState={changeCitationElementState}
                      index={index} elementKeyEntry={"publicationForms"} elementKeySubEntry={"pfLanguage"} multiple={true} />
                    :
                    <>{pfEntry.pfLanguage?.map((pfLanguage) => { if (languageLookup[pfLanguage]) { return " " + languageLookup[pfLanguage].display } else { return " " + pfLanguage } }).toString()}</>}
                </span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Accession number: </b>{editMode ? <><TextField style={{ width: "260px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.accessionNumber || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "accessionNumber"); }} /><br /></> : pfEntry.accessionNumber}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Pages: </b>{editMode ? <><TextField style={{ width: "260px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.pageString || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "pageString"); }} /><br /></> : pfEntry.pageString}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Page count: </b>{editMode ? <><TextField style={{ width: "260px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.pageCount || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "pageCount"); }} /><br /></> : pfEntry.pageCount}</span>
                <br />
                <span><b style={{ fontSize: "1.1em" }}>Copyright: </b>{editMode ? <><TextField style={{ width: "260px", maxWidth: "80%" }} multiline className="inputField" type='text' size="small" variant='outlined' value={pfEntry.copyright || ''} onChange={(e) => { changeCitationElementState(setCitationPublicationFormsState, e.target.value, undefined, undefined, "publicationForms", index, "copyright"); }} /><br /></> : <DisplayFromFHIR markdown={pfEntry.copyright} />}</span>
              </div>
              <br />
            </div>
          }
        })
        }
      </>
    }
    <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Publication Form"} onClick={() => { addResourceElementState(setCitationPublicationFormsState, undefined, undefined, "publicationForms", { "publishedIn": {}, "periodicRelease": {}, "identifiers": [] }); }} />}</span>
  </div>
}

const ContributorshipIndividualsRows = ({ citationState, contributorshipEntry, contributorshipEntryKey, setCitationIndividualContributorsState, contributorIdentifierTypeOptions, contributorRoleOptions, setContributorRoleOptions, contributionTypeOptions, setContributionTypeOptions, contributionInstanceTypeOptions, setContributionInstanceTypeOptions, affiliationsOptions, setAffiliationsOptions, editMode }) => {
  let nameGiven = contributorshipEntry.nameGiven;
  let nameFamily = contributorshipEntry.nameFamily;
  let initials = contributorshipEntry.initials;
  let nameText = contributorshipEntry.nameText;

  let namePrefix = contributorshipEntry.namePrefix;
  let nameSuffix = contributorshipEntry.nameSuffix;
  if (namePrefix === undefined) {
    namePrefix = [""];
  }
  if (nameGiven === undefined) {
    nameGiven = [""];
  }
  if (nameSuffix === undefined) {
    nameSuffix = [""];
  }

  let nameGivenString = "";
  if (nameGiven) {
    if (typeof nameGiven === "string") {
      nameGiven = [nameGiven];
    }
    nameGivenString = nameGiven.join(", ");
  }

  let displayName = "";
  if (nameFamily) {
    if (initials) {
      displayName = nameFamily + " " + initials;
    } else if (nameGivenString) {
      displayName = nameFamily + ", " + nameGiven.join(" ");
    } else {
      displayName = nameFamily;
    }
  } else if (nameText) {
    displayName = nameText;
  } else if (nameGivenString) {
    displayName = nameGiven.join(" ");
  }

  let editing = contributorshipEntry.editing;
  let editable = editing && editMode;

  return <Table.Body key={contributorshipEntryKey}>
    <Table.Row>
      <Table.Cell style={{ backgroundColor: "#F6F6FF", minWidth: "140px", verticalAlign: "top" }}>
        {contributorshipEntry.notAPerson ?
          <>
            {editable ?
              <TextField multiline className="inputField" type='text' label={'Collective Name'} size="small" variant='outlined' value={contributorshipEntry.collectiveName} onChange={(e) => {
                setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "collectiveName": e.target.value } } })
              }} />
              :
              <>{contributorshipEntry.collectiveName}</>
            }
          </>
          :
          <>
            {displayName}
          </>
        }
        {editMode && !editing &&
          <>
            <br />
            <Button className="formButton" style={{ color: "#000000" }} content="Edit Contributor" onClick={() => { setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "editing": true } } }) }} />
          </>
        }
      </Table.Cell>
      <Table.Cell style={{ backgroundColor: "#F6F6FF", paddingBottom: "0px", verticalAlign: "top" }} colSpan="3">
        {editable ?
          <>
            {contributorshipEntry.notAPerson ?
              <>
                {/*
              <TextField style={{width: "480px"}} multiline className="inputField" type='text' label={'Collective Name'} size="small" variant='outlined' value={nameText} onChange={(e) => { changeCitationElementState(setCitationIndividualContributorsState, e.target.value, undefined, undefined, "contributorshipIndividuals", contributorshipEntryKey, "collectiveName"); } }/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              */}
              </>
              :
              <span style={{ marginBottom: "0px" }}>
                <TextField style={{ width: "70px" }} multiline className="inputField" type='text' label={'Prefix'} size="small" variant='outlined' value={namePrefix[0] || ''} onChange={(e) => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].namePrefix; newArray[0] = e.target.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "namePrefix": newArray } } }) }} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "160px" }} multiline className="inputField" type='text' label={'Given Name'} size="small" variant='outlined' value={nameGiven[0] || ''} onChange={(e) => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].nameGiven; newArray[0] = e.target.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "nameGiven": newArray } } }) }} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "160px" }} multiline className="inputField" type='text' label={'Family Name'} size="small" variant='outlined' value={nameFamily} onChange={(e) => { setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "nameFamily": e.target.value } } }) }} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "140px" }} multiline className="inputField" type='text' label={'Initials (forename)'} size="small" variant='outlined' value={initials} onChange={(e) => { setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "initials": e.target.value } } }) }} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "70px" }} multiline className="inputField" type='text' label={'Suffix'} size="small" variant='outlined' value={nameSuffix[0] || ''} onChange={(e) => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].nameSuffix; newArray[0] = e.target.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "nameSuffix": newArray } } }) }} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "190px" }} multiline className="inputField" type='text' label={'Text'} size="small" variant='outlined' value={nameText} onChange={(e) => { setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "nameText": e.target.value } } }) }} />
              </span>
            }
            <p style={{ margin: "0px" }}>
              <Checkbox
                checked={contributorshipEntry.notAPerson}
                onChange={(e) => {
                  setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], notAPerson: e.target.checked } } });
                }}
                color="primary"
                style={{ paddingLeft: "0px" }}
              />Not a person?
            </p>
          </>
          :
          <>
            {!contributorshipEntry.notAPerson &&
              <>
                {namePrefix.length > 0 && <><b>Prefix: </b>{namePrefix.toString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {nameGiven.length > 0 && <><b>Forename: </b>{nameGivenString}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {nameFamily && <><b>Last name: </b>{nameFamily}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {initials && <><b>Initials (for forename): </b>{initials}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {nameSuffix.length > 0 && <><b>Suffix: </b>{nameSuffix.toString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {nameText && <><b>Text: </b>{nameText}</>}
              </>
            }
          </>
        }
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell>{contributorshipEntry.entryIdentifiers.length <= 1 ? <b>Identifier</b> : <b>Identifiers</b>}</Table.Cell>
      <Table.Cell>
        <table style={{ width: "100%" }}>
          <tbody>
            {contributorshipEntry.entryIdentifiers.map((entryIdentifier, identifierIndex) => {
              return <tr key={identifierIndex} className={"subTableRow"} style={{ width: "100%" }}>
                {generateIdentifierSystemCell(entryIdentifier, identifierIndex, "contributorshipIndividuals", contributorshipEntryKey, "entryIdentifiers", editable, contributorIdentifierSystemLookup, contributorIdentifierDisplayLookup, contributorIdentifierTypeOptions, undefined, setCitationIndividualContributorsState)}
                {generateIdentifierValueCell(entryIdentifier, identifierIndex, "contributorshipIndividuals", contributorshipEntryKey, "entryIdentifiers", editable, undefined, setCitationIndividualContributorsState)}
              </tr>
            })}
          </tbody>
        </table>
        {editable && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Identifier" onClick={() => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].entryIdentifiers; newArray.push({}); return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "entryIdentifiers": newArray } } }); }} />}
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell><b>Affiliation</b></Table.Cell>
      <Table.Cell colSpan="2">
        {/*
        {contributorshipEntry.affiliationInfos.map((affiliationInfoEntry, affiliationInfoIndex) => {
            return <span key={affiliationInfoIndex}>
              {editable ?
                <>
                  <TextField style={{ width: "600px" }} multiline className="inputField" type='text' label={'Affiliation'} size="small" variant='outlined' value={affiliationInfoEntry.affiliation}
                    onChange={(e) => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey]; newArray["affiliationInfos"][affiliationInfoIndex]["affiliation"] = e.target.value; return { ...prevState, [contributorshipEntryKey]: newArray } }) }} />
                  <br />
                </>
                :
                <>{affiliationInfoEntry.affiliation}</>
              }
              <br /></span>
        })}*/}
        {/*editable && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Affiliation" onClick={() => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey]; newArray["affiliationInfos"].push({ "affiliation": "" }); return { ...prevState, [contributorshipEntryKey]: newArray } }) }} />*/}
        {editable ?
          <DropDownWithAdditions currentValue={contributorshipEntry.affiliationSelectedValues} labelText={'Pick Affiliation'} options={affiliationsOptions.options} setter={setAffiliationsOptions} stateSetter={setCitationIndividualContributorsState} changeElementState={changeResourceElementState} elementKeyEntry={contributorshipEntryKey} elementKeySubEntry={"affiliationSelectedValues"} multiple={true} />
          :
          <>
            {contributorshipEntry.affiliationSelectedValues?.map((affiliationValue, affiliationValueIndex) => {
              return <span key={affiliationValueIndex}>{affiliationValue && citationState.affiliationDictionary[affiliationValue]?.display}<br /></span>;
            })}
          </>
        }
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell><b>Contact details</b></Table.Cell>
      <Table.Cell colSpan="2">
        {contributorshipEntry.entryTelecomsAndAddresses.map((telecomAddressEntry, telecomAddressIndex) => {
          return <span key={telecomAddressIndex}>
            {editable ?
              <>
                <Dropdown
                  name="contactSystem"
                  placeholder="Contact System"
                  closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                  style={{ width: "140px" }}
                  options={[{ key: 'address', text: 'address', value: 'address' }, { key: 'email', text: 'email', value: 'email' }, { key: 'fax', text: 'fax', value: 'fax' }, { key: 'pager', text: 'pager', value: 'pager' }, { key: 'phone', text: 'phone', value: 'phone' }, { key: 'sms', text: 'sms', value: 'sms' }, { key: 'url', text: 'url', value: 'url' }, { key: 'other', text: 'other', value: 'other' }]}
                  value={telecomAddressEntry.system || ''}
                  onChange={(e, data) => {
                    setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey]["entryTelecomsAndAddresses"]; newArray[telecomAddressIndex]["system"] = data.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "entryTelecomsAndAddresses": newArray } } });
                  }} />
                {/*<TextField style={{width: "100px"}} multiline className="inputField" type='text' label={'System'} size="small" variant='outlined' value={telecomAddressEntry.system} onChange={(e) => { changeCitationElementState(setCitationIndividualContributorsState, e.target.value, "contributorshipIndividuals", contributorshipEntryKey, "entryTelecomsAndAddresses", telecomAddressIndex, "system"); } }/>*/}
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "400px" }} multiline className="inputField" type='text' label={'Value'} size="small" variant='outlined' value={telecomAddressEntry.value} onChange={(e) => {
                  setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey]["entryTelecomsAndAddresses"]; newArray[telecomAddressIndex]["value"] = e.target.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "entryTelecomsAndAddresses": newArray } } });
                }} />
                <br /><br />
              </>
              :
              <>{telecomAddressEntry.value && <><b>{telecomAddressEntry.system && <>{telecomAddressEntry.system}: </>}</b> {telecomAddressEntry.value}<br /></>}</>
            }
          </span>
        })}
        {editable && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Contact Detail" onClick={() => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].entryTelecomsAndAddresses; newArray.push({}); return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "entryTelecomsAndAddresses": newArray } } }); }} />}
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell><b>Role</b></Table.Cell>
      <Table.Cell colSpan="2">
        {editable ?
          <>
            <DropDownWithAdditions currentValue={contributorshipEntry.entryRole} labelText={'Pick Role'} options={contributorRoleOptions.options} optionsLookup={contributorRoleLookup} setter={setContributorRoleOptions} stateSetter={setCitationIndividualContributorsState} changeElementState={undefined} index={contributorshipEntryKey} elementKeyEntry={"contributorshipIndividuals"} elementKeySubEntry={"entryRole"} multiple={false} />
            <Checkbox
              checked={contributorshipEntry.correspondingContact}
              onChange={(e) => {
                setCitationIndividualContributorsState(prevState => { return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "correspondingContact": e.target.checked } } });
              }}
              color="primary"
            />Corresponding?
            {/*<TextField style={{width: "200px"}} multiline className="inputField" type='text' label={'Role'} size="small" variant='outlined' value={contributorshipEntry.entryRole} onChange={(e) => { changeCitationElementState(setCitationIndividualContributorsState, e.target.value, "contributorshipIndividuals", contributorshipEntryKey, "entryAddresses", addressIndex, "text"); } }/>*/}
          </>
          :
          <>{contributorshipEntry.correspondingContact && <>Corresponding </>}{contributorshipEntry.entryRole && contributorRoleLookup[contributorshipEntry.entryRole] ? contributorRoleLookup[contributorshipEntry.entryRole].display : contributorshipEntry.entryRole}</>
        }
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell><b>Contribution types</b></Table.Cell>
      <Table.Cell colSpan="2">{editable ?
        <DropDownWithAdditions currentValue={contributorshipEntry.contributionTypes} labelText={'Pick Contribution Type'} options={contributionTypeOptions.options} optionsLookup={contributionTypeLookup} setter={setContributionTypeOptions} stateSetter={setCitationIndividualContributorsState} changeElementState={undefined} index={contributorshipEntryKey} elementKeyEntry={"contributorshipIndividuals"} elementKeySubEntry={"contributionTypes"} multiple={true} />
        :
        <>{contributorshipEntry.contributionTypes.toString()}</>}
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell><b>Contribution instances</b></Table.Cell>
      <Table.Cell colSpan="2">{contributorshipEntry.contributionInstances.map((contributionInstance, contributionInstanceIndex) => {
        return <span key={contributionInstanceIndex}>
          {editable ?
            <>
              <DropDownWithAdditions currentValue={contributionInstance.type} labelText={'Pick Contribution Instance Type'} options={contributionInstanceTypeOptions.options} optionsLookup={contributionInstanceTypeLookup} setter={setContributionInstanceTypeOptions} stateSetter={setCitationIndividualContributorsState} changeElementState={undefined} parentElement={"contributorshipIndividuals"} parentElementIndex={contributorshipEntryKey} elementKeyEntry={"contributionInstances"} index={contributionInstanceIndex} elementKeySubEntry={"type"} width={"40%"} multiple={false} />
              &nbsp;&nbsp;&nbsp;
              <TextField style={{ width: "40%" }} multiline className="inputField" type='text' label={'Time'} size="small" variant='outlined' value={contributionInstance.time} onChange={(e) => {
                setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey]["contributionInstances"]; newArray[contributionInstanceIndex]["time"] = e.target.value; return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "contributionInstances": newArray } } });
              }} />
              <br /><br />
            </>
            :
            <>{contributionInstance.type} {contributionInstance.time}<br /></>
          }
        </span>
      })}
        {editable && <><Button className="formButton" style={{ color: "#000000" }} content="+ Add Contribution Instance" onClick={() => { setCitationIndividualContributorsState(prevState => { let newArray = prevState[contributorshipEntryKey].contributionInstances; newArray.push({}); return { ...prevState, [contributorshipEntryKey]: { ...prevState[contributorshipEntryKey], "contributionInstances": newArray } } }); }} /></>}
      </Table.Cell>
    </Table.Row>
  </Table.Body>;
}

const CitationContributorsTextDisplay = ({ citationState, citationContributorsState, setCitationContributorsState, citationIndividualContributorsState, setCitationIndividualContributorsState, changeCitationElementState, contributorIdentifierTypeOptions, summaryTypeOptions, summarySourceOptions, setSummaryTypeOptions, setSummarySourceOptions, contributorRoleOptions, setContributorRoleOptions, contributionTypeOptions, setContributionTypeOptions, contributionInstanceTypeOptions, setContributionInstanceTypeOptions, affiliationsOptions, setAffiliationsOptions, editMode }) => {
  let contributorshipSummaries = citationContributorsState.contributorshipSummaries;
  let contributorshipIndividuals = citationIndividualContributorsState;
  let numberOfIndividuals = Object.keys(contributorshipIndividuals).length;
  return <>
    <h3 id="contributors">{numberOfIndividuals != 1 ? <>Contributors</> : <>Contributor</>}</h3>
    <div className={`CitationContributorshipSubSection`} style={{ paddingLeft: "40px" }}>
      <span><b style={{ fontSize: "1.1em" }}>{contributorshipSummaries?.length != 1 ? <>Summary statements</> : <>Summary statement</>}:</b></span>
      <div className={`CitationContributorshipSubSection`} style={{ paddingLeft: "40px" }}>
        {contributorshipSummaries?.length >= 1 ?
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Summary</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contributorshipSummaries.map((summaryEntry, index) => {
                let summaryTypeLength = 0;
                if (summaryEntry.summaryType) {
                  summaryTypeLength = summaryEntry.summaryType.length
                }
                let summarySourceLength = 0;
                if (summaryEntry.summarySource) {
                  summarySourceLength = summaryEntry.summarySource.length
                }
                return <Table.Row key={index}>
                  <Table.Cell style={{ verticalAlign: "top" }}>{editMode ?
                    <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Summary Value'} size="small" variant='outlined' value={summaryEntry.summaryValue} onChange={(e) => { changeCitationElementState(setCitationContributorsState, e.target.value, undefined, undefined, "contributorshipSummaries", index, "summaryValue"); }} />
                    :
                    <DisplayFromFHIR markdown={summaryEntry.summaryValue} />
                  }
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top", maxWidth: "260px" }}>{editMode
                    ?
                    <>
                      <DropDownWithAdditions currentValue={summaryEntry.summaryType} labelText={'Pick Summary Type'} options={summaryTypeOptions.options} optionsLookup={summaryTypeLookup} setter={setSummaryTypeOptions} stateSetter={setCitationContributorsState} changeElementState={changeCitationElementState} index={index} elementKeyEntry={"contributorshipSummaries"} elementKeySubEntry={"summaryType"} multiple={true} />
                      {summaryTypeLength === 1 && summaryTypeLookup[summaryEntry.summaryType[0]] && <><br /><b>Tool tip:</b> used for {summaryTypeLookup[summaryEntry.summaryType[0]].definition} </>}
                      {summaryTypeLength > 1 && <><br /><b>Tool tip:</b> you have two or more summary types, you can remove any summary type that you no longer wish to keep.</>}
                    </>
                    :
                    <>{summaryEntry.summaryType?.map((summaryType) => { if (summaryTypeLookup[summaryType]) { return " " + summaryTypeLookup[summaryType].display } else { return " " + summaryType } }).toString()}</>}
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top" }}>{editMode
                    ?
                    <>
                      <DropDownWithAdditions currentValue={summaryEntry.summarySource} labelText={'Pick Summary Source'} options={summarySourceOptions.options} optionsLookup={summarySourceLookup} setter={setSummarySourceOptions} stateSetter={setCitationContributorsState} changeElementState={changeCitationElementState} index={index} elementKeyEntry={"contributorshipSummaries"} elementKeySubEntry={"summarySource"} multiple={true} />
                      {summarySourceLength === 1 && summarySourceLookup[summaryEntry.summarySource[0]] && <><br /><b>Tool tip:</b> used for {summarySourceLookup[summaryEntry.summarySource[0]].definition} </>}
                      {summarySourceLength > 1 && <><br /><b>Tool tip:</b> you have two or more summary sources, you can remove any summary source that you no longer wish to keep.</>}
                    </>
                    :
                    <>{summaryEntry.summarySource?.map((summarySource) => { if (summarySourceLookup[summarySource]) { return " " + summarySourceLookup[summarySource].display } else { return " " + summarySource } }).toString()}</>}
                  </Table.Cell>
                </Table.Row>;
              })
              }
            </Table.Body>
          </Table>
          :
          <span style={{ color: "#EE1111" }}>No data.</span>
        }
        <span>{editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Contributorship Summary" onClick={() => { addResourceElementState(setCitationContributorsState, undefined, undefined, "contributorshipSummaries", {}); }} />}</span>
      </div>
      {editMode && <br />}
      <span><b style={{ fontSize: "1.1em" }}>{numberOfIndividuals == 1 ? <>Individual contributor</> : <>Individual contributors</>}:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Contributor list complete: </b>
        {editMode ?
          <fieldset style={{ width: "300px", display: "inline", border: "0px", verticalAlign: "bottom", padding: "0px" }}>
            &nbsp;&nbsp;&nbsp;
            <input type="radio" name="contributorListComplete" value="true" checked={citationContributorsState.contributorshipComplete} onChange={(e) => { changeCitationElementState(setCitationContributorsState, e.target.checked, undefined, undefined, "contributorshipComplete", undefined, undefined); }} />
            <label> Yes</label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="radio" name="contributorListComplete" value="false" checked={citationContributorsState.contributorshipCompleteProvided && !citationContributorsState.contributorshipComplete} onChange={(e) => { changeCitationElementState(setCitationContributorsState, !e.target.checked, undefined, undefined, "contributorshipComplete", undefined, undefined); changeCitationElementState(setCitationContributorsState, true, undefined, undefined, "contributorshipCompleteProvided", undefined, undefined); }} />
            <label> No</label>
          </fieldset>
          :
          <>{citationContributorsState.contributorshipComplete ? <>yes</> : <>no</>}</>
        }

      </span>
      <div className={`CitationContributorshipSubSection`} style={{ paddingLeft: "40px" }}>
        {numberOfIndividuals >= 1 ?
          <Table style={{ borderLeft: "solid 2px #777777" }}>
            {numberOfIndividuals > 0 ?

              Object.keys(contributorshipIndividuals).map((contributorshipEntryKey, contributorshipEntryIndex) => {
                let contributorshipEntry = contributorshipIndividuals[contributorshipEntryKey];
                return <ContributorshipIndividualsRows key={contributorshipEntryIndex} citationState={citationState} contributorshipEntry={contributorshipEntry} contributorshipEntryKey={contributorshipEntryKey} setCitationIndividualContributorsState={setCitationIndividualContributorsState} contributorIdentifierTypeOptions={contributorIdentifierTypeOptions} contributorRoleOptions={contributorRoleOptions} setContributorRoleOptions={setContributorRoleOptions} contributionTypeOptions={contributionTypeOptions} setContributionTypeOptions={setContributionTypeOptions} contributionInstanceTypeOptions={contributionInstanceTypeOptions} setContributionInstanceTypeOptions={setContributionInstanceTypeOptions} affiliationsOptions={affiliationsOptions} setAffiliationsOptions={setAffiliationsOptions} editMode={editMode} />
              })
              :
              <></>
            }
          </Table>
          :
          <span style={{ color: "#EE1111" }}>No data.</span>
        }
        {editMode && <Button className="formButton" style={{ color: "#000000" }} content="+ Add Contributor" onClick={() => { setCitationIndividualContributorsState(prevState => { return { ...prevState, [Object.keys(prevState).length]: { "nameGiven": [""], "namePrefix": [""], "nameSuffix": [""], "entryIdentifiers": [], "affiliationInfos": [], "entryTelecomsAndAddresses": [], "contributionTypes": [], "contributionInstances": [], "notAPerson": false } } }); }} />}
      </div>
    </div>
  </>
}


const citationTextViewChangesToJson = (fhirJson, citationState, citationPublicationFormsState, citationContributorsState, citationIndividualContributorsState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.meta = citationState.meta;

  if (fhirJson.citedArtifact === undefined) {
    fhirJson.citedArtifact = {};
  }

  if (fhirJson.identifier === undefined || fhirJson.identifier.length === 0) {
    fhirJson.identifier = [{}];
  }
  fhirJson.identifier = identifierTextViewChangesToJson(citationState.citationResourceIdentifiers, fhirJson.identifier, identifierSystemLookup, identifierDisplayLookup);

  if (citationState.status) {
    fhirJson.status = citationState.status;
  }
  if (citationState.identifiers.length === 0) {
    if (fhirJson.citedArtifact?.identifier) {
      delete fhirJson.citedArtifact.identifier;
    }
  } else {
    if (fhirJson.citedArtifact.identifier === undefined || fhirJson.citedArtifact.identifier.length === 0) {
      fhirJson.citedArtifact.identifier = [{}];
    }
    fhirJson.citedArtifact.identifier = identifierTextViewChangesToJson(citationState.identifiers, fhirJson.citedArtifact.identifier, identifierSystemLookup, identifierDisplayLookup);
  }

  if (citationState.relatedIdentifiers.length === 0) {
    if (fhirJson.citedArtifact?.relatedIdentifier) {
      delete fhirJson.citedArtifact.relatedIdentifier;
    }
  } else {
    if (fhirJson.citedArtifact.relatedIdentifier === undefined || fhirJson.citedArtifact.relatedIdentifier.length === 0) {
      fhirJson.citedArtifact.relatedIdentifier = [{}];
    }
    fhirJson.citedArtifact.relatedIdentifier = identifierTextViewChangesToJson(citationState.relatedIdentifiers, fhirJson.citedArtifact.relatedIdentifier, identifierSystemLookup, identifierDisplayLookup);
  }

  if (fhirJson.citedArtifact?.baseCitation?.identifier || fhirJson.citedArtifact?.version?.baseCitation?.identifier || (citationState.baseCitationIdentifier?.system || citationState.baseCitationIdentifier?.typeText || citationState.baseCitationIdentifier?.assignerDisplay || citationState.baseCitationIdentifier?.value) || citationState.baseCitationDisplay || citationState.baseCitationReference) {
    if (fhirJson.citedArtifact.version === undefined) {
      fhirJson.citedArtifact.version = "";
    }
    if (!fhirJson.citedArtifact.baseCitation && fhirJson.citedArtifact?.version?.baseCitation) {
      fhirJson.citedArtifact.baseCitation = fhirJson.citedArtifact.version.baseCitation;
    }
    if (fhirJson.citedArtifact.baseCitation === undefined) {
      fhirJson.citedArtifact.baseCitation = {};
    }
    fhirJson.citedArtifact.baseCitation.reference = citationState.baseCitationReference;
    fhirJson.citedArtifact.baseCitation.type = citationState.baseCitationType;
    fhirJson.citedArtifact.baseCitation.display = citationState.baseCitationDisplay;
    if (fhirJson.citedArtifact.baseCitation.identifier === undefined) {
      fhirJson.citedArtifact.baseCitation.identifier = {};
    }
    fhirJson.citedArtifact.baseCitation.identifier = identifierTextViewChangesToJson(citationState.baseCitationIdentifier, fhirJson.citedArtifact.baseCitation.identifier, identifierSystemLookup, identifierDisplayLookup);
  }

  if (citationState.partValue || fhirJson.citedArtifact.part?.baseCitation?.identifier || citationState.partTypes?.length > 0) {
    if (fhirJson.citedArtifact.part === undefined) {
      fhirJson.citedArtifact.part = {};
    }
    fhirJson.citedArtifact.part.value = citationState.partValue;
    if (fhirJson.citedArtifact.part.baseCitation) {
      fhirJson.citedArtifact.baseCitation = JSON.parse(JSON.stringify(fhirJson.citedArtifact.part.baseCitation));
      delete fhirJson.citedArtifact.part.baseCitation;
    }
    /*
    if (fhirJson.citedArtifact.part.type === undefined) {
        fhirJson.citedArtifact.part.type = {};
    }*/
    if (citationState.partTypes?.length > 0) {
      let typeJson = codesToCodeableConcept({}, citationState.partTypes, partTypeLookup, "http://hl7.org/fhir/cited-artifact-part-type");
      fhirJson.citedArtifact.part.type = typeJson;
    }
  } else if (!citationState.partValue) {
    delete fhirJson.citedArtifact.part;
  }

  if (fhirJson.citedArtifact.publicationForm === undefined) {
    fhirJson.citedArtifact.publicationForm = [];
  }
  let publicationForms = citationPublicationFormsState.publicationForms;
  for (let x in publicationForms) {
    if (fhirJson.citedArtifact.publicationForm[x] === undefined) {
      fhirJson.citedArtifact.publicationForm.push({});
    }
    if (fhirJson.citedArtifact.publicationForm[x].publishedIn === undefined) {
      fhirJson.citedArtifact.publicationForm[x].publishedIn = {};
    }
    if (fhirJson.citedArtifact.publicationForm[x].publishedIn.identifier === undefined || fhirJson.citedArtifact.publicationForm[x].publishedIn.length === 0) {
      fhirJson.citedArtifact.publicationForm[x].publishedIn.identifier = [{}];
    }
    fhirJson.citedArtifact.publicationForm[x].publishedIn.identifier = identifierTextViewChangesToJson(publicationForms[x].identifiers, fhirJson.citedArtifact.publicationForm[x].publishedIn.identifier, publicationFormIdentifierSystemLookup, publicationFormIdentifierDisplayLookup);

    fhirJson.citedArtifact.publicationForm[x].articleDate = publicationForms[x].articleDate || publicationForms[x].dateOfPublicationDate;
    fhirJson.citedArtifact.publicationForm[x].publicationDateYear = publicationForms[x].dateOfPublicationYear;
    fhirJson.citedArtifact.publicationForm[x].publicationDateMonth = publicationForms[x].dateOfPublicationMonth;
    fhirJson.citedArtifact.publicationForm[x].publicationDateDay = publicationForms[x].dateOfPublicationDay;
    fhirJson.citedArtifact.publicationForm[x].publicationDateSeason = publicationForms[x].dateOfPublicationSeason;
    fhirJson.citedArtifact.publicationForm[x].publicationDateText = publicationForms[x].dateOfPublicationText;
    fhirJson.citedArtifact.publicationForm[x].volume = publicationForms[x].volume;
    fhirJson.citedArtifact.publicationForm[x].issue = publicationForms[x].issue;

    fhirJson.citedArtifact.publicationForm[x].lastRevisionDate = publicationForms[x].lastRevisionDate;
    fhirJson.citedArtifact.publicationForm[x].accessionNumber = publicationForms[x].accessionNumber;
    fhirJson.citedArtifact.publicationForm[x].pageString = publicationForms[x].pageString;
    fhirJson.citedArtifact.publicationForm[x].pageCount = publicationForms[x].pageCount;
    fhirJson.citedArtifact.publicationForm[x].copyright = publicationForms[x].copyright;
    fhirJson.citedArtifact.publicationForm[x].publishedIn.title = publicationForms[x].publishedIn.title;
    if (fhirJson.citedArtifact.publicationForm[x].publishedIn.publisher == undefined) {
      fhirJson.citedArtifact.publicationForm[x].publishedIn.publisher = {};
    }
    if (publicationForms[x].publishedIn.publisher) {
      fhirJson.citedArtifact.publicationForm[x].publishedIn.publisher.display = publicationForms[x].publishedIn.publisher.display;
    }
    if (publicationForms[x].publishedIn.publisherLocation) {
      fhirJson.citedArtifact.publicationForm[x].publishedIn.publisherLocation = publicationForms[x].publishedIn.publisherLocation;
    }
    let pfLanguage = publicationForms[x].pfLanguage;
    if (pfLanguage) {
      if (fhirJson.citedArtifact.publicationForm[x].language === undefined) {
        fhirJson.citedArtifact.publicationForm[x].language = [];
      }
      for (let languangeIndex in pfLanguage) {
        fhirJson.citedArtifact.publicationForm[x].language[languangeIndex] = pfLanguage[languangeIndex];
      }
      if (fhirJson.citedArtifact.publicationForm[x].language.length > pfLanguage.length) {
        fhirJson.citedArtifact.publicationForm[x].language = fhirJson.citedArtifact.publicationForm[x].language.slice(0, pfLanguage.length);
      }
    }
    if (publicationForms[x].type) {
      let publishedInTypeEntry = {};
      if (fhirJson.citedArtifact.publicationForm[x].publishedIn?.type) {
        publishedInTypeEntry = fhirJson.citedArtifact.publicationForm[x].publishedIn.type;
      } else {
        publishedInTypeEntry = { "coding": [] };
        fhirJson.citedArtifact.publicationForm[x].publishedIn.type = publishedInTypeEntry;
      }
      let publicationFormPublishedInTypeJson = codesToCodeableConcept(publishedInTypeEntry, [publicationForms[x].type], publishedInTypeLookup, "http://hl7.org/fhir/published-in-type");
      fhirJson.citedArtifact.publicationForm[x].publishedIn.type = publicationFormPublishedInTypeJson;
    }
    if (publicationForms[x].citedMediumDisplay) {
      let citedMediumEntry = {};
      if (fhirJson.citedArtifact.publicationForm[x].citedMedium) {
        citedMediumEntry = fhirJson.citedArtifact.publicationForm[x].citedMedium;
      } else {
        citedMediumEntry = { "coding": [] };
        fhirJson.citedArtifact.publicationForm[x].citedMedium = citedMediumEntry;
      }
      let publicationFormCitedMediumJson = codesToCodeableConcept(citedMediumEntry, [publicationForms[x].citedMediumDisplay], journalIssueMediumLookup, "http://hl7.org/fhir/cited-medium");
      fhirJson.citedArtifact.publicationForm[x].citedMedium = publicationFormCitedMediumJson;
    }
    delete fhirJson.citedArtifact.publicationForm[x].periodicRelease;
  }
  if (publicationForms.length === 0) {
    delete fhirJson.citedArtifact.publicationForm;
  }

  for (let x in citationState.webLocations) {
    if (fhirJson.citedArtifact.webLocation === undefined) {
      fhirJson.citedArtifact.webLocation = [];
    }
    if (x >= fhirJson.citedArtifact.webLocation.length) {
      fhirJson.citedArtifact.webLocation.push({});
    }

    let webLocationEntry = {};
    if (fhirJson.citedArtifact.webLocation[x]) {
      webLocationEntry = fhirJson.citedArtifact.webLocation[x];
    }

    /*
    if (webLocationEntry.classifier === undefined) {
      webLocationEntry.classifier = [];
    }
    if (!Array.isArray(webLocationEntry.classifier)) {
      webLocationEntry.classifier = [webLocationEntry.classifier];
    }*/
    webLocationEntry.classifier = [];
    delete webLocationEntry.type; //Deletes an old element
    webLocationEntry.url = citationState.webLocations[x].url;
    //let webLocationClassifierJson = codesToCodeableConcept(webLocationEntry.classifier, citationState.webLocations[x].classifier, articleUrlTypeLookup, "http://hl7.org/fhir/artifact-url-classifier");
    //fhirJson.citedArtifact.webLocation[x].text = citationState.webLocations[x].text;
    //fhirJson.citedArtifact.webLocation[x].classifier = webLocationClassifierJson;

    for (let classifierIndex in citationState.webLocations[x].classifier) {
      let classifier = citationState.webLocations[x].classifier[classifierIndex];
      if (classifier) {
        let webLocationClassifierEntry;
        if (articleUrlTypeLookup[classifier]) {
          webLocationClassifierEntry = { "coding": [{ "system": articleUrlTypeLookup[classifier].system, "code": classifier, "display": articleUrlTypeLookup[classifier].display }] };
        } else {
          webLocationClassifierEntry = { "text": classifier };
        }
        webLocationEntry.classifier.push(webLocationClassifierEntry);
      }
    }
    //fhirJson.citedArtifact.webLocation[x] = webLocationEntry;    
  }
  if (citationState.webLocations.length === 0) {
    delete fhirJson.citedArtifact.webLocation;
  }

  if (citationState.classifications.length > 0 || citationState.meshHeadingSet.length > 0 || citationState.extraClassifications.length > 0) {
    fhirJson.citedArtifact.classification = [];
  }
  for (let x in citationState.classifications) {
    if (citationState.classifications[x].originalJson == null) {
      alert('null found here A.')
    }
    fhirJson.citedArtifact.classification.push(citationState.classifications[x].originalJson);
    let classificationEntry = {};
    if (fhirJson.citedArtifact.classification) {
      if (fhirJson.citedArtifact.classification[x]) {
        classificationEntry = fhirJson.citedArtifact.classification[x];
        if (fhirJson.citedArtifact.classification[x].classifier) {
          delete fhirJson.citedArtifact.classification[x].classifier;
        }
      }
    }
    if (classificationEntry.type === undefined) {
      classificationEntry.type = { "coding": [] };
    }
    let classificationTypeJson = codesToCodeableConcept(classificationEntry.type, [citationState.classifications[x].classificationType], classificationTypeLookup, "http://hl7.org/fhir/cited-artifact-classification-type");

    fhirJson.citedArtifact.classification[x].type = classificationTypeJson;

    /*
    if (fhirJson.citedArtifact.classification[x].whoClassified === undefined) {
      fhirJson.citedArtifact.classification[x].whoClassified = { "person": {}, "organization": {}, "publisher": {} };
    } else {
      if (fhirJson.citedArtifact.classification[x].whoClassified.person === undefined) {
        fhirJson.citedArtifact.classification[x].whoClassified.person = {};
      }
      if (fhirJson.citedArtifact.classification[x].whoClassified.organization === undefined) {
        fhirJson.citedArtifact.classification[x].whoClassified.organization = {};
      }
      if (fhirJson.citedArtifact.classification[x].whoClassified.publisher === undefined) {
        fhirJson.citedArtifact.classification[x].whoClassified.publisher = {};
      }
    }
    fhirJson.citedArtifact.classification[x].whoClassified.person.display = citationState.classifications[x].whoClassifiedPerson;
    fhirJson.citedArtifact.classification[x].whoClassified.organization.display = citationState.classifications[x].whoClassifiedOrganization;
    fhirJson.citedArtifact.classification[x].whoClassified.publisher.display = citationState.classifications[x].whoClassifiedPublisher;
    fhirJson.citedArtifact.classification[x].whoClassified.classifierCopyright = citationState.classifications[x].whoClassifiedCopyright;
    if (citationState.classifications[x].whoClassifiedFreeToShare === "Yes") {
      fhirJson.citedArtifact.classification[x].whoClassified.freeToShare = true;
    } else if (citationState.classifications[x].whoClassifiedFreeToShare === "No") {
      fhirJson.citedArtifact.classification[x].whoClassified.freeToShare = false;
    } else {
      delete fhirJson.citedArtifact.classification[x].whoClassified.freeToShare;
    }
    */
    fhirJson.citedArtifact.classification[x].classifier = citationState.classifications[x].classifiersCoded;
    if (fhirJson.citedArtifact.classification[x].classifier === undefined) {
      fhirJson.citedArtifact.classification[x].classifier = [];
    }
    for (let classifierTextIndex in citationState.classifications[x].classifiersTexts) {
      fhirJson.citedArtifact.classification[x].classifier.push({ "text": citationState.classifications[x].classifiersTexts[classifierTextIndex] });
    }
  }
  for (let x in citationState.meshHeadingSet) {
    if (citationState.meshHeadingSet[x].originalJson == null) {
      alert('null found here on B.')
    }
    fhirJson.citedArtifact.classification.push(citationState.meshHeadingSet[x].originalJson);
  }
  for (let x in citationState.extraClassifications) {
    if (citationState.extraClassifications[x].originalJson == null) {
      console.log(x)
      console.log(citationState.extraClassifications[x])
      console.log(citationState.extraClassifications)
      alert('null found here on C.')
    }
    fhirJson.citedArtifact.classification.push(citationState.extraClassifications[x].originalJson);
  }

  if (fhirJson.meta?.profile?.length > 0) {
    let knowledgeArtifactTypeClassifictionFound = false;
    let profileurl = fhirJson.meta.profile[0];
    const profileDictionary = ProfilesByResourceType["Citation"];
    for (const key in profileDictionary) {
      if (profileurl === profileDictionary[key].url) {
        if (profileDictionary[key].citedArtifactClassificationToAdd) {
          let newClassification = profileDictionary[key].citedArtifactClassificationToAdd;
          for (let x in fhirJson.citedArtifact.classification) {
            if (fhirJson.citedArtifact.classification[x].type?.coding &&
              fhirJson.citedArtifact.classification[x].type.coding.length > 0 &&
              fhirJson.citedArtifact.classification[x].type.coding[0].code === "knowledge-artifact-type") {
              knowledgeArtifactTypeClassifictionFound = true;
              fhirJson.citedArtifact.classification[x].classifier[0] = newClassification.classifier[0];
            }
          }
          if (!knowledgeArtifactTypeClassifictionFound) {
            fhirJson.citedArtifact.classification.push(newClassification);
          }
        }
      }
    }
  }

  /*
  if (fhirJson.citedArtifact.webLocation === undefined) {
      fhirJson.citedArtifact.webLocation = [];
  }
  for (let x in citationState.webLocations) {
      if (fhirJson.citedArtifact.webLocation[x] === undefined) {
      fhirJson.citedArtifact.webLocation.push({});
      }
      fhirJson.citedArtifact.webLocation[x].url = citationState.webLocations[x].url;

      let typeEntry = {};
      if (fhirJson.citedArtifact.webLocation[x].type) {
      typeEntry = fhirJson.citedArtifact.webLocation[x].type;
      } else {
      typeEntry = {"text": ""};
      fhirJson.citedArtifact.webLocation[x].type = typeEntry;
      }
      if (typeEntry.coding === undefined) {
      typeEntry.coding = [];
      }
      let webLocationTypeJson = codesToCodeableConcept(typeEntry, citationState.webLocations[x].type, articleUrlTypeLookup, "http://hl7.org/fhir/artifact-url-classifier");
      webLocationTypeJson.text = citationState.webLocations[x].typeText;
      if (citationState.webLocations[x].computableResource) {
      if (!webLocationTypeJson.text.includes("Computable resource")) {
          if (webLocationTypeJson.text.length > 0) {
          webLocationTypeJson.text += ", ";
          }
          webLocationTypeJson.text += "Computable resource";
      }
      } else {
      webLocationTypeJson.text = webLocationTypeJson.text.replaceAll(", Computable resource", "").replaceAll(",Computable resource", "").replaceAll("Computable resource", "");
      }
      fhirJson.citedArtifact.webLocation[x].type = webLocationTypeJson;
  }
  */

  if (fhirJson.citedArtifact.dateAccessed || citationState.dateAccessed) {
    fhirJson.citedArtifact.dateAccessed = citationState.dateAccessed;
  }

  if (citationState.versionValue || typeof fhirJson.citedArtifact.version === "string") {
    fhirJson.citedArtifact.version = citationState.versionValue;
  } else if (fhirJson.citedArtifact.version?.value) {
    fhirJson.citedArtifact.version = fhirJson.citedArtifact.version.value;
  }

  /*
  if (fhirJson.citedArtifact.version?.baseCitation?.identifier || (citationState.baseCitationIdentifier.system || citationState.baseCitationIdentifier.typeText || citationState.baseCitationIdentifier.assignerDisplay || citationState.baseCitationIdentifier.value)) {
      if (fhirJson.citedArtifact.version === undefined) {
      fhirJson.citedArtifact.version = {};
      }
      if (fhirJson.citedArtifact.version.baseCitation === undefined) {
      fhirJson.citedArtifact.version.baseCitation = {};
      }
      if (fhirJson.citedArtifact.version.baseCitation.identifier === undefined) {
      fhirJson.citedArtifact.version.baseCitation.identifier = {};
      }
      let identifierJson = {};
      let identifier = citationState.baseCitationIdentifier;
      if (identifier.typeText !== undefined) {
      identifierJson.type.text = identifier.typeText;
      } else if (systemLookup[identifier.system]) {
      identifierJson.type.text = systemLookup[identifier.system].display;
      }
      if (identifier.system !== undefined) {
      identifierJson.system = identifier.system;
      } else if (displayLookup[identifier.typeText] !== undefined) {
      identifierJson.system = displayLookup[identifier.typeText].system;
      }
      if (identifier.assignerDisplay !== undefined) {
      identifierJson.assigner.display = identifier.assignerDisplay;
      } else if (displayLookup[identifier.typeText]?.assignerDisplay !== undefined) {
      identifierJson[x].assigner.display = displayLookup[identifier.typeText].assignerDisplay;
      }
      identifierJson[x].value = identifier.value;
  }
  */

  for (let x in citationState.relatesToSet) {
    if (citationState.relatesToSet[x].editing) {
      if (fhirJson.citedArtifact.relatesTo === undefined) {
        fhirJson.citedArtifact.relatesTo = [];
      }
      if (x >= fhirJson.citedArtifact.relatesTo.length) {
        fhirJson.citedArtifact.relatesTo.push({});
      }

      fhirJson.citedArtifact.relatesTo[x].type = citationState.relatesToSet[x].type;
      fhirJson.citedArtifact.relatesTo[x].classifier = citationState.relatesToSet[x].classifiersNonEditableJson;
      for (let y in citationState.relatesToSet[x].classifiersEditable) {
        let classifierEntry = citationState.relatesToSet[x].classifiersEditable[y];
        if (artifactClassifierTypeLookup[classifierEntry]) {
          fhirJson.citedArtifact.relatesTo[x].classifier.push({ "coding": [{ "system": artifactClassifierTypeLookup[classifierEntry].system, "code": classifierEntry, "display": artifactClassifierTypeLookup[classifierEntry].display }] });
        } else {
          fhirJson.citedArtifact.relatesTo[x].classifier.push({ "text": classifierEntry });
        }

      }
      //fhirJson.citedArtifact.relatesTo[x].classifier

      fhirJson.citedArtifact.relatesTo[x].citation = citationState.relatesToSet[x].citation;
      fhirJson.citedArtifact.relatesTo[x].display = citationState.relatesToSet[x].display;

      if (fhirJson.citedArtifact.relatesTo[x].targetReference === undefined) {
        fhirJson.citedArtifact.relatesTo[x].targetReference = {};
      }
      if (fhirJson.citedArtifact.relatesTo[x].targetReference.identifier === undefined) {
        fhirJson.citedArtifact.relatesTo[x].targetReference.identifier = {};
      }
      fhirJson.citedArtifact.relatesTo[x].targetReference.identifier = identifierTextViewChangesToJson(citationState.relatesToSet[x].targetReferenceIdentifier || citationState.relatesToSet[x].resourceReferenceIdentifier, fhirJson.citedArtifact.relatesTo[x].targetReference?.identifier || fhirJson.citedArtifact.relatesTo[x].resourceReference.identifier, identifierSystemLookup, identifierDisplayLookup);

      if (fhirJson.citedArtifact.relatesTo[x].targetUri || fhirJson.citedArtifact.relatesTo[x].document?.url || citationState.relatesToSet[x].documentURL || citationState.relatesToSet[x].targetUri) {
        if (fhirJson.citedArtifact.relatesTo[x].targetReference.identifier.value && !fhirJson.citedArtifact.relatesTo[x].targetReference.reference) {
          fhirJson.citedArtifact.relatesTo[x].targetReference.reference = citationState.relatesToSet[x].targetUri || citationState.relatesToSet[x].documentURL;
        } else {
          fhirJson.citedArtifact.relatesTo[x].targetUri = citationState.relatesToSet[x].targetUri || citationState.relatesToSet[x].documentURL;
        }
      }
      delete fhirJson.citedArtifact.relatesTo[x].document;
      delete fhirJson.citedArtifact.relatesTo[x].resourceReference;
    }
  }

  for (let x in citationState.titles) {
    if (fhirJson.citedArtifact.title === undefined) {
      fhirJson.citedArtifact.title = [];
    }
    if (x >= fhirJson.citedArtifact.title.length) {
      fhirJson.citedArtifact.title.push({});
    }

    let titleEntry = {};
    titleEntry = fhirJson.citedArtifact.title[x];
    if (titleEntry.type === undefined) {
      titleEntry.type = { "coding": [] };
    }

    //let titleTypesJson = codesToCodeableConcept(titleEntry, citationState.titles[x].titleType, titleTypeLookup, "http://terminology.hl7.org/CodeSystem/title-type");
    //let titleTypesJson = codesToCodeableConcept(titleEntry.type, citationState.titles[x].titleType, titleTypeLookup, "http://hl7.org/fhir/title-type");
    let titleTypesJson = [];
    for (let titleTypesIndex in citationState.titles[x].titleType) {
      let titleType = citationState.titles[x].titleType[titleTypesIndex];
      if (titleType) {
        if (titleTypeLookup[titleType]) {
          titleTypesJson.push({ "coding": [{ "system": titleTypeLookup[titleType].system, "code": titleType, "display": titleTypeLookup[titleType].display }] });
        } else {
          titleTypesJson.push({ "text": titleType });
        }
      }
    }

    fhirJson.citedArtifact.title[x].text = citationState.titles[x].text;
    if (citationState.titles[x].titleLanguage) {
      fhirJson.citedArtifact.title[x].language = citationState.titles[x].titleLanguage;
    }

    fhirJson.citedArtifact.title[x].type = titleTypesJson;
  }


  let containedCount = 0;
  if (fhirJson.contained) {
    containedCount = fhirJson.contained.length;
  }
  let containedResources = getContainedResources(fhirJson);

  let contributorshipSummaries = citationContributorsState.contributorshipSummaries;
  let contributorshipIndividuals = citationIndividualContributorsState;

  if (citationContributorsState.contributorshipCompleteProvided || citationContributorsState.contributorshipComplete || contributorshipSummaries.length > 0 || (contributorshipIndividuals && Object.keys(contributorshipIndividuals).length > 0)) {
    if (fhirJson.citedArtifact.contributorship === undefined) {
      fhirJson.citedArtifact.contributorship = {};
    }
    if (citationContributorsState.contributorshipCompleteProvided || citationContributorsState.contributorshipComplete) {
      fhirJson.citedArtifact.contributorship.complete = citationContributorsState.contributorshipComplete;
    }
    for (let x in contributorshipSummaries) {
      if (fhirJson.citedArtifact.contributorship.summary === undefined) {
        fhirJson.citedArtifact.contributorship.summary = [];
      }
      if (x >= fhirJson.citedArtifact.contributorship.summary.length) {
        fhirJson.citedArtifact.contributorship.summary.push({});
      }

      let summaryEntry = {};
      summaryEntry = fhirJson.citedArtifact.contributorship.summary[x];

      if (summaryEntry.type === undefined) {
        summaryEntry.type = { "coding": [] };
      }
      if (summaryEntry.source === undefined) {
        summaryEntry.source = { "coding": [] };
      }
      //let titleTypesJson = codesToCodeableConcept(titleEntry, citationState.titles[x].titleType, titleTypeLookup, "http://terminology.hl7.org/CodeSystem/title-type");
      let summaryTypeJson = codesToCodeableConcept(summaryEntry.type, contributorshipSummaries[x].summaryType, summaryTypeLookup, "http://hl7.org/fhir/contributor-summary-type");
      let summarySourceJson = codesToCodeableConcept(summaryEntry.source, contributorshipSummaries[x].summarySource, summarySourceLookup, "http://hl7.org/fhir/contributor-summary-source");

      fhirJson.citedArtifact.contributorship.summary[x].value = contributorshipSummaries[x].summaryValue;
      fhirJson.citedArtifact.contributorship.summary[x].type = summaryTypeJson;
      fhirJson.citedArtifact.contributorship.summary[x].source = summarySourceJson;

    }
    for (let x in contributorshipIndividuals) {
      //if (contributorshipIndividuals[x].editing) {
      if (fhirJson.citedArtifact.contributorship.entry === undefined) {
        fhirJson.citedArtifact.contributorship.entry = [];
      }
      if (x >= fhirJson.citedArtifact.contributorship.entry.length) {
        fhirJson.citedArtifact.contributorship.entry.push({ "contributor": {}, "forenameInitials": "", "affiliation": [] });
      }
      fhirJson.citedArtifact.contributorship.entry[x].affiliation = [];
      let contributorId = "contributor" + x;
      if (containedResources[contributorId] === undefined) {
        containedResources[contributorId] = { "index": undefined, "json": { "resourceType": "" } };
      }
      let containedResourceJson = JSON.parse(JSON.stringify(containedResources[contributorId]["json"]));
      containedResourceJson.id = contributorId;
      if (fhirJson.citedArtifact.contributorship.entry[x].contributor === undefined) {
        fhirJson.citedArtifact.contributorship.entry[x].contributor = {};
      }
      fhirJson.citedArtifact.contributorship.entry[x].contributor.reference = "#" + contributorId;

      if (contributorshipIndividuals[x].notAPerson) {
        fhirJson.citedArtifact.contributorship.entry[x].contributor.type = "Organization";
        containedResourceJson.resourceType = "Organization";
        containedResourceJson.name = contributorshipIndividuals[x].collectiveName;

      } else {
        fhirJson.citedArtifact.contributorship.entry[x].contributor.type = "Practitioner";
        containedResourceJson.resourceType = "Practitioner";
        if (containedResourceJson.name === undefined || !Array.isArray(containedResourceJson) || containedResourceJson.name.length === 0) {
          containedResourceJson.name = [{}];
        }
        containedResourceJson.name[0].family = contributorshipIndividuals[x].nameFamily;
        containedResourceJson.name[0].text = contributorshipIndividuals[x].nameText;
        containedResourceJson.name[0].prefix = contributorshipIndividuals[x].namePrefix;
        containedResourceJson.name[0].suffix = contributorshipIndividuals[x].nameSuffix;
        fhirJson.citedArtifact.contributorship.entry[x].forenameInitials = contributorshipIndividuals[x].initials;

        if (containedResourceJson.name[0].given?.length > 0 && Array.isArray(contributorshipIndividuals[x].nameGiven) && contributorshipIndividuals[x].nameGiven.length > 0) {
          containedResourceJson.name[0].given[0] = contributorshipIndividuals[x].nameGiven[0];
        } else {
          if (Array.isArray(contributorshipIndividuals[x].nameGiven)) {
            containedResourceJson.name[0].given = contributorshipIndividuals[x].nameGiven;
          } else if (contributorshipIndividuals[x].nameGiven) {
            containedResourceJson.name[0].given = [contributorshipIndividuals[x].nameGiven];
          } else {
            containedResourceJson.name[0].given = [""];
          }
        }
      }
      delete fhirJson.citedArtifact.contributorship.entry[x].name;
      delete fhirJson.citedArtifact.contributorship.entry[x].initials;
      delete fhirJson.citedArtifact.contributorship.entry[x].collectiveName;
      delete fhirJson.citedArtifact.contributorship.entry[x].address;
      delete fhirJson.citedArtifact.contributorship.entry[x].telecom;
      delete fhirJson.citedArtifact.contributorship.entry[x].identifier;

      let addresses = [];
      let telecoms = [];
      for (let y in contributorshipIndividuals[x].entryTelecomsAndAddresses) {
        let telecomOrAddress = contributorshipIndividuals[x].entryTelecomsAndAddresses[y];
        if (telecomOrAddress.system === "address") {
          addresses.push(telecomOrAddress.value);
        } else {
          telecoms.push(telecomOrAddress);
        }
      }
      //TO DO todo for Organization use Organization.contact, a lot of work on interpretation for this to be
      for (let y in addresses) {
        if (containedResourceJson.address === undefined) {
          containedResourceJson.address = [];
        }
        if (y >= containedResourceJson.address.length) {
          containedResourceJson.address.push({});
        }
        if (containedResourceJson.address[y].text !== addresses[y]) {
          containedResourceJson.address[y] = {};
        }
        containedResourceJson.address[y].text = addresses[y];
      }
      if (containedResourceJson.address?.length > addresses.length) {
        containedResourceJson.address = containedResourceJson.address.slice(0, addresses.length);
      }
      for (let y in telecoms) {
        if (containedResourceJson.telecom === undefined) {
          containedResourceJson.telecom = [];
        }
        if (y >= containedResourceJson.telecom.length) {
          containedResourceJson.telecom.push({});
        }
        if (containedResourceJson.telecom[y].value !== telecoms[y].value || containedResourceJson.telecom[y].system !== telecoms[y].system) {
          containedResourceJson.telecom[y] = {};
        }
        containedResourceJson.telecom[y].system = telecoms[y].system;
        containedResourceJson.telecom[y].value = telecoms[y].value;
      }

      if (containedResourceJson.telecom?.length > telecoms.length) {
        containedResourceJson.telecom = containedResourceJson.elecom.slice(0, telecoms.length);
      }
      if (containedResourceJson.address && containedResourceJson.telecom) {
        //console.log(fhirJson.citedArtifact.contributorship.entry[x].address.length);
        //console.log(fhirJson.citedArtifact.contributorship.entry[x].telecom.length);
      }

      if (contributorshipIndividuals[x].entryIdentifiers?.length > 0) {
        if (containedResourceJson.identifier === undefined || containedResourceJson.identifier.length === 0) {
          containedResourceJson.identifier = [{}];
        }
        containedResourceJson.identifier = identifierTextViewChangesToJson(contributorshipIndividuals[x].entryIdentifiers, containedResourceJson.identifier, contributorIdentifierSystemLookup, contributorIdentifierDisplayLookup);
      }

      if (fhirJson.contained === undefined) {
        fhirJson.contained = [];
      }
      if (containedResources[contributorId]["index"] === undefined) {
        fhirJson.contained.push(containedResourceJson);
      } else {
        fhirJson.contained[containedResources[contributorId]["index"]] = containedResourceJson;
      }

      if (contributorshipIndividuals[x].correspondingContact || contributorshipIndividuals[x].correspondingContactPresent) {
        fhirJson.citedArtifact.contributorship.entry[x].correspondingContact = contributorshipIndividuals[x].correspondingContact;
      }
      fhirJson.citedArtifact.contributorship.entry[x].rankingOrder = contributorshipIndividuals[x].rankingOrder;
      let stateEntryRole = ""
      if (contributorshipIndividuals[x].entryRole !== undefined) {
        stateEntryRole = contributorshipIndividuals[x].entryRole;
      }
      let entryRole = {};
      if (fhirJson.citedArtifact.contributorship.entry[x].role) {
        entryRole = fhirJson.citedArtifact.contributorship.entry[x].role;
      } else {
        entryRole = { "coding": [] };
        fhirJson.citedArtifact.contributorship.entry[x].role = entryRole;
      }
      fhirJson.citedArtifact.contributorship.entry[x].role = codesToCodeableConcept(entryRole, [stateEntryRole], contributorRoleLookup, "http://hl7.org/fhir/contributor-role");

      if (fhirJson.citedArtifact.contributorship.entry[x].contributionType === undefined) {
        fhirJson.citedArtifact.contributorship.entry[x].contributionType = [];
      }
      let contributionTypes = contributorshipIndividuals[x].contributionTypes;
      for (let y in contributionTypes) {
        if (y >= fhirJson.citedArtifact.contributorship.entry[x].contributionType.length) {
          fhirJson.citedArtifact.contributorship.entry[x].contributionType.push({ "coding": [] });
        }
        let contributionType = fhirJson.citedArtifact.contributorship.entry[x].contributionType[y];
        fhirJson.citedArtifact.contributorship.entry[x].contributionType[y] = codesToCodeableConcept(contributionType, [contributionTypes[y]], contributionTypeLookup, "http://hl7.org/fhir/artifact-contribution-type");
      }
      if (fhirJson.citedArtifact.contributorship.entry[x].contributionType && fhirJson.citedArtifact.contributorship.entry[x].contributionType.length > contributionTypes.length) {
        fhirJson.citedArtifact.contributorship.entry[x].contributionType = fhirJson.citedArtifact.contributorship.entry[x].contributionType.slice(0, contributionTypes.length);
      }

      if (fhirJson.citedArtifact.contributorship.entry[x].contributionInstance === undefined) {
        fhirJson.citedArtifact.contributorship.entry[x].contributionInstance = [];
      }
      for (let y in contributorshipIndividuals[x].contributionInstances) {
        if (y >= fhirJson.citedArtifact.contributorship.entry[x].contributionInstance.length) {
          fhirJson.citedArtifact.contributorship.entry[x].contributionInstance.push({ "type": { "coding": [] } });
        }
        let contributionInstance = fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].type;
        fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].type = codesToCodeableConcept(contributionInstance, [contributorshipIndividuals[x].contributionInstances[y].type], contributionInstanceTypeLookup, "http://hl7.org/fhir/artifact-contribution-instance-type");
        fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].time = contributorshipIndividuals[x].contributionInstances[y].time;
      }

      if (contributorshipIndividuals[x].affiliationSelectedValues?.length > 0) {
        if (fhirJson.citedArtifact.contributorship.entry[x].affiliation === undefined) {
          fhirJson.citedArtifact.contributorship.entry[x].affiliation = [];
        }
        for (let affiliationSelectedValuesIndex in contributorshipIndividuals[x].affiliationSelectedValues) {
          let affiliationSelectedValue = contributorshipIndividuals[x].affiliationSelectedValues[affiliationSelectedValuesIndex];
          let affiliationJsonEntry = { "reference": undefined, "type": undefined, "display": undefined };
          if (affiliationSelectedValue) {
            if (citationState.affiliationDictionary[affiliationSelectedValue]?.usesReference) {
              affiliationJsonEntry.display = citationState.affiliationDictionary[affiliationSelectedValue].simpleDisplay;
              affiliationJsonEntry.type = citationState.affiliationDictionary[affiliationSelectedValue].resourceType;
              affiliationJsonEntry.reference = "#" + affiliationSelectedValue;
            } else {
              affiliationJsonEntry.display = affiliationSelectedValue;
            }
          }
          fhirJson.citedArtifact.contributorship.entry[x].affiliation.push(affiliationJsonEntry);
        }
        delete fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo;
      }

      //if (contributorshipIndividuals[x].affiliationInfos?.length > 0) {
      //if (fhirJson.citedArtifact.contributorship.entry[x].affiliation === undefined) {
      //  fhirJson.citedArtifact.contributorship.entry[x].affiliation = [];
      //}
      //for (let y in contributorshipIndividuals[x].affiliationInfos) {
      /*
      for (let y in contributorshipIndividuals[x].affiliationInfosReferences) {
        let affiliationId = "affiliation"+affiliationCount;
        affiliationCount += 1;
        if (containedResources[affiliationId] === undefined) {
            containedResources[affiliationId] = {"index": undefined, "json": {"resourceType":""}};
        }
        let containedAffiliationJson = containedResources[affiliationId]["json"];
        containedAffiliationJson.id = affiliationId;
        if (y >= fhirJson.citedArtifact.contributorship.entry[x].affiliation.length) {
          fhirJson.citedArtifact.contributorship.entry[x].affiliation.push({});
        }
        fhirJson.citedArtifact.contributorship.entry[x].affiliation[y].reference = "";
        if (contributorshipIndividuals[x].affiliationInfos[y].role !== undefined) { //TO DO todo
          fhirJson.citedArtifact.contributorship.entry[x].affiliation[y].type = "PractitionerRole";
          containedAffiliationJson.resourceType = "PractitionerRole";
          if (containedAffiliationJson.organization === undefined) {
            containedAffiliationJson.organization = {};
          }
          //containedAffiliationJson.organization.display = contributorshipIndividuals[x].affiliationInfos[y].affiliation; //TO DO todo
          //containedAffiliationJson.organization.identifier = contributorshipIndividuals[x].affiliationInfos[y].identifier[0];  //TO DO todo
          if (contributorshipIndividuals[x].entryIdentifiers.length > 0) {
            containedAffiliationJson.organization.identifier = contributorshipIndividuals[x].entryIdentifiers[0];
          }
          if (containedAffiliationJson.code === undefined) {
            containedAffiliationJson.code = {};
          }
          //containedAffiliationJson.code.text = contributorshipIndividuals[x].affiliationInfos[y].role;   //BAD, ON THE RIGHT SIDE REPLACE WITH THE ACTUAL ROLE STATE FIELD
        } else {
          fhirJson.citedArtifact.contributorship.entry[x].affiliation[y].type = "Organization";
          containedAffiliationJson.resourceType = "Organization";
          //containedAffiliationJson.name = contributorshipIndividuals[x].affiliationInfos[y].affiliation; //TO DO todo
          //containedAffiliationJson.identifier = contributorshipIndividuals[x].affiliationInfos[y].identifier; //To DO todo
        }
  
        
        //if (fhirJson.contained === undefined) {
        //  fhirJson.contained = [];
        //}
        let addNewAffiliation = true;
        let existingAffiliationId = undefined;
        let containedAffiliationWithoutId = JSON.parse(JSON.stringify(containedAffiliationJson));
        delete containedAffiliationWithoutId.id;
        let containedAffiliationWithoutIdString = JSON.stringify(containedAffiliationWithoutId);
        for (let containedIndex in fhirJson.contained) {
          let contained = fhirJson.contained[containedIndex];
          let containedWithoutId = JSON.parse(JSON.stringify(contained));
          delete containedWithoutId.id;
          let containedWithoutIdString = JSON.stringify(containedWithoutId);
          if (containedWithoutIdString === containedAffiliationWithoutIdString) {
            addNewAffiliation = false;
            existingAffiliationId = contained.id;
            break;
          }
        }
        if (addNewAffiliation) {
          fhirJson.citedArtifact.contributorship.entry[x].affiliation[y].reference = "#"+affiliationId;
          if (containedResources[affiliationId]["index"] === undefined) {
            fhirJson.contained.push(containedAffiliationJson);
          } else {
            fhirJson.contained[containedResources[affiliationId]["index"]] = containedAffiliationJson;
          }
        } else {
          fhirJson.citedArtifact.contributorship.entry[x].affiliation[y].reference = "#"+existingAffiliationId;
        }
        delete fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo;
      }
      */
      //}
      //}
    }

    /*
  for (let x in contributorshipIndividuals) {
    if (contributorshipIndividuals[x].editing) {
      if (fhirJson.citedArtifact.contributorship.entry === undefined) {
        fhirJson.citedArtifact.contributorship.entry = [];
      } else if (fhirJson.citedArtifact.contributorship.entry.length > 0) {
        if (fhirJson.citedArtifact.contributorship.entry[0].contributor === undefined && fhirJson.contained === undefined) {
          fhirJson.contained = [];
          //DO CONVERSION
          modelConversion = true;
          for (let entryIndex in fhirJson.citedArtifact.contributorship.entry) {            
            let entry = fhirJson.citedArtifact.contributorship.entry[entryIndex];
            let newEntry = {"contributor":{},"forenameInitials":"","affiliation":[]};

            let containedId = "contained"+containedCount;
            containedCount += 1;
            let containedResourceJson = {"resourceType": "", "id": containedId, "identifier": [], "name": undefined, "address": [], "telecom": []};
            
            let newEntryContributor = {"reference":"#"+containedId,"type":""};
            let containedContributorFound = false;

            for (let elementName in entry) {
              let elementValue = entry[elementName];
              if (elementName === "address") {
                containedResourceJson["address"] = entry["address"];
                containedContributorFound = true;
              } else if (elementName === "telecom") {
                containedResourceJson["telecom"] = entry["telecom"];
                containedContributorFound = true;
              } else if (elementName === "name") {
                containedResourceJson["name"] = [entry["name"]];
                containedResourceJson["resourceType"] = "Practitioner";
                newEntryContributor["type"] = "Practitioner";
                containedContributorFound = true;
              } else if (elementName === "collectiveName") {
                containedResourceJson["name"] = entry["collectiveName"];
                containedResourceJson["resourceType"] = "Organization";
                newEntryContributor["type"] = "Organization";
                containedContributorFound = true;
              } else if (elementName === "identifier") {
                containedResourceJson["identifier"] = entry["identifier"];
                containedContributorFound = true;
              } else if (elementName === "initials") {
                newEntry["forenameInitials"] = entry["initials"];
              } else if (elementName === "affiliationInfo" && entry["affiliationInfo"].length > 0) {
                for (let affiliationIndex in entry["affiliationInfo"]) {
                  let affiliationInfo = entry["affiliationInfo"][affiliationIndex];
                  let affiliationContainedId = "contained"+containedCount;
                  containedCount += 1;
                  let affiliationContainedResourceJson = {"resourceType": "", "id": affiliationContainedId};
                  let affiliationResourceType;
                  if (affiliationInfo["role"] !== undefined) {
                    affiliationResourceType = "PractitionerRole";
                    affiliationContainedResourceJson["resourceType"] = affiliationResourceType;
                    affiliationContainedResourceJson["organization"] = {"identifier": {}, "display": affiliationInfo.affiliation};
                    affiliationContainedResourceJson["code"] = { "text": affiliationInfo.role };
                    if (affiliationInfo.identifier?.length > 0) {
                      affiliationContainedResourceJson["organization"]["identifier"] = affiliationInfo.identifier[0];
                    }
                  } else {
                    affiliationResourceType = "Organization";
                    affiliationContainedResourceJson["resourceType"] = affiliationResourceType;
                    affiliationContainedResourceJson["identifier"] = affiliationInfo.identifier;
                    affiliationContainedResourceJson["name"] = affiliationInfo.affiliation;
                  }
                  fhirJson.contained.push(affiliationContainedResourceJson);
                  newEntry["affiliation"].push({"reference": "#"+affiliationContainedId, "type": affiliationResourceType});
                }
              } else {
                newEntry[elementName] = elementValue;
              }
            }

            if (containedContributorFound) {
              fhirJson.contained.push(containedResourceJson);
              newEntry["contributor"] = newEntryContributor;
            }

            fhirJson.citedArtifact.contributorship.entry[entryIndex] = newEntry;
          }
        }
      }

      if (modelConversion === false) {
        if (x >= fhirJson.citedArtifact.contributorship.entry.length) {
          fhirJson.citedArtifact.contributorship.entry.push({});
        }
        if (fhirJson.citedArtifact.contributorship.entry[x].name === undefined) {
          fhirJson.citedArtifact.contributorship.entry[x].name = {};
        }
        fhirJson.citedArtifact.contributorship.entry[x].name.family = contributorshipIndividuals[x].nameFamily;
        fhirJson.citedArtifact.contributorship.entry[x].name.text = contributorshipIndividuals[x].nameText;
        fhirJson.citedArtifact.contributorship.entry[x].name.prefix = contributorshipIndividuals[x].namePrefix;
        fhirJson.citedArtifact.contributorship.entry[x].name.suffix = contributorshipIndividuals[x].nameSuffix;
        fhirJson.citedArtifact.contributorship.entry[x].initials = contributorshipIndividuals[x].initials;

        if (fhirJson.citedArtifact.contributorship.entry[x].name.given && fhirJson.citedArtifact.contributorship.entry[x].name.given[0]) {
          if (fhirJson.citedArtifact.contributorship.entry[x].name.given[0] !== contributorshipIndividuals[x].nameGiven[0]) {
            fhirJson.citedArtifact.contributorship.entry[x].name.given = contributorshipIndividuals[x].nameGiven;
          }
        } else {
          fhirJson.citedArtifact.contributorship.entry[x].name.given = contributorshipIndividuals[x].nameGiven;
        }

        if (contributorshipIndividuals[x].notAPerson) {
          //contributorshipIndividuals[x].collectiveName
          fhirJson.citedArtifact.contributorship.entry[x].collectiveName = contributorshipIndividuals[x].collectiveName;
          delete fhirJson.citedArtifact.contributorship.entry[x].name;
          delete fhirJson.citedArtifact.contributorship.entry[x].initials;
        }

        let addresses = [];
        let telecoms = [];
        for (let y in contributorshipIndividuals[x].entryTelecomsAndAddresses) {
          let telecomOrAddress = contributorshipIndividuals[x].entryTelecomsAndAddresses[y];
          if (telecomOrAddress.system === "address") {
            addresses.push(telecomOrAddress.value);
          } else {
            telecoms.push(telecomOrAddress);
          }
        }
        for (let y in addresses) {
          if (fhirJson.citedArtifact.contributorship.entry[x].address === undefined) {
            fhirJson.citedArtifact.contributorship.entry[x].address = [];
          }
          if (y >= fhirJson.citedArtifact.contributorship.entry[x].address.length) {
            fhirJson.citedArtifact.contributorship.entry[x].address.push({});
          }
          if (fhirJson.citedArtifact.contributorship.entry[x].address[y].text !== addresses[y]) {
            fhirJson.citedArtifact.contributorship.entry[x].address[y] = {};
          }
          fhirJson.citedArtifact.contributorship.entry[x].address[y].text = addresses[y];
        }
        if (fhirJson.citedArtifact.contributorship.entry[x].address?.length > addresses.length) {
          fhirJson.citedArtifact.contributorship.entry[x].address = fhirJson.citedArtifact.contributorship.entry[x].address.slice(0, addresses.length);
        }
        for (let y in telecoms) {
          if (fhirJson.citedArtifact.contributorship.entry[x].telecom === undefined) {
            fhirJson.citedArtifact.contributorship.entry[x].telecom = [];
          }
          if (y >= fhirJson.citedArtifact.contributorship.entry[x].telecom.length) {
            fhirJson.citedArtifact.contributorship.entry[x].telecom.push({});
          }
          if (fhirJson.citedArtifact.contributorship.entry[x].telecom[y].value !== telecoms[y].value || fhirJson.citedArtifact.contributorship.entry[x].telecom[y].system !== telecoms[y].system) {
            fhirJson.citedArtifact.contributorship.entry[x].telecom[y] = {};
          }
          fhirJson.citedArtifact.contributorship.entry[x].telecom[y].system = telecoms[y].system;
          fhirJson.citedArtifact.contributorship.entry[x].telecom[y].value = telecoms[y].value;
        }
        if (fhirJson.citedArtifact.contributorship.entry[x].telecom?.length > telecoms.length) {
          fhirJson.citedArtifact.contributorship.entry[x].telecom = fhirJson.citedArtifact.contributorship.entry[x].telecom.slice(0, telecoms.length);
        }
        if (fhirJson.citedArtifact.contributorship.entry[x].address && fhirJson.citedArtifact.contributorship.entry[x].telecom) {
          //console.log(fhirJson.citedArtifact.contributorship.entry[x].address.length);
          //console.log(fhirJson.citedArtifact.contributorship.entry[x].telecom.length);
        }
        if (contributorshipIndividuals[x].correspondingContact || contributorshipIndividuals[x].correspondingContactPresent) {
          fhirJson.citedArtifact.contributorship.entry[x].correspondingContact = contributorshipIndividuals[x].correspondingContact;
        }
        fhirJson.citedArtifact.contributorship.entry[x].rankingOrder = contributorshipIndividuals[x].rankingOrder;
        let stateEntryRole = ""
        if (contributorshipIndividuals[x].entryRole !== undefined) {
          stateEntryRole = contributorshipIndividuals[x].entryRole;
        }
        let entryRole = {};
        if (fhirJson.citedArtifact.contributorship.entry[x].role) {
          entryRole = fhirJson.citedArtifact.contributorship.entry[x].role;
        } else {
          entryRole = { "coding": [] };
          fhirJson.citedArtifact.contributorship.entry[x].role = entryRole;
        }
        fhirJson.citedArtifact.contributorship.entry[x].role = codesToCodeableConcept(entryRole, [stateEntryRole], contributorRoleLookup, "http://hl7.org/fhir/contributor-role");

        if (fhirJson.citedArtifact.contributorship.entry[x].contributionType === undefined) {
          fhirJson.citedArtifact.contributorship.entry[x].contributionType = [];
        }
        let contributionTypes = contributorshipIndividuals[x].contributionTypes;
        for (let y in contributionTypes) {
          if (y >= fhirJson.citedArtifact.contributorship.entry[x].contributionType.length) {
            fhirJson.citedArtifact.contributorship.entry[x].contributionType.push({ "coding": [] });
          }
          let contributionType = fhirJson.citedArtifact.contributorship.entry[x].contributionType[y];
          fhirJson.citedArtifact.contributorship.entry[x].contributionType[y] = codesToCodeableConcept(contributionType, [contributionTypes[y]], contributionTypeLookup, "http://hl7.org/fhir/artifact-contribution-type");
        }
        if (fhirJson.citedArtifact.contributorship.entry[x].contributionType?.length > contributionTypes.length) {
          fhirJson.citedArtifact.contributorship.entry[x].contributionType = fhirJson.citedArtifact.contributorship.entry[x].contributionType.slice(0, contributionTypes.length);
        }

        if (fhirJson.citedArtifact.contributorship.entry[x].contributionInstance === undefined) {
          fhirJson.citedArtifact.contributorship.entry[x].contributionInstance = [];
        }
        for (let y in contributorshipIndividuals[x].contributionInstances) {
          if (y >= fhirJson.citedArtifact.contributorship.entry[x].contributionInstance.length) {
            fhirJson.citedArtifact.contributorship.entry[x].contributionInstance.push({ "type": { "coding": [] } });
          }
          let contributionInstance = fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].type;
          fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].type = codesToCodeableConcept(contributionInstance, [contributorshipIndividuals[x].contributionInstances[y].type], contributionInstanceTypeLookup, "http://hl7.org/fhir/artifact-contribution-instance-type");
          fhirJson.citedArtifact.contributorship.entry[x].contributionInstance[y].time = contributorshipIndividuals[x].contributionInstances[y].time;
        }

        if (fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo === undefined) {
          fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo = [];
        }
        for (let y in contributorshipIndividuals[x].affiliationInfos) {
          if (y >= fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo.length) {
            fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo.push({ "affiliation": { "coding": [] } });
          }
          fhirJson.citedArtifact.contributorship.entry[x].affiliationInfo[y].affiliation = contributorshipIndividuals[x].affiliationInfos[y].affiliation;
        }
        if (contributorshipIndividuals[x].entryIdentifiers?.length > 0) {
          if (fhirJson.citedArtifact.contributorship.entry[x].identifier === undefined || fhirJson.citedArtifact.contributorship.entry[x].identifier.length === 0) {
            fhirJson.citedArtifact.contributorship.entry[x].identifier = [{}];
          }
          fhirJson.citedArtifact.contributorship.entry[x].identifier = identifierTextViewChangesToJson(contributorshipIndividuals[x].entryIdentifiers, fhirJson.citedArtifact.contributorship.entry[x].identifier, contributorIdentifierSystemLookup, contributorIdentifierDisplayLookup);
        }

      }
    }
  }
  */
  } else {
    delete fhirJson.citedArtifact.contributorship;
  }

  //let modelConversion = false;
  //let affiliationCount = 0;

  if (citationState.citationUseContext) {
    fhirJson.useContext = citationState.citationUseContext
  } else if (fhirJson.useContext) {
    delete fhirJson.useContext;
  }

  //if (fhirJson.citedArtifact.currentState === undefined) {
  //  fhirJson.citedArtifact.currentState = [];
  //}
  if (citationState.currentStates.length > 0) {
    fhirJson.citedArtifact.currentState = [];
    for (let x in citationState.currentStates) {
      if (x >= fhirJson.citedArtifact.currentState.length) {
        fhirJson.citedArtifact.currentState.push({ "coding": [] });
      }
      let currentStateJson = codesToCodeableConcept(fhirJson.citedArtifact.currentState[x], [citationState.currentStates[x]], citedArtifactStatusTypeLookup, "http://hl7.org/fhir/cited-artifact-status-type");
      fhirJson.citedArtifact.currentState[x] = currentStateJson;
    }
  } else {
    delete fhirJson.citedArtifact.currentState;
  }

  if (citationState.citationCurrentStates.length > 0) {
    fhirJson.currentState = [];
    for (let x in citationState.citationCurrentStates) {
      if (x >= fhirJson.currentState.length) {
        fhirJson.currentState.push({ "coding": [] });
      }
      let citationCurrentStateJson = codesToCodeableConcept(fhirJson.currentState[x], [citationState.citationCurrentStates[x]], citationStatusTypeLookup, "http://hl7.org/fhir/citation-status-type");
      fhirJson.currentState[x] = citationCurrentStateJson;
    }
  } else {
    delete fhirJson.currentState;
  }

  let contributorRolesOrganized = { "author": [], "editor": [], "reviewer": [], "endorser": [], "recorder": [] }
  for (let x in citationState.citationResourceContributors) {
    let role = citationState.citationResourceContributors[x].role;
    if (role === "Creator") {
      contributorRolesOrganized.author.push(citationState.citationResourceContributors[x]);
    } else if (role === "Editor") {
      contributorRolesOrganized.editor.push(citationState.citationResourceContributors[x]);
    } else if (role === "Reviewer") {
      contributorRolesOrganized.reviewer.push(citationState.citationResourceContributors[x]);
    } else if (role === "Endorser") {
      contributorRolesOrganized.endorser.push(citationState.citationResourceContributors[x]);
    } else if (role === "Recorder") {
      contributorRolesOrganized.recorder.push(citationState.citationResourceContributors[x]);
    }
  }
  for (let key in contributorRolesOrganized) {
    for (let x in contributorRolesOrganized[key]) {
      if (fhirJson[key] === undefined) {
        fhirJson[key] = [];
      }
      if (x >= fhirJson[key].length) {
        fhirJson[key].push({});
      }
      if (fhirJson[key][x].name !== contributorRolesOrganized[key][x].name) {
        fhirJson[key][x] = {};
      }
      fhirJson[key][x].name = contributorRolesOrganized[key][x].name;
    }
  }

  if (fhirJson.citedArtifact.statusDate === undefined && citationState.statusDates.length > 0) {
    fhirJson.citedArtifact.statusDate = [];
  }
  for (let x in citationState.statusDates) {
    if (x >= fhirJson.citedArtifact.statusDate.length) {
      fhirJson.citedArtifact.statusDate.push({ "activity": { "coding": [] } });
    }
    fhirJson.citedArtifact.statusDate[x].activity = codesToCodeableConcept(fhirJson.citedArtifact.statusDate[x].activity, [citationState.statusDates[x].activity], citedArtifactStatusTypeLookup, "http://hl7.org/fhir/cited-artifact-status-type");
    if (citationState.statusDates[x]["actual"] || citationState.statusDates[x]["actualProvided"]) {
      fhirJson.citedArtifact.statusDate[x].actual = citationState.statusDates[x]["actual"];
    }
    fhirJson.citedArtifact.statusDate[x]["period"] = { "start": citationState.statusDates[x]["periodStart"], "end": citationState.statusDates[x]["periodEnd"] };
  }

  if (fhirJson.statusDate === undefined && citationState.citationStatusDates.length > 0) {
    fhirJson.statusDate = [];
  }
  for (let x in citationState.citationStatusDates) {
    if (x >= fhirJson.statusDate.length) {
      fhirJson.statusDate.push({ "activity": { "coding": [] } });
    }
    fhirJson.statusDate[x].activity = codesToCodeableConcept(fhirJson.statusDate[x].activity, [citationState.citationStatusDates[x].activity], citationStatusTypeLookup, "http://hl7.org/fhir/cited-artifact-status-type");
    if (citationState.citationStatusDates[x]["actual"] || citationState.citationStatusDates[x]["actualProvided"]) {
      fhirJson.statusDate[x].actual = citationState.citationStatusDates[x]["actual"];
    }
    fhirJson.statusDate[x]["period"] = { "start": citationState.citationStatusDates[x]["periodStart"], "end": citationState.citationStatusDates[x]["periodEnd"] };
  }

  if (citationState.notes.length > 0) {
    let noteJson = fhirJson.citedArtifact.note;
    if (noteJson === undefined) {
      noteJson = [];
    }
    for (let noteIndex in citationState.notes) {
      if (noteIndex >= noteJson.length) {
        noteJson.push({});
      }
      noteJson[noteIndex].text = citationState.notes[noteIndex];
    }
    fhirJson.citedArtifact.note = noteJson;
  } else {
    delete fhirJson.citedArtifact.note;
  }

  for (let x in citationState.abstracts) {
    let abstractTypeJson = [];
    for (let abstractTypesIndex in citationState.abstracts[x].abstractTypeArray) {
      let abstractType = citationState.abstracts[x].abstractTypeArray[abstractTypesIndex];
      if (abstractType) {
        if (abstractTypeLookup[abstractType]) {
          abstractTypeJson.push({ "coding": [{ "system": abstractTypeLookup[abstractType].system, "code": abstractType, "display": abstractTypeLookup[abstractType].display }] });
        } else {
          abstractTypeJson.push({ "text": abstractType });
        }
      }
    }

    if (fhirJson.citedArtifact.abstract === undefined) {
      fhirJson.citedArtifact.abstract = [];
    }
    if (x >= fhirJson.citedArtifact.abstract.length) {
      fhirJson.citedArtifact.abstract.push({});
    }
    fhirJson.citedArtifact.abstract[x].text = citationState.abstracts[x].text;
    if (citationState.abstracts[x].abstractLanguage) {
      fhirJson.citedArtifact.abstract[x].language = citationState.abstracts[x].abstractLanguage;
    }
    fhirJson.citedArtifact.abstract[x].type = abstractTypeJson;
    fhirJson.citedArtifact.abstract[x].copyright = citationState.abstracts[x].abstractCopyright;
  }

  if (citationState.citationIdentifier) {
    if (fhirJson.identifier === undefined) {
      fhirJson.identifier = [];
    }
    if (fhirJson.identifier[0] === undefined) {
      fhirJson.identifier.push({});
    }
    fhirJson.identifier[0].value = citationState.citationIdentifier;
  }

  if (citationState.citationPublisher === "Computable Publishing LLC") {
    fhirJson.contact = [{ "telecom": [{ "system": "email", "value": "support@computablepublishing.com" }] }];
  } else {
    if (fhirJson.contact === undefined) {
      fhirJson.contact = [];
    }
    if (fhirJson.contact[0] === undefined) {
      fhirJson.contact.push({});
    }
    if (fhirJson.contact[0].telecom === undefined) {
      fhirJson.contact[0].telecom = [];
    }
    if (fhirJson.contact[0].telecom[0] === undefined) {
      fhirJson.contact[0].telecom.push({});
    }

    fhirJson.contact[citationState.bestContactIndex].telecom[citationState.bestTelecomIndex].system = citationState.bestContactSystem;
    fhirJson.contact[citationState.bestContactIndex].telecom[citationState.bestTelecomIndex].value = citationState.bestContact;
  }

  fhirJson.approvalDate = citationState.approvalDate || citationState.dateApproved;
  if (fhirJson.dateApproved) {
    delete fhirJson.dateApproved;
  }
  fhirJson.lastReviewDate = citationState.lastReviewDate;
  fhirJson.description = citationState.citationDescription;

  /*
  if (fhirEntryState.title?.trim() !== "") {
      fhirJson.title = fhirEntryState.title;
  }*/

  fhirJson.title = citationState.citationTitle || "DELETEME";
  fhirJson.date = citationState.citationDate || "DELETEME";
  fhirJson.purpose = citationState.citationPurpose || "DELETEME";
  fhirJson.copyright = citationState.citationCopyright || "DELETEME";
  fhirJson.effectivePeriod = citationState.citationEffectivePeriod || "DELETEME";
  fhirJson.relatesTo = citationState.citationRelatedArtifact || "DELETEME";
  fhirJson.note = citationState.citationNote || "DELETEME";

  //fhirJson.version = citationState.citationVersion;

  //let today = new Date();
  //fhirJson.date = today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, '0') + "-" + String(today.getDate()).padStart(2, '0');

  //fhirJson.url = citationState.citationURL;

  deleteEmptyElementsInObjectRecursive(fhirJson);

  return fhirJson
}


const createMetaTagFromClassificationArtifactAssessmentJson = (metaTag, json, meshHeading) => {
  let classificationJson = JSON.parse(JSON.stringify(json));
  if (metaTag["display"] === "" && classificationJson.title) {
    metaTag["display"] = classificationJson.title;
  }
  metaTag["copyright"] = classificationJson.copyright || "";
  for (let classificationContentIndex in classificationJson.content) {
    let classificationBit = classificationJson.content[classificationContentIndex];
    let metaTagContent = { "classifiersDisplayString": "", "qualifiersDisplay": "", "freeToShare": classificationBit.freeToShare, "authorDisplay": "" };  //true, false, or undefined
    if (classificationBit.type) {
      if (classificationBit.type.coding?.length > 0 && classificationBit.type.coding[0].display) {
        metaTagContent["typeDisplay"] = classificationBit.type.coding[0].display;
      } else if (classificationBit.type.text) {
        metaTagContent["typeDisplay"] = classificationBit.type.text;
      } else if (classificationBit.type.coding?.length > 0 && classificationBit.type.coding[0].code) {
        metaTagContent["typeDisplay"] = classificationBit.type.coding[0].code;
      }
      if (metaTagContent["typeDisplay"].toLowerCase().replace(" ", "-") === "mesh-heading") {
        meshHeading = true;
      }
    }
    if (classificationBit.author) {
      if (classificationBit.author.display) {
        metaTagContent["authorDisplay"] = classificationBit.author.display;
      } else if (classificationBit.author.reference) {
        metaTagContent["authorDisplay"] = classificationBit.author.reference + " (This will be replaced with the author display)";
      }
    }

    metaTagContent["classifiersDisplayString"] = getCodeableConceptsDisplay(classificationBit.classifier);
    let qualifierDisplayArray = [];
    for (let componentIndex in classificationBit.component) {
      let component = classificationBit.component[componentIndex];
      if (component.type?.text.toLowerCase() === "qualifier") {
        let qualifierString = "";
        if (component.classifier) {
          qualifierString = getCodeableConceptsDisplay(component.classifier);
        }
        for (let subComponentIndex in component.component) {
          let subComponent = component.component[subComponentIndex];
          if (subComponent.type.text) {
            for (let subComponentClassifierIndex in subComponent.classifier) {
              let subComponentClassifier = subComponent.classifier[subComponentClassifierIndex];
              for (let subComponentClassifierCodingIndex in subComponentClassifier.coding) {
                let subComponentClassifierCoding = subComponentClassifier.coding[subComponentClassifierCodingIndex];
                let code = subComponentClassifierCoding.code;
                if (code.toUpperCase() === "Y" || code.toLowerCase() === "yes" ||
                  code.toLowerCase() === "is-major-topic" || code.toLowerCase() === "ismajortopic" ||
                  code === "C49488") {
                  qualifierString += ` (${subComponent.type.text})`;
                }
              }
            }
          }
        }
        if (qualifierString) {
          qualifierDisplayArray.push(qualifierString);
        }
      }
    }
    metaTagContent["qualifiersDisplay"] = qualifierDisplayArray.join("; ");

    metaTag["contents"].push(metaTagContent);
  }
  return [metaTag, meshHeading];
}

const citationTextViewCalculations = async (fhirJson, resourceId, fhirEntryState, changeFhirEntryState, setCitationState, setCitationIndividualContributorsState,
  setCitationContributorsState, setCitationPublicationFormsState, citationBlankState, contributionInstanceTypeOptions,
  setContributionInstanceTypeOptions, setAffiliationsOptions, setContributionTypeOptions, setContributorRoleOptions, setLanguageOptions,
  setCitationProjectOptions, setCitationStatusTypeOptions, setCitedArtifactStatusTypeOptions, setArtifactClassifierTypeOptions,
  setPartTypeOptions, setSummaryTypeOptions, setSummarySourceOptions, setAbstractOptions, setArticleUrlTypeOptions,
  setClassificationTypeOptions, setClassifierOptions, setTitleTypeOptions,
  userState, classifications, extraClassifications, extraClassificationFoisProcessed) => {

  if (!fhirJson) {
    clearState(setCitationState, citationBlankState);
    setCitationState(prevState => { return { ...prevState, invalid: true }; });
    return false;
  }

  let affiliationsList = [];
  let affiliationsReferencesList = [];

  let affiliationDictionary = {};

  try {
    if (fhirEntryState.editMode == false && fhirJson.citedArtifact && Object.keys(fhirJson.citedArtifact).length === 0) {
      //Then it's a brand new Citation
      changeFhirEntryState(true, "editMode");
    }

    let containedResources = getContainedResources(fhirJson);

    let identifiers = [];
    let relatedIdentifiers = [];
    let titles = [];
    let contributorshipSummaries = [];
    let contributorshipCompleteProvided = false;
    let contributorshipComplete = false;
    let publicationForms = [];
    let webLocations = [];
    //let meshHeadingClassifications = [];
    let meshHeadingSet = [];
    let currentStates = [];
    let partTypes = [];
    let statusDates = [];
    let relatesToSet = [];
    let abstracts = [];
    let dateAccessed = "";
    let versionValue = "";
    let baseCitationReference = "";
    let baseCitationType = "Citation";
    let baseCitationDisplay = "";
    let baseCitationIdentifier = {};
    let partValue = "";
    let notes = [];

    if (fhirJson.citedArtifact) {
      for (let identifierIndex in fhirJson.citedArtifact.identifier) {
        let identifierEntry = formatIdentifiersForState(fhirJson.citedArtifact.identifier[identifierIndex], false);
        identifierEntry["identifierIndex"] = identifierIndex;
        identifiers.push(identifierEntry);
      }
      for (let relatedIdentifierIndex in fhirJson.citedArtifact.relatedIdentifier) {
        let relatedIdentifierEntry = formatIdentifiersForState(fhirJson.citedArtifact.relatedIdentifier[relatedIdentifierIndex], true);
        relatedIdentifierEntry["relatedIdentifierIndex"] = relatedIdentifierIndex;
        relatedIdentifiers.push(relatedIdentifierEntry);
      }
      for (let titleIndex in fhirJson.citedArtifact.title) {
        let title = fhirJson.citedArtifact.title[titleIndex];
        let titleLanguage = "";
        if (title.language) {
          if (typeof title.language === "string") {
            titleLanguage = title.language;
          } else {
            for (let codingIndex in title.language.coding) {
              if (title.language.coding[codingIndex].code) {
                titleLanguage = title.language.coding[codingIndex].code;
                break;
              }
            }
            if (!titleLanguage && title.language.text) {
              titleLanguage = title.language.text;
            }
          }
        }
        let titleType = [];
        if (title.type && !Array.isArray(title.type)) {
          title.type = [title.type];
        }
        for (let titleTypeIndex in title.type) {
          let titleTypeJson = title.type[titleTypeIndex];
          for (let codingIndex in titleTypeJson.coding) {
            if (titleTypeJson.coding[codingIndex].code) {
              titleType.push(titleTypeJson.coding[codingIndex].code);
            }
          }
          if (titleTypeJson.text) {
            addOption(setTitleTypeOptions, titleTypeJson.text);
            titleType.push(titleTypeJson.text);
          }
        }
        //else if (title.text && title.text !== "") {
        //  titleType = ["primary"];
        //}
        titles.push({ "text": title.text, "titleLanguage": titleLanguage, "titleType": titleType });
      }
      if (fhirJson.citedArtifact.contributorship) {
        contributorshipCompleteProvided = true;
        contributorshipComplete = fhirJson.citedArtifact.contributorship.complete;
        if (contributorshipComplete === undefined) {
          contributorshipComplete = false;
          contributorshipCompleteProvided = false;
        }
        for (let summaryIndex in fhirJson.citedArtifact.contributorship.summary) {
          let summary = fhirJson.citedArtifact.contributorship.summary[summaryIndex];
          let summaryType = [];
          if (summary.type) {
            if (summary.type.coding) {
              for (let codingIndex in summary.type.coding) {
                if (summary.type.coding[codingIndex].code) {
                  summaryType.push(summary.type.coding[codingIndex].code);
                }
              }
            }
            if (summary.type.text) {
              addOption(setSummaryTypeOptions, summary.type.text);
              summaryType.push(summary.type.text);
            }
          }
          let summaryStyle = [];
          if (summary.style) {
            if (summary.style.coding) {
              for (let codingIndex in summary.style.coding) {
                if (summary.style.coding[codingIndex].code) {
                  summaryStyle.push(summary.style.coding[codingIndex].code);
                }
              }
            }
            if (summary.style.text) {
              //addOption(setSummaryStyleOptions, summary.style.text);
              summaryStyle.push(summary.style.text);
            }
          }
          let summarySource = [];
          if (summary.source) {
            if (summary.source.coding) {
              for (let codingIndex in summary.source.coding) {
                if (summary.source.coding[codingIndex].code) {
                  summarySource.push(summary.source.coding[codingIndex].code);
                }
              }
            }
            if (summary.source.text) {
              addOption(setSummarySourceOptions, summary.source.text);
              summarySource.push(summary.source.text);
            }
          }
          let summaryValue = summary.value;
          contributorshipSummaries.push({ "summaryType": summaryType, "summaryStyle": summaryStyle, "summarySource": summarySource, "summaryValue": summaryValue });
        }

        for (let entryIndex in fhirJson.citedArtifact.contributorship.entry) {
          let entry = fhirJson.citedArtifact.contributorship.entry[entryIndex];
          let entryInstance = {};
          entryInstance.nameGiven = [""];
          entryInstance.namePrefix = [""];
          entryInstance.nameSuffix = [""];
          let entryIdentifiers = [];

          let affiliationSelectedValues = [];

          let affiliationInfos = []; //
          let affiliationInfosReferences = []; //
          let affiliationInfosDisplays = []; //

          let entryTelecomsAndAddresses = [];

          //let contributorIndex;
          let contributorJson;
          let contributorContainer = entry;

          entryInstance.notAPerson = false;

          if (entry.contributor) {
            //New model
            contributorContainer = {};
            let reference = entry.contributor.reference;
            if (reference) {
              if (reference.indexOf("#") === 0) {
                //contained0
                let containedContributor = containedResources[reference.replace("#", "")]; //Remvoes the first "#"
                if (containedContributor) {
                  //contributorIndex = containedContributor.index;
                  contributorJson = JSON.parse(JSON.stringify(containedContributor.json));
                }
              } else if (reference.split("/").length === 2) {
                //Organization/558
                //Get resource from our server
              } else {
                //Someone else's server?
              }
              if (contributorJson) {
                contributorContainer = contributorJson;
                if (!(contributorJson.resourceType && contributorJson.resourceType !== "Organization")) {
                  //If it's an Organization resourceType or if it doesn't have a resourceType
                  if (contributorJson.name && typeof contributorJson.name === "string") {
                    entryInstance.notAPerson = true;
                    entryInstance.collectiveName = contributorJson.name;
                  }
                } else if (contributorJson.resourceType === "Practitioner") {
                  if (contributorJson.name) {
                    if (Array.isArray(contributorJson.name) && contributorJson.name.length > 0) {
                      contributorContainer.name = contributorJson.name[0];
                    } else if (typeof contributorJson.name === "string") {
                      contributorContainer.name = { "text": contributorJson.name };
                    }
                  }
                }
                /*
                for (let identifierIndex in contributorJson.identifier) {
                  let identifierEntry = formatIdentifiersForState(contributorJson.identifier[identifierIndex], false);
                  identifierEntry["identifierIndex"] = identifierIndex;
                  entryIdentifiers.push(identifierEntry);
                }
                */
              }

            }

            for (let affiliationInfoIndex in entry.affiliation) {
              //let affiliationInstance = {"refId": undefined, "resourceType": undefined, "refBasedDisplay": "", "simpleDisplay": ""};
              let refId = undefined;
              let affiliationResourceType = undefined;
              let simpleDisplay = "";
              let refBasedDisplay = "";
              let entryAffiliation = entry.affiliation[affiliationInfoIndex];

              if (entryAffiliation.display) {
                simpleDisplay = entryAffiliation.display;
              }

              let affiliationId;
              let practitionerRoleOrganizationId;
              let practitionerRoleOrganizationJson;
              let affiliationJson;
              let reference = entryAffiliation.reference;
              if (reference) {
                if (reference.indexOf("#") === 0) {
                  affiliationId = reference.replace("#", "");
                  affiliationJson = containedResources[affiliationId].json; //Remvoes the first "#"
                  refId = affiliationId;
                  affiliationResourceType = affiliationJson.resourceType || entryAffiliation.type;
                  if (affiliationResourceType === "Organization") {
                    containedResources[affiliationId].display = affiliationJson.name || "";
                    refBasedDisplay = affiliationJson.name || "";
                  } else if (affiliationResourceType === "PractitionerRole") {
                    if (affiliationJson.organization) {
                      if (affiliationJson.organization.display) {
                        containedResources[affiliationId].display = affiliationJson.organization.display;
                        refBasedDisplay = affiliationJson.organization.display;
                      }
                      if (affiliationJson.organization.reference) {
                        if (affiliationJson.organization.reference.indexOf("#") === 0) {
                          practitionerRoleOrganizationId = affiliationJson.organization.reference.replace("#", "");
                          practitionerRoleOrganizationJson = containedResources[practitionerRoleOrganizationId].json; //Remvoes the first "#"
                          if (practitionerRoleOrganizationJson.name) {
                            containedResources[affiliationId].display = practitionerRoleOrganizationJson.name;
                            refBasedDisplay = practitionerRoleOrganizationJson.name;
                          }
                        }
                      }
                    }
                  }
                }
              }

              //TO DO DELETE THESE VARIABLE DECLARATIONS AFTER WE REMOVE IT FROM THE STATE AND DISPLAY (March 18, 2022)
              let affiliationInfosEntry = {};
              //let affiliationInfo = entry.affiliation[affiliationInfoIndex];
              //let affiliationIndex;
              let affiliationInfoIdentifiers = [];
              let affiliationDisplay = reference;

              //TO DO DELETE THIS ENTIRE IF STATEMENT BLOCK (March 18, 2022) after we confirm the other code above replaces it
              if (reference) {
                if (reference.indexOf("#") === 0) {
                  //contained0
                  let containedAffiliation = containedResources[reference.replace("#", "")]; //Remvoes the first "#"
                  if (containedAffiliation) {
                    //affiliationIndex = containedAffiliation.index;
                    affiliationJson = containedAffiliation.json;
                  }
                } else if (reference.split("/").length === 2) {
                  //Organization/558
                  //Get resource from our server
                } else {
                  //Someone else's server?
                }
                if (affiliationJson) {
                  affiliationDisplay = affiliationJson.name || reference;
                  if (affiliationJson.name) {
                    affiliationInfosEntry.affiliation = affiliationJson.name;
                  } else if (affiliationJson.organization?.display) {
                    affiliationInfosEntry.affiliation = affiliationJson.organization.display;
                  }
                  if (affiliationJson.code?.text) {
                    affiliationInfosEntry.role = affiliationJson.code.text;
                  }
                  let affiliationIdentifiers = affiliationJson.identifier;

                  if (affiliationJson.organization?.identifier) {
                    affiliationIdentifiers = affiliationJson.organization.identifier;
                  }

                  for (let affiliationInfoIdentifierIndex in affiliationIdentifiers) {
                    let identifierEntry = formatIdentifiersForState(affiliationIdentifiers[affiliationInfoIdentifierIndex], false);
                    identifierEntry["identifierIndex"] = affiliationInfoIdentifierIndex;
                    affiliationInfoIdentifiers.push(identifierEntry);
                  }
                }

                if (!affiliationsList.includes(reference)) {
                  affiliationsList.push(reference);
                  //addOption(setAffiliationsOptions, affiliationDisplay, reference);
                }
                if (!affiliationsReferencesList.includes(reference)) {
                  affiliationsReferencesList.push(reference);
                }

              }

              //TO DO DELETE THESE 5 lines AFTER WE REMOVE IT FROM THE STATE AND DISPLAY (March 18, 2022)
              affiliationInfosEntry.affiliationInfoIdentifiers = affiliationInfoIdentifiers;
              affiliationInfosEntry.resourceId = reference;
              affiliationInfos.push(affiliationInfosEntry);
              affiliationInfosReferences.push(reference);
              affiliationInfosDisplays.push(affiliationDisplay);

              //KEEP THIS
              if (refId) {
                if (affiliationDictionary[refId] === undefined) {
                  let display = refBasedDisplay || simpleDisplay || "";
                  affiliationDictionary[refId] = { "display": display, "usesReference": true, "resourceType": affiliationResourceType, "refBasedDisplay": refBasedDisplay, "simpleDisplay": simpleDisplay };
                  addOption(setAffiliationsOptions, display, refId);
                }
              } else if (simpleDisplay && affiliationDictionary[simpleDisplay] === undefined) {
                affiliationDictionary[simpleDisplay] = { "display": simpleDisplay, "usesReference": false };
                addOption(setAffiliationsOptions, simpleDisplay, simpleDisplay);
              }
              affiliationSelectedValues.push(refId || simpleDisplay || "");
              //affiliationInstances.push(affiliationInstance);
            }
            //} else if (entry.contributor.identifier) {
            //}
          } else {
            //This is for supporting the old Citation resource structure from before March 2022
            if (entry.collectiveName) {
              entryInstance.notAPerson = true;
              entryInstance.collectiveName = entry.collectiveName;
            }
            for (let affiliationInfoIndex in entry.affiliationInfo) {
              //let affiliationInstance = {"refBasedDisplay": "", "simpleDisplay": ""};
              let simpleDisplay = "";
              let affiliationInfo = entry.affiliationInfo[affiliationInfoIndex];

              simpleDisplay = affiliationInfo.affiliation;
              if (simpleDisplay && affiliationDictionary[simpleDisplay] === undefined) {
                affiliationDictionary[simpleDisplay] = { "display": simpleDisplay, "usesReference": false };
                addOption(setAffiliationsOptions, simpleDisplay, simpleDisplay);
              }
              affiliationSelectedValues.push(simpleDisplay || "");
              //affiliationInstances.push(affiliationInstance);

              /* //TO DO todo DELETE THIS SOON (March 18, 2022)
              affiliationInstance.simpleDisplay = affiliationInfo.affiliation;
              let affiliationInfosEntry = {};
              affiliationInfosEntry.affiliation = affiliationInfo.affiliation;
              affiliationInfosEntry.role = affiliationInfo.role;
              let affiliationInfoIdentifiers = [];
              for (let affiliationInfoIdentifierIndex in affiliationInfo.identifier) {
                let identifierEntry = formatIdentifiersForState(affiliationInfo.identifier[affiliationInfoIdentifierIndex], false);
                identifierEntry["identifierIndex"] = affiliationInfoIdentifierIndex;
                affiliationInfoIdentifiers.push(identifierEntry);
              }
              affiliationInfosEntry.affiliationInfoIdentifiers = affiliationInfoIdentifiers;
              affiliationInfosEntry.resourceId = undefined;
              affiliationInfos.push(affiliationInfosEntry);
              
              if (!affiliationsList.includes(affiliationInfosEntry.affiliation)) {
                affiliationsList.push(affiliationInfosEntry.affiliation);
                addOption(setAffiliationsOptions, affiliationInfosEntry.affiliation, affiliationInfosEntry.affiliation);
              }
  
              affiliationInfosReferences.push(affiliationInfosEntry.affiliation);
              affiliationInfosDisplays.push(affiliationInfosEntry.affiliation);
              */
            }
          }

          if (contributorContainer.name) {
            let contributorName = contributorContainer.name;
            //contributorName.use       //Isn't captured
            //contributorName.period    //Isn't captured
            entryInstance.nameText = contributorName.text;
            entryInstance.nameFamily = contributorName.family;
            if (contributorName.given) {
              entryInstance.nameGiven = contributorName.given;  // 0..* array of strings
            }
            if (contributorName.prefix) {
              entryInstance.namePrefix = contributorName.prefix;  // 0..* array of strings
            }
            if (contributorName.suffix) {
              entryInstance.nameSuffix = contributorName.suffix;  // 0..* array of strings
            }
          }

          for (let identifierIndex in contributorContainer.identifier) {
            let identifierEntry = formatIdentifiersForState(contributorContainer.identifier[identifierIndex], false);
            identifierEntry["identifierIndex"] = identifierIndex;
            entryIdentifiers.push(identifierEntry);
          }

          if (contributorContainer.telecom) {
            //entryInstance.entryTelecoms = entry.telecom;
            entryTelecomsAndAddresses = JSON.parse(JSON.stringify({ "telecom": contributorContainer.telecom }))["telecom"];
          }

          //let entryAddresses = [];
          for (let addressIndex in contributorContainer.address) {
            let addressEntry = {};
            let address = contributorContainer.address[addressIndex]; //entry.address[addressIndex].use;
            addressEntry.use = address.use;
            addressEntry.type = address.type;
            addressEntry.text = address.text;
            addressEntry.line = [];
            if (address.line) {
              addressEntry.line = address.line;
            }
            addressEntry.city = address.city;
            addressEntry.district = address.district;
            addressEntry.state = address.state;
            addressEntry.postalCode = address.postalCode;
            addressEntry.country = address.country;
            addressEntry.period = {};
            if (address.period) {
              addressEntry.period = address.period;
            }
            addressEntry.system = "address";
            addressEntry.value = addressEntry.text;

            //entryAddresses.push(addressEntry);
            entryTelecomsAndAddresses.push(addressEntry);
          }

          //entryInstance.affiliationInstances = affiliationInstances;
          entryInstance.affiliationSelectedValues = affiliationSelectedValues;
          entryInstance.affiliationInfos = affiliationInfos;
          entryInstance.affiliationInfosReferences = affiliationInfosReferences;
          entryInstance.affiliationInfosDisplays = affiliationInfosDisplays;
          entryInstance.initials = entry.forenameInitials || entry.initials;
          entryInstance.entryIdentifiers = entryIdentifiers;
          entryInstance.entryTelecomsAndAddresses = entryTelecomsAndAddresses;
          //entryInstance.entryAddresses = entryAddresses;
          //entryInstance.entryTelecoms = [];

          let contributionTypes = [];
          for (let contributionTypeIndex in entry.contributionType) {
            let contributionTypeEntry = "";
            let contributionType = entry.contributionType[contributionTypeIndex];
            if (contributionType.coding && contributionType.coding[0] && (contributionType.coding[0].code || contributionType.coding[0].display)) {
              contributionTypeEntry = contributionType.coding[0].code || contributionType.coding[0].display;
            } else {
              contributionTypeEntry = contributionType.text;
            }
            if (contributionTypeEntry && contributionTypeLookup[contributionTypeEntry] === undefined) {
              addOption(setContributionTypeOptions, contributionTypeEntry);
            }
            contributionTypes.push(contributionTypeEntry);
          }
          entryInstance.contributionTypes = contributionTypes;

          if (entry.role) {
            if (entry.role.coding && entry.role.coding[0] && (entry.role.coding[0].code || entry.role.coding[0].display)) {
              entryInstance.entryRole = entry.role.coding[0].code || entry.role.coding[0].display;
            } else {
              entryInstance.entryRole = entry.role.text;
            }
            if (entryInstance.entryRole && contributorRoleLookup[entryInstance.entryRole] === undefined) {
              addOption(setContributorRoleOptions, entryInstance.entryRole);
            }
          }

          let contributionInstances = [];
          for (let contributionInstanceIndex in entry.contributionInstance) {
            let contributionInstanceEntry = {};
            let contributionInstance = entry.contributionInstance[contributionInstanceIndex];
            contributionInstanceEntry.time = contributionInstance.time;
            if (contributionInstance.type?.coding && contributionInstance.type.coding[0] && (contributionInstance.type.coding[0].code || contributionInstance.type.coding[0].display)) {
              contributionInstanceEntry.type = contributionInstance.type.coding[0].code || contributionInstance.type.coding[0].display;
            } else {
              contributionInstanceEntry.type = contributionInstance.type.text;
            }
            if (contributionInstanceEntry.type && contributionInstanceTypeOptions[contributionInstanceEntry.type] === undefined) {
              addOption(setContributionInstanceTypeOptions, contributionInstanceEntry.type);
            }
            contributionInstances.push(contributionInstanceEntry);
          }
          entryInstance.contributionInstances = contributionInstances;

          entryInstance.correspondingContactPresent = true;    //To keep track if it wasn't present in the JSON before then to not include it in the exported JSON unless it's checked on the form
          entryInstance.correspondingContact = entry.correspondingContact;
          if (entryInstance.correspondingContact === undefined || entryInstance.correspondingContact === "") {
            entryInstance.correspondingContact = false;
            entryInstance.correspondingContactPresent = false;
          }
          entryInstance.rankingOrder = entry.rankingOrder;
          //contributorshipIndividuals.push(entryInstance);

          setCitationIndividualContributorsState(prevState => {
            return {
              ...prevState,
              [entryIndex]: entryInstance
            };
          });
        }
      }

      for (let publicationFormIndex in fhirJson.citedArtifact.publicationForm) {
        let publicationForm = fhirJson.citedArtifact.publicationForm[publicationFormIndex];
        let publishedIn = publicationForm.publishedIn;
        let pfIdentifiers = [];
        if (publishedIn?.identifier) {
          for (let identifierIndex in publishedIn.identifier) {
            let identifierEntry = formatIdentifiersForState(publishedIn.identifier[identifierIndex], false);
            identifierEntry["identifierIndex"] = identifierIndex;
            pfIdentifiers.push(identifierEntry);
          }
          delete publishedIn.identifier;
        }
        let pfLanguage = [];
        if (publicationForm.language) {
          for (let languageIndex in publicationForm.language) {
            //for (let codingIndex in publicationForm.language[languageIndex].coding) {
            //  if (publicationForm.language[languageIndex].coding[codingIndex].code) {
            //    pfLanguage.push(publicationForm.language[languageIndex].coding[codingIndex].code);
            //  }
            //}
            if (publicationForm.language[languageIndex]) {
              if (typeof publicationForm.language[languageIndex] === "string") {
                pfLanguage.push(publicationForm.language[languageIndex])
              } else if (publicationForm.language[languageIndex].coding
                && publicationForm.language[languageIndex].coding[0] && publicationForm.language[languageIndex].coding[0].code) {
                pfLanguage.push(publicationForm.language[languageIndex].coding[0].code);
              } else if (publicationForm.language[languageIndex].text) {
                addOption(setLanguageOptions, publicationForm.language[languageIndex].text);
                pfLanguage.push(publicationForm.language[languageIndex].text);
              }
            }
          }
        }
        // publicationForm used here for both the FHIR JSON and the text view concept
        // Converts data from old FHIR Citation structure (now-deprecated periodicRelease element) to use for Text View
        if (publicationForm.periodicRelease) {
          if (publicationForm.periodicRelease.dateOfPublication) {
            publicationForm.dateOfPublicationDate = publicationForm.periodicRelease.dateOfPublication.date;
            publicationForm.dateOfPublicationYear = publicationForm.periodicRelease.dateOfPublication.year;
            publicationForm.dateOfPublicationMonth = publicationForm.periodicRelease.dateOfPublication.month;
            publicationForm.dateOfPublicationDay = publicationForm.periodicRelease.dateOfPublication.day;
            publicationForm.dateOfPublicationSeason = publicationForm.periodicRelease.dateOfPublication.season;
            publicationForm.dateOfPublicationText = publicationForm.periodicRelease.dateOfPublication.text;
          }
          if (publicationForm.periodicRelease.citedMedium) {
            publicationForm.citedMedium = publicationForm.periodicRelease.citedMedium;
          }
          if (publicationForm.periodicRelease.volume) {
            publicationForm.volume = publicationForm.periodicRelease.volume;
          }
          if (publicationForm.periodicRelease.issue) {
            publicationForm.issue = publicationForm.periodicRelease.issue;
          }
        }
        if (publicationForm.publicationDateYear) {
          publicationForm.dateOfPublicationYear = publicationForm.publicationDateYear;
        }
        if (publicationForm.publicationDateMonth) {
          publicationForm.dateOfPublicationMonth = publicationForm.publicationDateMonth;
        }
        if (publicationForm.publicationDateDay) {
          publicationForm.dateOfPublicationDay = publicationForm.publicationDateDay;
        }
        if (publicationForm.publicationDateSeason) {
          publicationForm.dateOfPublicationSeason = publicationForm.publicationDateSeason;
        }
        if (publicationForm.publicationDateText) {
          publicationForm.dateOfPublicationText = publicationForm.publicationDateText;
        }
        if (publicationForm.publishedIn) {
          if (publicationForm.publishedIn.type?.coding && publicationForm.publishedIn.type.coding[0] && publicationForm.publishedIn.type.coding[0].code) {
            publicationForm.type = publicationForm.publishedIn.type.coding[0].code;
          }
        } else {
          publicationForm.publishedIn = {};
        }
        if (publicationForm.citedMedium?.coding?.[0]?.code) {
          publicationForm.citedMediumDisplay = publicationForm.citedMedium.coding[0].code.toLowerCase();
        }
        publicationForm.pfLanguage = pfLanguage;
        publicationForm.identifiers = pfIdentifiers;
        publicationForms.push(publicationForm);
      }

      for (let webLocationIndex in fhirJson.citedArtifact.webLocation) {
        let webLocation = fhirJson.citedArtifact.webLocation[webLocationIndex];

        let webLocationClassifier;
        if (webLocation.classifier) {
          webLocationClassifier = webLocation.classifier;
        } else if (webLocation.type) {
          webLocationClassifier = webLocation.type;
        }

        //let oldStructureClassifier = false;
        if (webLocationClassifier === undefined) {
          webLocationClassifier = [];
        } else if (!Array.isArray(webLocationClassifier)) {
          webLocationClassifier = [webLocationClassifier];
          //oldStructureClassifier = true;
        }

        let classifiers = [];
        for (let classifierIndex in webLocationClassifier) {
          let classifier = webLocationClassifier[classifierIndex];
          if (classifier) {
            if (classifier.coding) {
              for (let codingIndex in classifier.coding) {
                if (classifier.coding[codingIndex].code) {
                  if (articleUrlTypeLookup[classifier.coding[codingIndex].code]) {
                    classifiers.push(classifier.coding[codingIndex].code);
                  } else {
                    let newClassifier = JSON.stringify(classifier.coding[codingIndex]);
                    /*
                    let newClassifier = classifier.coding[codingIndex].code;
                    if (classifier.coding[codingIndex].display) {
                      newClassifier = classifier.coding[codingIndex].display;
                    }*/
                    classifiers.push(newClassifier);
                    addOption(setArticleUrlTypeOptions, newClassifier);
                  }
                } else if (classifier.coding[codingIndex].display) {
                  if (classifier.coding[codingIndex].system) {
                    let newClassifier = JSON.stringify(classifier.coding[codingIndex]);
                    classifiers.push(newClassifier);
                    addOption(setArticleUrlTypeOptions, newClassifier);
                  } else {
                    addOption(setArticleUrlTypeOptions, classifier.coding[codingIndex].display);
                    classifiers.push(classifier.coding[codingIndex].display);
                  }
                }
              }
            }
            if (classifier.text) {
              addOption(setArticleUrlTypeOptions, classifier.text);
              classifiers.push(classifier.text);
            }
          }
        }
        webLocations.push({ "classifier": classifiers, "url": webLocation.url });
      }


      for (let classificationIndex in fhirJson.citedArtifact.classification) {
        let classification = fhirJson.citedArtifact.classification[classificationIndex];
        let meshHeading = false;
        let classificationType = "[Undeclared]";
        //let classificationSystem;
        if (classification["type"]) {
          if (classification["type"].coding?.length > 0) {
            if (classification["type"].coding[0].code) {
              classificationType = classification["type"].coding[0].code;
            } else if (classification["type"].coding[0].display) {
              classificationType = classification["type"].coding[0].display;
            }
            //classificationSystem = classification["type"].coding[0].system;
          }
          if (classificationType === "[Undeclared]" && classification["type"].text) {
            classificationType = classification["type"].text;
          }
          if (classificationType !== "[Undeclared]") {
            if (classificationType.toLowerCase().replace(" ", "-") === "mesh-heading") {
              meshHeading = true;
            }
            if (classificationTypeLookup[classificationType] === undefined) {
              addOption(setClassificationTypeOptions, classificationType);
            }
          }
        }
        /*
        let whoClassifiedPerson;
        let whoClassifiedPublisher;
        let whoClassifiedOrganization;
        let whoClassifiedCopyright;
        let whoClassifiedFreeToShare;
        if (classification["whoClassified"]) {
          if (classification["whoClassified"].person) {
            whoClassifiedPerson = classification["whoClassified"].person.display;
          }
          if (classification["whoClassified"].publisher) {
            whoClassifiedPublisher = classification["whoClassified"].publisher.display;
          }
          if (classification["whoClassified"].organization) {
            whoClassifiedOrganization = classification["whoClassified"].organization.display;
          }
          whoClassifiedCopyright = classification["whoClassified"].classifierCopyright;
          if (classification["whoClassified"].freeToShare !== undefined) {
            if (classification["whoClassified"].freeToShare) {
              whoClassifiedFreeToShare = "Yes";
            } else {
              whoClassifiedFreeToShare = "No";
            }
          } else {              
            //whoClassifiedFreeToShare = "[Undeclared]";
          }
        }*/
        let classifiersCoded = []; //classifiersWithCodingJsonArray, to be kept for when saving back to JSON since this isn't currently editable
        let classifiersCodedDisplay = ""; //classifiersCodeableConceptsWithCodingDisplay, This will be the display in Edit Mode and doesn't include the text elements
        let classifiersCodeableConceptsWithCodingDisplayArray = [];
        let classifiersTexts = []; //classifiersTextsWithNoCodingArray, To populate the dropdown in edit mode with codeableConcepts with only a text element, doesn't include text elements that also have a coding
        //let classifiersTextsDisplay = "";
        let classifiersAllDisplay = "";  //classifiersAllCodeableConceptsDisplay, This will be the display in Read Mode
        let classifiersAllCodeableConceptsDisplayArray = [];
        for (let classifierIndex in classification["classifier"]) {
          let classifier = classification["classifier"][classifierIndex];
          let singleClassifierDisplayArray = [];
          //let classliferCodedEntry = [];
          let hasACoding = false;
          for (let classifierCodingIndex in classifier.coding) {
            if (classifier.coding[classifierCodingIndex].display) {
              hasACoding = true;
              singleClassifierDisplayArray.push(classifier.coding[classifierCodingIndex].display); //
              /*
              let parantheticalDisplay = "";
              if (classifier.coding[classifierCodingIndex].extension) {
                for (let extensionIndex in classifier.coding[classifierCodingIndex].extension) {
  
                  let extension = classifier.coding[classifierCodingIndex].extension[extensionIndex];
                  let extensionTempDisplay = "";
                  if (extension.url === "https://fevir.net/resources/StructureDefinition/21201" && extension.valueCodeableConcept) {
                    for (let qualifierCodingIndex in extension.valueCodeableConcept.coding) {
                      let qualifierCoding = extension.valueCodeableConcept.coding[qualifierCodingIndex];
                      if (qualifierCoding.display) {
                        extensionTempDisplay += qualifierCoding.display + ", ";
                      }
                    }
                    if (extension.valueCodeableConcept.text) {
                      extensionTempDisplay += extension.valueCodeableConcept.text + ", ";
                    }
                    if (extensionTempDisplay) {
                      extensionTempDisplay = extensionTempDisplay.substring(0, extensionTempDisplay.length - 2);
                      if (parantheticalDisplay) {
                        parantheticalDisplay += "; ";
                      }
                      parantheticalDisplay += extensionTempDisplay;
                    }
                  }
                }
                if (parantheticalDisplay) {
                  parantheticalDisplay = " (" + parantheticalDisplay + ")";
                }
              }
              classifiersAllDisplay += classifier.coding[classifierCodingIndex].display + parantheticalDisplay + ", ";
              */
            } else if (classifier.coding[classifierCodingIndex].code) {
              hasACoding = true;
              //singleClassifierDisplayArray.push(classifier.coding[classifierCodingIndex].code); //
            }
          }

          if (hasACoding) { //If a code or display was found in the codeable concept
            classifiersCoded.push(classifier);
            if (classifier.text) {
              singleClassifierDisplayArray.push(classifier.text);
            }
            if (singleClassifierDisplayArray.length > 0) {
              classifiersCodeableConceptsWithCodingDisplayArray.push(singleClassifierDisplayArray.join(", "));
            }
          } else if (classifier.text) { //If only a text element for the codeable concept
            classifiersTexts.push(classifier.text);
            singleClassifierDisplayArray.push(classifier.text);
            addOption(setClassifierOptions, classifier.text);
          }

          if (singleClassifierDisplayArray.length > 0) {
            classifiersAllCodeableConceptsDisplayArray.push(singleClassifierDisplayArray.join(", "));
          }

          /*
          let classifierCode;
          if (classifier.coding && classifier.coding[0]) {
            classifierCode = classifier.coding[0].code;
          }
          classifiers.push(classifierCode);
          */
        }
        classifiersCodedDisplay = classifiersCodeableConceptsWithCodingDisplayArray.join("; ");
        classifiersAllDisplay = classifiersAllCodeableConceptsDisplayArray.join("; ");
        let metaTags = [];
        for (let artifactAssessmentIndex in classification.artifactAssessment) {
          let artifactAssessment = classification.artifactAssessment[artifactAssessmentIndex];
          let metaTag = { "contents": [] };

          metaTag["display"] = artifactAssessment.display || "";

          if (artifactAssessment.reference) {
            if (artifactAssessment.reference.indexOf("#") === 0) {
              let containedClassification = containedResources[artifactAssessment.reference.replace("#", "")];
              if (containedClassification?.json) {
                [metaTag, meshHeading] = createMetaTagFromClassificationArtifactAssessmentJson(metaTag, containedClassification.json, meshHeading);
              }
            } else if (artifactAssessment.reference.indexOf("ArtifactAssessment/") === 0) {
              let foiToRetrieve = artifactAssessment.reference.replace("ArtifactAssessment/", "");
              let retrievedArtifactAssessment = await getResource(foiToRetrieve, "ArtifactAssessment", userState.idToken);
              let json = JSON.parse(retrievedArtifactAssessment.fhirResource);
              if (json && json.resourceType === "ArtifactAssessment") {
                [metaTag, meshHeading] = createMetaTagFromClassificationArtifactAssessmentJson(metaTag, json, meshHeading);
              }
              extraClassificationFoisProcessed.push(foiToRetrieve);
            }
          }
          if (metaTag.contents.length === 0 && metaTag.display === "") {
            metaTag = "EMPTY";
          }
          if (metaTag !== "EMPTY") {
            metaTags.push(metaTag);
          }
        }
        if (meshHeading) {
          //mesh heading classifiers
          //"classifiersCodeableConceptsWithCodingDisplayArray": classifiersCodeableConceptsWithCodingDisplayArray,
          //"classifiersAllCodeableConceptsDisplayArray": classifiersAllCodeableConceptsDisplayArray,
          meshHeadingSet.push({ "originalJson": classification, "classificationType": classificationType, "classifiersCoded": classifiersCoded, "classifiersCodedDisplay": classifiersCodedDisplay, "classifiersTexts": classifiersTexts, "classifiersAllDisplay": classifiersAllDisplay, "metaTags": metaTags });
        } else if (metaTags.length > 0) {   //classification.artifactAssessment?.length > 0
          //compound classifiers
          //"classifiersCodeableConceptsWithCodingDisplayArray": classifiersCodeableConceptsWithCodingDisplayArray,
          //"classifiersAllCodeableConceptsDisplayArray": classifiersAllCodeableConceptsDisplayArray,
          let extraClassificationEntry = { "originalJson": classification, "classificationType": classificationType, "classifiersCoded": classifiersCoded, "classifiersCodedDisplay": classifiersCodedDisplay, "classifiersTexts": classifiersTexts, "classifiersAllDisplay": classifiersAllDisplay, "metaTags": metaTags };
          extraClassifications.push(extraClassificationEntry);
        } else {
          //Simple classifiers
          classifications.push({ "originalJson": classification, "classificationType": classificationType, "classifiersCoded": classifiersCoded, "classifiersCodedDisplay": classifiersCodedDisplay, "classifiersTexts": classifiersTexts, "classifiersAllDisplay": classifiersAllDisplay }); //"whoClassifiedPublisher": whoClassifiedPublisher, "whoClassifiedPerson": whoClassifiedPerson, "whoClassifiedOrganization": whoClassifiedOrganization, "whoClassifiedCopyright": whoClassifiedCopyright, "whoClassifiedFreeToShare": whoClassifiedFreeToShare,
        }
      }

      for (let currentStateIndex in fhirJson.citedArtifact.currentState) {
        for (let codingIndex in fhirJson.citedArtifact.currentState[currentStateIndex].coding) {
          if (fhirJson.citedArtifact.currentState[currentStateIndex].coding[codingIndex].code) {
            currentStates.push(fhirJson.citedArtifact.currentState[currentStateIndex].coding[codingIndex].code);
          }
        }
        if (fhirJson.citedArtifact.currentState[currentStateIndex].text) {
          let currentStateText = fhirJson.citedArtifact.currentState[currentStateIndex].text;
          addOption(setCitedArtifactStatusTypeOptions, currentStateText);
          currentStates.push(currentStateText);
        }
      }

      if (fhirJson.citedArtifact.part?.type) {
        for (let codingIndex in fhirJson.citedArtifact.part.type.coding) {
          if (fhirJson.citedArtifact.part.type.coding[codingIndex].code) {
            partTypes.push(fhirJson.citedArtifact.part.type.coding[codingIndex].code);
          }
        }
        if (fhirJson.citedArtifact.part.type.text) {
          let partText = fhirJson.citedArtifact.part.type.text;
          addOption(setPartTypeOptions, partText);
          partTypes.push(partText);
        }
      }

      for (let statusDateIndex in fhirJson.citedArtifact.statusDate) {
        let statusDate = fhirJson.citedArtifact.statusDate[statusDateIndex];

        let statusDateState = {};
        if (statusDate.actual !== undefined) {
          statusDateState["actual"] = statusDate.actual;
          statusDateState["actualProvided"] = true;
        } else {
          statusDateState["actual"] = false;
          statusDateState["actualProvided"] = false;
        }
        if (statusDate.period) {
          statusDateState["periodStart"] = statusDate.period.start;
          statusDateState["periodEnd"] = statusDate.period.end;
        }
        if (statusDate.activity) {

          if (statusDate.activity.coding && statusDate.activity.coding[0] && (statusDate.activity.coding[0].code || statusDate.activity.coding[0].display)) {
            if (statusDate.activity.coding[0].code) {
              statusDateState["activity"] = statusDate.activity.coding[0].code;
            } else {
              statusDateState["activity"] = statusDate.activity.coding[0].display;
              addOption(setCitedArtifactStatusTypeOptions, statusDate.activity.coding[0].display);
            }
          } else {
            statusDateState["activity"] = statusDate.activity.text;
            addOption(setCitedArtifactStatusTypeOptions, statusDate.activity.text);
          }

        }
        statusDates.push(statusDateState);
      }

      for (let x in fhirJson.citedArtifact.relatesTo) {
        let relatesToStateEntry = { type: "", target: "", classifiersCoded: [], classifiersEditable: [], classifiersCodedDisplay: "", classifiersNonEditableJson: [], classifiersNonEditableDisplay: "", classifiersTexts: [], classifiersAllDisplay: "", targetReferenceType: "", display: "", targetReferenceIdentifier: {}, targetUri: "", isTargetFhirResource: false, editing: false };

        if (fhirJson.citedArtifact.relatesTo[x].type && typeof fhirJson.citedArtifact.relatesTo[x].type === "string") {
          relatesToStateEntry.type = fhirJson.citedArtifact.relatesTo[x].type;
        }

        let classifiersCoded = [];
        let classifiersEditable = [];
        let classifiersCodedDisplay = "";
        let classifiersNonEditableDisplay = "";
        let classifiersNonEditableJson = [];
        let classifiersTexts = [];
        let classifiersAllDisplay = "";
        for (let classifierIndex in fhirJson.citedArtifact.relatesTo[x].classifier) {

          let nonEditable = false;
          let classifier = fhirJson.citedArtifact.relatesTo[x].classifier[classifierIndex];
          let lastCode = "";
          let lastDisplay = "";
          for (let classifierCodingIndex in classifier.coding) {
            if (classifier.coding[classifierCodingIndex].code) {
              if (classifier.coding.length > 1 && classifier.coding[classifierCodingIndex].code) {
                nonEditable = true;
                if (artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code]) {
                  classifiersNonEditableDisplay += artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display + ", ";
                  classifiersCodedDisplay += artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display + ", ";
                  classifiersAllDisplay += artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display + ", ";
                  lastDisplay = artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display;
                } else {
                  classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  classifiersCodedDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  classifiersAllDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  if (classifier.coding[classifierCodingIndex].display) {
                    lastDisplay = classifier.coding[classifierCodingIndex].display;
                  } else {
                    lastCode = classifier.coding[classifierCodingIndex].code;
                  }
                }
              } else if (classifier.coding[classifierCodingIndex].code) {
                if (artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code]) {
                  classifiersCodedDisplay += artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display + ", ";
                  classifiersAllDisplay += artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display + ", ";
                  lastDisplay = artifactClassifierTypeLookup[classifier.coding[classifierCodingIndex].code].display;
                } else {
                  nonEditable = true;
                  classifiersCodedDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  addOption(setArtifactClassifierTypeOptions, classifier.coding[classifierCodingIndex].code);
                  if (classifier.coding[classifierCodingIndex].display) {
                    lastDisplay = classifier.coding[classifierCodingIndex].display;
                    classifiersAllDisplay += classifier.coding[classifierCodingIndex].display + ", ";
                    classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].display + ", ";
                  } else {
                    lastCode = classifier.coding[classifierCodingIndex].code;
                    classifiersAllDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                    classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  }
                }
                if (!(classifier.text && classifier.text !== classifier.coding[classifierCodingIndex].code) || !(classifier.text && classifier.coding[classifierCodingIndex].display && classifier.coding[classifierCodingIndex].display !== classifier.text)) {
                  if (!nonEditable) {
                    classifiersEditable.push(classifier.coding[classifierCodingIndex].code);
                  }
                } else {
                  nonEditable = true;
                  if (classifier.coding[classifierCodingIndex].display) {
                    classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].display + ", ";
                  } else {
                    classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].code + ", ";
                  }
                }
              }
            } else if (classifier.coding[classifierCodingIndex].display) {
              nonEditable = true;
              classifiersCodedDisplay += classifier.coding[classifierCodingIndex].display + ", ";
              classifiersAllDisplay += classifier.coding[classifierCodingIndex].display + ", ";
              classifiersNonEditableDisplay += classifier.coding[classifierCodingIndex].display + ", ";
              lastDisplay = classifier.coding[classifierCodingIndex].display;
            }
          }
          if (!nonEditable && classifier.coding?.length > 0) {
            if (classifier.text && lastDisplay !== classifier.text && lastCode !== classifier.text) {
              classifiersCodedDisplay += classifier.text + ", ";
            } else {
              classifiersCoded.push(classifier);
            }
          } else if (!nonEditable) {  //if (classifier.text && lastDisplay !== classifier.text && lastCode !== classifier.text)
            classifiersTexts.push(classifier.text);
            classifiersEditable.push(classifier.text);
          }
          if (classifier.text && lastDisplay !== classifier.text && lastCode !== classifier.text) {
            if (lastCode || lastDisplay) {
              nonEditable = true;
              classifiersNonEditableDisplay += classifier.text + "; ";
            }
            classifiersAllDisplay += classifier.text + "; ";
            addOption(setArtifactClassifierTypeOptions, classifier.text);
          } else if (classifiersAllDisplay.length > 0) {
            classifiersAllDisplay = classifiersAllDisplay.slice(0, -2);
            classifiersAllDisplay += "; ";
            classifiersNonEditableDisplay = classifiersNonEditableDisplay.slice(0, -2);
            classifiersNonEditableDisplay += "; ";
          }
          if (nonEditable) {
            classifiersNonEditableJson.push(classifier);
          }
        }

        if (classifiersCodedDisplay.length > 0) {
          classifiersCodedDisplay = classifiersCodedDisplay.slice(0, -2);
        }
        if (classifiersAllDisplay.length > 0) {
          classifiersAllDisplay = classifiersAllDisplay.slice(0, -2);
        }
        if (classifiersNonEditableDisplay.length > 0) {
          classifiersNonEditableDisplay = classifiersNonEditableDisplay.slice(0, -2);
        }

        //classifications.push( {"classificationType": classificationType, "whoClassifiedPublisher": whoClassifiedPublisher, "whoClassifiedPerson": whoClassifiedPerson, "whoClassifiedOrganization": whoClassifiedOrganization, "whoClassifiedCopyright": whoClassifiedCopyright, "whoClassifiedFreeToShare": whoClassifiedFreeToShare, "classifiersCoded": classifiersCoded, "classifiersCodedDisplay": classifiersCodedDisplay, "classifiersTexts": classifiersTexts, "classifiersAllDisplay": classifiersAllDisplay } );

        relatesToStateEntry.classifiersCoded = classifiersCoded;
        relatesToStateEntry.classifiersEditable = classifiersEditable;
        relatesToStateEntry.classifiersCodedDisplay = classifiersCodedDisplay;
        relatesToStateEntry.classifiersNonEditableDisplay = classifiersNonEditableDisplay;
        relatesToStateEntry.classifiersTexts = classifiersTexts;
        relatesToStateEntry.classifiersAllDisplay = classifiersAllDisplay;
        relatesToStateEntry.classifiersNonEditableJson = classifiersNonEditableJson;

        if (fhirJson.citedArtifact.relatesTo[x].citation) {
          relatesToStateEntry.citation = fhirJson.citedArtifact.relatesTo[x].citation;
        }
        if (fhirJson.citedArtifact.relatesTo[x].display) {
          relatesToStateEntry.display = fhirJson.citedArtifact.relatesTo[x].display;
        }
        if (fhirJson.citedArtifact.relatesTo[x].label) {
          relatesToStateEntry.label = fhirJson.citedArtifact.relatesTo[x].label;
        }

        if (fhirJson.citedArtifact.relatesTo[x].resourceReference) {

          if (fhirJson.citedArtifact.relatesTo[x].resourceReference.reference) {
            relatesToStateEntry.targetReferenceReference = fhirJson.citedArtifact.relatesTo[x].resourceReference.reference;
            //relatesToState.isTargetFhirResource = true;
          }
          if (relatesToStateEntry.display === "" && fhirJson.citedArtifact.relatesTo[x].resourceReference.display) {
            relatesToStateEntry.display = fhirJson.citedArtifact.relatesTo[x].resourceReference.display;
          }
          /*
          if (fhirJson.citedArtifact.relatesTo[x].resourceReference.type) {
            //relatesToState.resourceReferenceType = fhirJson.citedArtifact.relatesTo[x].resourceReference.type;
          }
          */
          if (fhirJson.citedArtifact.relatesTo[x].resourceReference.identifier) {
            let targetReferenceIdentifier = formatIdentifiersForState(fhirJson.citedArtifact.relatesTo[x].resourceReference.identifier, true);
            targetReferenceIdentifier["resourceReferenceIdentifierIndex"] = 0;
            relatesToStateEntry.targetReferenceIdentifier = targetReferenceIdentifier;
          } else if (relatesToStateEntry.targetReferenceReference?.split("/").length === 2 && !isNaN(relatesToStateEntry.targetReferenceReference?.split("/")[1])) { // || relatesToStateEntry.resourceReferenceReference.split("/").length === 1 //relatesToStateEntry.resourceReferenceReference.indexOf("https://fevir.net/")
            relatesToStateEntry.targetReferenceIdentifier = {
              "resourceReferenceIdentifierIndex": 0,
              "typeText": "FEvIR Object Identifier",
              "assignerDisplay": "Computable Publishing LLC",
              "system": "urn:ietf:rfc:3986",
              "value": "https://fevir.net/FOI/" + relatesToStateEntry.targetReferenceReference.split("/")[1]
            };
          }
          delete fhirJson.citedArtifact.relatesTo[x].resourceReference;
        }
        if (fhirJson.citedArtifact.relatesTo[x].targetReference) {

          if (fhirJson.citedArtifact.relatesTo[x].targetReference.reference) {
            relatesToStateEntry.targetReferenceReference = fhirJson.citedArtifact.relatesTo[x].targetReference.reference;
          }
          if (relatesToStateEntry.display === "" && fhirJson.citedArtifact.relatesTo[x].targetReference.display) {
            relatesToStateEntry.display = fhirJson.citedArtifact.relatesTo[x].targetReference.display;
          }
          if (fhirJson.citedArtifact.relatesTo[x].targetReference.identifier) {
            let targetReferenceIdentifier = formatIdentifiersForState(fhirJson.citedArtifact.relatesTo[x].targetReference.identifier, true);
            targetReferenceIdentifier["resourceReferenceIdentifierIndex"] = 0;
            relatesToStateEntry.targetReferenceIdentifier = targetReferenceIdentifier;
          } else if (relatesToStateEntry.targetReferenceReference?.split("/").length === 2 && !isNaN(relatesToStateEntry.targetReferenceReference?.split("/")[1])) { // || relatesToStateEntry.targetReferenceReference.split("/").length === 1 //relatesToStateEntrytargetReferenceReference.indexOf("https://fevir.net/")
            relatesToStateEntry.targetReferenceIdentifier = {
              "resourceReferenceIdentifierIndex": 0,
              "typeText": "FEvIR Object Identifier",
              "assignerDisplay": "Computable Publishing LLC",
              "system": "urn:ietf:rfc:3986",
              "value": "https://fevir.net/FOI/" + relatesToStateEntry.targetReferenceReference.split("/")[1]
            };
          }
        }
        if (fhirJson.citedArtifact.relatesTo[x].targetUri) {
          relatesToStateEntry.targetUri = fhirJson.citedArtifact.relatesTo[x].targetUri;
        } else if (fhirJson.citedArtifact.relatesTo[x].document?.url) {
          //relatesToState.targetUri = fhirJson.citedArtifact.relatesTo[x].targetUri;
          relatesToStateEntry.targetUri = fhirJson.citedArtifact.relatesTo[x].document.url;
          delete fhirJson.citedArtifact.relatesTo[x].document;
        } else if (relatesToStateEntry.targetReferenceIdentifier) {
          if (relatesToStateEntry.targetReferenceIdentifier.system === "https://fevir.net" && !isNaN(relatesToStateEntry.targetReferenceIdentifier.value)) {
            relatesToStateEntry.targetReferenceReference = "https://fevir.net/" + relatesToStateEntry.targetReferenceIdentifier.value;
          } else if (relatesToStateEntry.targetReferenceIdentifier.system === "https://pubmed.ncbi.nlm.nih.gov" && !isNaN(relatesToStateEntry.targetReferenceIdentifier.value)) {
            relatesToStateEntry.targetReferenceReference = `https://pubmed.ncbi.nlm.nih.gov/${relatesToStateEntry.targetReferenceIdentifier.value}/`;
          } else if (relatesToStateEntry.targetReferenceIdentifier.system === "https://doi.org") {
            if (relatesToStateEntry.targetReferenceIdentifier.value.indexOf("https://") === 0 || relatesToStateEntry.targetReferenceIdentifier.value.indexOf("http://") === 0) {
              relatesToStateEntry.targetReferenceReference = relatesToStateEntry.targetReferenceIdentifier.value;
            } else {
              relatesToStateEntry.targetReferenceReference = `https://doi.org/${relatesToStateEntry.targetReferenceIdentifier.value}`;
            }
          }
        } //else if (relatesToStateEntry.resourceReferenceReference?.split("/").length === 2 && !isNaN(relatesToStateEntry.resourceReferenceReference?.split("/")[1])) {
        //relatesToStateEntry.documentURL = "https://fevir.net/resources/" + relatesToStateEntry.resourceReferenceReference;
        //}
        /*
        if (fhirJson.citedArtifact.relatesTo[x].resource) {
          //relatesToState.resourceReferenceReference = fhirJson.citedArtifact.relatesTo[x].resourceReference.reference;
          //relatesToState.targetURL = fhirJson.citedArtifact.relatesTo[x].resourceReference.reference;
          //relatesToState.isTargetFhirResource = true;
          if (fhirJson.citedArtifact.relatesTo[x].resource.type) {
            //relatesToState.resourceReferenceType = fhirJson.citedArtifact.relatesTo[x].resourceReference.type;
          }
          if (fhirJson.citedArtifact.relatesTo[x].resource.display) {
            //relatesToState.citation = fhirJson.citedArtifact.relatesTo[x].resource.display;
          }
        }
        */

        relatesToSet.push(relatesToStateEntry);
      }

      /*
      let webLocations = [];
      for (let webLocationIndex in fhirJson.citedArtifact.webLocation) {
        let webLocationJson = fhirJson.citedArtifact.webLocation[webLocationIndex];
        let webLocationEntry = {"url": webLocationJson.url, "computableResource": false, "type": [], "typesString": ""};
        if (webLocationJson.type) {
          for (let codingIndex in webLocationJson.type.coding) {
            let coding = webLocationJson.type.coding[codingIndex];
            if (coding.code) {
              webLocationEntry.type.push(coding.code);
              if (articleUrlTypeLookup[coding.code]) {
                webLocationEntry.typesString += articleUrlTypeLookup[coding.code].display + ", ";
              } else {
                webLocationEntry.typesString += coding.code + ", ";
              }
            }
          }
          webLocationEntry.typeText = webLocationJson.type.text;
          if (webLocationJson.type.text?.split(', ').includes("Computable resource")) {
            webLocationEntry.computableResource = true;
            webLocationJson.type.text = webLocationJson.type.text.replaceAll(", Computable resource", "");
            webLocationJson.type.text = webLocationJson.type.text.replaceAl("Computable resource", "");
          }
        }
        if (webLocationEntry.typesString.length > 1) {
          webLocationEntry.typesString = webLocationEntry.typesString.slice(0, -2);
        }
        webLocations.push(webLocationEntry);
      }
      */

      for (let abstractIndex in fhirJson.citedArtifact.abstract) {
        let abstract = fhirJson.citedArtifact.abstract[abstractIndex];
        let abstractLanguage = "";
        if (abstract.language) {
          if (typeof abstract.language === "string") {
            abstractLanguage = abstract.language;
          } else {
            for (let codingIndex in abstract.language.coding) {
              if (abstract.language.coding[codingIndex].code) {
                abstractLanguage = abstract.language.coding[codingIndex].code;
                break;
              }
            }
            if (!abstractLanguage && abstract.language.text) {
              abstractLanguage = abstract.language.text;
            }
          }
        }
        let abstractTypeArray = [];
        if (abstract.type) {
          if (Array.isArray(abstract.type)) {
            for (let abstractTypeFhirJson of abstract.type) {
              if (abstractTypeFhirJson.coding) {
                for (let codingIndex in abstractTypeFhirJson.coding) {
                  if (abstractTypeFhirJson.coding[codingIndex].code) {
                    abstractTypeArray.push(abstractTypeFhirJson.coding[codingIndex].code);
                  }
                }
              }
              if (abstractTypeFhirJson.text) {
                addOption(setAbstractOptions, abstractTypeFhirJson.text);
                abstractTypeArray.push(abstractTypeFhirJson.text);
              }
            }
          } else {
            let abstractTypeFhirJson = abstract.type;
            if (abstractTypeFhirJson.coding) {
              for (let codingIndex in abstractTypeFhirJson.coding) {
                if (abstractTypeFhirJson.coding[codingIndex].code) {
                  abstractTypeArray.push(abstractTypeFhirJson.coding[codingIndex].code);
                }
              }
            }
            if (abstractTypeFhirJson.text) {
              addOption(setAbstractOptions, abstractTypeFhirJson.text);
              abstractTypeArray.push(abstractTypeFhirJson.text);
            }
          }

        } else if (abstractIndex === 0 && abstract.text && abstract.text !== "") {
          abstractTypeArray = ["primary-human-use"];
        }
        abstracts.push({
          "text": abstract.text,
          "abstractLanguage": abstractLanguage,
          "abstractTypeArray": abstractTypeArray,
          "abstractCopyright": abstract.copyright
        });
      }

      if (fhirJson.citedArtifact.dateAccessed) {
        dateAccessed = fhirJson.citedArtifact.dateAccessed;
      }

      if (fhirJson.citedArtifact.baseCitation) {

        if (fhirJson.citedArtifact.baseCitation.reference) {
          baseCitationReference = fhirJson.citedArtifact.baseCitation.reference;
        }
        if (fhirJson.citedArtifact.baseCitation.type) {
          baseCitationType = fhirJson.citedArtifact.baseCitation.type;
        }
        if (fhirJson.citedArtifact.baseCitation.display) {
          baseCitationDisplay = fhirJson.citedArtifact.baseCitation.display;
        }
        if (fhirJson.citedArtifact.baseCitation.identifier) {
          let identifierEntry = formatIdentifiersForState(fhirJson.citedArtifact.baseCitation.identifier, false);
          baseCitationIdentifier.system = identifierEntry["system"];
          baseCitationIdentifier.typeText = identifierEntry["typeText"];
          baseCitationIdentifier.assignerDisplay = identifierEntry["assignerDisplay"];
          baseCitationIdentifier.value = identifierEntry["value"];
        }
      }

      if (fhirJson.citedArtifact.version !== undefined) {
        if (typeof fhirJson.citedArtifact.version === "string") {
          versionValue = fhirJson.citedArtifact.version;
        } else {
          if (fhirJson.citedArtifact.version.value) {
            versionValue = fhirJson.citedArtifact.version.value;
          }
          if (fhirJson.citedArtifact.version.baseCitation) {

            if (fhirJson.citedArtifact.version.baseCitation.reference) {
              baseCitationReference = fhirJson.citedArtifact.version.baseCitation.reference;
            }
            if (fhirJson.citedArtifact.version.baseCitation.type) {
              baseCitationType = fhirJson.citedArtifact.version.baseCitation.type;
            }
            if (fhirJson.citedArtifact.version.baseCitation.display) {
              baseCitationDisplay = fhirJson.citedArtifact.version.baseCitation.display;
            }
            if (fhirJson.citedArtifact.version.baseCitation.identifier) {
              let identifierEntry = formatIdentifiersForState(fhirJson.citedArtifact.version.baseCitation.identifier, false);
              baseCitationIdentifier.system = identifierEntry["system"];
              baseCitationIdentifier.typeText = identifierEntry["typeText"];
              baseCitationIdentifier.assignerDisplay = identifierEntry["assignerDisplay"];
              baseCitationIdentifier.value = identifierEntry["value"];
            }
          }
        }
      }

      if (fhirJson.citedArtifact.part !== undefined) {
        if (fhirJson.citedArtifact.part.value) {
          partValue = fhirJson.citedArtifact.part.value;
        }
        if (fhirJson.citedArtifact.part.baseCitation) {

          if (fhirJson.citedArtifact.part.baseCitation.reference) {
            baseCitationReference = fhirJson.citedArtifact.part.baseCitation.reference;
          }
          if (fhirJson.citedArtifact.part.baseCitation.type) {
            baseCitationType = fhirJson.citedArtifact.part.baseCitation.type;
          }
          if (fhirJson.citedArtifact.part.baseCitation.display) {
            baseCitationDisplay = fhirJson.citedArtifact.part.baseCitation.display;
          }
          if (fhirJson.citedArtifact.part.baseCitation.identifier) {
            let identifierEntry = formatIdentifiersForState(fhirJson.citedArtifact.part.baseCitation.identifier, false);
            baseCitationIdentifier.system = identifierEntry["system"];
            baseCitationIdentifier.typeText = identifierEntry["typeText"];
            baseCitationIdentifier.assignerDisplay = identifierEntry["assignerDisplay"];
            baseCitationIdentifier.value = identifierEntry["value"];
          }
        }
      }

      for (let noteIndex in fhirJson.citedArtifact.note) {
        if (fhirJson.citedArtifact.note[noteIndex].text) {
          notes.push(fhirJson.citedArtifact.note[noteIndex].text);
        }
      }

    }

    let citationURL = "";
    if (fhirJson.url) {
      citationURL = fhirJson.url;
    }

    let citationFOI = "";
    let citationResourceIdentifiers = [];
    for (let identifierIndex in fhirJson.identifier) {
      let identifier = fhirJson.identifier[identifierIndex];
      if (identifier.value) {
        if (identifier.system === "https://fevir.net" || (identifier.type?.text === "FEvIR Object Identifier")) {
          citationFOI = identifier.value;
        }
      }
      let identifierEntry = formatIdentifiersForState(fhirJson.identifier[identifierIndex], false);
      identifierEntry["identifierIndex"] = identifierIndex;
      citationResourceIdentifiers.push(identifierEntry);
    }

    //if (fhirJson.identifier && fhirJson.identifier[0] && fhirJson.identifier[0].value) {
    //  citationIdentifier = fhirJson.identifier[0].value;
    //}
    let status = "unknown";
    if (fhirJson.status) {
      status = fhirJson.status;
    }

    let citationPublisher = "";
    if (fhirJson.publisher) {
      citationPublisher = fhirJson.publisher;
    }

    let contactURL;
    let urlContactIndex;
    let urlTelecomIndex;
    let contactEmail;
    let emailContactIndex;
    let emailTelecomIndex;
    let contactPhone;
    let phoneContactIndex;
    let phoneTelecomIndex;
    let contactOther;
    let otherContactIndex;
    let otherTelecomIndex;
    let firstContact;
    let firstContactIndex;
    let firstTelecomIndex;
    let firstContactWithSystem;
    let firstContactWithSystemSystem;
    let firstWithSystemContactIndex;
    let firstWithSystemTelecomIndex;
    let bestContact;
    let bestContactSystem;
    let bestContactIndex = 0;
    let bestTelecomIndex = 0;
    //let contacts = [];

    for (let contactIndex in fhirJson.contact) {
      //let telecoms = [];
      for (let telecomIndex in fhirJson.contact[contactIndex].telecom) {
        let telecom = fhirJson.contact[contactIndex].telecom[telecomIndex];
        let telecomValue = telecom.value;
        let telecomSystem = telecom.system;
        if (telecomValue) {
          if (firstContact === undefined) {
            firstContact = telecomValue;
            firstContactIndex = contactIndex;
            firstTelecomIndex = telecomIndex;
          }
          if (contactURL === undefined && telecomSystem === "url") {
            contactURL = telecomValue;
            urlContactIndex = contactIndex;
            urlTelecomIndex = telecomIndex;
          }
          if (contactEmail === undefined && telecomSystem === "email") {
            contactEmail = telecomValue;
            emailContactIndex = contactIndex;
            emailTelecomIndex = telecomIndex;
          }
          if (contactPhone === undefined && telecomSystem === "phone") {
            contactPhone = telecomValue;
            phoneContactIndex = contactIndex;
            phoneTelecomIndex = telecomIndex;
          }
          if (contactOther === undefined && telecomSystem === "other") {
            contactOther = telecomValue;
            otherContactIndex = contactIndex;
            otherTelecomIndex = telecomIndex;
          }
          if (firstContactWithSystem === undefined && telecomSystem) {
            firstContactWithSystem = telecomValue;
            firstContactWithSystemSystem = telecomSystem;
            firstWithSystemContactIndex = contactIndex;
            firstWithSystemTelecomIndex = telecomIndex;
          }
        }
        //telecoms.push({"value": telecomValue, "system": telecomSystem});
      }
      //contacts.push(telecoms);
    }

    if (contactURL) {
      bestContact = contactURL;
      bestContactSystem = "url";
      bestContactIndex = urlContactIndex;
      bestTelecomIndex = urlTelecomIndex;
    } else if (contactEmail) {
      bestContact = contactEmail;
      bestContactSystem = "email";
      bestContactIndex = emailContactIndex;
      bestTelecomIndex = emailTelecomIndex;
    } else if (contactPhone) {
      bestContact = contactPhone;
      bestContactSystem = "phone";
      bestContactIndex = phoneContactIndex;
      bestTelecomIndex = phoneTelecomIndex;
    } else if (contactOther) {
      bestContact = contactOther;
      bestContactSystem = "other";
      bestContactIndex = otherContactIndex;
      bestTelecomIndex = otherTelecomIndex;
    } else if (firstContactWithSystem) {
      bestContact = firstContactWithSystem;
      bestContactSystem = firstContactWithSystemSystem;
      bestContactIndex = firstWithSystemContactIndex;
      bestTelecomIndex = firstWithSystemTelecomIndex;
    } else if (firstContact) {
      bestContact = firstContact;
      bestContactIndex = firstContactIndex;
      bestTelecomIndex = firstTelecomIndex;
    }

    bestContactIndex = parseInt(bestContactIndex);
    bestTelecomIndex = parseInt(bestTelecomIndex);

    let citationDate = "";
    if (fhirJson.date) {
      citationDate = fhirJson.date;
    }
    let citationPurpose = "";
    if (fhirJson.purpose) {
      citationPurpose = fhirJson.purpose;
    }
    let citationCopyright = "";
    if (fhirJson.copyright) {
      citationCopyright = fhirJson.copyright;
    }
    let citationEffectivePeriod = "";
    if (fhirJson.effectivePeriod) {
      citationEffectivePeriod = fhirJson.effectivePeriod;
    }
    let citationRelatedArtifact = "";
    if (fhirJson.relatedArtifact) {
      citationRelatedArtifact = fhirJson.relatedArtifact;
    }
    if (fhirJson.relatesTo) {
      citationRelatedArtifact = fhirJson.relatesTo;
    }
    let citationNote = "";
    if (fhirJson.note) {
      citationNote = fhirJson.note;
    }

    let citationVersion = "";
    if (fhirJson.version) {
      citationVersion = fhirJson.version;
    }
    let fevirPlatformUse = false;

    let fevirPlatformUseClassifierCode = "";
    let fevirPlatformUseClassifierDisplay = "";
    for (let classificationIndex in fhirJson.classification) {
      let classification = fhirJson.classification[classificationIndex];
      let fevirPlatformUseInThisClassification = false;
      if (classification.type) {
        for (let codingIndex in classification.type.coding) {
          let coding = classification.type.coding[codingIndex];
          if (coding.code === "fevir-platform-use" || coding.display === "FEvIR Platform Use") {
            fevirPlatformUse = true;
            fevirPlatformUseInThisClassification = true;
            break;
          }
        }
        if (fevirPlatformUseInThisClassification) {
          for (let classifierIndex in classification.classifier) {
            let classifier = classification.classifier[classifierIndex];
            for (let codingIndex in classifier.coding) {
              let coding = classifier.coding[codingIndex];
              if (coding.display) {
                fevirPlatformUseClassifierDisplay = coding.display;
                fevirPlatformUseClassifierCode = coding.code;
              }
            }
          }
        }
      }
    }

    let citationUseContext = "";
    if (fhirJson.useContext) {
      citationUseContext = fhirJson.useContext;
      let fevirPlatformUseContextDetected = false;
      try {
        for (let useContext of fhirJson.useContext) {
          if (useContext.code.code === "fevir-platform-use") {
            fevirPlatformUseClassifierCode = useContext.valueCodeableConcept.coding[0].code;
            fevirPlatformUseClassifierDisplay = useContext.valueCodeableConcept.coding[0].display;
            fevirPlatformUseContextDetected = true;
          }
        }
        if (fevirPlatformUseContextDetected === false && fevirPlatformUse === true) {
          citationUseContext.push({
            "code": {
              "system": "http://hl7.org/fhir/citation-classification-type",
              "code": "fevir-platform-use",
              "display": "FEvIR Platform Use"
            },
            "valueCodeableConcept":
            {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/citation-artifact-classifier",
                  "code": fevirPlatformUseClassifierCode || "project-specific",
                  "display": fevirPlatformUseClassifierDisplay || "Project Specific"
                }
              ]
            }
          })
        }
      } catch { }
    } else if (fevirPlatformUseClassifierCode || fevirPlatformUseClassifierDisplay) {
      citationUseContext = [{
        "code": {
          "system": "http://hl7.org/fhir/citation-classification-type",
          "code": "fevir-platform-use",
          "display": "FEvIR Platform Use"
        },
        "valueCodeableConcept":
        {
          "coding": [
            {
              "system": "http://hl7.org/fhir/citation-artifact-classifier",
              "code": fevirPlatformUseClassifierCode || "project-specific",
              "display": fevirPlatformUseClassifierDisplay || "Project Specific"
            }
          ]
        }
      }]
    }

    let citationCurrentStates = [];
    for (let x in fhirJson.currentState) {
      for (let codingIndex in fhirJson.currentState[x].coding) {
        if (fhirJson.currentState[x].coding[codingIndex].code && citationStatusTypeLookup[fhirJson.currentState[x].coding[codingIndex].code]) {
          citationCurrentStates.push(fhirJson.currentState[x].coding[codingIndex].code);
        } else if (fhirJson.currentState[x].coding[codingIndex].display) {
          if (citationStatusTypeDisplayLookup[fhirJson.currentState[x].coding[codingIndex].display]) {
            citationCurrentStates.push(citationStatusTypeDisplayLookup[fhirJson.currentState[x].coding[codingIndex].display].code);
          } else {
            citationCurrentStates.push(fhirJson.currentState[x].coding[codingIndex].display);
            addOption(setCitationStatusTypeOptions, fhirJson.currentState[x].coding[codingIndex].display);
          }
        }
      }
      if (fhirJson.currentState[x].text) {
        let currentStateText = fhirJson.currentState[x].text;
        citationCurrentStates.push(currentStateText);
        addOption(setCitationStatusTypeOptions, currentStateText);
      }
    }

    let citationStatusDates = [];
    for (let statusDateIndex in fhirJson.statusDate) {
      let statusDate = fhirJson.statusDate[statusDateIndex];
      let statusDateState = { "activity": "", "actual": false, "actualProvided": false, "periodStart": "", "periodEnd": "" };
      if (statusDate.actual !== undefined) {
        statusDateState["actual"] = statusDate.actual;
        statusDateState["actualProvided"] = true;
      }
      if (statusDate.period) {
        if (statusDate.period.start) {
          statusDateState["periodStart"] = statusDate.period.start;
        }
        if (statusDate.period.end) {
          statusDateState["periodEnd"] = statusDate.period.end;
        }
      }
      if (statusDate.activity) {
        if (statusDate.activity.coding && statusDate.activity.coding[0]) {
          if (statusDate.activity.coding[0].code) {
            statusDateState["activity"] = statusDate.activity.coding[0].code;
          } else if (statusDate.activity.coding[0].display) {
            if (citationStatusTypeDisplayLookup[statusDate.activity.coding[0].display]) {
              statusDateState["activity"] = citationStatusTypeDisplayLookup[statusDate.activity.coding[0].display].code;
            } else {
              statusDateState["activity"] = statusDate.activity.coding[0].display;
              addOption(setCitationStatusTypeOptions, statusDate.activity.coding[0].display);
            }
          }
        }
        if (statusDateState["activity"] === "" && statusDate.activity.text) {
          statusDateState["activity"] = statusDate.activity.text;
          addOption(setCitationStatusTypeOptions, statusDate.activity.text);
        }
      }
      citationStatusDates.push(statusDateState);
    }

    let citationResourceContributors = [];
    let citationResourceContributorsByRole = { "Creator": "", "Editor": "", "Reviewer": "", "Endorser": "", "Recorder": "" };
    let citationResourceContributorsRoleCount = { "Creator": 0, "Editor": 0, "Reviewer": 0, "Endorser": 0, "Recorder": 0 };
    let citationResourceContributorsDisplayString = "";
    let roleLookup = { "author": "Creator", "editor": "Editor", "reviewer": "Reviewer", "endorser": "Endorser", "recorder": "Recorder" };
    for (let role in roleLookup) {
      if (fhirJson[role]) {
        for (let x in fhirJson[role]) {
          let name = fhirJson[role][x].name;
          if (name) {
            citationResourceContributors.push({ role: roleLookup[role], name: name });
            citationResourceContributorsByRole[roleLookup[role]] += name + ", ";
            citationResourceContributorsRoleCount[roleLookup[role]] += 1;
          }
        }
      }
    }

    for (let role in citationResourceContributorsByRole) {
      if (citationResourceContributorsByRole[role].length > 0) {
        if (citationResourceContributorsRoleCount[role] == 1) {
          citationResourceContributorsDisplayString += citationResourceContributorsByRole[role].slice(0, -2) + " (" + role + "), ";
        } else {
          citationResourceContributorsDisplayString += citationResourceContributorsByRole[role].slice(0, -2) + " (" + role + "s), ";
        }

      }
    }
    if (citationResourceContributorsDisplayString.length > 0) {
      citationResourceContributorsDisplayString = citationResourceContributorsDisplayString.slice(0, -2);
    }

    let approvalDate = "";
    if (fhirJson.approvalDate) {
      approvalDate = fhirJson.approvalDate;
    } else if (fhirJson.dateApproved) {
      approvalDate = fhirJson.dateApproved;
    }
    let lastReviewDate = "";
    if (fhirJson.lastReviewDate) {
      lastReviewDate = fhirJson.lastReviewDate;
    }
    let citationDescription = "";
    if (fhirJson.description) {
      citationDescription = fhirJson.description;
    }
    let citationTitle = "";

    if (fhirJson.title && typeof fhirJson.title === "string" && fhirJson.title.trim()) {
      citationTitle = fhirJson.title;
    } else if (fhirJson.name && typeof fhirJson.name === "string" && fhirJson.name.trim()) {
      citationTitle = fhirJson.name;
    }

    /* //I am using fhirEntryState.status instead
    let citationStatus = "";
    if (fhirJson.status) {
      citationTitle = fhirJson.status;
    }*/

    setCitationState(prevState => {
      return {
        ...prevState,
        meta: fhirJson.meta,
        invalid: false,
        identifiers: identifiers,
        relatedIdentifiers: relatedIdentifiers,
        titles: titles,
        webLocations: webLocations,
        meshHeadingSet: meshHeadingSet,
        currentStates: currentStates,
        statusDates: statusDates,
        abstracts: abstracts,
        dateAccessed: dateAccessed,
        versionValue: versionValue,
        baseCitationReference: baseCitationReference,
        baseCitationType: baseCitationType,
        baseCitationDisplay: baseCitationDisplay,
        baseCitationIdentifier: baseCitationIdentifier,
        partTypes: partTypes,
        partValue: partValue,
        relatesToSet: relatesToSet,
        notes: notes,
        citationMetadataEditMode: false,
        citationURL: citationURL,
        citationFOI: citationFOI,
        citationResourceIdentifiers: citationResourceIdentifiers,
        citationDate: citationDate,
        citationPurpose: citationPurpose,
        citationCopyright: citationCopyright,
        citationEffectivePeriod: citationEffectivePeriod,
        citationRelatedArtifact: citationRelatedArtifact,
        citationNote: citationNote,
        citationVersion: citationVersion,
        status: status,
        citationPublisher: citationPublisher,
        bestContact: bestContact,
        bestContactSystem: bestContactSystem,
        bestContactIndex: bestContactIndex,
        bestTelecomIndex: bestTelecomIndex,
        fevirPlatformUseClassifierDisplay: fevirPlatformUseClassifierDisplay,
        fevirPlatformUseClassifierCode: fevirPlatformUseClassifierCode,
        approvalDate: approvalDate,
        lastReviewDate: lastReviewDate,
        citationUseContext: citationUseContext,
        citationCurrentStates: citationCurrentStates,
        citationStatusDates: citationStatusDates,
        citationResourceContributors: citationResourceContributors,
        citationResourceContributorsDisplayString: citationResourceContributorsDisplayString,
        citationDescription: citationDescription,
        citationTitle: citationTitle,
        affiliationsList: affiliationsList,                                       //TO do delete this later March 18, 2022
        affiliationsReferencesList: affiliationsReferencesList,                   //TO do delete this later March 18, 2022
        affiliationDictionary: affiliationDictionary,
        citationLoaded: true
      };
    });

    setCitationContributorsState(prevState => {
      return {
        ...prevState,
        contributorshipComplete: contributorshipComplete,
        contributorshipCompleteProvided: contributorshipCompleteProvided,
        contributorshipSummaries: contributorshipSummaries,
        //contributorshipIndividuals: contributorshipIndividuals
      };
    });


    setCitationPublicationFormsState(prevState => {
      return {
        ...prevState,
        publicationForms: publicationForms,
      };
    });

    //return fhirJson;
    return fevirPlatformUseClassifierCode;

  } catch (e) {
    setCitationState(prevState => { return { ...prevState, invalid: true }; });
  }
  return;
}

const citationTextViewSummaryCalculations = async (fhirJson, setCitationState, changeSummaryStyleVisibility, globalContext) => {
  let computablePublishingSummary = "";
  let otherSummaries = [];
  if (fhirJson) {

    const body = {
      'functionid': 'generatecomputablepublishingsummary',
      'citation': fhirJson,
      'idToken': ''
    };
    let response;
    try {
      response = await submitToFevirServer(globalContext, 8000, body, true);
      if (response.success) {
        computablePublishingSummary = response.computablePublishingSummary;
      }
    } catch (e) { }

    for (let summaryIndex in fhirJson.summary) {
      let summaryText = fhirJson.summary[summaryIndex].text;
      let lastCodingCode;
      //let lastCodingSystem;
      let lastCodingDisplay;
      let summaryStyleText = "";
      if (fhirJson.summary[summaryIndex].style) {
        summaryStyleText = fhirJson.summary[summaryIndex].style.text;
        for (let styleCodingIndex in fhirJson.summary[summaryIndex].style.coding) {
          if (fhirJson.summary[summaryIndex].style.coding[styleCodingIndex]) {
            lastCodingCode = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].code;
            //lastCodingSystem = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].system;
            lastCodingDisplay = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].display;
          }
        }
      }
      if (lastCodingCode !== "comppub") {
        let humanReadableStyle = lastCodingDisplay || summaryStyleText || lastCodingCode || "[Not entered]";
        //"summaryJSON": fhirJson.summary[summaryIndex],
        let summaryCustomStyleName = "";
        let hasCustomStyleName = false;
        if (humanReadableStyle in customStyleNames) {
          summaryCustomStyleName = humanReadableStyle;
          hasCustomStyleName = true;
        }
        let isGeneratedTextAvailable = humanReadableStyle in generatedSummaryTexts;
        let generatedSummaryText = summaryText;
        if (isGeneratedTextAvailable) {
          const body = {
            'functionid': 'generatetextsummary',
            'citation': fhirJson,
            'style': humanReadableStyle,
            'idToken': ''
          };
          let response;
          try {
            response = await submitToFevirServer(globalContext, 8000, body, true, false);
            if (response.success) {
              generatedSummaryText = response.generatedSummary;
            }
          } catch (e) { }
        }
        let otherSummaryEntry = {
          "summaryJSONIndex": summaryIndex,
          "hasCustomStyleName": hasCustomStyleName,
          "summaryCustomStyleName": summaryCustomStyleName,
          "summaryText": summaryText,
          "humanReadableStyle": humanReadableStyle,
          "generatedSummaryTextAvailable": isGeneratedTextAvailable,
          "generatedSummaryText": generatedSummaryText,
          "hide": false
        };
        changeSummaryStyleVisibility(humanReadableStyle, true);
        otherSummaries.push(otherSummaryEntry);
      }

    }
  }

  setCitationState(prevState => {
    return {
      ...prevState,
      computablePublishingSummary: computablePublishingSummary,
      otherSummaries: otherSummaries,
      summariesLoaded: true,
    };
  });
}

const citationTextViewClassifierCalculations = async (fhirJson, fevirPlatformUseClassifierCode, resourceId, setCitationState,
  globalContext, classifications, extraClassifications, extraClassificationFoisProcessed) => {

  let externalClassifications = [];
  if (fhirJson) {
    let body = {
      functionid: 'getexternalclassificationsfromindex',
      'idToken': '',
      "contextInfo": { 'citationClassifications': true },
      "fhirJson": fhirJson,
      "externalClassifications": [],
      "resourceId": resourceId,
      "extraClassificationFoisProcessed": extraClassificationFoisProcessed,
      "fevirPlatformUseClassifierCode": fevirPlatformUseClassifierCode
    }
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success && response.externalClassifications) {
      externalClassifications = response.externalClassifications;
    }
  }
  setCitationState(prevState => {
    return {
      ...prevState,
      classifications: classifications,
      extraClassifications: extraClassifications,
      externalClassifications: externalClassifications,
      classificationsLoaded: true
    };
  });
}

const builderUpdateCitationJson = async (resourceId, fhirEntryState, formInputsStateRef, globalContext) => {
  let citationState = formInputsStateRef.current.citationState;
  let citationPublicationFormsState = formInputsStateRef.current.citationPublicationFormsState;
  let citationContributorsState = formInputsStateRef.current.citationContributorsState;
  let citationIndividualContributorsState = formInputsStateRef.current.citationIndividualContributorsState;

  let fhirJson = jsonErrorCheck("Citation", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, citationState.citationURL);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (fhirEntryState.activeIndex == 0) {
    fhirJson = citationTextViewChangesToJson(fhirJson, citationState, citationPublicationFormsState, citationContributorsState, citationIndividualContributorsState);
  }
  //GENERATED STUFF LAST
  let computablePublishingSummaryInserted = false;
  if (fhirJson.summary) {
  } else {
    fhirJson.summary = [];
  }
  let otherSummaries;
  if (fhirEntryState.activeIndex == 1) {
    otherSummaries = [];
    for (let summaryIndex in fhirJson.summary) {
      let summaryText = fhirJson.summary[summaryIndex].text;
      let lastCodingCode;
      //let lastCodingSystem;
      let lastCodingDisplay;
      let summaryStyleText = "";
      if (fhirJson.summary[summaryIndex].style) {
        summaryStyleText = fhirJson.summary[summaryIndex].style.text;
        for (let styleCodingIndex in fhirJson.summary[summaryIndex].style.coding) {
          if (fhirJson.summary[summaryIndex].style.coding[styleCodingIndex]) {
            lastCodingCode = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].code;
            //lastCodingSystem = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].system;
            lastCodingDisplay = fhirJson.summary[summaryIndex].style.coding[styleCodingIndex].display;
          }
        }
      }
      if (lastCodingCode !== "comppub") {
        let humanReadableStyle = lastCodingDisplay || summaryStyleText || lastCodingCode || "[Not entered]";
        //"summaryJSON": fhirJson.summary[summaryIndex],
        let summaryCustomStyleName = "";
        let hasCustomStyleName = false;
        if (humanReadableStyle in customStyleNames) {
          summaryCustomStyleName = humanReadableStyle;
          hasCustomStyleName = true;
        }
        let isGeneratedTextAvailable = humanReadableStyle in generatedSummaryTexts;
        let generatedSummaryText = summaryText;
        if (isGeneratedTextAvailable) {
          const body = {
            'functionid': 'generatetextsummary',
            'citation': fhirJson,
            'style': humanReadableStyle,
            'idToken': ''
          };
          let response;
          try {
            response = await submitToFevirServer(globalContext, 8000, body, true, false);
            if (response.success) {
              generatedSummaryText = response.generatedSummary;
            }
          } catch (e) { }
        }
        let otherSummaryEntry = {
          "summaryJSONIndex": summaryIndex,
          "hasCustomStyleName": hasCustomStyleName,
          "summaryCustomStyleName": summaryCustomStyleName,
          "summaryText": summaryText,
          "humanReadableStyle": humanReadableStyle,
          "generatedSummaryTextAvailable": isGeneratedTextAvailable,
          "generatedSummaryText": generatedSummaryText,
          "hide": false
        };
        otherSummaries.push(otherSummaryEntry);
      }
    }
  } else {
    otherSummaries = citationState.otherSummaries;
  }

  let computablePublishingSummary = "";
  const body = {
    'functionid': 'generatecomputablepublishingsummary',
    'citation': fhirJson,
    'idToken': ''
  };
  let response;
  try {
    response = await submitToFevirServer(globalContext, 8000, body, true, false);
    if (response.success) {
      computablePublishingSummary = response.computablePublishingSummary;
    }
  } catch (e) { }

  for (let x in otherSummaries) {
    let summary = otherSummaries[x];
    if (summary.hide === false) {
      let summaryJSONIndex = summary.summaryJSONIndex;
      let styleText;
      if (summary.hasCustomStyleName) {
        styleText = summary.summaryCustomStyleName;
      } else {
        styleText = summary.humanReadableStyle;
      }
      let summaryText = summary.generatedSummaryText || summary.summaryText;
      /* //DELETE THIS Commented out on July 6, 2022
      let summaryText = "";
      if (summary.generatedSummaryTextAvailable) {
        summaryText = summary.generatedSummaryText || generateTextSummary(fhirJson, summary.humanReadableStyle);
      } else {
        summaryText = summary.summaryText;
      }*/
      if (summaryJSONIndex !== null && summaryJSONIndex < fhirJson.summary.length) {
        fhirJson.summary[summaryJSONIndex].text = summaryText;
        if (styleText) {
          if (fhirJson.summary[summaryJSONIndex].style === undefined) {
            fhirJson.summary[summaryJSONIndex].style = {};
          }
          fhirJson.summary[summaryJSONIndex].style.text = styleText;
        }
      } else {
        fhirJson.summary.push({
          "style": { "text": styleText },
          "text": summaryText
        });
      }
    }
  }
  if (fhirJson.summary) {
    fhirJson.summary.some((summary) => {
      if (summary.style?.coding) {
        summary.style.coding.some((coding) => {
          let summaryCode;
          if (coding.code) {
            summaryCode = coding.code;
          } else {
            summaryCode = summary.style.text;
          }
          if (summaryCode) {
            summaryCode = summaryCode.toLowerCase().replace(/ /g, "");  //Replace all spaces with nothing
            if (summaryCode === "comppub") {
              summary.text = computablePublishingSummary;
              computablePublishingSummaryInserted = true;
            }
          }
        });
      }
    });
  } else {
    fhirJson.summary = [];
  }

  if (computablePublishingSummaryInserted === false) {
    fhirJson.summary.push({
      "style": {
        //"coding": [ { "system": "http://terminology.hl7.org/ValueSet/citation-summary-style", "code": "comppub", "display": "Computable Publishing" } ]
        "coding": [{ "system": "http://hl7.org/fhir/citation-summary-style", "code": "comppub", "display": "Computable Publishing" }]
      },
      "text": computablePublishingSummary
    });
  }
  return fhirJson;
}

const getExternalClassificationsFromIndex = (externalClassifications, indexEntriesForTargetFoi, extraClassificationFoisProcessed, classificationResourcesFoiList) => {
  if (!classificationResourcesFoiList) {
    classificationResourcesFoiList = [];
  }
  for (const entry of indexEntriesForTargetFoi) {
    if (!classificationResourcesFoiList.includes(entry.sourcefoi) && !extraClassificationFoisProcessed.includes(entry.sourcefoi)) {
      classificationResourcesFoiList.push(entry.sourcefoi);
    }
  }
  let metaTags = [];
  if (classificationResourcesFoiList.length > 0) {
    for (const classificationfoi of classificationResourcesFoiList) {
      let indexEntriesForSourceFoi = indexEntriesForTargetFoi.filter(entry => entry.sourcefoi == classificationfoi).sort((a, b) => {
        if (a.datecreated && b.datecreated) {
          if (a.datecontentcreated.toLowerCase() > b.datecontentcreated.toLowerCase()) return -1;
          if (a.datecontentcreated.toLowerCase() < b.datecontentcreated.toLowerCase()) return 1;
        }
        return 0;
      });
      if (indexEntriesForSourceFoi.length > 0) {
        let metaTag = { "contents": [], "display": indexEntriesForSourceFoi[0].sourcetitle || "[UNTITLED]" };
        if (indexEntriesForSourceFoi[0].sourcecopyright) {
          metaTag["copyright"] = indexEntriesForSourceFoi[0].sourcecopyright;
        }
        let classificationBitIndex = {};
        let untypedEntryCounter = 0;
        for (const entry of indexEntriesForSourceFoi) {
          let entryType = entry.typedisplay || entry.typetext || entry.typecode;
          let metaTagContent;
          if (entryType) {
            if (classificationBitIndex[entryType]) {
              metaTagContent = classificationBitIndex[entryType];
            } else {
              metaTagContent = {
                "classifiersDisplayString": "", "qualifiersDisplay": "", "freeToShare": entry.freetoshare,
                "authorDisplay": entry.authordisplay || entry.authorreference + " (This will be replaced with the author display)" || "",
                "typeDisplay": entryType
              };
              classificationBitIndex[entryType] = metaTagContent;
              metaTag["contents"].push(metaTagContent);
            }
          } else {
            untypedEntryCounter += 1;
            metaTagContent = {
              "classifiersDisplayString": "", "qualifiersDisplay": "", "freeToShare": entry.freetoshare,
              "authorDisplay": entry.authordisplay || entry.authorreference + " (This will be replaced with the author display)" || ""
            };
            classificationBitIndex["Untyped Entry " + untypedEntryCounter.toString()] = metaTagContent;
            metaTag["contents"].push(metaTagContent);
          }

          let classifierDisplay = entry.classifierdisplay || "";
          if (entry.classifiercode) {
            classifierDisplay += " (coded as " + entry.classifiercode + ")";
          }
          if (entry.classifiertext) {
            if (classifierDisplay) {
              classifierDisplay += ", " + entry.classifiertext;
            } else {
              classifierDisplay = entry.classifiertext;
            }
          }
          if (classifierDisplay) {
            if (metaTagContent["classifiersDisplayString"]) {
              metaTagContent["classifiersDisplayString"] = metaTagContent["classifiersDisplayString"] + "; " + classifierDisplay;
            } else {
              metaTagContent["classifiersDisplayString"] = classifierDisplay;
            }
          }

          if (entry.componentjsonstring) {
            let componentJson = JSON.parse(entry.componentjsonstring);
            let qualifierDisplayArray = [];
            for (let component of componentJson) {
              if (component.type?.text.toLowerCase() === "qualifier") {
                let qualifierString = "";
                if (component.classifier) {
                  qualifierString = getCodeableConceptsDisplay(component.classifier);
                }
                for (let subComponentIndex in component.component) {
                  let subComponent = component.component[subComponentIndex];
                  if (subComponent.type.text) {
                    for (let subComponentClassifierIndex in subComponent.classifier) {
                      let subComponentClassifier = subComponent.classifier[subComponentClassifierIndex];
                      for (let subComponentClassifierCodingIndex in subComponentClassifier.coding) {
                        let subComponentClassifierCoding = subComponentClassifier.coding[subComponentClassifierCodingIndex];
                        let code = subComponentClassifierCoding.code;
                        if (code.toUpperCase() === "Y" || code.toLowerCase() === "yes" || code.toLowerCase() === "is-major-topic" || code.toLowerCase() === "ismajortopic") {
                          qualifierString += ` (${subComponent.type.text})`;
                        }
                      }
                    }
                  }
                }
                if (qualifierString) {
                  qualifierDisplayArray.push(qualifierString);
                }
              }
            }
            if (qualifierDisplayArray.length > 0) {
              if (metaTagContent["qualifiersDisplay"]) {
                metaTagContent["qualifiersDisplay"] = metaTagContent["qualifiersDisplay"] + "; " + qualifierDisplayArray.join("; ");
              } else {
                metaTagContent["qualifiersDisplay"] = qualifierDisplayArray.join("; ");
              }
            }
          }
        }
        metaTags.push(metaTag);
      }
    }
  }
  if (metaTags.length > 0) {
    let externalClassificationEntry = { "metaTags": metaTags };
    externalClassifications.push(externalClassificationEntry);
  }
  return { 'externalClassifications': externalClassifications, 'classificationResourcesFoiList': classificationResourcesFoiList };
}

export { formatIdentifiersForState, getWebLocation, CitationPublicationFormsTextDisplay, ContributorshipIndividualsRows, CitationContributorsTextDisplay, citationTextViewChangesToJson, citationTextViewCalculations, citationTextViewSummaryCalculations, citationTextViewClassifierCalculations, builderUpdateCitationJson };