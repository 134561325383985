import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import "firebase/compat/auth";
import axios from 'axios';
import server_config from './ServerConfiguration';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
const go_server_address = server_config["go_server_address"];

const CtisConvertPage = ({ useTitle, urlCTISid, ctisIdFill }) => {

  useTitle("FEvIR EU CTIS-to-FEvIR Converter");
  const globalContext = useContext(FevirContext);

  const [CTISEntryState, setCTISEntryState] = useState({ "ctisid": urlCTISid || ctisIdFill, "loadingSubmission": false });

  const changeCTISEntryState = (newValue, field) => {
    setCTISEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const submitCtisId = async (ctisid, redirect) => {
    changeCTISEntryState(true, "loadingSubmission");
    if (ctisid === undefined) {
      ctisid = CTISEntryState.ctisid;
    }
    if (redirect === undefined) {
      redirect = true;
    }

    if (ctisid) {
      changeCTISEntryState(true, "loadingSubmission");
    } else {
      globalContext.openAlert({ header: "Please enter a EU CTIS ID.", content: "" });
    }

    const body = {
      'functionid': "submitctisid",
      'tool': "ctistofhirconverter",
      'idToken': "",
      'ctisid': ctisid || "",
      'addtodatabase': true,
      'status': "active",
    };

    let response = await submitToFevirServer(globalContext, 120000, body, true, false);

    if (response?.success) {
      if (redirect) {
        if (response.jobAlreadyRunning && response.message) {
          globalContext.openAlert({ "header": "Job Currently In Process", "content": response.message });
        } else if (response.numberOfResourcesCreated) {
          if (response.largeCollection) {
            const messageContent = "The EU CTIS record was converted to " + response.numberOfResourcesCreated + " FHIR Resources. Click out to view the ResearchStudy Resource. Because the number of Resources created is so large, it may take a few minutes before the referenced Resources are loaded to the FEvIR Platform.";
            globalContext.openAlert({ "header": "Processing", "content": messageContent });
          } else {
            const messageContent = "The EU CTIS record was converted to " + response.numberOfResourcesCreated + " FHIR Resources. Click out to view the ResearchStudy Resource.";
            globalContext.openAlert({ "header": "Complete", "content": messageContent });
          }
        } else if (response.message) {
          globalContext.openAlert({ "header": "", "content": response.message });
        }
        if (response.researchstudyfoi) {
          history.push(`/resources/ResearchStudy/${response.researchstudyfoi}`);
        }
      }
    } else if (response?.errormessage) {
      changeCTISEntryState(false, "loadingSubmission");
      globalContext.openAlert({ "header": "Error", "content": response.errormessage });
    } else {
      changeCTISEntryState(false, "loadingSubmission");
      console.log(response)
      const messageContent = "We were unable to convert this item. Perhaps this EU CTIS ID: " + ctisid + " doesn't exist or there are issues in its structure.";
      globalContext.openAlert({ "header": "Error", "content": messageContent });
    }

  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      submitCtisId();
    }
  }

  useEffect(async () => {
    if (globalContext.userState.loading === false) {
      if (urlCTISid) {
        submitCtisId();
        history.replace("/ctisconvert");
      } else if (ctisIdFill) {
        history.replace("/ctisconvert");
      }
    }
  }, [globalContext.userState]);

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: EU CTIS-to-FEvIR Converter</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br /><br />
        {/*I made it so you don't need to be logged in.*/}
        {true || globalContext.userState.firebaseuid ?
          <>
            <TextField style={{ width: "180px" }} className="inputField" type='text' size="small" variant='outlined'
              placeholder='EU CTIS ID' value={CTISEntryState.ctisid} onKeyUp={handleKeyUp}
              onChange={(e) => { changeCTISEntryState(e.target.value, "ctisid"); }} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button className="formButton positive" content="Submit"
              compact onClick={() => submitCtisId()} disabled={CTISEntryState.loadingSubmission} />
            <br />
            <br />
            <img className={CTISEntryState.loadingSubmission ? 'visible' : 'invisible'}
              style={{ height: "22px" }} src="/spinner.gif" alt="Loading" />
            <div style={{ "textAlign": "left", "width": "100%" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h3>{CTISEntryState.loadingSubmission ? <>Your requested EU CTIS study record is being converted to</> : <><br />Enter a EU CTIS Identifier to automatically create</>} HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources.</h3>
              <br />
              <p>The EU CTIS-to-FEvIR Converter is currently in development and only converts a portion of the EU CTIS Record at this time.</p>
              <br />
              <span>HL7<sup>&#174;</sup> and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
            </div>
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
      </Segment>
    </div>
  );
};

export default CtisConvertPage;