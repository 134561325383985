import React, { useState, useEffect } from "react";
import { loadResourceVersionList, getHistoryVersionOfResource, getVersionDifference } from './ResourceFunctions';
import { Button, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import YesNoRadioCheckBoxSwitch from './YesNoRadioCheckBoxSwitch';
import JsonVersionComparison from './JsonVersionComparison';

const VersionDifferenceViewer = ({ resourceId, globalContext, fhirEntryState, setFhirEntryState }) => {

    const [versionHistoryState, setVersionHistoryState] = useState({
        "viewVersions": false, "selectedVersion": undefined, "showButtonClicked": false
    });
    const [versionListState, setVersionListState] = useState([]);
    const [viewVersionDifferencesState, setViewVersionDifferencesState] = useState({
        "viewDifferences": false, "showButtonClicked": false
    });
    const [versionDifferencesInterfaceState, setVersionDifferencesInterfaceState] = useState({
        "selectedVersionLeft": undefined, "selectedVersionRight": undefined, "justTheDifferences": true
    });
    const [versionDifferencesState, setVersionDifferencesState] = useState();
    const [loadingVersionDifferences, setLoadingVersionDifferences] = useState(false);

    const updateVersionDifferencesFunction = async () => {
        if (viewVersionDifferencesState.viewDifferences) {

            let selectedVersionLeft = versionDifferencesInterfaceState.selectedVersionLeft;
            let selectedVersionRight = versionDifferencesInterfaceState.selectedVersionRight;
            if (selectedVersionLeft === selectedVersionRight) {
                //THEY SHOULDN'T BE EQUAL
            } else {
                setLoadingVersionDifferences(true);
                let justTheDifferences = versionDifferencesInterfaceState.justTheDifferences;
                let newVersionNumber;
                let oldVersionNumber;
                if (selectedVersionLeft > selectedVersionRight) {
                    newVersionNumber = selectedVersionLeft;
                    oldVersionNumber = selectedVersionRight;
                } else {
                    newVersionNumber = selectedVersionRight;
                    oldVersionNumber = selectedVersionLeft;
                }
                let newHistoryId = versionListState[versionListState.length - newVersionNumber]["historyid"]; //Because reverse order, so for example if there are 64 versions and I selected version 62, 64 - 62 == index 2, version 63 would be index 1, and version 64 would be index 0
                let oldHistoryId = versionListState[versionListState.length - oldVersionNumber]["historyid"];
                let versionDiffJson = await getVersionDifference(resourceId, newVersionNumber, oldVersionNumber,
                    newHistoryId, oldHistoryId, justTheDifferences, globalContext);
                setVersionDifferencesState(versionDiffJson);
                setLoadingVersionDifferences(false);
            }

        }
    };

    useEffect(() => {
        updateVersionDifferencesFunction();
    }, [versionDifferencesInterfaceState]);


    let leftVersionDifferencesOptions = [];
    let rightVersionDifferencesOptions = [];
    if (fhirEntryState.editpermission && fhirEntryState.startingVersionId > 1 && viewVersionDifferencesState.viewDifferences) {
        for (let versionEntry of versionListState) {
            if (versionEntry) {
                let versionNumber = versionEntry.version;
                let datecreated = versionEntry.datecreated;
                let datecreatedTruncated = datecreated.substring(0, 16);
                let text = `${versionNumber}: ${datecreatedTruncated}`; //by ${username}
                let leftOption = {
                    key: versionNumber,
                    value: versionNumber,
                    text: text,
                    disabled: versionDifferencesInterfaceState.selectedVersionRight == versionNumber,
                    selected: versionDifferencesInterfaceState.selectedVersionLeft == versionNumber
                };
                let rightOption = {
                    key: versionNumber,
                    value: versionNumber,
                    text: text,
                    disabled: versionDifferencesInterfaceState.selectedVersionLeft == versionNumber,
                    selected: versionDifferencesInterfaceState.selectedVersionRight == versionNumber
                };
                let index = fhirEntryState.startingVersionId - versionNumber;
                leftVersionDifferencesOptions[index] = leftOption;
                rightVersionDifferencesOptions[index] = rightOption;
            }
        }
    }

    return <>
        {(fhirEntryState.loading ||
            (versionHistoryState.viewResourceButtonClicked && versionHistoryState.viewVersions)
            || (versionHistoryState.showButtonClicked && !versionHistoryState.viewVersions)
            || (viewVersionDifferencesState.showButtonClicked && !viewVersionDifferencesState.viewDifferences)) &&
            <Dimmer className={"loadingDimmer"} active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        }
        <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }}
            className="resourceListHeaderText"><span>Version History</span></div></div>
        {!versionHistoryState.viewVersions && <>
            <Button className="formButton" style={{ color: "#000000" }} content={"Show Version History"}
                onClick={async () => {
                    setVersionHistoryState(prevState => { return { ...prevState, "showButtonClicked": true } });
                    if (versionListState.length === 0) {
                        let versionList = await loadResourceVersionList(resourceId, globalContext);
                        setVersionListState(versionList);
                    }
                    setVersionHistoryState(prevState => { return { ...prevState, "viewVersions": true } });
                }}
                disabled={versionHistoryState.showButtonClicked} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>}
        {versionHistoryState.viewVersions &&
            <div>
                <select name="versionHistoryList" size="10" style={{ width: "100%" }}
                    value={versionHistoryState.selectedVersion}
                    onChange={(e) => {
                        setVersionHistoryState(prevState => {
                            return { ...prevState, "selectedVersion": e.target.value }
                        });
                    }}>
                    {versionListState.map((historyEntry, historyIndex) => {
                        let version = historyEntry?.version || "NULL";
                        let datecreated = historyEntry?.datecreated;
                        let username = historyEntry?.username || "Anonymous";
                        return <option value={version} key={historyIndex}
                            disabled={(historyIndex === 0 || version == versionHistoryState.viewingVersion)} >
                            {version}: {datecreated} by {username}
                        </option>
                    })}
                </select>
                <Button className="formButton" style={{ color: "#000000" }} content={"View Version"}
                    onClick={async () => {
                        let proceed = true;
                        if (fhirEntryState.editMode) {
                            proceed = window.confirm("Warning: You're in edit mode any unsaved data will be lost. Do you wish to continue?");
                        }
                        if (proceed) {
                            let selectedVersion = versionHistoryState.selectedVersion;
                            let historyid;
                            try {
                                historyid = versionListState[versionListState.length - selectedVersion]["historyid"]; //Because reverse order, so for example if there are 64 versions and I selected version 62, 64 - 62 == index 2, version 63 would be index 1, and version 64 would be index 0
                            } catch { }
                            setVersionHistoryState(prevState => { return { ...prevState, "viewResourceButtonClicked": true } });
                            let historyVersionOfResourceResponse = await getHistoryVersionOfResource(resourceId, selectedVersion, historyid, globalContext);
                            let historyVersionOfResource = historyVersionOfResourceResponse["fhirjsonstring"];
                            let historyVersionOfResourceStatus = historyVersionOfResourceResponse["status"];
                            //KEEPS RECORD OF CURRENT JSON, CURRENT VERSION, AND CURRENT STATUS
                            if (historyVersionOfResourceResponse.warning) {
                                alert(historyVersionOfResourceResponse.warning);
                            }
                            setVersionHistoryState(prevState => { return { ...prevState, 'selectedVersion': undefined, 'viewingVersion': selectedVersion, "viewHistoryVersionOfResource": true, "historyVersionOfResource": historyVersionOfResource, "historyVersionOfResourceStatus": historyVersionOfResourceStatus, "viewResourceButtonClicked": false, "currentStatus": fhirEntryState.status, "currentVersion": fhirEntryState.startingVersionId, "currentFhirEntryString": fhirEntryState.fhirEntryString } });
                            setFhirEntryState(prevState => { return { ...prevState, "previousVersionLoaded": true, "fhirEntryString": historyVersionOfResource, "status": historyVersionOfResourceStatus, "activeIndex": 0, "editMode": false } });
                        }
                    }} disabled={!versionHistoryState.selectedVersion || versionHistoryState.viewResourceButtonClicked} />
            </div>
        }
        {!viewVersionDifferencesState.viewVersions &&
            <>
                {versionHistoryState.viewVersions && <br />}
                {!viewVersionDifferencesState.viewDifferences &&
                    <Button className="formButton"
                        style={{ color: "#000000" }}
                        content={"Show Version Differences"}
                        onClick={async () => {
                            setViewVersionDifferencesState(prevState => { return { ...prevState, "showButtonClicked": true } });
                            if (versionListState.length === 0) {
                                let versionList = await loadResourceVersionList(resourceId, globalContext);
                                setVersionListState(versionList);
                            }
                            setViewVersionDifferencesState(prevState => { return { ...prevState, "viewDifferences": true } });
                            let currentVersion = parseInt(fhirEntryState.startingVersionId);
                            setVersionDifferencesInterfaceState({ "selectedVersionLeft": currentVersion, "selectedVersionRight": (currentVersion - 1), "justTheDifferences": true })
                        }}
                        disabled={viewVersionDifferencesState.showButtonClicked} />}
            </>
        }
        {viewVersionDifferencesState.viewDifferences &&
            <>
                <br /><br />
                <Dropdown
                    name="versionDifferencesOne"
                    placeholder=""
                    closeOnChange selection selectOnBlur={false} autoComplete="off"
                    style={{ width: "200px" }}
                    options={leftVersionDifferencesOptions}
                    value={versionDifferencesInterfaceState.selectedVersionLeft}
                    onChange={(e, data) => {
                        setVersionDifferencesInterfaceState(prevState => { return { ...prevState, "selectedVersionLeft": data.value } });
                    }}
                    disabled={false}
                />
                &nbsp;&nbsp;<i>vs.</i>&nbsp;&nbsp;
                <Dropdown
                    name="versionDifferencesTwo"
                    placeholder=""
                    closeOnChange selection selectOnBlur={false} autoComplete="off"
                    style={{ width: "200px" }}
                    options={rightVersionDifferencesOptions}
                    value={versionDifferencesInterfaceState.selectedVersionRight}
                    onChange={(e, data) => {
                        setVersionDifferencesInterfaceState(prevState => { return { ...prevState, "selectedVersionRight": data.value } });
                    }}
                    disabled={false}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <YesNoRadioCheckBoxSwitch elementName={"justTheDifferences"}
                    labelElementName={"Only show the differences?"}
                    flatElementValue={true} togglable={false} thin={true} fontSize={"14px"}
                    alternativeValues={[true, false]}
                    alternativeLabels={["Just the Differences", "Full JSON"]}
                    startingValue={versionDifferencesInterfaceState.justTheDifferences}
                    setter={setVersionDifferencesInterfaceState} />
                <div>
                    {loadingVersionDifferences &&
                        <Dimmer className={"loadingDimmer"} active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                    <br />
                    {versionDifferencesState && <JsonVersionComparison jsonDifferences={versionDifferencesState} />}
                </div>
            </>
        }
    </>
}

export { VersionDifferenceViewer };