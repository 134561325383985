import React, { useState, useEffect, memo } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { getStringFromFHIR, DisplayFromFHIR } from './ResourceFunctions';
import {
  emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet,
  sectionCodeSummaryOfFindingsForSingleOutcome, sectionCodeEvidenceWithTotalGroup,
  sectionCodeEvidenceWithInterventionAlone, sectionCodeEvidenceWithComparatorAlone,
  sectionCodeEvidenceWithInterventionVsComparator, sectionCodeInterventionGroup,
  sectionCodeComparatorGroup, sectionCodeGroupAssignment, sectionCodeGroups, sectionCodeVariables,
  sectionCodeResults, compositionTypeCodeOutcomeMeasureReport, compositionTypeCodeBaselineMeasureReport,
  compositionTypeCodeParticipantFlowReport, emptyTextNoData,
  sectionCodeScreenedGroup,
  sectionCodeEnrolledGroup,
  sectionCodePopulationDescription,
  sectionCodeInterventionDescription,
  sectionCodeComparatorDescription,
  sectionCodeEnrollmentResults,
  sectionCodeComparativeResults,
  sectionCodeEvidenceForEnrolledGroup,
  sectionCodeEvidenceForScreenedGroup,
  sectionCodeEvidenceForScreenedVsEnrolled,
  sectionCodeEvidenceForAnalyzedGroup,
  sectionCodeAnalyzedGroup
} from './CodeSystemLookup';
import { generateEvidenceSummary, generateGroupSummary, generateVariablesSummary } from './GenerateNarrativeSummaryFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import EditTableCellModal from './EditTableCellModal';
import scrollTo from './ScrollTo';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } });
}

const InnerSectionDataEntry = memo(({ startingValue, elementName, setResourceState,
  entryHeader, entrySetProfile, variableDefinition, resourceDictionary, setSourceJsonState,
  researchStudyRelatedArtfifact, resourceState, suggestedEvidenceTitle,
  globalContext, statisticType, statisticUnit, knownDataCount,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode, sectionArrayIndex }) => {
  let sectionCode;
  if (adaptationReportState?.pathList && startingValue?.code) {
    if (sectionArrayIndex || sectionArrayIndex === 0) {
      if (startingValue.code.text) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section[" + sectionArrayIndex + "]:" + JSON.stringify(startingValue.code);
      }
    } else {
      if (startingValue.code.text) {
        sectionCode = "section:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section:" + JSON.stringify(startingValue.code);
      }
    }
    if (parentSectionCode) {
      sectionCode = parentSectionCode.split(":")[0] + "." + sectionCode;
    }
  }

  let compositionId = resourceState.id;
  let compositionTitle = resourceState.title || resourceState.name;

  let entryAddElementValues = {
    title: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.title || suggestedEvidenceTitle,
    relatesTo: researchStudyRelatedArtfifact || "",
    description: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.description || "",
    variableDefinition: variableDefinition || "",
    statistic: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.statistic || ""
  };
  if (!entryAddElementValues.relatesTo) {
    entryAddElementValues.relatesTo = [
      {
        "type": "part-of",
        "targetReference": {
          "reference": "Composition/" + compositionId,
          "type": "Composition",
          "display": compositionTitle
        }
      }
    ];
  } else {
    entryAddElementValues.relatesTo.push({
      "type": "part-of",
      "targetReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    });
  }

  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp },
    author: [], focus: "", text: emptyTextNoData, orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [endSectionState, setEndSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  const [openEditModalState, setOpenEditModalState] = useState({ "openModal": false });

  useEffect((() => {
    if (Object.keys(endSectionState).length > 0) {
      let newSection = {};
      if (endSectionState.extension) { newSection.extension = endSectionState.extension; }
      if (endSectionState.title) { newSection.title = endSectionState.title; }
      if (endSectionState.code) { newSection.code = endSectionState.code; }
      if (Array.isArray(endSectionState.author) && endSectionState.author.length > 0) {
        newSection.author = endSectionState.author;
      }
      if (endSectionState.focus) { newSection.focus = endSectionState.focus; }
      if (endSectionState.text) { newSection.text = endSectionState.text; }
      if (endSectionState.orderedBy) { newSection.orderedBy = endSectionState.orderedBy; }
      if (Array.isArray(endSectionState.entry) && endSectionState.entry.length > 0) {
        newSection.entry = endSectionState.entry;
      }
      if (endSectionState.emptyReason) { newSection.emptyReason = endSectionState.emptyReason; }
      if (Array.isArray(endSectionState.section) && endSectionState.section.length > 0) {
        newSection.section = endSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      handleChange(elementName, newSection, setResourceState);
    }
  }), [endSectionState]);

  if (!openEditModalState.openModal) {
    return <div>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingSection.text?.div &&
          <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
        {startingSection.entry?.length > 0 && <>
          {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
            :
            <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
        </>}
        <br />
      </div>}
      &nbsp;&nbsp;
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setOpenEditModalState({ openModal: true });
        }}>
        {startingValue ? <>✎ Edit</> : <>➕ Add</>}
      </span>
    </div>
  } else {
    return <EditTableCellModal endSectionState={endSectionState}
      entryStartingResourceType={"Evidence"} resourceDictionary={resourceDictionary}
      setOpenEditModalState={setOpenEditModalState} globalContext={globalContext}
      generateTextDivFunction={generateEvidenceSummary} sectionCode={parentSectionCode}
      setResourceState={setEndSectionState} setProfile={entrySetProfile}
      statisticType={statisticType} statisticUnit={statisticUnit}
      knownDataCount={knownDataCount} entryHeader={entryHeader}
      entryAddElementValues={entryAddElementValues}
      setSourceJsonState={setSourceJsonState} resourceState={resourceState}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState}
      singleEvidenceEntry={true} />
  }
});

const generateEvidenceNarrative = (newEvidenceJson) => {

  if (newEvidenceJson) {
    let description = "";
    let statistic = "";
    if (newEvidenceJson.statistic?.length === 1) {
      if (newEvidenceJson.statistic[0].statisticType && newEvidenceJson.statistic[0].quantity) {
        let statisticTypeDisplay = newEvidenceJson.statistic[0].statisticType.coding?.[0]?.display || newEvidenceJson.statistic[0].statisticType.text || getStringFromFHIR.CodeableConcept(newEvidenceJson.statistic[0].statisticType);
        statistic = statisticTypeDisplay + " " + getStringFromFHIR.Quantity(newEvidenceJson.statistic[0].quantity);
      } else if (newEvidenceJson.statistic[0].description) {
        statistic = newEvidenceJson.statistic[0].description;
      }
      if (newEvidenceJson.statistic[0].attributeEstimate?.[0]?.type) {
        let attributeEstimate = newEvidenceJson.statistic[0].attributeEstimate[0];
        let attribute = "";
        if (attributeEstimate.level) {
          attribute = (attributeEstimate.level * 100).toString() + "% ";
        }
        attribute += attributeEstimate.type.coding?.[0]?.display || attributeEstimate.type.text || getStringFromFHIR.CodeableConcept(attributeEstimate.type);
        if (attributeEstimate.quantity) {
          attribute += " " + getStringFromFHIR.Quantity(attributeEstimate.quantity);
        }
        if (attributeEstimate.range) {
          attribute += " " + getStringFromFHIR.Range(attributeEstimate.range);
        }
        statistic += " (" + attribute + ")";
      }
    }
    if (newEvidenceJson.statistic?.length > 1) {
      for (const stat of newEvidenceJson.statistic) {
        let statisticInstance = "";
        if (stat.category) {
          statisticInstance = stat.category + ": ";
        }
        if (stat.statisticType && stat.quantity) {
          let statisticTypeDisplay = stat.statisticType.coding?.[0]?.display || stat.statisticType.text || getStringFromFHIR.CodeableConcept(stat.statisticType);
          statisticInstance += statisticTypeDisplay + " " + getStringFromFHIR.Quantity(stat.quantity);
        } else if (stat.description) {
          statisticInstance += stat.description;
        }
        if (stat.attributeEstimate?.[0]?.type) {
          let attributeEstimate = statisticInstance.attributeEstimate[0];
          let attribute = "";
          if (attributeEstimate.level) {
            attribute = (attributeEstimate.level * 100).toString() + "% ";
          }
          attribute += attributeEstimate.type.coding?.[0]?.display || attributeEstimate.type.text || getStringFromFHIR.CodeableConcept(attributeEstimate.type);
          if (attributeEstimate.quantity) {
            attribute += " " + getStringFromFHIR.Quantity(attributeEstimate.quantity);
          }
          if (attributeEstimate.range) {
            attribute += " " + getStringFromFHIR.Range(attributeEstimate.range);
          }
          statisticInstance += " (" + attribute + ")";
        }
        if (statistic) {
          statistic += "; " + statisticInstance;
        } else {
          statistic = statisticInstance;
        }
      }
    }
    if (statistic) {
      description = statistic;
    }
    if (!description) {
      description = newEvidenceJson.description || "";
    }
    let narrative = description;
    let newText = JSON.parse(JSON.stringify(emptyTextNoData));
    if (narrative) {
      let divContainedNarrative = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + narrative + "</p></div>";
      newText = { status: "generated", div: divContainedNarrative };
    }
    return newText;
  }
  return { status: "empty", div: "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>" };
}

//generate text.div from entry values for the table row sent as sections and returned as newRowSections
const generateTableRowContent = (sections, resourceDictionary = {}) => {
  let sourceReferences = {
    totalGroupEvidence: sections?.totalSection?.entry?.[0] || "",
    interventionOnlyEvidence: sections?.interventionSection?.entry?.[0] || "",
    comparatorOnlyEvidence: sections?.comparatorSection?.entry?.[0] || "",
    comparativeEvidence: sections?.comparativeSection?.entry?.[0] || ""
  };
  let newRowSections = JSON.parse(JSON.stringify(sections));
  if (Object.keys(newRowSections).length > 0) {
    let totalGroupEvidenceJson;
    let comparatorOnlyEvidenceJson;
    let interventionOnlyEvidenceJson;
    let comparativeEvidenceJson;
    if (sourceReferences.totalGroupEvidence) {
      let totalGroupEvidenceFoi = sourceReferences.totalGroupEvidence.reference?.split('/')[1] || "missing";
      if ((totalGroupEvidenceFoi === "missing" || isNaN(totalGroupEvidenceFoi)) &&
        sourceReferences.totalGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.totalGroupEvidence.identifier.value) {
        let totalGroupEvidenceFli = sourceReferences.totalGroupEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[totalGroupEvidenceFli]) {
          totalGroupEvidenceFoi = resourceDictionary.fliToFoi[totalGroupEvidenceFli];
          sourceReferences.totalGroupEvidence.reference = "Evidence/" + totalGroupEvidenceFoi;
        }
      }

      if (totalGroupEvidenceFoi) {
        totalGroupEvidenceJson = resourceDictionary[totalGroupEvidenceFoi];
      }
    }
    if (sourceReferences.comparatorOnlyEvidence) {
      let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
        sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparatorOnlyEvidence.identifier.value) {
        let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
          comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
          sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
        }
      }
      if (comparatorOnlyEvidenceFoi) {
        comparatorOnlyEvidenceJson = resourceDictionary[comparatorOnlyEvidenceFoi];
      }
    }
    if (sourceReferences.interventionOnlyEvidence) {
      let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
        sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.interventionOnlyEvidence.identifier.value) {
        let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
          interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
          sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
        }
      }
      if (interventionOnlyEvidenceFoi) {
        interventionOnlyEvidenceJson = resourceDictionary[interventionOnlyEvidenceFoi];
      }
    }
    if (sourceReferences.comparativeEvidence) {
      let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
      if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
        sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparativeEvidence.identifier.value) {
        let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
          comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
          sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
        }
      }
      if (comparativeEvidenceFoi) {
        comparativeEvidenceJson = resourceDictionary[comparativeEvidenceFoi];
      }
    }

    if (totalGroupEvidenceJson) {
      newRowSections.totalSection.entry = [sourceReferences.totalGroupEvidence];
      newRowSections.totalSection.text = generateEvidenceNarrative(totalGroupEvidenceJson);
    }
    if (comparatorOnlyEvidenceJson) {
      newRowSections.comparatorSection.entry = [sourceReferences.comparatorOnlyEvidence];
      newRowSections.comparatorSection.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
    }
    if (interventionOnlyEvidenceJson) {
      newRowSections.interventionSection.entry = [sourceReferences.interventionOnlyEvidence];
      newRowSections.interventionSection.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
    }
    if (comparativeEvidenceJson) {
      newRowSections.comparativeSection.entry = [sourceReferences.comparativeEvidence];
      newRowSections.comparativeSection.text = generateEvidenceNarrative(comparativeEvidenceJson);
    }
    return newRowSections;
  } else {
    console.log('newRowSections is not an object.')
    console.log(newRowSections);
  }
};

const generateEvidenceTableContent = (oldSectionSet, resourceDictionary = {}) => {
  let newSectionSet = [];
  for (let row of oldSectionSet) {
    let newRow = JSON.parse(JSON.stringify(row));
    let sourceReferences = {
      interventionOnlyEvidence: "", comparatorOnlyEvidence: "",
      comparativeEvidence: "", totalGroupEvidence: ""
    };
    for (let section of row.section) {
      let sectionCode;
      if (section?.code) {
        sectionCode = section.code.coding?.[0]?.code || section.code.text;
      }
      if (sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" ||
        sectionCode === "Evidence with total group") {
        if (section.entry?.length > 0) {
          sourceReferences.totalGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-comparator-alone" || sectionCode === "Control-group-alone-Evidence" ||
        sectionCode === "evidence-with-comparator-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.comparatorOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-intervention-alone" || sectionCode === "Intervention-group-alone-Evidence" ||
        sectionCode === "evidence-with-intervention-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.interventionOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-intervention-vs-comparator" || sectionCode === "Intervention-vs-Control-Evidence" ||
        sectionCode === "evidence-with-intervention-vs-comparator") {
        if (section.entry?.length > 0) {
          sourceReferences.comparativeEvidence = section.entry[0];
        }
      }
    }

    let newRowSections = newRow.section;
    if (Array.isArray(newRowSections) && newRowSections.length > 0) {
      let totalGroupEvidenceJson;
      let comparatorOnlyEvidenceJson;
      let interventionOnlyEvidenceJson;
      let comparativeEvidenceJson;
      if (sourceReferences.totalGroupEvidence) {
        let totalGroupEvidenceFoi = sourceReferences.totalGroupEvidence.reference?.split('/')[1] || "missing";
        if ((totalGroupEvidenceFoi === "missing" || isNaN(totalGroupEvidenceFoi)) &&
          sourceReferences.totalGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.totalGroupEvidence.identifier.value) {
          let outcomeDefinitionFli = sourceReferences.totalGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[outcomeDefinitionFli]) {
            totalGroupEvidenceFoi = resourceDictionary.fliToFoi[outcomeDefinitionFli];
            sourceReferences.totalGroupEvidence.reference = "Evidence/" + totalGroupEvidenceFoi;
          }
        }

        if (resourceDictionary[totalGroupEvidenceFoi]) {
          totalGroupEvidenceJson = resourceDictionary[totalGroupEvidenceFoi];
        }
      }
      if (sourceReferences.comparatorOnlyEvidence) {
        let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
          sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparatorOnlyEvidence.identifier.value) {
          let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
            comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
            sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[comparatorOnlyEvidenceFoi]) {
          comparatorOnlyEvidenceJson = resourceDictionary[comparatorOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.interventionOnlyEvidence) {
        let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
          sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.interventionOnlyEvidence.identifier.value) {
          let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
            interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
            sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[interventionOnlyEvidenceFoi]) {
          interventionOnlyEvidenceJson = resourceDictionary[interventionOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.comparativeEvidence) {
        let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
        if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
          sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparativeEvidence.identifier.value) {
          let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
            comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
            sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
          }
        }
        if (resourceDictionary[comparativeEvidenceFoi]) {
          comparativeEvidenceJson = resourceDictionary[comparativeEvidenceFoi];
        }
      }

      for (let section of newRowSections) {
        if (section.code?.coding && section.code.coding[0].code) {
          switch (section.code.coding[0].code) {
            case "evidence-with-total-group":
              if (totalGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(totalGroupEvidenceJson);
              }
              break;
            case "result-with-total-group":
              if (totalGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(totalGroupEvidenceJson);
              }
              break;
            case "Evidence with total group":
              if (totalGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(totalGroupEvidenceJson);
              }
              break;
            case "result-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "evidence-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "Control-group-alone-Evidence":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "result-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "evidence-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "Intervention-group-alone-Evidence":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "result-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "evidence-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "Intervention-vs-Control-Evidence":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            default:
              console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
          }
        }
      }
    } else {
      console.log('newRowSections is not an array.')
      console.log(newRowSections);
    }
    newSectionSet.push(newRow);
  }
  return newSectionSet;
}

const generateEnrolledResultsTableContent = (oldSectionSet, resourceDictionary = {}) => {
  let newSectionSet = [];
  for (let row of oldSectionSet) {
    let newRow = JSON.parse(JSON.stringify(row));
    let sourceReferences = {
      screenedGroupEvidence: "",
      comparativeScreeningEvidence: "", enrolledGroupEvidence: ""
    };
    for (let section of row.section) {
      let sectionCode;
      if (section?.code) {
        sectionCode = section.code.coding?.[0]?.code || section.code.text;
      }
      if (sectionCode === "evidence-for-enrolled-group" || sectionCode === "evidence-with-total-group" ||
        sectionCode === "result-with-total-group" || sectionCode === "Evidence for Enrolled Group" ||
        sectionCode === "Evidence with total group") {
        if (section.entry?.length > 0) {
          sourceReferences.enrolledGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "evidence-for-screened-group") {
        if (section.entry?.length > 0) {
          sourceReferences.screenedGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "evidence-for-screened-vs-enrolled") {
        if (section.entry?.length > 0) {
          sourceReferences.comparativeScreeningEvidence = section.entry[0];
        }
      }
    }

    let newRowSections = newRow.section;
    if (Array.isArray(newRowSections) && newRowSections.length > 0) {
      let screenedGroupEvidenceJson;
      let enrolledGroupEvidenceJson;
      let comparativeScreeningEvidenceJson;
      if (sourceReferences.enrolledGroupEvidence) {
        let enrolledGroupEvidenceFoi = sourceReferences.enrolledGroupEvidence.reference?.split('/')[1] || "missing";
        if ((enrolledGroupEvidenceFoi === "missing" || isNaN(enrolledGroupEvidenceFoi)) &&
          sourceReferences.enrolledGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.enrolledGroupEvidence.identifier.value) {
          let fli = sourceReferences.enrolledGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            enrolledGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.enrolledGroupEvidence.reference = "Evidence/" + enrolledGroupEvidenceFoi;
          }
        }

        if (resourceDictionary[enrolledGroupEvidenceFoi]) {
          enrolledGroupEvidenceJson = resourceDictionary[enrolledGroupEvidenceFoi];
        }
      }
      if (sourceReferences.screenedGroupEvidence) {
        let screenedGroupEvidenceFoi = sourceReferences.screenedGroupEvidence.reference?.split('/')[1] || "missing";
        if ((screenedGroupEvidenceFoi === "missing" || isNaN(screenedGroupEvidenceFoi)) &&
          sourceReferences.screenedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.screenedGroupEvidence.identifier.value) {
          let fli = sourceReferences.screenedGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            screenedGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.screenedGroupEvidence.reference = "Evidence/" + screenedGroupEvidenceFoi;
          }
        }
        if (resourceDictionary[screenedGroupEvidenceFoi]) {
          screenedGroupEvidenceJson = resourceDictionary[screenedGroupEvidenceFoi];
        }
      }
      if (sourceReferences.comparativeScreeningEvidence) {
        let comparativeScreeningEvidenceFoi = sourceReferences.comparativeScreeningEvidence.reference?.split('/')[1] || "missing";
        if ((comparativeScreeningEvidenceFoi === "missing" || isNaN(comparativeScreeningEvidenceFoi)) &&
          sourceReferences.comparativeScreeningEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparativeScreeningEvidence.identifier.value) {
          let fli = sourceReferences.comparativeScreeningEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            comparativeScreeningEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.comparativeScreeningEvidence.reference = "Evidence/" + comparativeScreeningEvidenceFoi;
          }
        }
        if (resourceDictionary[comparativeScreeningEvidenceFoi]) {
          comparativeScreeningEvidenceJson = resourceDictionary[comparativeScreeningEvidenceFoi];
        }
      }

      for (let section of newRowSections) {
        if (section.code?.coding && section.code.coding[0].code) {
          switch (section.code.coding[0].code) {
            case "evidence-for-enrolled-group":
              if (enrolledGroupEvidenceJson) {
                section.entry = [sourceReferences.enrolledGroupEvidence];
                section.text = generateEvidenceNarrative(enrolledGroupEvidenceJson);
              }
              break;
            case "evidence-for-screened-group":
              if (screenedGroupEvidenceJson) {
                section.entry = [sourceReferences.screenedGroupEvidence];
                section.text = generateEvidenceNarrative(screenedGroupEvidenceJson);
              }
              break;
            case "evidence-for-screened-vs-enrolled":
              if (comparativeScreeningEvidenceJson) {
                section.entry = [sourceReferences.comparativeScreeningEvidence];
                section.text = generateEvidenceNarrative(comparativeScreeningEvidenceJson);
              }
              break;
            default:
              console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
          }
        }
      }
    } else {
      console.log('newRowSections is not an array.')
      console.log(newRowSections);
    }
    newSectionSet.push(newRow);
  }
  return newSectionSet;
}

const generateComparativeResultsTableContent = (oldSectionSet, resourceDictionary = {}) => {
  let newSectionSet = [];
  for (let row of oldSectionSet) {
    let newRow = JSON.parse(JSON.stringify(row));
    let sourceReferences = {
      analyzedGroupEvidence: "",
      interventionOnlyEvidence: "",
      comparatorOnlyEvidence: "",
      comparativeEvidence: ""
    };
    for (let section of row.section) {
      let sectionCode;
      if (section?.code) {
        sectionCode = section.code.coding?.[0]?.code || section.code.text;
      }
      if (sectionCode === "evidence-for-analyzed-group" || sectionCode === "Evidence for Analyzed Group" ||
        sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" ||
        sectionCode === "Evidence with total group") {
        if (section.entry?.length > 0) {
          sourceReferences.analyzedGroupEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-comparator-alone" || sectionCode === "Control-group-alone-Evidence" ||
        sectionCode === "evidence-with-comparator-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.comparatorOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-intervention-alone" || sectionCode === "Intervention-group-alone-Evidence" ||
        sectionCode === "evidence-with-intervention-alone") {
        if (section.entry?.length > 0) {
          sourceReferences.interventionOnlyEvidence = section.entry[0];
        }
      }
      if (sectionCode === "result-with-intervention-vs-comparator" || sectionCode === "Intervention-vs-Control-Evidence" ||
        sectionCode === "evidence-with-intervention-vs-comparator") {
        if (section.entry?.length > 0) {
          sourceReferences.comparativeEvidence = section.entry[0];
        }
      }
    }

    let newRowSections = newRow.section;
    if (Array.isArray(newRowSections) && newRowSections.length > 0) {
      let analyzedGroupEvidenceJson;
      let comparatorOnlyEvidenceJson;
      let interventionOnlyEvidenceJson;
      let comparativeEvidenceJson;
      if (sourceReferences.analyzedGroupEvidence) {
        let analyzedGroupEvidenceFoi = sourceReferences.analyzedGroupEvidence.reference?.split('/')[1] || "missing";
        if ((analyzedGroupEvidenceFoi === "missing" || isNaN(analyzedGroupEvidenceFoi)) &&
          sourceReferences.analyzedGroupEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.analyzedGroupEvidence.identifier.value) {
          let fli = sourceReferences.analyzedGroupEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[fli]) {
            analyzedGroupEvidenceFoi = resourceDictionary.fliToFoi[fli];
            sourceReferences.analyzedGroupEvidence.reference = "Evidence/" + analyzedGroupEvidenceFoi;
          }
        }

        if (resourceDictionary[analyzedGroupEvidenceFoi]) {
          analyzedGroupEvidenceJson = resourceDictionary[analyzedGroupEvidenceFoi];
        }
      }
      if (sourceReferences.comparatorOnlyEvidence) {
        let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
          sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparatorOnlyEvidence.identifier.value) {
          let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
            comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
            sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[comparatorOnlyEvidenceFoi]) {
          comparatorOnlyEvidenceJson = resourceDictionary[comparatorOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.interventionOnlyEvidence) {
        let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
        if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
          sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.interventionOnlyEvidence.identifier.value) {
          let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
            interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
            sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
          }
        }
        if (resourceDictionary[interventionOnlyEvidenceFoi]) {
          interventionOnlyEvidenceJson = resourceDictionary[interventionOnlyEvidenceFoi];
        }
      }
      if (sourceReferences.comparativeEvidence) {
        let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
        if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
          sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
          sourceReferences.comparativeEvidence.identifier.value) {
          let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
          if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
            comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
            sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
          }
        }
        if (resourceDictionary[comparativeEvidenceFoi]) {
          comparativeEvidenceJson = resourceDictionary[comparativeEvidenceFoi];
        }
      }

      for (let section of newRowSections) {
        if (section.code?.coding && section.code.coding[0].code) {
          switch (section.code.coding[0].code) {
            case "evidence-for-analyzed-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "evidence-with-total-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "result-with-total-group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "Evidence with total group":
              if (analyzedGroupEvidenceJson) {
                section.entry = [sourceReferences.totalGroupEvidence];
                section.text = generateEvidenceNarrative(analyzedGroupEvidenceJson);
              }
              break;
            case "result-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "evidence-with-comparator-alone":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "Control-group-alone-Evidence":
              if (comparatorOnlyEvidenceJson) {
                section.entry = [sourceReferences.comparatorOnlyEvidence];
                section.text = generateEvidenceNarrative(comparatorOnlyEvidenceJson);
              }
              break;
            case "result-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "evidence-with-intervention-alone":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "Intervention-group-alone-Evidence":
              if (interventionOnlyEvidenceJson) {
                section.entry = [sourceReferences.interventionOnlyEvidence];
                section.text = generateEvidenceNarrative(interventionOnlyEvidenceJson);
              }
              break;
            case "result-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "evidence-with-intervention-vs-comparator":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            case "Intervention-vs-Control-Evidence":
              if (comparativeEvidenceJson) {
                section.entry = [sourceReferences.comparativeEvidence];
                section.text = generateEvidenceNarrative(comparativeEvidenceJson);
              }
              break;
            default:
              console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
          }
        }
      }
    } else {
      console.log('newRowSections is not an array.')
      console.log(newRowSections);
    }
    newSectionSet.push(newRow);
  }
  return newSectionSet;
}

const InnerSectionArrayDataEntry = memo(({ startingValue, setResourceState,
  totalGroupReference, interventionGroupReference, setSourceJsonState, sampleSizeDefaultState,
  analyzedGroupReference, enrolledGroupReference, screenedGroupReference, resultsType,
  comparatorGroupReference, groupAssignmentReference, comparatorCategory, resourceDictionary,
  researchStudyRelatedArtfifact, resourceState, globalContext,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode }) => {

  let analyzedSection = null;
  let enrolledSection = null;
  let screenedSection = null;
  let comparativeScreeningSection = null;
  let comparatorSection = null;
  let interventionSection = null;
  let totalSection = null;
  let comparativeSection = null;
  let startingSectionStateValues = {};
  let defaultStatisticType = "";
  let defaultStatisticUnits = "";
  if (startingValue) {
    for (let itemIndex in startingValue.section) {
      let subsection = startingValue.section[itemIndex];
      let subsectionCode = subsection?.code?.coding?.[0]?.code;
      if (subsectionCode === "evidence-with-comparator-alone") {
        comparatorSection = subsection;
        startingSectionStateValues["comparatorSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-intervention-alone") {
        interventionSection = subsection;
        startingSectionStateValues["interventionSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-total-group") {
        totalSection = subsection;
        startingSectionStateValues["totalSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
        comparativeSection = subsection;
        startingSectionStateValues["comparativeSection"] = subsection;
      } else if (subsectionCode === "evidence-for-screened-group") {
        screenedSection = subsection;
        startingSectionStateValues["screenedSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-for-enrolled-group") {
        enrolledSection = subsection;
        startingSectionStateValues["enrolledSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-for-analyzed-group") {
        analyzedSection = subsection;
        startingSectionStateValues["analyzedSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "evidence-for-screened-vs-enrolled") {
        comparativeScreeningSection = subsection;
        startingSectionStateValues["comparativeScreeningSection"] = subsection;
      }
    }
  }
  let screenedVariableDefinition = [
    {
      "variableRole": "population",
      "observed": screenedGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedScreenedGroupEvidenceTitle = startingValue.focus.display + " for screened group";
  let analyzedVariableDefinition = [
    {
      "variableRole": "population",
      "observed": analyzedGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedAnalyzedGroupEvidenceTitle = startingValue.focus.display + " for analyzed group";
  let enrolledVariableDefinition = [
    {
      "variableRole": "population",
      "observed": enrolledGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedEnrolledGroupEvidenceTitle = startingValue.focus.display + " for enrolled group";
  let comparatorVariableDefinition = [
    {
      "variableRole": "population",
      "observed": comparatorGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparatorEvidenceTitle = startingValue.focus.display + " with comparator";
  let interventionVariableDefinition = [
    {
      "variableRole": "population",
      "observed": interventionGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedInterventionEvidenceTitle = startingValue.focus.display + " with intervention";
  let totalVariableDefinition = [
    {
      "variableRole": "population",
      "observed": totalGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedTotalEvidenceTitle = startingValue.focus.display + " in total group";
  let comparativeToScreenedVariableDefinition = [
    {
      "variableRole": "population",
      "observed": screenedGroupReference
    },
    {
      "variableRole": "exposure",
      "comparatorCategory": comparatorCategory || "",
      "observed": groupAssignmentReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparativeToScreenedEvidenceTitle = startingValue.focus.display + " comparing screened vs. enrolled";
  let comparativeVariableDefinition = [
    {
      "variableRole": "population",
      "observed": totalGroupReference
    },
    {
      "variableRole": "exposure",
      "comparatorCategory": comparatorCategory || "",
      "observed": groupAssignmentReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparativeEvidenceTitle = startingValue.focus.display + " comparing intervention vs. comparator";

  const [sectionState, setSectionState] = useState(JSON.parse(JSON.stringify(startingSectionStateValues || null)));
  const [statisticDefaultsState, setStatisticDefaultsState] = useState({
    "statisticType": "", "units": ""
  });

  useEffect(() => {
    let newSection = [];
    for (const key in sectionState) {
      newSection.push(sectionState[key]);
    }
    if (newSection && Object.keys(newSection).length) {
      setResourceState(prevState => { return { ...prevState, "section": newSection } });
    }
  }, [sectionState]);

  if (resultsType === "enrollment-results") {
    return <>
      <Table.Cell style={{ verticalAlign: "top" }}>
        {startingValue.title || startingValue.focus?.display}
        <p><span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => {
            setStatisticDefaultsState({
              "statisticType": defaultStatisticType || "",
              "units": defaultStatisticUnits || ""
            })
          }}>
          {(defaultStatisticType && !statisticDefaultsState.statisticType) &&
            <>➕ Add Default Statistic Type {getStringFromFHIR.CodeableConcept(defaultStatisticType)} and Units {defaultStatisticUnits || "none"}</>}
        </span></p>
        <br />
        <span>
          <div><br />
            <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
              className="formButton"
              positive
              content={"Regenerate Table Row"}
              onClick={() => {
                let newRowSections = generateTableRowContent(sectionState, resourceDictionary);
                setSectionState(newRowSections);
              }}
            />
          </div>
        </span>
      </Table.Cell>
      {screenedGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {screenedSection && <InnerSectionDataEntry startingValue={screenedSection}
          elementName={"screenedSection"} setResourceState={setSectionState}
          entryHeader="screened group" entrySetProfile={"NoncomparativeEvidence"}
          variableDefinition={screenedVariableDefinition}
          suggestedEvidenceTitle={suggestedScreenedGroupEvidenceTitle}
          resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          statisticType={statisticDefaultsState.statisticType}
          statisticUnit={statisticDefaultsState.units}
          knownDataCount={sampleSizeDefaultState.screenedKnownDataCount}
          resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={0} />}
      </Table.Cell>}
      {enrolledGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {enrolledSection && <InnerSectionDataEntry startingValue={enrolledSection}
          elementName={"enrolledSection"} setResourceState={setSectionState}
          entryHeader="enrolled group" entrySetProfile={"NoncomparativeEvidence"}
          variableDefinition={enrolledVariableDefinition}
          suggestedEvidenceTitle={suggestedEnrolledGroupEvidenceTitle} resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          statisticType={statisticDefaultsState.statisticType}
          statisticUnit={statisticDefaultsState.units}
          knownDataCount={sampleSizeDefaultState.enrolledKnownDataCount}
          resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={1} />}
      </Table.Cell>}
      {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {comparativeScreeningSection && <InnerSectionDataEntry startingValue={comparativeScreeningSection}
          elementName={"comparativeScreeningSection"} setResourceState={setSectionState}
          entryHeader="comparison (screening vs. enrolled)" entrySetProfile={"ComparativeEvidence"}
          variableDefinition={comparativeToScreenedVariableDefinition}
          suggestedEvidenceTitle={suggestedComparativeToScreenedEvidenceTitle} resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          knownDataCount={sampleSizeDefaultState.comparativeKnownDataCount} resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={3} />}
      </Table.Cell>}
    </>
  } else {
    return <>
      <Table.Cell style={{ verticalAlign: "top" }}>
        {startingValue.title || startingValue.focus?.display}
        <p><span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => {
            setStatisticDefaultsState({
              "statisticType": defaultStatisticType || "",
              "units": defaultStatisticUnits || ""
            })
          }}>
          {(defaultStatisticType && !statisticDefaultsState.statisticType) &&
            <>➕ Add Default Statistic Type {getStringFromFHIR.CodeableConcept(defaultStatisticType)} and Units {defaultStatisticUnits || "none"}</>}
        </span></p>
        <br />
        <span>
          <div><br />
            <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
              className="formButton"
              positive
              content={"Regenerate Table Row"}
              onClick={() => {
                let newRowSections = generateTableRowContent(sectionState, resourceDictionary);
                setSectionState(newRowSections);
              }}
            />
          </div>
        </span>
      </Table.Cell>
      {(analyzedGroupReference || totalGroupReference) && <Table.Cell style={{ verticalAlign: "top" }}>
        {(analyzedSection || totalSection) && <>
          {analyzedSection ?
            <InnerSectionDataEntry startingValue={analyzedSection}
              elementName={"analyzedSection"} setResourceState={setSectionState}
              entryHeader="total (analyzed) group" entrySetProfile={"NoncomparativeEvidence"}
              variableDefinition={analyzedVariableDefinition}
              suggestedEvidenceTitle={suggestedAnalyzedGroupEvidenceTitle}
              resourceDictionary={resourceDictionary}
              researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
              statisticType={statisticDefaultsState.statisticType}
              statisticUnit={statisticDefaultsState.units}
              knownDataCount={sampleSizeDefaultState.totalKnownDataCount}
              resourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              parentSectionCode={parentSectionCode} sectionArrayIndex={0} />
            :
            <InnerSectionDataEntry startingValue={totalSection}
              elementName={"totalSection"} setResourceState={setSectionState}
              entryHeader="total group" entrySetProfile={"NoncomparativeEvidence"}
              variableDefinition={totalVariableDefinition}
              suggestedEvidenceTitle={suggestedTotalEvidenceTitle}
              resourceDictionary={resourceDictionary}
              researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
              statisticType={statisticDefaultsState.statisticType}
              statisticUnit={statisticDefaultsState.units}
              knownDataCount={sampleSizeDefaultState.totalKnownDataCount}
              resourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              parentSectionCode={parentSectionCode} sectionArrayIndex={0} />
          }
        </>}
      </Table.Cell>}
      {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {interventionSection && <InnerSectionDataEntry startingValue={interventionSection}
          elementName={"interventionSection"} setResourceState={setSectionState}
          entryHeader="intervention group" entrySetProfile={"NoncomparativeEvidence"}
          variableDefinition={interventionVariableDefinition}
          suggestedEvidenceTitle={suggestedInterventionEvidenceTitle} resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          statisticType={statisticDefaultsState.statisticType}
          statisticUnit={statisticDefaultsState.units}
          knownDataCount={sampleSizeDefaultState.interventionKnownDataCount}
          resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={1} />}
      </Table.Cell>}
      {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {comparatorSection && <InnerSectionDataEntry startingValue={comparatorSection}
          elementName={"comparatorSection"} setResourceState={setSectionState}
          entryHeader="comparator group" entrySetProfile={"NoncomparativeEvidence"}
          variableDefinition={comparatorVariableDefinition}
          suggestedEvidenceTitle={suggestedComparatorEvidenceTitle} resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          statisticType={statisticDefaultsState.statisticType}
          statisticUnit={statisticDefaultsState.units}
          knownDataCount={sampleSizeDefaultState.comparatorKnownDataCount} resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={2} />}
      </Table.Cell>}
      {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
        {comparativeSection && <InnerSectionDataEntry startingValue={comparativeSection}
          elementName={"comparativeSection"} setResourceState={setSectionState}
          entryHeader="comparison (intevention vs. comparator)" entrySetProfile={"ComparativeEvidence"}
          variableDefinition={comparativeVariableDefinition}
          suggestedEvidenceTitle={suggestedComparativeEvidenceTitle} resourceDictionary={resourceDictionary}
          researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
          knownDataCount={sampleSizeDefaultState.comparativeKnownDataCount} resourceState={resourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          parentSectionCode={parentSectionCode} sectionArrayIndex={3} />}
      </Table.Cell>}
    </>
  }
});

const TableRowEdit = memo(({ startingValue, setResourceState, sectionArrayIndex,
  elementName, setSourceJsonState, sampleSizeDefaultState, resultsType,
  totalGroupReference, interventionGroupReference, comparatorGroupReference, groupAssignmentReference,
  screenedGroupReference, enrolledGroupReference, analyzedGroupReference,
  comparatorGroupDisplay, resourceDictionary, globalContext, researchStudyRelatedArtfifact,
  resourceState, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let parentSectionCode = "section[3].section[" + sectionArrayIndex + "]:";
  if (resultsType === "comparative-results") {
    parentSectionCode = "section[3].section[1].section[" + sectionArrayIndex + "]:";
  }
  if (resultsType === "enrollment-results") {
    parentSectionCode = "section[3].section[0].section[" + sectionArrayIndex + "]:";
  }
  let startingSection = {
    title: "", code: sectionCodeSummaryOfFindingsForSingleOutcome,
    author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (startingValue) {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [tableRowSectionState, setTableRowSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  /*
    useEffect(() => {
      if (startingValue.title !== tableRowSectionState.title) {
        let startingSection = {
          title: "", code: "", author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
        }
        if (startingValue) {
          if (startingValue.extension) { startingSection.extension = startingValue.extension; }
          if (startingValue.title) { startingSection.title = startingValue.title; }
          if (startingValue.code) { startingSection.code = startingValue.code; }
          if (startingValue.author) { startingSection.author = startingValue.author; }
          if (startingValue.focus) { startingSection.focus = startingValue.focus; }
          if (startingValue.text) { startingSection.text = startingValue.text; }
          if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
          if (startingValue.entry) { startingSection.entry = startingValue.entry; }
          if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
          if (startingValue.section) { startingSection.section = startingValue.section; }
        }
        setTableRowSectionState(JSON.parse(JSON.stringify(startingSection)));
      }
    }, [startingValue]);
  */
  useEffect((() => {
    if (Object.keys(tableRowSectionState).length > 0) {
      let newSection = {};
      if (tableRowSectionState.extension) { newSection.extension = tableRowSectionState.extension; }
      if (tableRowSectionState.title) { newSection.title = tableRowSectionState.title; }
      if (tableRowSectionState.code) { newSection.code = tableRowSectionState.code; }
      if (Array.isArray(tableRowSectionState.author) && tableRowSectionState.author.length > 0) {
        newSection.author = tableRowSectionState.author;
      }
      if (tableRowSectionState.focus) { newSection.focus = tableRowSectionState.focus; }
      if (tableRowSectionState.text) { newSection.text = tableRowSectionState.text; }
      if (tableRowSectionState.orderedBy) { newSection.orderedBy = tableRowSectionState.orderedBy; }
      if (Array.isArray(tableRowSectionState.entry) && tableRowSectionState.entry.length > 0) {
        newSection.entry = tableRowSectionState.entry;
      }
      if (tableRowSectionState.emptyReason) { newSection.emptyReason = tableRowSectionState.emptyReason; }
      if (Array.isArray(tableRowSectionState.section) && tableRowSectionState.section.length > 0) {
        newSection.section = tableRowSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0 || (Object.keys(newSection).length === 1 && newSection.code)) {
        newSection = null;
      }
      newSection = JSON.parse(JSON.stringify(newSection));
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          return { ...prevState, [sectionArrayIndex]: newSection };
        });
      } else if (elementName || elementName === 0 || elementName === "0") {
        handleChange(elementName, newSection, setResourceState);
      }
    }
    //}
  }), [tableRowSectionState]);

  return <InnerSectionArrayDataEntry startingValue={startingSection}
    setResourceState={setTableRowSectionState} resultsType={resultsType}
    totalGroupReference={totalGroupReference}
    interventionGroupReference={interventionGroupReference}
    comparatorGroupReference={comparatorGroupReference}
    groupAssignmentReference={groupAssignmentReference}
    screenedGroupReference={screenedGroupReference}
    enrolledGroupReference={enrolledGroupReference}
    analyzedGroupReference={analyzedGroupReference}
    comparatorCategory={comparatorGroupDisplay}
    resourceDictionary={resourceDictionary}
    sampleSizeDefaultState={sampleSizeDefaultState}
    researchStudyRelatedArtfifact={researchStudyRelatedArtfifact} resourceState={resourceState}
    globalContext={globalContext} setSourceJsonState={setSourceJsonState}
    adaptationReportState={adaptationReportState}
    setAdaptationReportState={setAdaptationReportState}
    setChangeAvailableToSaveState={setChangeAvailableToSaveState}
    parentSectionCode={parentSectionCode} />
});

const createStartingArrayAsObject = (relatesTo, section, reportType) => {
  let usableSection;
  if (section?.[2]?.code?.coding?.[0]?.code === "results" && section[2].section?.length) {
    usableSection = section[2].section;
  } else {
    let variableReferencesDictionary = {};
    let variableReferencesList = [];
    if (Array.isArray(relatesTo)) {
      for (let item of relatesTo) {
        if (item.type === "depends-on") {
          if (item.label === reportType) {
            let variableName = item.resourceReference?.display || item.resourceReference?.reference || item.display;
            let variableReference = item.resourceReference || "";
            if (variableName && variableName !== "[Not yet identified.]") {
              variableReferencesDictionary[variableName] = variableReference;
              variableReferencesList.push(variableName);
            }
          }
        }
      }
    }
    let includedRowFocusList = [];
    usableSection = section.filter((row) => {
      let rowFocus = row.focus?.display || row.focus?.reference || row.title;
      let match = false;
      if (rowFocus && variableReferencesList.includes(rowFocus)) {
        match = true;
        includedRowFocusList.push(rowFocus);
      }
      return match;
    });
    for (const rowFocus of variableReferencesList) {
      if (!includedRowFocusList.includes(rowFocus)) {
        let starterSection = {
          "title": rowFocus,
          "code": sectionCodeSummaryOfFindingsForSingleOutcome,
          "text": emptyTextNoData,
          "focus": variableReferencesDictionary[rowFocus],
          "section": [
            {
              "code": sectionCodeEvidenceWithTotalGroup,
              "text": emptyTextNoData,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "code": sectionCodeEvidenceWithInterventionAlone,
              "text": emptyTextNoData,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "code": sectionCodeEvidenceWithComparatorAlone,
              "text": emptyTextNoData,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "code": sectionCodeEvidenceWithInterventionVsComparator,
              "text": emptyTextNoData,
              "emptyReason": emptyReasonNotStarted
            }
          ]
        };
        usableSection.push(starterSection);
      }
    }
  }

  let startingArrayAsObject = {};
  if (usableSection) {
    for (let itemIndex in usableSection) {
      startingArrayAsObject[itemIndex] = usableSection[itemIndex];
    }
  }
  return startingArrayAsObject;
}

const createStartingArrayAsObject2 = (section, resultsType, notStartedSection) => {
  let usableSection;
  if (section?.[3]?.code?.coding?.[0]?.code === "results" && section[3].section?.length) {
    if (resultsType === "enrollment-results" &&
      section[3].section[0].code?.coding?.[0]?.code === "enrollment-results" &&
      section[3].section[0].section?.length) {
      usableSection = section[3].section[0].section;
    } else if (resultsType === "comparative-results" &&
      section[3].section[1]?.code?.coding?.[0]?.code === "comparative-results" &&
      section[3].section[1].section?.length) {
      usableSection = section[3].section[1].section;
    }
  }

  if (!usableSection) {
    let variablesDictionary = {};
    let variableReferencesList = [];
    let variablesSection = section[2];
    if (variablesSection?.code?.coding?.[0]?.code === "variables" && variablesSection.entry?.length) {
      for (const entry of variablesSection.entry) {
        variablesDictionary[entry.display] = {
          "title": entry.display,
          "text": emptyTextNoData,
          "focus": entry,
          "section": notStartedSection
        };
        variableReferencesList.push(entry.display || entry.reference);
      }
    }

    let includedRowFocusList = [];
    let starterSection = [];
    if (resultsType === "enrollment-results") {
      starterSection = section[3].section[0].section || [];
    }
    if (resultsType === "comparative-results") {
      starterSection = section[3].section[1].section || [];
    }
    usableSection = starterSection.filter((row) => {
      let rowFocus = row.focus?.display || row.focus?.reference || row.title;
      let match = false;
      if (rowFocus && variableReferencesList.includes(rowFocus)) {
        match = true;
        includedRowFocusList.push(rowFocus);
      }
      return match;
    });
    for (const rowFocus of variableReferencesList) {
      if (!includedRowFocusList.includes(rowFocus)) {
        let starterSection = variablesDictionary[rowFocus];
        usableSection.push(starterSection);
      }
    }
  }

  let startingArrayAsObject = {};
  if (usableSection) {
    for (let itemIndex in usableSection) {
      startingArrayAsObject[itemIndex] = usableSection[itemIndex];
    }
  }
  return startingArrayAsObject;
}

const EvidenceTableEdit = ({ resourceState, setResourceState, setSourceJsonState,
  resourceDictionary, globalContext, reportType, adaptationReportState, setAdaptationReportState,
  setChangeAvailableToSaveState }) => {

  let interventionGroupReference = null;
  let interventionGroupDisplay = "";
  let comparatorGroupReference = null;
  let comparatorGroupDisplay = "";
  let totalGroupReference = null;
  let totalGroupDisplay = "";
  let groupAssignmentReference = null;
  let groupAssignmentDisplay = "";
  let researchStudyRelatedArtfifact;
  let totalKnownDataCount = "";
  let interventionKnownDataCount = "";
  let comparatorKnownDataCount = "";
  let comparativeKnownDataCount = "";
  if (Array.isArray(resourceState.relatesTo)) {
    for (let item of resourceState.relatesTo) {
      if (item.type === "depends-on") {
        if (item.label === "Intervention Group") {
          interventionGroupReference = item.resourceReference?.reference;
          if (interventionGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[interventionGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[interventionGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              interventionKnownDataCount = groupJson.quantity;
            }
          }
          interventionGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Comparator Group") {
          comparatorGroupReference = item.resourceReference?.reference;
          if (comparatorGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[comparatorGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[comparatorGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              comparatorKnownDataCount = groupJson.quantity;
            }
          }
          comparatorGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Total Group") {
          totalGroupReference = item.resourceReference?.reference;
          if (totalGroupReference?.split("/")[1] && resourceDictionary && resourceDictionary[totalGroupReference.split("/")[1]]) {
            let groupJson = resourceDictionary[totalGroupReference.split("/")[1]];
            if (groupJson.quantity) {
              totalKnownDataCount = groupJson.quantity;
            }
          }
          totalGroupDisplay = item.display || item.resourceReference?.display || "";
        }
      } else if (item.type === "composed-of") {
        if (item.label === "Group Assignment" || item.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentReference = item.resourceReference?.reference;
          groupAssignmentDisplay = item.display || item.resourceReference?.display || "";
        }
      } else if (item.type === "derived-from") {
        if (item.classifier?.[0]?.coding?.[0]?.code === "protocol") {
          researchStudyRelatedArtfifact = item;
        }
      }
    }
  }
  if (Array.isArray(resourceState.section) && resourceState.section[0]?.code?.coding?.[0]?.code === "groups" &&
    resourceState.section[0].section.length) {
    for (let item of resourceState.section[0].section) {
      let sectionCode = item.code?.coding?.[0]?.code;
      if (sectionCode && item.entry?.[0]) {
        if (sectionCode === "intervention-group") {
          interventionGroupReference = item.entry[0];
          if (interventionGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[interventionGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[interventionGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              interventionKnownDataCount = groupJson.quantity;
            }
          }
          interventionGroupDisplay = item.entry[0].display || interventionGroupReference.reference || "";
        } else if (sectionCode === "comparator-group") {
          comparatorGroupReference = item.entry[0];
          if (comparatorGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[comparatorGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[comparatorGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              comparatorKnownDataCount = groupJson.quantity;
            }
          }
          comparatorGroupDisplay = item.entry[0].display || comparatorGroupReference.reference || "";
        } else if (sectionCode === "population") {
          totalGroupReference = item.entry[0];
          if (totalGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[totalGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[totalGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              totalKnownDataCount = groupJson.quantity;
            }
          }
          totalGroupDisplay = item.entry[0].display || totalGroupReference.reference || "";
        } else if (sectionCode === "GroupAssignment") {
          groupAssignmentReference = item.entry[0];
          groupAssignmentDisplay = item.entry[0].display || groupAssignmentReference.reference || "";
        }
      }
    }
  }
  if (interventionKnownDataCount && comparatorKnownDataCount) {
    comparativeKnownDataCount = interventionKnownDataCount + comparatorKnownDataCount;
  } else if (totalKnownDataCount) {
    comparativeKnownDataCount = totalKnownDataCount;
  }

  const [arrayState, setArrayState] = useState(null);
  const [startingArrayState, setStartingArrayState] = useState(null);

  const [sampleSizeDefaultState, setSampleSizeDefaultState] = useState({});

  const notStartedSection = [
    {
      "code": sectionCodeEvidenceWithTotalGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithInterventionAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithComparatorAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithInterventionVsComparator,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    }
  ];

  const reviseResourceStateTempsRelateTo = (resourceStateTemp) => {
    let resourceStateTempRelatesTo = resourceStateTemp.relatesTo || [];
    if (Array.isArray(resourceStateTemp.section) &&
      resourceStateTemp.section[1]?.code?.coding?.[0]?.code === "variables" &&
      resourceStateTemp.section[1].entry?.length) {
      for (let item of resourceStateTemp.section[1].entry) {
        let relatesToEntry = {
          "type": "depends-on",
          "label": reportType,
          "display": item.display || "[Unlabeled Variable]",
          "resourceReference": item
        }
        resourceStateTempRelatesTo.push(relatesToEntry);
      }
    }
    return resourceStateTempRelatesTo;
  }

  useEffect(() => {
    let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
    let resourceStateTempRelatesTo = reviseResourceStateTempsRelateTo(resourceStateTemp);
    let startingArrayAsObject = createStartingArrayAsObject(resourceStateTempRelatesTo, resourceStateTemp.section, reportType);
    setArrayState(startingArrayAsObject);
    setStartingArrayState(startingArrayAsObject);
  }, [resourceState]);

  useEffect((() => {
    if (arrayState && Object.keys(arrayState).length > 0 && startingArrayState &&
      JSON.stringify(arrayState) !== JSON.stringify(startingArrayState)) {
      let relatesToStarterDictionary = {};
      let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
      let resourceStateTempRelatesTo = reviseResourceStateTempsRelateTo(resourceStateTemp);
      for (let item of resourceStateTempRelatesTo) {
        if (item.type === "depends-on") {
          if (item.label === reportType && item.display) {
            relatesToStarterDictionary[item.display] = {
              "title": item.display,
              "text": emptyTextNoData,
              "focus": item.resourceReference || {
                "reference": "",
                "type": "EvidenceVariable",
                "display": item.display
              },
              "section": notStartedSection
            };
          }
        }
      }
      let oldArrayStateDictionary = {};
      for (const index in arrayState) {
        let focus = arrayState[index].focus?.display || arrayState[index].focus?.reference || arrayState[index].title;
        oldArrayStateDictionary[focus] = JSON.parse(JSON.stringify(arrayState[index]));
      }
      let newArrayStateDictionary = {};
      for (const focus of Object.keys(oldArrayStateDictionary)) {
        newArrayStateDictionary[focus] = oldArrayStateDictionary[focus];
      }
      for (const focus of Object.keys(relatesToStarterDictionary)) {
        if (!oldArrayStateDictionary[focus]) {
          newArrayStateDictionary[focus] = relatesToStarterDictionary[focus];
        }
      }
      if (Object.keys(newArrayStateDictionary).length > 0) {
        let newArray = [];
        for (let key of Object.keys(newArrayStateDictionary)) {
          if (typeof newArrayStateDictionary[key] === "object" && Object.keys(newArrayStateDictionary[key]).length > 0) {
            newArray.push(newArrayStateDictionary[key]);
          }
        }
        if (newArray.length > 0) {
          setResourceState(prevState => {
            let newSection = prevState.section;
            newSection[2].section = JSON.parse(JSON.stringify(newArray));
            return { ...prevState, "section": newSection }
          });
        }
      }

    }
  }), [arrayState]);

  return <>{arrayState && <>
    {(Object.keys(arrayState).length > 0) && <>
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
        className="formButton"
        positive
        content={"Regenerate Table Content"}
        onClick={() => {
          if (resourceState.section[2].section) {
            let oldSectionSet = JSON.parse(JSON.stringify(resourceState.section[2].section));
            let newSectionSet = generateEvidenceTableContent(oldSectionSet, resourceDictionary);
            if (newSectionSet.length) {
              setResourceState(prevState => {
                let newSection = prevState.section;
                newSection[2].section = JSON.parse(JSON.stringify(newSectionSet));
                return { ...prevState, "section": newSection }
              });
            } else {
              alert("problem creating newSectionSet");
            }
          } else {
            alert("missing resourceState.section[2].section");
          }
        }}
      />
    </>}
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {totalGroupReference && <Table.HeaderCell>Total Group</Table.HeaderCell>}
          {interventionGroupReference && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {comparatorGroupReference && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {totalGroupDisplay || totalGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "totalKnownDataCount": totalKnownDataCount,
                    "totalKnownDataCountSelected": true
                  }
                })
              }}>
              {(totalKnownDataCount && !sampleSizeDefaultState.totalKnownDataCountSelected) &&
                <>➕ Add {totalKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {interventionGroupDisplay || interventionGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "interventionKnownDataCount": interventionKnownDataCount,
                    "interventionKnownDataCountSelected": true
                  }
                })
              }}>
              {(interventionKnownDataCount && !sampleSizeDefaultState.interventionKnownDataCountSelected) &&
                <>➕ Add {interventionKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {comparatorGroupDisplay || comparatorGroupReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparatorKnownDataCount": comparatorKnownDataCount,
                    "comparatorKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparatorKnownDataCount && !sampleSizeDefaultState.comparatorKnownDataCountSelected) &&
                <>➕ Add {comparatorKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
          {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
            {groupAssignmentDisplay || groupAssignmentReference}
            <p><span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => {
                setSampleSizeDefaultState(prevState => {
                  return {
                    ...prevState, "comparativeKnownDataCount": comparativeKnownDataCount,
                    "comparativeKnownDataCountSelected": true
                  }
                })
              }}>
              {(comparativeKnownDataCount && !sampleSizeDefaultState.comparativeKnownDataCountSelected) &&
                <>➕ Add {comparativeKnownDataCount} as Sample Size</>}
            </span></p>
          </Table.Cell>}
        </Table.Row>
        {(arrayState && Object.keys(arrayState).length > 0) &&
          Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
            return <Table.Row key={keyValuePairIndex}>
              <TableRowEdit startingValue={keyValuePair[1]}
                setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                totalGroupReference={totalGroupReference}
                interventionGroupReference={interventionGroupReference}
                comparatorGroupReference={comparatorGroupReference}
                groupAssignmentReference={groupAssignmentReference}
                comparatorGroupDisplay={comparatorGroupDisplay}
                resourceDictionary={resourceDictionary}
                researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
                sampleSizeDefaultState={sampleSizeDefaultState} resourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </Table.Row>
          })}
      </Table.Body>
    </Table>
  </>}
  </>
};

const EvidenceTableEdit2 = ({ resourceState, setResourceState, setSourceJsonState,
  resourceDictionary, globalContext, resultsType }) => {

  let screenedGroupReference = null;
  let screenedGroupDisplay = "";
  let enrolledGroupReference = null;
  let enrolledGroupDisplay = "";
  let analyzedGroupReference = null;
  let analyzedGroupDisplay = "";
  let screenedKnownDataCount = "";
  let enrolledKnownDataCount = "";
  let analyzedKnownDataCount = "";
  let interventionGroupReference = null;
  let interventionGroupDisplay = "";
  let comparatorGroupReference = null;
  let comparatorGroupDisplay = "";
  let totalGroupReference = null;
  let totalGroupDisplay = "";
  let groupAssignmentReference = null;
  let groupAssignmentDisplay = "";
  let researchStudyRelatedArtfifact;
  let totalKnownDataCount = "";
  let interventionKnownDataCount = "";
  let comparatorKnownDataCount = "";
  let comparativeKnownDataCount = "";

  if (Array.isArray(resourceState.section) && resourceState.section[0]?.code?.coding?.[0]?.code === "groups" &&
    resourceState.section[0].section.length) {
    for (let item of resourceState.section[0].section) {
      let sectionCode = item?.code?.coding?.[0]?.code;
      if (sectionCode && item.entry?.[0]) {
        if (sectionCode === "intervention-group") {
          interventionGroupReference = item.entry[0];
          if (interventionGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[interventionGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[interventionGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              interventionKnownDataCount = groupJson.quantity;
            }
          }
          interventionGroupDisplay = item.entry[0].display || interventionGroupReference.reference || "";
        } else if (sectionCode === "comparator-group") {
          comparatorGroupReference = item.entry[0];
          if (comparatorGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[comparatorGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[comparatorGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              comparatorKnownDataCount = groupJson.quantity;
            }
          }
          comparatorGroupDisplay = item.entry[0].display || comparatorGroupReference.reference || "";
        } else if (sectionCode === "population") {
          totalGroupReference = item.entry[0];
          if (totalGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[totalGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[totalGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              totalKnownDataCount = groupJson.quantity;
            }
          }
          totalGroupDisplay = item.entry[0].display || totalGroupReference.reference || "";
        } else if (sectionCode === "screened-group") {
          screenedGroupReference = item.entry[0];
          if (screenedGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[screenedGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[screenedGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              screenedKnownDataCount = groupJson.quantity;
            }
          }
          screenedGroupDisplay = item.entry[0].display || screenedGroupReference.reference || "";
        } else if (sectionCode === "enrolled-group") {
          enrolledGroupReference = item.entry[0];
          if (enrolledGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[enrolledGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[enrolledGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              enrolledKnownDataCount = groupJson.quantity;
            }
          }
          enrolledGroupDisplay = item.entry[0].display || enrolledGroupReference.reference || "";
        } else if (sectionCode === "analyzed-group") {
          analyzedGroupReference = item.entry[0];
          if (analyzedGroupReference?.reference?.split("/")[1] && resourceDictionary && resourceDictionary[analyzedGroupReference.reference.split("/")[1]]) {
            let groupJson = resourceDictionary[analyzedGroupReference.reference.split("/")[1]];
            if (groupJson.quantity) {
              analyzedKnownDataCount = groupJson.quantity;
            }
          }
          analyzedGroupDisplay = item.entry[0].display || analyzedGroupReference.reference || "";
        }
      }
      if (resourceState.section[1]?.code?.coding?.[0]?.code === "GroupAssignment" &&
        resourceState.section[1].entry?.length) {
        groupAssignmentReference = resourceState.section[1].entry[0];
        groupAssignmentDisplay = resourceState.section[1].entry[0].display || resourceState.section[1].entry[0].reference || "";
      }
    }
    if (interventionKnownDataCount && comparatorKnownDataCount) {
      comparativeKnownDataCount = interventionKnownDataCount + comparatorKnownDataCount;
    } else if (analyzedKnownDataCount) {
      comparativeKnownDataCount = analyzedKnownDataCount;
    } else if (totalKnownDataCount) {
      comparativeKnownDataCount = totalKnownDataCount;
    } else if (enrolledKnownDataCount) {
      comparativeKnownDataCount = enrolledKnownDataCount;
    }
  }

  const [arrayState, setArrayState] = useState(null);
  const [startingArrayState, setStartingArrayState] = useState(null);

  const [sampleSizeDefaultState, setSampleSizeDefaultState] = useState({});

  let notStartedSection = [
    {
      "code": sectionCodeEvidenceForAnalyzedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithInterventionAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithComparatorAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "code": sectionCodeEvidenceWithInterventionVsComparator,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    }
  ];

  if (resultsType === "enrollment-results") {
    notStartedSection = [
      {
        "code": sectionCodeEvidenceForScreenedGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "code": sectionCodeEvidenceForEnrolledGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "code": sectionCodeEvidenceForScreenedVsEnrolled,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ];
  }

  useEffect(() => {
    let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
    let startingArrayAsObject = createStartingArrayAsObject2(resourceStateTemp.section, resultsType, notStartedSection);
    setArrayState(startingArrayAsObject);
    setStartingArrayState(startingArrayAsObject);
  }, [resourceState]);

  useEffect((() => {
    if (arrayState && Object.keys(arrayState).length > 0 && startingArrayState &&
      JSON.stringify(arrayState) !== JSON.stringify(startingArrayState)) {
      let variablesDictionary = {};
      let variablesSection = JSON.parse(JSON.stringify(resourceState.section[2]));
      if (variablesSection?.code?.coding?.[0]?.code === "variables" && variablesSection.entry?.length) {
        for (const entry of variablesSection.entry) {
          variablesDictionary[entry.display] = {
            "title": entry.display,
            "text": emptyTextNoData,
            "focus": entry,
            "section": notStartedSection
          };
        }
      }
      let oldArrayStateDictionary = {};
      for (const index in arrayState) {
        let focus = arrayState[index].focus?.display || arrayState[index].focus?.reference || arrayState[index].title;
        oldArrayStateDictionary[focus] = JSON.parse(JSON.stringify(arrayState[index]));
      }
      let newArrayStateDictionary = {};
      for (const focus of Object.keys(oldArrayStateDictionary)) {
        newArrayStateDictionary[focus] = oldArrayStateDictionary[focus];
      }
      for (const focus of Object.keys(variablesDictionary)) {
        if (!oldArrayStateDictionary[focus]) {
          newArrayStateDictionary[focus] = variablesDictionary[focus];
        }
      }
      if (Object.keys(newArrayStateDictionary).length > 0) {
        let newArray = [];
        for (let key of Object.keys(newArrayStateDictionary)) {
          if (typeof newArrayStateDictionary[key] === "object" && Object.keys(newArrayStateDictionary[key]).length > 0) {
            newArray.push(newArrayStateDictionary[key]);
          }
        }
        if (newArray.length > 0) {
          if (resultsType === "enrollment-results") {
            setResourceState(prevState => {
              let newSection = prevState.section;
              newSection[3].section[0].section = JSON.parse(JSON.stringify(newArray));
              return { ...prevState, "section": newSection }
            });
          }
          if (resultsType === "comparative-results") {
            setResourceState(prevState => {
              let newSection = prevState.section;
              newSection[3].section[1].section = JSON.parse(JSON.stringify(newArray));
              return { ...prevState, "section": newSection }
            });
          }
        }
      }

    }
  }), [arrayState]);

  if (resultsType === "comparative-results") {
    return <>{arrayState && <>
      {(Object.keys(arrayState).length > 0) && <>
        <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
          className="formButton"
          positive
          content={"Regenerate Table Content"}
          onClick={() => {
            if (resourceState.section[2].section) {
              let oldSectionSet = JSON.parse(JSON.stringify(resourceState.section[3].section[1].section));
              let newSectionSet = generateComparativeResultsTableContent(oldSectionSet, resourceDictionary);
              if (newSectionSet.length) {
                setResourceState(prevState => {
                  let newSection = prevState.section;
                  newSection[3].section[1].section = JSON.parse(JSON.stringify(newSectionSet));
                  return { ...prevState, "section": newSection }
                });
              } else {
                alert("problem creating newSectionSet");
              }
            } else {
              alert("missing resourceState.section[3].section[1].section");
            }
          }}
        />
      </>}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Variable Name</Table.HeaderCell>
            {(analyzedGroupReference || totalGroupReference) && <Table.HeaderCell>Total Group</Table.HeaderCell>}
            {interventionGroupReference && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
            {comparatorGroupReference && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
            {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"topline"}>
            <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
            {(analyzedGroupReference || totalGroupReference) && <Table.Cell style={{ verticalAlign: "top" }}>
              {analyzedGroupDisplay || analyzedGroupReference || totalGroupDisplay || totalGroupReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "totalKnownDataCount": totalKnownDataCount || analyzedKnownDataCount,
                      "totalKnownDataCountSelected": true
                    }
                  })
                }}>
                {((totalKnownDataCount || analyzedKnownDataCount) && !sampleSizeDefaultState.totalKnownDataCountSelected) &&
                  <>➕ Add {(totalKnownDataCount || analyzedKnownDataCount)} as Sample Size</>}
              </span></p>
            </Table.Cell>}
            {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {interventionGroupDisplay || interventionGroupReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "interventionKnownDataCount": interventionKnownDataCount,
                      "interventionKnownDataCountSelected": true
                    }
                  })
                }}>
                {(interventionKnownDataCount && !sampleSizeDefaultState.interventionKnownDataCountSelected) &&
                  <>➕ Add {interventionKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
            {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {comparatorGroupDisplay || comparatorGroupReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "comparatorKnownDataCount": comparatorKnownDataCount,
                      "comparatorKnownDataCountSelected": true
                    }
                  })
                }}>
                {(comparatorKnownDataCount && !sampleSizeDefaultState.comparatorKnownDataCountSelected) &&
                  <>➕ Add {comparatorKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
            {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {groupAssignmentDisplay || groupAssignmentReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "comparativeKnownDataCount": comparativeKnownDataCount,
                      "comparativeKnownDataCountSelected": true
                    }
                  })
                }}>
                {(comparativeKnownDataCount && !sampleSizeDefaultState.comparativeKnownDataCountSelected) &&
                  <>➕ Add {comparativeKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
          </Table.Row>
          {(arrayState && Object.keys(arrayState).length > 0) &&
            Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
              return <Table.Row key={keyValuePairIndex}>
                <TableRowEdit startingValue={keyValuePair[1]}
                  setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                  resultsType="comparative-results"
                  totalGroupReference={totalGroupReference || analyzedGroupReference}
                  analyzedGroupReference={analyzedGroupReference}
                  interventionGroupReference={interventionGroupReference}
                  comparatorGroupReference={comparatorGroupReference}
                  groupAssignmentReference={groupAssignmentReference}
                  comparatorGroupDisplay={comparatorGroupDisplay}
                  enrolledGroupReference={enrolledGroupReference}
                  screenedGroupReference={screenedGroupReference}
                  resourceDictionary={resourceDictionary}
                  researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
                  sampleSizeDefaultState={sampleSizeDefaultState} resourceState={resourceState}
                  globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
              </Table.Row>
            })}
        </Table.Body>
      </Table>
    </>}
    </>
  }

  if (resultsType === "enrollment-results") {
    return <>{arrayState && <>
      {(Object.keys(arrayState).length > 0) && <>
        <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
          className="formButton"
          positive
          content={"Regenerate Table Content"}
          onClick={() => {
            if (resourceState.section[2].section) {
              let oldSectionSet = JSON.parse(JSON.stringify(resourceState.section[3].section[1].section));
              let newSectionSet = generateEnrolledResultsTableContent(oldSectionSet, resourceDictionary);
              if (newSectionSet.length) {
                setResourceState(prevState => {
                  let newSection = prevState.section;
                  newSection[3].section[0].section = JSON.parse(JSON.stringify(newSectionSet));
                  return { ...prevState, "section": newSection }
                });
              } else {
                alert("problem creating newSectionSet");
              }
            } else {
              alert("missing resourceState.section[3].section[0].section");
            }
          }}
        />
      </>}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Variable Name</Table.HeaderCell>
            {screenedGroupReference && <Table.HeaderCell>Screened Group</Table.HeaderCell>}
            {(enrolledGroupReference || totalGroupReference) && <Table.HeaderCell>Enrolled Group</Table.HeaderCell>}
            {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence (Screened vs. Enrolled)</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"topline"}>
            <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
            {screenedGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {screenedGroupDisplay || screenedGroupReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "screenedKnownDataCount": screenedKnownDataCount,
                      "screenedKnownDataCountSelected": true
                    }
                  })
                }}>
                {(screenedKnownDataCount && !sampleSizeDefaultState.screenedKnownDataCountSelected) &&
                  <>➕ Add {screenedKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
            {(enrolledGroupReference || totalGroupReference) && <Table.Cell style={{ verticalAlign: "top" }}>
              {enrolledGroupDisplay || enrolledGroupReference || totalGroupDisplay || totalGroupReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "enrolledKnownDataCount": enrolledKnownDataCount,
                      "enrolledKnownDataCountSelected": true
                    }
                  })
                }}>
                {(enrolledKnownDataCount && !sampleSizeDefaultState.enrolledKnownDataCountSelected) &&
                  <>➕ Add {enrolledKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
            {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {groupAssignmentDisplay || groupAssignmentReference}
              <p><span className={"unselectable"} style={{ cursor: "pointer" }}
                onClick={() => {
                  setSampleSizeDefaultState(prevState => {
                    return {
                      ...prevState, "comparativeKnownDataCount": comparativeKnownDataCount,
                      "comparativeKnownDataCountSelected": true
                    }
                  })
                }}>
                {(comparativeKnownDataCount && !sampleSizeDefaultState.comparativeKnownDataCountSelected) &&
                  <>➕ Add {comparativeKnownDataCount} as Sample Size</>}
              </span></p>
            </Table.Cell>}
          </Table.Row>
          {(arrayState && Object.keys(arrayState).length > 0) &&
            Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
              return <Table.Row key={keyValuePairIndex}>
                <TableRowEdit startingValue={keyValuePair[1]}
                  setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                  resultsType="enrollment-results"
                  screenedGroupReference={screenedGroupReference}
                  enrolledGroupReference={enrolledGroupReference}
                  groupAssignmentReference={groupAssignmentReference}
                  comparatorGroupDisplay={enrolledGroupDisplay}
                  resourceDictionary={resourceDictionary}
                  researchStudyRelatedArtfifact={researchStudyRelatedArtfifact}
                  sampleSizeDefaultState={sampleSizeDefaultState} resourceState={resourceState}
                  globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
              </Table.Row>
            })}
        </Table.Body>
      </Table>
    </>}
    </>
  }

  return <></>
};


const GroupsSectionDataEntry = ({ startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingScreenedGroup = {
    "title": "Screened Group",
    "code": sectionCodeScreenedGroup,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingEnrolledGroup = {
    "title": "Enrolled Group",
    "code": sectionCodeEnrolledGroup,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingAnalyzedGroup = {
    "title": "Analyzed Group",
    "code": sectionCodeAnalyzedGroup,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingPopulationDefinition = {
    "title": "Population Definition",
    "code": sectionCodePopulationDescription,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingInterventionGroup = {
    "title": "Intervention Group",
    "code": sectionCodeInterventionGroup,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingComparatorGroup = {
    "title": "Comparator Group",
    "code": sectionCodeComparatorGroup,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingInterventionDescription = {
    "title": "Intervention Description",
    "code": sectionCodeInterventionDescription,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingComparatorDescription = {
    "title": "Comparator Description",
    "code": sectionCodeComparatorDescription,
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingTopSection = {
    "title": "Groups",
    "code": sectionCodeGroups,
    "author": [],
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": [
      startingScreenedGroup,
      startingEnrolledGroup,
      startingAnalyzedGroup,
      startingPopulationDefinition,
      startingInterventionGroup,
      startingComparatorGroup,
      startingInterventionDescription,
      startingComparatorDescription
    ]
  };
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.code) { startingTopSection.code = startingValue.code; }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingTopSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {
      startingTopSection.section = startingValue.section;
      for (let unit of startingTopSection.section) {
        let sectionCode = unit?.code?.coding?.[0]?.code;
        if (sectionCode === "screened-group") {
          startingScreenedGroup = unit;
        } else if (sectionCode === "enrolled-group" || sectionCode === "population") {
          startingEnrolledGroup = unit;
        } else if (sectionCode === "analyzed-group") {
          startingAnalyzedGroup = unit;
        } else if (sectionCode === "population-definition") {
          startingPopulationDefinition = unit;
        } else if (sectionCode === "intervention-group") {
          startingInterventionGroup = unit;
        } else if (sectionCode === "comparator-group") {
          startingComparatorGroup = unit;
        } else if (sectionCode === "intervention-description") {
          startingInterventionDescription = unit;
        } else if (sectionCode === "comparator-description") {
          startingComparatorDescription = unit;
        }
      }
    }
  } else {
    startingScreenedGroup = {
      "title": "Screened Group",
      "code": sectionCodeScreenedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingEnrolledGroup = {
      "title": "Enrolled Group",
      "code": sectionCodeEnrolledGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingAnalyzedGroup = {
      "title": "Analyzed Group",
      "code": sectionCodeAnalyzedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingPopulationDefinition = {
      "title": "Population Definition",
      "code": sectionCodePopulationDescription,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingInterventionGroup = {
      "title": "Intervention Group",
      "code": sectionCodeInterventionGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingComparatorGroup = {
      "title": "Comparator Group",
      "code": sectionCodeComparatorGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingInterventionDescription = {
      "title": "Intervention Description",
      "code": sectionCodeInterventionDescription,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingComparatorDescription = {
      "title": "Comparator Description",
      "code": sectionCodeComparatorDescription,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    startingTopSection = {
      "title": "Groups",
      "code": sectionCodeGroups,
      "section": [
        startingScreenedGroup,
        startingEnrolledGroup,
        startingAnalyzedGroup,
        startingPopulationDefinition,
        startingInterventionGroup,
        startingComparatorGroup,
        startingInterventionDescription,
        startingComparatorDescription
      ]
    };
  }

  const [topSectionState, setTopSectionState] = useState(JSON.parse(JSON.stringify(startingTopSection || null)));
  const [screenedGroupState, setScreenedGroupState] = useState(JSON.parse(JSON.stringify(startingScreenedGroup || null)));
  const [enrolledGroupState, setEnrolledGroupState] = useState(JSON.parse(JSON.stringify(startingEnrolledGroup || null)));
  const [analyzedGroupState, setAnalyzedGroupState] = useState(JSON.parse(JSON.stringify(startingAnalyzedGroup || null)));
  const [populationDefinitionState, setPopulationDefinitionState] = useState(JSON.parse(JSON.stringify(startingPopulationDefinition || null)));
  const [interventionGroupState, setInterventionGroupState] = useState(JSON.parse(JSON.stringify(startingInterventionGroup || null)));
  const [comparatorGroupState, setComparatorGroupState] = useState(JSON.parse(JSON.stringify(startingComparatorGroup || null)));
  const [interventionDescriptionState, setInterventionDescriptionState] = useState(JSON.parse(JSON.stringify(startingInterventionDescription || null)));
  const [comparatorDescriptionState, setComparatorDescriptionState] = useState(JSON.parse(JSON.stringify(startingComparatorDescription || null)));

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(screenedGroupState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[0] = newSection;
    setTopSectionState(newResource);
  }, [screenedGroupState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(enrolledGroupState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[1] = newSection;
    setTopSectionState(newResource);
  }, [enrolledGroupState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(analyzedGroupState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[7] = newSection;
    setTopSectionState(newResource);
  }, [analyzedGroupState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(populationDefinitionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[2] = newSection;
    setTopSectionState(newResource);
  }, [populationDefinitionState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(interventionGroupState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[3] = newSection;
    setTopSectionState(newResource);
  }, [interventionGroupState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(comparatorGroupState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[4] = newSection;
    setTopSectionState(newResource);
  }, [comparatorGroupState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(interventionDescriptionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[5] = newSection;
    setTopSectionState(newResource);
  }, [interventionDescriptionState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(topSectionState));
    let newSection = JSON.parse(JSON.stringify(comparatorDescriptionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[6] = newSection;
    setTopSectionState(newResource);
  }, [comparatorDescriptionState]);

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[0] = newSection;
    setResourceState(newResource);
  }, [topSectionState]);

  return <div style={{ marginLeft: "24px" }}>
    <h3>Screened Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Screened Group'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(screenedGroupState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={screenedGroupState.text} setResourceState={setScreenedGroupState} />
      <h3>Create or identify the Group Resource (StudyGroup Profile) for the Screened Group.</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource (for the Screened Group)'}
          startingValue={screenedGroupState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          setProfile={"StudyGroup"}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setScreenedGroupState} />
      </div>
      <br />
      {((!screenedGroupState.section || screenedGroupState.section.length === 0) &&
        (!screenedGroupState.entry || screenedGroupState.entry.length === 0) &&
        (!screenedGroupState.text || screenedGroupState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={screenedGroupState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setScreenedGroupState} />
          </div>
        </>}
    </div>
    <h3>Enrolled Group (Study Population)</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Enrolled Group (Study Population)'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(enrolledGroupState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={enrolledGroupState.text} setResourceState={setEnrolledGroupState} />
      <h3>Create or identify the Group Resource (StudyGroup Profile) for the Enrolled Group (Study Population).</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource for the Enrolled Group (Study Population)'}
          startingValue={enrolledGroupState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          setProfile={"StudyGroup"}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setEnrolledGroupState} />
      </div>
      <br />
      {((!enrolledGroupState.section || enrolledGroupState.section.length === 0) &&
        (!enrolledGroupState.entry || enrolledGroupState.entry.length === 0) &&
        (!enrolledGroupState.text || enrolledGroupState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={enrolledGroupState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setEnrolledGroupState} />
          </div>
        </>}
    </div>
    <h3>Analyzed Group (Study Population)</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Analyzed Group (Study Population)'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(analyzedGroupState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={analyzedGroupState.text} setResourceState={setAnalyzedGroupState} />
      <h3>Create or identify the Group Resource (StudyGroup Profile) for the Analyzed Group (Study Population).</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource for the Analyzed Group (Study Population)'}
          startingValue={analyzedGroupState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          setProfile={"StudyGroup"}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setAnalyzedGroupState} />
      </div>
      <br />
      {((!analyzedGroupState.section || analyzedGroupState.section.length === 0) &&
        (!analyzedGroupState.entry || analyzedGroupState.entry.length === 0) &&
        (!analyzedGroupState.text || analyzedGroupState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={analyzedGroupState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setAnalyzedGroupState} />
          </div>
        </>}
    </div>
    <h3>Population Definition (Eligibility Criteria)</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Population Definition (Eligibility Criteria)'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(populationDefinitionState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={populationDefinitionState.text} setResourceState={setPopulationDefinitionState} />
      <h3>Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Population Definition (Study Eligibility Criteria).</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource for the Population Definition (Eligibility Criteria)'}
          startingValue={populationDefinitionState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setPopulationDefinitionState} />
      </div>
      <br />
      {((!populationDefinitionState.section || populationDefinitionState.section.length === 0) &&
        (!populationDefinitionState.entry || populationDefinitionState.entry.length === 0) &&
        (!populationDefinitionState.text || populationDefinitionState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={populationDefinitionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setPopulationDefinitionState} />
          </div>
        </>}
    </div>
    <h3>Intervention Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Intervention Group'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(interventionGroupState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={interventionGroupState.text} setResourceState={setInterventionGroupState} />
      <h3>Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Intervention Group.</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource (for the Intervention Group'}
          startingValue={interventionGroupState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          selectProfile={["ExposureGroup", "ComparatorGroup"]}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setInterventionGroupState} />
      </div>
      <br />
      {((!interventionGroupState.section || interventionGroupState.section.length === 0) &&
        (!interventionGroupState.entry || interventionGroupState.entry.length === 0) &&
        (!interventionGroupState.text || interventionGroupState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={interventionGroupState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setInterventionGroupState} />
          </div>
        </>}
    </div>
    <h3>Comparator Group</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Comparator Group'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(comparatorGroupState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={comparatorGroupState.text} setResourceState={setComparatorGroupState} />
      <h3>Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Comparator Group.</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource (for the Comparator Group'}
          startingValue={comparatorGroupState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          selectProfile={["ExposureGroup", "ComparatorGroup"]}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setComparatorGroupState} />
      </div>
      <br />
      {((!comparatorGroupState.section || comparatorGroupState.section.length === 0) &&
        (!comparatorGroupState.entry || comparatorGroupState.entry.length === 0) &&
        (!comparatorGroupState.text || comparatorGroupState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={comparatorGroupState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setComparatorGroupState} />
          </div>
        </>}
    </div>
    <h3>Intervention Description</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Intervention Description'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(interventionDescriptionState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={interventionDescriptionState.text} setResourceState={setInterventionDescriptionState} />
      <h3>Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Intervention Description.</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource (for the Intervention Description)'}
          startingValue={interventionDescriptionState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setInterventionDescriptionState} />
      </div>
      <br />
      {((!interventionDescriptionState.section || interventionDescriptionState.section.length === 0) &&
        (!interventionDescriptionState.entry || interventionDescriptionState.entry.length === 0) &&
        (!interventionDescriptionState.text || interventionDescriptionState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={interventionDescriptionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setInterventionDescriptionState} />
          </div>
        </>}
    </div>
    <h3>Comparator Description</h3>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={'Comparator Description'}
        editTextDiv startCollapsed
        generateSummaryFunction={generateGroupSummary}
        entryFoi={getFoiFromReference(comparatorDescriptionState.entry, "Group", resourceDictionary)}
        globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={comparatorDescriptionState.text} setResourceState={setComparatorDescriptionState} />
      <h3>Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Comparator Description.</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Reference' elementName='entry'
          fieldLabel={'Group Resource (for the Comparator Description)'}
          startingValue={comparatorDescriptionState.entry} referencedResourceTypes={['Group']}
          startingResourceType="Group"
          selectProfile={["CohortDefinition", "ConceptualCohortDefinition"]}
          startCollapsed startEmptyArrayClosed enableCreation={true}
          setResourceState={setComparatorDescriptionState} />
      </div>
      <br />
      {((!comparatorDescriptionState.section || comparatorDescriptionState.section.length === 0) &&
        (!comparatorDescriptionState.entry || comparatorDescriptionState.entry.length === 0) &&
        (!comparatorDescriptionState.text || comparatorDescriptionState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={comparatorDescriptionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setComparatorDescriptionState} />
          </div>
        </>}
    </div>
  </div>
}

const GroupAssignmentSectionDataEntry = ({ startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingTopSection = {
    "title": "Group Assignment",
    "code": sectionCodeGroupAssignment,
    "author": [],
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.code) { startingTopSection.code = startingValue.code; }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingTopSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {
      startingTopSection.section = startingValue.section;
    }
  } else {
    startingTopSection = {
      "title": "Group Assignment",
      "code": sectionCodeGroupAssignment
    };
  }

  const [topSectionState, setTopSectionState] = useState(JSON.parse(JSON.stringify(startingTopSection || null)));

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[1] = newSection;
    setResourceState(newResource);
  }, [topSectionState]);

  return <div style={{ marginLeft: "24px" }}>
    <NarrativeEntry elementName='text' fieldLabel={'Group Assignment Section Summary'}
      editTextDiv startCollapsed
      generateNarrativeFromEntryArrayFunction={generateVariablesSummary}
      entryFoiList={getFoisFromReference(topSectionState.entry, "EvidenceVariable", resourceDictionary)}
      globalContext={globalContext}
      resourceType="EvidenceVariable" resourceDictionary={resourceDictionary}
      startingValue={topSectionState.text} setResourceState={setTopSectionState} />
    <h3>Create or identify the EvidenceVariable Resource(s) (GroupAssignment Profile).</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='Reference' elementName='entry'
        fieldLabel={'GroupAssignment EvidenceVariable Resource'}
        startingValue={topSectionState.entry} referencedResourceTypes={['EvidenceVariable']}
        startingResourceType="EvidenceVariable"
        setProfile="GroupAssignment"
        startCollapsed startEmptyArrayClosed enableCreation={true}
        setResourceState={setTopSectionState} />
    </div>
    <br />
    {((!topSectionState.section || topSectionState.section.length === 0) &&
      (!topSectionState.entry || topSectionState.entry.length === 0) &&
      (!topSectionState.text || topSectionState.text.status === "empty")) && <>
        <h3>Explain why empty</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            setResourceState={setTopSectionState} />
        </div>
      </>}
  </div>
}

const VariablesSectionDataEntry = ({ startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState, reportType }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingTopSection = {
    "title": "Variables (Measures)",
    "code": sectionCodeVariables,
    "author": [],
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.code) { startingTopSection.code = startingValue.code; }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingTopSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {
      startingTopSection.section = startingValue.section;
    }
  } else {
    startingTopSection = {
      "title": "Variables (Measures)",
      "code": sectionCodeVariables
    };
  }

  const [topSectionState, setTopSectionState] = useState(JSON.parse(JSON.stringify(startingTopSection || null)));

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    newSection = autoEditEmptyReason(newSection);
    newResource.section[2] = newSection;
    setResourceState(newResource);
  }, [topSectionState]);

  return <div style={{ marginLeft: "24px" }}>
    <NarrativeEntry elementName='text' fieldLabel={'Variables (Measures) Section Summary'}
      editTextDiv startCollapsed
      generateNarrativeFromEntryArrayFunction={generateVariablesSummary}
      entryFoiList={getFoisFromReference(topSectionState.entry, "EvidenceVariable", resourceDictionary)}
      globalContext={globalContext}
      resourceType="EvidenceVariable" resourceDictionary={resourceDictionary}
      startingValue={topSectionState.text} setResourceState={setTopSectionState} />
    <h3>Create or identify the EvidenceVariable Resource(s) to organize the Evidence Table.</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='Reference' elementName='entry'
        fieldLabel={'EvidenceVariable Resource (for the ' + reportType}
        startingValue={topSectionState.entry} referencedResourceTypes={['EvidenceVariable']}
        startingResourceType="EvidenceVariable"
        setProfile={reportType === "Participant Flow" ? "ParticipantFlowEvidenceVariable" : "VariableDefinition"}
        startCollapsed startEmptyArrayClosed enableCreation={true}
        setResourceState={setTopSectionState} />
    </div>
    <br />
    {((!topSectionState.section || topSectionState.section.length === 0) &&
      (!topSectionState.entry || topSectionState.entry.length === 0) &&
      (!topSectionState.text || topSectionState.text.status === "empty")) && <>
        <h3>Explain why empty</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            setResourceState={setTopSectionState} />
        </div>
      </>}
  </div>
}

const reorderResultsSections = (resultsSection, setSectionsReorderedState) => {
  let originalResultsSectionString = JSON.stringify(resultsSection);
  let reorderedResults = resultsSection.map(result => {
    if (!result.code) {
      result.code = sectionCodeSummaryOfFindingsForSingleOutcome;
    }
    let emptyTotalEvidence = {
      "code": sectionCodeEvidenceForAnalyzedGroup,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyInterventionEvidence = {
      "code": sectionCodeEvidenceWithInterventionAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyComparatorEvidence = {
      "code": sectionCodeEvidenceWithComparatorAlone,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyEffectEstimate = {
      "code": sectionCodeEvidenceWithInterventionVsComparator,
      "text": emptyTextNoData,
      "emptyReason": emptyReasonNotStarted
    };
    let resultDictionary = {
      total: emptyTotalEvidence,
      intervention: emptyInterventionEvidence,
      comparator: emptyComparatorEvidence,
      effect: emptyEffectEstimate,
      additionalSections: []
    };
    for (const section of result.section) {
      let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
      if (sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" || sectionCode === "Evidence with total group" ||
        sectionCode === "evidence-for-analyzed-group" || sectionCode === "Evidence for Analyzed Group"
      ) {
        if (sectionCode === "evidence-with-total-group" || sectionCode === "result-with-total-group" || sectionCode === "Evidence with total group") {
          section.code = sectionCodeEvidenceForAnalyzedGroup;
        }
        resultDictionary.total = section;
      } else if (sectionCode === "evidence-with-intervention-alone" || sectionCode === "Evidence with intervention alone") {
        resultDictionary.intervention = section;
      } else if (sectionCode === "evidence-with-comparator-alone" || sectionCode === "Evidence with comparator alone") {
        resultDictionary.comparator = section;
      } else if (sectionCode === "evidence-with-intervention-vs-comparator" || sectionCode === "Evidence with intervention vs. comparator") {
        resultDictionary.effect = section;
      } else if (section) {
        console.log("caught it")
        resultDictionary.additionalSections.push(section);
      }
    }
    let reorderedResultArray = [
      resultDictionary.total,
      resultDictionary.intervention,
      resultDictionary.comparator,
      resultDictionary.effect
    ].concat(resultDictionary.additionalSections);
    result.section = reorderedResultArray;
    return result;
  });
  if (originalResultsSectionString !== JSON.stringify(reorderedResults)) {
    console.log("set to true on line 3066")
    setSectionsReorderedState(true);
  }
  return reorderedResults;
}

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptyGroups = {
    "title": "Groups",
    "code": sectionCodeGroups,
    "section": [
      {
        "title": "Screened Group",
        "code": sectionCodeScreenedGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Enrolled Group",
        "code": sectionCodeEnrolledGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Population Definition",
        "code": sectionCodePopulationDescription,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Intervention Group",
        "code": sectionCodeInterventionGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Comparator Group",
        "code": sectionCodeComparatorGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Intervention Description",
        "code": sectionCodeInterventionDescription,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Comparator Description",
        "code": sectionCodeComparatorDescription,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Analyzed Group",
        "code": sectionCodeAnalyzedGroup,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  let emptyGroupAssignment = {
    "title": "Group Assignment",
    "code": sectionCodeGroupAssignment,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyVariables = {
    "title": "Variables (Measures)",
    "code": sectionCodeVariables,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyResults = {
    "title": "Results",
    "code": sectionCodeResults,
    "text": emptyTextNoData,
    "section": [
      {
        "title": "Enrollment Results",
        "code": sectionCodeEnrollmentResults,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Comparative Results",
        "code": sectionCodeComparativeResults,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  let sectionDictionary = {
    groups: emptyGroups,
    groupAssignment: emptyGroupAssignment,
    variables: emptyVariables,
    results: emptyResults,
    oldResults: [],
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
    if (sectionCode === "groups" || sectionCode === "Groups") {
      section.section = section.section.map(sect => { return autoEditEmptyReason(sect); });
      sectionDictionary.groups = section;
    } else if (sectionCode === "GroupAssignment" || sectionCode === "Group Assignment" || sectionCode === "group-assignment") {
      sectionDictionary.groupAssignment = section;
    } else if (sectionCode === "variables" || sectionCode === "Variables") {
      sectionDictionary.variables = section;
    } else if (sectionCode === "results" || sectionCode === "Results") {
      sectionDictionary.results = section;
    } else if (section.focus) {
      sectionDictionary.oldResults.push(section);
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  if (!(sectionDictionary.results.section?.[1]?.code?.coding?.[0]?.code === "comparative-results")) {
    if (sectionDictionary.results.section?.length) {
      let tempSection = JSON.parse(JSON.stringify(sectionDictionary.results.section));
      sectionDictionary.results = emptyResults;
      sectionDictionary.results.section[1].section = tempSection;
    } else {
      sectionDictionary.results = emptyResults;
    }
    console.log("set to true on line 3190")
    setSectionsReorderedState(true);
  }
  if (sectionDictionary.oldResults.length && !sectionDictionary.results.section) {
    sectionDictionary.results.section = sectionDictionary.oldResults;
  }
  if (Array.isArray(sectionDictionary.results.section[1].section) && sectionDictionary.results.section[1].section.length) {
    sectionDictionary.results.section[1].section = reorderResultsSections(sectionDictionary.results.section[1].section, setSectionsReorderedState);
  }
  let reorderedSectionArray = [
    sectionDictionary.groups,
    sectionDictionary.groupAssignment,
    sectionDictionary.variables,
    sectionDictionary.results
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray.length !== reorderedSectionArray.length) {

    console.log("set to true on line 3207")
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const convertRelatesToToSections = (newSection, relatesTo, setSectionsReorderedState) => {
  let contentChanged = false;
  let interventionGroupReference;
  let interventionGroupDisplay;
  let comparatorGroupReference;
  let comparatorGroupDisplay;
  let totalGroupReference;
  let totalGroupDisplay;
  let groupAssignmentReference;
  let groupAssignmentDisplay;
  let variablesArray = [];
  let newRelatesTo = [];
  if (Array.isArray(relatesTo)) {
    for (let item of relatesTo) {
      let pushIt = true;
      if (item.type === "depends-on") {
        if (item.label === "Intervention Group") {
          interventionGroupReference = item.resourceReference;
          interventionGroupDisplay = item.display || item.resourceReference?.display || "";
          if (interventionGroupReference || interventionGroupDisplay) {
            pushIt = false;
          }
        } else if (item.label === "Comparator Group") {
          comparatorGroupReference = item.resourceReference;
          comparatorGroupDisplay = item.display || item.resourceReference?.display || "";
          if (comparatorGroupReference || comparatorGroupDisplay) {
            pushIt = false;
          }
        } else if (item.label === "Total Group") {
          totalGroupReference = item.resourceReference;
          totalGroupDisplay = item.display || item.resourceReference?.display || "";
          if (totalGroupReference || totalGroupDisplay) {
            pushIt = false;
          }
        } else if (item.label === "Baseline Measure" || item.label === "Participant Flow Measure" ||
          item.label === "Outcome Measure") {
          let measureEntry;
          if (item.resourceReference) {
            measureEntry = item.resourceReference;
          } else if (item.display) {
            measureEntry = { "display": totalGroupDisplay };
          }
          if (measureEntry) {
            variablesArray.push(measureEntry);
            pushIt = false;
          }
        }
      } else if (item.type === "composed-of") {
        if (item.label === "Group Assignment" || item.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentReference = item.resourceReference;
          groupAssignmentDisplay = item.display || item.resourceReference?.display || "";
          if (groupAssignmentReference || groupAssignmentDisplay) {
            pushIt = false;
          }
        }
      }
      if (pushIt) {
        newRelatesTo.push(item);
      }
    }
  }
  if (!newSection[0].section[0].entry) {
    if (totalGroupReference) {
      newSection[0].section[0].entry = [totalGroupReference];
      contentChanged = true;
    } else if (totalGroupDisplay) {
      newSection[0].section[0].entry = [{ "display": totalGroupDisplay }];
      contentChanged = true;
    }
  }
  if (!newSection[0].section[1].entry) {
    if (interventionGroupReference) {
      newSection[0].section[1].entry = [interventionGroupReference];
      contentChanged = true;
    } else if (interventionGroupDisplay) {
      newSection[0].section[1].entry = [{ "display": interventionGroupDisplay }];
      contentChanged = true;
    }
  }
  if (!newSection[0].section[2].entry) {
    if (comparatorGroupReference) {
      newSection[0].section[2].entry = [comparatorGroupReference];
      contentChanged = true;
    } else if (comparatorGroupDisplay) {
      newSection[0].section[2].entry = [{ "display": comparatorGroupDisplay }];
      contentChanged = true;
    }
  }
  if (!newSection[0].section[3].entry) {
    if (groupAssignmentReference) {
      newSection[0].section[3].entry = [groupAssignmentReference];
      contentChanged = true;
    } else if (groupAssignmentDisplay) {
      newSection[0].section[3].entry = [{ "display": groupAssignmentDisplay }];
      contentChanged = true;
    } else if (interventionGroupDisplay && comparatorGroupDisplay) {
      newSection[0].section[3].entry = [{ "display": "GroupAssignment: " + interventionGroupDisplay + " vs. " + comparatorGroupDisplay }];
    }
  }
  if ((!newSection[2].entry || newSection[2].entry.length === 0) && variablesArray.length > 0) {
    newSection[2].entry = variablesArray;
    contentChanged = true;
  }
  if (contentChanged) {
    console.log("set to true on line 3317")
    setSectionsReorderedState(true);
  }
  return [newSection, newRelatesTo];
}

const CompositionTableReportAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, profile,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let reportType;
  if (profile === "OutcomeMeasureReport") {
    reportType = "Outcome Measure"
  } else if (profile === "BaselineMeasureReport") {
    reportType = "Baseline Measure"
  } else if (profile === "ParticipantFlowReport") {
    reportType = "Participant Flow"
  }

  let defaultType = compositionTypeCodeOutcomeMeasureReport;
  if (reportType === "Baseline Measure") {
    defaultType = compositionTypeCodeBaselineMeasureReport;
  } else if (reportType === "Participant Flow") {
    defaultType = compositionTypeCodeParticipantFlowReport;
  }

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        let newRelatesTo;
        [newSection, newRelatesTo] = convertRelatesToToSections(newSection, prevState.relatesTo, setSectionsReorderedState);
        return {
          ...prevState,
          type: prevState.type || defaultType,
          relatesTo: newRelatesTo,
          section: newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded && resourceState?.section?.length) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    } else if (sourceJsonState?.scrollTo) {
      scrollTo("resourceTextViewSegment", sourceJsonState.scrollTo, true);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{(sourceJsonState.loaded && resourceState.section?.length > 2) ?
    <div style={{ marginTop: "12px" }}>
      {adaptationReportState?.adaptOn ?
        <>
          <h2>NOTE: The Adaptation feature is not supported for this Resource.</h2>
          <h3 id="groups">Groups</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={0}
              fieldLabel="Groups Section" globalContext={globalContext}
              sectionCode="section[0]:https://fevir.net/resources/CodeSystem/179423#groups"
              startingValue={resourceState.section[0] || null}
              fixedTitle={"Groups"} fixedCode={sectionCodeGroups} editTextDiv={false} textDeletable={false}
              editAuthor={false}
              noEntry={true}
              editEmptyReason={false}
              editSection={true}
              editableSectionCodes={false} addSectionAllowed={false}
              allowedSectionCodes={[
                sectionCodeScreenedGroup,
                sectionCodeEnrolledGroup,
                sectionCodePopulationDescription,
                sectionCodeInterventionGroup,
                sectionCodeComparatorGroup,
                sectionCodeInterventionDescription,
                sectionCodeComparatorDescription,
                sectionCodeAnalyzedGroup
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"screened-group","display":"Screened Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Screened Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (StudyGroup Profile) for the Screened Group.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySetProfile': "StudyGroup",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"enrolled-group","display":"Enrolled Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Enrolled Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (StudyGroup Profile) for the Enrolled Group (Study Population).",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySetProfile': "StudyGroup",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"population-definition","display":"Population Definition"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Population Definition (Eligibility Criteria)",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Study Population Definition (Eligibility Criteria).",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySelectProfile': ["CohortDefinition", "ConceptualCohortDefinition"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"intervention-group","display":"Intervention Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Intervention Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Intervention Group.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySelectProfile': ["ExposureGroup", "ComparatorGroup"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"comparator-group","display":"Comparator Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Comparator Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Comparator Group.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySelectProfile': ["ExposureGroup", "ComparatorGroup"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"intervention-description","display":"Intervention Description"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Intervention Description",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Intervention Description.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySelectProfile': ["CohortDefinition", "ConceptualCohortDefinition"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"comparator-description","display":"Comparator Description"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Comparator Description",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "<p>[No data.]</p>", 'generateTextDivFunction': generateGroupSummary,
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryStartCollapsed': true,
                  'entryHeader': "Create or identify the Group Resource (CohortDefinition or ConceptualCohortDefinition Profile) for the Comparator Description.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartingResourceType': "Group",
                  'entryStartEmptyArrayClosed': true, 'entrySelectProfile': ["CohortDefinition", "ConceptualCohortDefinition"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': false
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <DocumentSectionEntry sectionArrayIndex={1}
            fieldLabel="Group Assignment Section" globalContext={globalContext}
            sectionCode="section[1]:https://fevir.net/resources/CodeSystem/179423#GroupAssignment"
            startingValue={resourceState.section[1] || null}
            fixedTitle="Group Assignment"
            fixedCode={sectionCodeGroupAssignment} editTextDiv={true} textDeletable={true} editTextStatus={false}
            emptyTextDivValue="<p>[No data.]</p>" generateSummaryFromArrayFunction={generateVariablesSummary}
            editAuthor={false}
            noEntry={false} entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryStartCollapsed={true}
            entryHeader="Create or identify the EvidenceVariable Resource (GroupAssignment Profile)."
            entryReferencedResourceTypes={['EvidenceVariable']}
            entryStartingResourceType="EvidenceVariable"
            entrySetProfile="GroupAssignment"
            entryStartEmptyArrayClosed={true}
            editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            noSection={true} editOrderedBy={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
          {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
          {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
          <DocumentSectionEntry sectionArrayIndex={2}
            fieldLabel="Variables (Measures) Section" globalContext={globalContext}
            sectionCode="section[2]:https://fevir.net/resources/CodeSystem/179423#variables"
            startingValue={resourceState.section[2] || null}
            fixedTitle={reportType === "Outcome Measure" ? "Outcome Measures" :
              reportType === "Baseline Measure" ? "Baseline Measures" :
                reportType === "Participant Flow" ? "Participant Flow Measures" : "Untyped Measures"}
            fixedCode={sectionCodeVariables} editTextDiv={true} textDeletable={true} editTextStatus={false}
            emptyTextDivValue="<p>[No data.]</p>" generateSummaryFromArrayFunction={generateVariablesSummary}
            editAuthor={false}
            noEntry={false} entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryStartCollapsed={true}
            entryHeader="Create or identify the EvidenceVariable Resource(s) to organize the Evidence Table."
            entryReferencedResourceTypes={['EvidenceVariable']}
            entryStartingResourceType="EvidenceVariable"
            entrySetProfile={reportType === "Participant Flow" ? "ParticipantFlowEvidenceVariable" : "VariableDefinition"}
            entryStartEmptyArrayClosed={true}
            editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            noSection={true} editOrderedBy={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="evidence">Evidence</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              reportType={reportType}
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>
        :
        <div>
          <h3 id="groups">Groups</h3>
          <div style={{ marginLeft: "24px" }}>
            <GroupsSectionDataEntry startingValue={resourceState.section[0] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState}
            />
          </div>
          <div style={{ marginLeft: "24px" }}>
            <GroupAssignmentSectionDataEntry startingValue={resourceState.section[1] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState}
            />
          </div>
          {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
          {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
          {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
          <div style={{ marginLeft: "24px" }}>
            <VariablesSectionDataEntry startingValue={resourceState.section[2] || null}
              globalContext={globalContext} reportType={reportType}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <h3 id="evidence">Enrollment Results</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit2 resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              reportType={reportType} resultsType="enrollment-results"
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext} />
          </div>
          <h3 id="comparative-results">Comparative Results</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit2 resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              reportType={reportType} resultsType="comparative-results"
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext} />
          </div>
        </div>
      }
    </div>
    :
    <div>
      <p>Loading ...</p>
    </div>}</>
};

export { CompositionTableReportAuthor, generateEvidenceNarrative };