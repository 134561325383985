import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const getCodeSystemConceptValuesFromFhirJson = (fhirEntryState, codeSystemTermState) => {
  //For the CodeSystem Builder in the Term Detail tab
  //Gets the values from the FHIR JSON for a single concept (term) and returns the values to be used for the codeSystemTermState for display/editing in the Term Detail tab. It doesn't set the state in this function.

  let update = false; //Maybe make a better variable name
  let code = "TOP-level-so-no-code";
  let display = "";
  let definition = "";
  let designation = [];
  let property = [];
  let concept = [];

  let termJson = fhirEntryState.conceptSelected; //Maybe call it conceptJson, because it's the original FHIR JSON
  let codeSystemConceptValues = codeSystemTermState || {};
  if (codeSystemConceptValues.loaded === false || codeSystemConceptValues.loaded === undefined) {
    update = true;
  }

  if (JSON.stringify(codeSystemTermState.pathIndexes) !== JSON.stringify(fhirEntryState.conceptPathIndexes)) {
    update = true;
    if (termJson) {
      if (termJson.code) {
        code = termJson.code;
      }
      if (termJson.display) {
        display = termJson.display;
      }
      if (termJson.definition) {
        definition = termJson.definition;
      }
      if (termJson.designation) {
        designation = termJson.designation;
      }
      if (termJson.property) {
        property = termJson.property;
      }
      if (termJson.concept) {
        concept = termJson.concept;
      }
    }
  }
  if (termJson === undefined && fhirEntryState.fhirEntryString) {
    let json = JSON.parse(fhirEntryState.fhirEntryString);
    if (json.concept?.length > 0) {
      concept = json.concept;
    }
  }

  if (update) { //Then update the codeSystemConceptValues
    codeSystemConceptValues = {
      loaded: true,
      code: code,
      display: display,
      definition: definition,
      designation: designation,
      property: property,
      concept: concept,
      pathIndexes: fhirEntryState.conceptPathIndexes,
      termChanged: false
    };
  }
  return codeSystemConceptValues;
}

const codeSystemTextViewChangesToJson = (fhirJson, codeSystemState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.meta = codeSystemState.meta;
  fhirJson.language = codeSystemState.language;
  fhirJson.text = codeSystemState.text;
  fhirJson.contained = codeSystemState.contained || "DELETEME";
  fhirJson.extension = codeSystemState.extension || "DELETEME";
  fhirJson.modifierExtension = codeSystemState.modifierExtension || "DELETEME";
  fhirJson.url = codeSystemState.url || "DELETEME";
  fhirJson.language = codeSystemState.language || "DELETEME";
  fhirJson.identifier = codeSystemState.identifier || "DELETEME";
  fhirJson.version = codeSystemState.version || "DELETEME";
  fhirJson.versionAlgorithmString = codeSystemState.versionAlgorithmString || "DELETEME";
  fhirJson.versionAlgorithmCoding = codeSystemState.versionAlgorithmCoding || "DELETEME";
  fhirJson.name = codeSystemState.name || "DELETEME";
  fhirJson.title = codeSystemState.title || "DELETEME";
  fhirJson.status = codeSystemState.status || "unknown";
  fhirJson.experimental = codeSystemState.experimental ?? "DELETEME";
  fhirJson.date = codeSystemState.date || "DELETEME";
  fhirJson.publisher = codeSystemState.publisher || "DELETEME";
  fhirJson.contact = codeSystemState.contact || "DELETEME";
  fhirJson.description = codeSystemState.description || "DELETEME";
  fhirJson.useContext = codeSystemState.useContext || "DELETEME";
  fhirJson.jurisdiction = codeSystemState.jurisdiction || "DELETEME";
  fhirJson.purpose = codeSystemState.purpose || "DELETEME";
  fhirJson.copyright = codeSystemState.copyright || "DELETEME";
  fhirJson.copyrightLabel = codeSystemState.copyrightLabel || "DELETEME";
  fhirJson.approvalDate = codeSystemState.approvalDate || "DELETEME";
  fhirJson.lastReviewDate = codeSystemState.lastReviewDate || "DELETEME";
  fhirJson.effectivePeriod = codeSystemState.effectivePeriod || "DELETEME";
  fhirJson.topic = codeSystemState.topic || "DELETEME";
  fhirJson.author = codeSystemState.author || "DELETEME";
  fhirJson.editor = codeSystemState.editor || "DELETEME";
  fhirJson.reviewer = codeSystemState.reviewer || "DELETEME";
  fhirJson.endorser = codeSystemState.endorser || "DELETEME";
  fhirJson.citeAs = codeSystemState.citeAs || "DELETEME";
  fhirJson.relatedArtifact = codeSystemState.relatedArtifact || "DELETEME";
  fhirJson.caseSensitive = codeSystemState.caseSensitive ?? "DELETEME";
  fhirJson.valueSet = codeSystemState.valueSet || "DELETEME";
  fhirJson.hierarchyMeaning = codeSystemState.hierarchyMeaning || "DELETEME";
  fhirJson.compositional = codeSystemState.compositional ?? "DELETEME";
  fhirJson.versionNeeded = codeSystemState.versionNeeded ?? "DELETEME";
  fhirJson.content = codeSystemState.content || "DELETEME";
  fhirJson.supplements = codeSystemState.supplements || "DELETEME";
  fhirJson.count = codeSystemState.count || "DELETEME";
  fhirJson.filter = codeSystemState.filter || "DELETEME";
  fhirJson.property = codeSystemState.property || "DELETEME";
  fhirJson.concept = codeSystemState.concept || "DELETEME";

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;
  return fhirJson;
}

//TODO -- consider how to add back the check for duplicate or missing codes
const checkConceptCodes = (json, conceptArray, duplicateCodesFound, termsWithMultipleParents) => {
  let codeCompliant = true;
  for (let conceptIndex in json.concept) {
    let concept = json.concept[conceptIndex];
    if (concept.code) {
      if (!termsWithMultipleParents.includes(concept.code)) {
        for (let propertyIndex in concept.property) {
          let property = concept.property[propertyIndex];
          if (property.code === "multiple-parents") {
            termsWithMultipleParents.push(concept.code);
            break;
          }
        }
      }
      if (!conceptArray.includes(concept.code)) {
        conceptArray.push(concept.code);
        //duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound);
        //if (codeCompliant === false) {
        //return false;
        //return duplicateCodesFound;
        //}
      } else {
        //alert("At least two terms have identical codes. CODE: " + concept.code);
        if (!duplicateCodesFound.indexOf(concept.code)) {
          if (!termsWithMultipleParents.includes(concept.code)) {
            duplicateCodesFound.push(concept.code);
          }
        }
        codeCompliant = false;
        //return false;
        //return duplicateCodesFound;
      }
      duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound, termsWithMultipleParents);
    } else {
      codeCompliant = false;
      alert("A term is missing a code.");
      duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound, termsWithMultipleParents);
      //return false;
      //return duplicateCodesFound;
    }
  }
  //return codeCompliant
  return duplicateCodesFound;
}

const addingNewChildConcept = (concept, newChildConcepts) => {
  for (let newChildConceptIndex in newChildConcepts) {
    let newChildConcept = newChildConcepts[newChildConceptIndex];
    if (newChildConcept.code && newChildConcept.display) {
      let newChildConceptJson = { "code": newChildConcept.code, "display": newChildConcept.display };
      if (newChildConcept.definition) {
        newChildConceptJson["definition"] = newChildConcept.definition;
      }
      if (concept.concept === undefined) {
        concept.concept = [];
      }
      concept.concept.push(newChildConceptJson)
    } else {
      alert("All child concepts are required to have a code and a display.");
      return false;
    }
  }
  return concept
}

const createNewClassificationJson = (codeSystemState) => {
  let contentArray = codeSystemState.newClassifications;
  if (!contentArray) {
    return "";
  }
  let classifiedResourceType = "CodeSystem";
  let classifiedFoi = codeSystemState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = codeSystemState.title || codeSystemState.name || "[Untitled.]";
  let classificationJson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "reference": classifiedReference,
      "type": classifiedResourceType,
      "display": classifedTitle
    },
    "content": contentArray,
    "meta": {
      "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
    }
  };
  return classificationJson;
}

const codeSystemTermDetailViewChangesToJson = (codeSystemTermState) => {
  let codeSystemTermJson = {};
  if (codeSystemTermState.code === "TOP-level-so-no-code") {
    codeSystemTermJson.code = codeSystemTermState.code;
    codeSystemTermJson.concept = codeSystemTermState.concept || [];
    return codeSystemTermJson;
  }
  codeSystemTermJson.extension = codeSystemTermState.extension || "DELETEME";
  codeSystemTermJson.code = codeSystemTermState.code || "DELETEME";
  codeSystemTermJson.display = codeSystemTermState.display || "DELETEME";
  codeSystemTermJson.definition = codeSystemTermState.definition || "DELETEME";
  codeSystemTermJson.designation = codeSystemTermState.designation || "DELETEME";
  codeSystemTermJson.property = codeSystemTermState.property || "DELETEME";
  codeSystemTermJson.concept = codeSystemTermState.concept || "DELETEME";
  for (const key in codeSystemTermJson) {
    if (codeSystemTermJson[key] === "DELETEME" ||
      (typeof codeSystemTermJson[key] === "object" && Object.keys(codeSystemTermJson[key]).length === 0) ||
      codeSystemTermJson[key] === "" || codeSystemTermJson[key] === undefined || 
      codeSystemTermJson[key] === null || 
      (Array.isArray(codeSystemTermJson[key]) && codeSystemTermJson[key].length === 0)) {
      delete codeSystemTermJson[key];
    }
  }
  return codeSystemTermJson;
}

const builderUpdateCodeSystemTermDetailJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let codeSystemTermState = formInputsStateRef.current.codeSystemTermState;
  let newCodeSystemTermJson = codeSystemTermDetailViewChangesToJson(codeSystemTermState);
  let fhirJson = jsonErrorCheck2("CodeSystem", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (!newCodeSystemTermJson.code && !newCodeSystemTermJson.conceptDelete) {
    alert("The 'Code' field can't be blank.")
    return false;
  }
  if (newCodeSystemTermJson.code === "TOP-level-so-no-code") {
    if (fhirEntryState.conceptsRearranged) {
      fhirJson.concept = fhirEntryState.conceptsRearranged;
    }
    fhirJson = addingNewChildConcept(fhirJson, fhirEntryState.newChildConcepts);
  } else if (codeSystemTermState.pathIndexes.length > 0) {
    let concept = fhirJson;
    for (const pathNumberIndex in codeSystemTermState.pathIndexes) {
      let pathNumber = parseInt(codeSystemTermState.pathIndexes[pathNumberIndex]);
      if (parseInt(pathNumberIndex) + 1 === codeSystemTermState.pathIndexes.length) {
        if (codeSystemTermState.conceptDelete === true) {
          concept["concept"].splice(pathNumber, 1);
        } else {
          concept["concept"][pathNumber] = newCodeSystemTermJson;
        }
      } else {
        concept = concept["concept"][pathNumber];
      }
    }
  }
  if (fhirEntryState.newChildConcepts.length > 0 && newCodeSystemTermJson.concept === undefined) {
    newCodeSystemTermJson.concept = [];
  }
  newCodeSystemTermJson = addingNewChildConcept(newCodeSystemTermJson, fhirEntryState.newChildConcepts);
  if (newCodeSystemTermJson === false) {
    return false;
  }
//TODO - figure out how newCodeSystemTermJson relates to the next line
fhirJson = jsonErrorCheck2("CodeSystem", resourceId, fhirEntryState.startingVersionId, fhirJson);
if (fhirJson === undefined || fhirJson === false) {
  return false;
}

return fhirJson;
}

const builderUpdateCodeSystemJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let codeSystemState = formInputsStateRef.current.codeSystemState;
  let fhirJson = jsonErrorCheck2("CodeSystem", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  if (fhirEntryState.activeIndex === 0) {
    fhirJson = codeSystemTextViewChangesToJson(fhirJson, codeSystemState);
    fhirJson = jsonErrorCheck2("CodeSystem", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(codeSystemState);
  return [fhirJson, newClassificationJson];
}

export { builderUpdateCodeSystemJson, builderUpdateCodeSystemTermDetailJson, getCodeSystemConceptValuesFromFhirJson };