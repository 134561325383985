import React, { useState, useEffect, useImperativeHandle, memo } from 'react';
import "firebase/compat/auth";
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { getAdaptationComponentValuesFromFhirJson } from './AdaptationFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import { Button, Dropdown } from 'semantic-ui-react';
import { Checkbox } from '@mui/material';
import getFhirDatatype from './getFhirDatatype';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

const ChooseItemForCodeableConceptArrayEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, valueSet }) => {
  //TODO - check if this function can be imported instead of repeated here
  let startingCodeableConceptArray = [{ "coding": [], "text": "" }];
  let startingCode = "";
  if (!startingValue || startingValue.length === 0 || !startingValue[0]) {
    startingCodeableConceptArray = {};
  } else {
    if (startingValue[0].coding) {
      startingCodeableConceptArray[0].coding = startingValue[0].coding;
      startingCode = startingValue[0].coding[0].code;
    }
    if (startingValue[0].text) {
      startingCodeableConceptArray[0].text = startingValue[0].text;
    }
  }

  if (!valueSet) {
    return <></>
  }
  return <>
    <p><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <Dropdown
        name={"picklist-for-" + elementName}
        placeholder="Preset" search
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%", width: "25%" }}
        options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
        value={startingCode}
        onChange={(e, data) => {
          let selectedCode;
          let selectedSystem;
          let selectedDisplay;
          let selectedVersion;
          for (const term of valueSet) {
            if (term.code === data.value) {
              selectedCode = term.code;
              selectedSystem = term.system || "";
              selectedDisplay = term.display || "";
              selectedVersion = term.version || "";
            }
          }
          let newCoding = { system: selectedSystem, version: selectedVersion, code: selectedCode, display: selectedDisplay };
          if (selectedCode || selectedDisplay || selectedSystem || selectedVersion) {
            let newClassifier = [{ "coding": [newCoding] }]
            handleChange(elementName, newClassifier, setResourceState);
          } else {
            handleChange(elementName, null, setResourceState);
          }
        }}
      />
    </div>
  </>
});

const convertObjectJsonToAdaptationComponent = (objectJson, pathStarter, comparedElementJson) => {
  if (!pathStarter) {
    pathStarter = "Resource";
  }
  if (typeof (comparedElementJson) === "string" || typeof (comparedElementJson) === "number" || typeof (comparedElementJson) === "boolean") {
    comparedElementJson = { "primitiveTypeValue": comparedElementJson };
  }
  if (typeof (comparedElementJson) !== "object") {
    comparedElementJson = {};
  }
  if (objectJson && Object.keys(objectJson).length > 0) {
    let adaptationContent = [];
    for (let key of Object.keys(objectJson)) {
      let valueType;
      let valueTypeClassifier;
      let comparisonValueTypeClassifier;
      let typeValue;
      let keyValue;
      let componentComponents;
      let adaptationComponent;
      let comparisonKeyValue;
      if (key in comparedElementJson) {
        comparisonKeyValue = comparedElementJson[key];
        if (typeof (comparisonKeyValue) === 'string') {
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "string-datatype",
              "display": "string Datatype"
            }]
          };
        } else if (typeof (comparisonKeyValue) === 'number' || typeof (comparisonKeyValue) === 'boolean') {
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": typeof (comparisonKeyValue) + "-datatype",
              "display": typeof (comparisonKeyValue) + " Datatype"
            }]
          };
          comparisonKeyValue = comparisonKeyValue.toString();
        } else if (key === 'coding') {
          if (Array.isArray(comparisonKeyValue)) {
            comparisonValueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "coding-array",
                "display": "Coding Array"
              }]
            };
          } else if (typeof (comparisonKeyValue) === 'object') {
            comparisonValueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "single-coding-object",
                "display": "Single Coding Object"
              }]
            };
          }
        } else if (Array.isArray(comparisonKeyValue)) {
          let comparisonArrayValueDatatype = getFhirDatatype(key, comparisonKeyValue);
          if (comparisonArrayValueDatatype !== "ERROR--Element value is an Array not matched.") {
            comparisonArrayValueDatatype = comparisonArrayValueDatatype.replace("array-of-", "");
            comparisonValueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + comparisonArrayValueDatatype,
                "display": "Manage as " + comparisonArrayValueDatatype
              }]
            };
          }
        } else if (typeof (comparisonKeyValue) === 'object') {
          let comparisonObjectValueDatatype = getFhirDatatype(key, comparisonKeyValue);
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "manageAs" + comparisonObjectValueDatatype,
              "display": "Manage as " + comparisonObjectValueDatatype
            }]
          };
        }
      }
      if (typeof (objectJson[key]) === "string" || typeof (objectJson[key]) === "number" || typeof (objectJson[key]) === "boolean") {
        valueType = 'primitive';
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": typeof (objectJson[key]) + "-datatype",
            "display": typeof (objectJson[key]) + " Datatype"
          }]
        };
      } else if (key === 'coding') {
        valueType = 'Coding';
        if (Array.isArray(objectJson[key])) {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "coding-array",
              "display": "Coding Array"
            }]
          };
        } else if (typeof (objectJson[key]) === 'object') {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "single-coding-object",
              "display": "Single Coding Object"
            }]
          };
        }
      } else if (Array.isArray(objectJson[key])) {
        valueType = 'array';
      } else if (typeof (objectJson[key]) === 'object') {
        valueType = 'object';
      }
      switch (valueType) {
        case 'primitive':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "PrimitiveElement",
              "display": "Primitive Element"
            }]
          };
          if (typeof (objectJson[key]) === 'string') {
            keyValue = objectJson[key];
          }
          if (typeof (objectJson[key]) === 'number' || typeof (objectJson[key]) === 'boolean') {
            keyValue = objectJson[key].toString();
          }
          componentComponents = [
            {

              "summary": keyValue,
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [
                {

                  "summary": comparisonKeyValue,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ComparedResourceContent",
                        "display": "Compared Resource Content"
                      }
                    ]
                  },
                  "classifier": [comparisonValueTypeClassifier]
                }
              ]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [
                getComparisonRatingClassifier(keyValue, comparisonKeyValue)
              ]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {

            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [pathStarter + "." + key],
            "component": componentComponents
          };
          adaptationContent.push(adaptationComponent);
          break;
        case 'Coding':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "CodingElement",
              "display": "Coding Element"
            }]
          };
          keyValue = objectJson[key];
          let codingDisplay;
          if (Array.isArray(keyValue)) {
            codingDisplay = keyValue.map(coding => getStringFromFHIR.Coding(coding)).join('; ');
          } else {
            codingDisplay = getStringFromFHIR.Coding(keyValue);
          }
          componentComponents = [
            {

              "summary": codingDisplay,
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [{

                "summary": JSON.stringify(keyValue),
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContentJSON",
                    "display": "Original Resource Content JSON"
                  }]
                }
              },
              {

                "type": {
                  "coding": [
                    {
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "ComparedResourceContent",
                      "display": "Compared Resource Content"
                    }
                  ]
                },
                "classifier": [comparisonValueTypeClassifier],
                "component": [{

                  "summary": JSON.stringify(comparisonKeyValue),
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                }]
              }
              ]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [getComparisonRatingClassifier(keyValue, comparisonKeyValue)]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {

            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [pathStarter + "." + key],
            "component": componentComponents
          };
          adaptationContent.push(adaptationComponent);
          break;
        case 'array':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          keyValue = JSON.stringify(objectJson[key]);
          let arrayValueDatatype = getFhirDatatype(key, objectJson[key]);
          if (arrayValueDatatype !== "ERROR--Element value is an Array not matched.") {
            arrayValueDatatype = arrayValueDatatype.replace("array-of-", "");
            valueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + arrayValueDatatype,
                "display": "Manage as " + arrayValueDatatype
              }]
            };
          }
          adaptationComponent = {

            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ArrayElement",
                "display": "Array Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [pathStarter + "." + key],
            "component": [
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{

                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                {

                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ComparedResourceContent",
                        "display": "Compared Resource Content"
                      }
                    ]
                  },
                  "classifier": [comparisonValueTypeClassifier],
                  "component": [{

                    "summary": JSON.stringify(comparisonKeyValue),
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContentJSON",
                        "display": "Original Resource Content JSON"
                      }]
                    }
                  }]
                }
                ]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [
                  getComparisonRatingClassifier(JSON.parse(keyValue), comparisonKeyValue)
                ]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {

                "summary": keyValue,
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [pathStarter + "." + key]
              }
            ]
          };
          if (arrayValueDatatype === "ERROR--Element value is an Array not matched.") {
            delete adaptationComponent.classifier;
          }
          adaptationContent.push(adaptationComponent);
          break;
        case 'object':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          keyValue = JSON.stringify(objectJson[key]);
          let objectValueDatatype = getFhirDatatype(key, objectJson[key]);
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "manageAs" + objectValueDatatype,
              "display": "Manage as " + objectValueDatatype
            }]
          };
          adaptationComponent = {

            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ObjectElement",
                "display": "Object Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [pathStarter + "." + key],
            "component": [
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{

                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                {

                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ComparedResourceContent",
                        "display": "Compared Resource Content"
                      }
                    ]
                  },
                  "classifier": [comparisonValueTypeClassifier],
                  "component": [{

                    "summary": JSON.stringify(comparisonKeyValue),
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContentJSON",
                        "display": "Original Resource Content JSON"
                      }]
                    }
                  }]
                }
                ]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [
                  getComparisonRatingClassifier(JSON.parse(keyValue), comparisonKeyValue)
                ]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {

                "summary": keyValue,
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [pathStarter + "." + key]
              }
            ]
          };
          adaptationContent.push(adaptationComponent);
          break;
        default:
          console.log(key + 'has unrecognized datatype')
      }
    }
    return adaptationContent;
  }
}

const getComparisonRatingClassifier = (itemA, itemB) => {
  let comparisonRatingClassifier = {
    "coding": [{
      "system": "https://fevir.net/resources/CodeSystem/112109",
      "code": "missing-comparator-value",
      "display": "Missing key in comparator element"
    }]
  };
  if (itemA === undefined) {
    comparisonRatingClassifier = {
      "coding": [{
        "system": "https://fevir.net/resources/CodeSystem/112109",
        "code": "missing-index-value",
        "display": "Missing key in index element"
      }]
    };
  };
  if (itemB !== undefined) {
    if (itemA === itemB ||
      JSON.stringify(itemA) === JSON.stringify(itemB)) {
      comparisonRatingClassifier.coding[0].code = "same";
      comparisonRatingClassifier.coding[0].display = "Same";
    } else if (itemA == itemB ||
      JSON.stringify(itemA) == JSON.stringify(itemB)) {
      comparisonRatingClassifier.coding[0].code = "similar";
      comparisonRatingClassifier.coding[0].display = "Similar";
    } else {
      comparisonRatingClassifier.coding[0].code = "different";
      comparisonRatingClassifier.coding[0].display = "Different";
    }
  };
  return comparisonRatingClassifier;
}

const expandElementComponents = (containedElementComponentString, parentPath,
  comparedContainedElementComponentString) => {

  const elementJson = JSON.parse(containedElementComponentString);
  let comparedElementJson;
  if (!comparedContainedElementComponentString) {
    comparedElementJson = undefined;
  } else {
    comparedElementJson = JSON.parse(comparedContainedElementComponentString);
  }
  let pathStarter = parentPath || "";
  let expandedElementComponents = [{ "error": "error" }];
  if (Array.isArray(elementJson)) {
    let comparedElementArrayLength = 0;
    if (Array.isArray(comparedElementJson)) {
      comparedElementArrayLength = comparedElementJson.length;
    }
    let i = 0;
    let arrayComponents = [];
    for (const instanceJson of elementJson) {
      let pathOfInstance = pathStarter + '[' + i + ']';
      let valueType;
      let valueTypeClassifier;
      let comparisonValueTypeClassifier;
      let typeValue;
      let keyValue;
      let comparisonKeyValue;
      let componentComponents;
      let adaptationComponent;
      let comparedInstanceJson;
      let instanceComparisonRatingClassifier = {
        "coding": [{
          "system": "https://fevir.net/resources/CodeSystem/112109",
          "code": "missing-comparator-value",
          "display": "Missing key in comparator element"
        }]
      };
      if (i < comparedElementArrayLength) {
        comparedInstanceJson = comparedElementJson[i];
        instanceComparisonRatingClassifier = getComparisonRatingClassifier(instanceJson, comparedInstanceJson);
        if (typeof (comparedInstanceJson) === 'string') {
          comparisonKeyValue = comparedInstanceJson;
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "string-datatype",
              "display": "string Datatype"
            }]
          };
        } else if (typeof (comparedInstanceJson) === 'number' || typeof (comparedInstanceJson) === 'boolean') {
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": typeof (comparedInstanceJson) + "-datatype",
              "display": typeof (comparedInstanceJson) + " Datatype"
            }]
          };
          comparisonKeyValue = comparedInstanceJson.toString();
        } else if (typeof (comparedInstanceJson) === 'object') {
          comparisonKeyValue = JSON.stringify(comparedInstanceJson);
          let comparisonObjectValueDatatype = getFhirDatatype(parentPath, comparedInstanceJson);
          comparisonValueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "manageAs" + comparisonObjectValueDatatype,
              "display": "Manage as " + comparisonObjectValueDatatype
            }]
          };
        }
      }
      if (typeof (instanceJson) === "string" || typeof (instanceJson) === "number" || typeof (instanceJson) === "boolean") {
        valueType = 'primitive';
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": typeof (instanceJson) + "-datatype",
            "display": typeof (instanceJson) + " Datatype"
          }]
        };
      } else if (Array.isArray(instanceJson)) {
        valueType = 'array';
      } else if (typeof (instanceJson) === 'object') {
        valueType = 'object';
        let objectValueDatatype = getFhirDatatype(parentPath, instanceJson);
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": "manageAs" + objectValueDatatype,
            "display": "Manage as " + objectValueDatatype
          }]
        };
      }
      switch (valueType) {
        case 'primitive':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "PrimitiveElement",
              "display": "Primitive Element"
            }]
          };
          if (typeof (instanceJson) === 'string') {
            keyValue = instanceJson;
          }
          if (typeof (instanceJson) === 'number' || typeof (instanceJson) === 'boolean') {
            keyValue = instanceJson.toString();
          }
          componentComponents = [
            {

              "summary": keyValue,
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [
                {

                  "summary": comparisonKeyValue,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ComparedResourceContent",
                        "display": "Compared Resource Content"
                      }
                    ]
                  },
                  "classifier": [comparisonValueTypeClassifier]
                }
              ]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [instanceComparisonRatingClassifier]
            },
            {

              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {

            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [pathOfInstance],
            "component": componentComponents
          };
          arrayComponents.push(adaptationComponent);
          break;
        case 'object':
          keyValue = JSON.stringify(instanceJson);
          adaptationComponent = {

            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ObjectElement",
                "display": "Object Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [pathOfInstance],
            "component": [
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{

                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                {

                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ComparedResourceContent",
                        "display": "Compared Resource Content"
                      }
                    ]
                  },
                  "classifier": [comparisonValueTypeClassifier],
                  "component": [{

                    "summary": comparisonKeyValue,
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContentJSON",
                        "display": "Original Resource Content JSON"
                      }]
                    }
                  }]
                }]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [instanceComparisonRatingClassifier]
              },
              {

                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {

                "summary": keyValue,
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [pathOfInstance]
              }
            ]
          };
          arrayComponents.push(adaptationComponent);
          break;
        default:
          console.log(pathOfInstance + 'has unrecognized datatype')
      }
      i += 1;
    }
    expandedElementComponents = arrayComponents;
  } else if (typeof elementJson === 'object') {
    expandedElementComponents = convertObjectJsonToAdaptationComponent(elementJson, pathStarter, comparedElementJson);
  } else {
    alert('Improper JSON format');
  }
  return expandedElementComponents;
}

const ExpandElementComponentsForComparisonButton = ({ containedElementComponentString, parentPath, elementName,
  setInnerState, postClickFunction, comparedContainedElementComponentString }) => {
  return <Button className="formButton expandElementButton" style={{ color: "#000000" }}
    content={"Expand to Element Components"}
    onClick={() => {
      let expandedElementComponents = expandElementComponents(containedElementComponentString, parentPath,
        comparedContainedElementComponentString);
      setInnerState(prevState => { return { ...prevState, [elementName]: expandedElementComponents } });
      if (postClickFunction) { postClickFunction() } else {
        alert('Click the Update button on the left to save the expansion of this item for Contained Element Adaptation.');
      };
    }} />
}

const ComparisonDetailDataEntry = ({ adaptationDetailState, fhirEntryState, setAdaptationDetailState }) => {

  useEffect((() => {
    try {
      let aDdatatype = adaptationDetailState.aDdatatypeClassifier[0].coding[0].code;
      if (aDdatatype && aDdatatype !== adaptationDetailState.aDdatatype) {
        handleChange('aDdatatype', aDdatatype, setAdaptationDetailState);
      }
    } catch { }
  }), [adaptationDetailState.aDdatatypeClassifier])

  if (!adaptationDetailState.aDcomponentType) {
    try {
      if (fhirEntryState.characteristicSelected.type.coding[0].code) {
        setAdaptationDetailState(getAdaptationComponentValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));
      }
    } catch {
      alert('failed attempt to load a recognized editable component');
    }
  }

  let thisisaproblem;
  let contentType = adaptationDetailState.aDcomponentType;
  if (contentType === "Primitive Element" || contentType === "PrimitiveElement" || contentType === "Editable Resource Content Element") {
    contentType = "PrimitiveElement";
  } else if (contentType === "Coding Element" || contentType === "CodingElement" || contentType === "Editable Resource Content Coding") {
    contentType = "CodingElement";
  } else if (contentType === "Array Element" || contentType === "ArrayElement") {
    contentType = "ArrayElement";
  } else if (contentType === "Object Element" || contentType === "ObjectElement") {
    contentType = "ObjectElement";
  } else {
    console.log('Unrecognized contentType in aDcomponentType: ' + contentType);
    thisisaproblem = true;
  }

  const manageAsDatatypeValueSet = [
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsAddress", display: "Manage as Address" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsAnnotation", display: "Manage as Annotation" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsCodeableConcept", display: "Manage as CodeableConcept" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsCoding", display: "Manage as Coding" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsContactDetail", display: "Manage as ContactDetail" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsDosage", display: "Manage as Dosage" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsExpression", display: "Manage as Expression" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsHumanName", display: "Manage as HumanName" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsIdentifier", display: "Manage as Identifier" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsPeriod", display: "Manage as Period" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsQuantity", display: "Manage as Quantity" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsRange", display: "Manage as Range" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsRatio", display: "Manage as Ratio" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsReference", display: "Manage as Reference" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsRelatedArtifact", display: "Manage as RelatedArtifact" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsRelatesTo", display: "Manage as RelatesTo" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsTiming", display: "Manage as Timing" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsBackboneElement", display: "Manage as BackboneElement" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "manageAsstring", display: "Manage as string" }
  ]

  const isComparisonRatingValueSet = [
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "same", display: "Same" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "similar", display: "Similar" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "different", display: "Different" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "missing-index-value", display: "Missing key in index element" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "missing-comparator-value", display: "	Missing key in comparator element" }
  ]

  const isChangeSuggestedValueSet = [
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "no-change", display: "No change" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "suggested-change", display: "Suggested change" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "suggested-replacement", display: "Suggested replacement" }
  ]

  const isChangeAcceptedValueSet = [
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "rejected-change", display: "Rejected change" },
    { system: "https://fevir.net/resources/CodeSystem/112109", code: "accepted-change", display: "Accepted change" }
  ]

  if (thisisaproblem) {
    return <></>
  }

  return <div>
    <br />
    <p><b>Specific to: </b>{adaptationDetailState.aDpath?.toString()} element</p>
    <div>
      {contentType === "PrimitiveElement" && <p><b>Datatype: </b>{adaptationDetailState.aDdatatype}</p>}
      {contentType === "CodingElement" && <p><b>Coding Object or Array? </b>{adaptationDetailState.aDdatatype}</p>}
      {(contentType === "ArrayElement" || contentType === "ObjectElement") &&
        <ChooseItemForCodeableConceptArrayEntry elementName='aDdatatypeClassifier'
          startingValue={adaptationDetailState.aDdatatypeClassifier}
          fieldLabel='Datatype'
          valueSet={manageAsDatatypeValueSet}
          setResourceState={setAdaptationDetailState} />
      }
    </div>
    <div>
      <br />
      <p><b>Original Resource Content:</b></p>
      <div style={{ marginLeft: "24px" }}>
        {(adaptationDetailState.aDoriginalSummary && contentType === 'PrimitiveElement') &&
          <><DisplayFromFHIR markdown={adaptationDetailState.aDoriginalSummary} /></>}
        {contentType === "CodingElement" &&
          <div>
            {Array.isArray(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))
              ?
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
              })
              :
              <p>{getStringFromFHIR.Coding(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
          </div>
        }
        {(contentType === "ArrayElement" && Array.isArray(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))) &&
          <div>
            <br />
            {adaptationDetailState.aDdatatype === 'manageAsCoding' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsIdentifier' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Identifier(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsReference' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Reference(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsQuantity' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Quantity(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsAddress' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Address(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsAnnotation' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Annotation(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsContactDetail' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}>
                  <DisplayFromFHIR contactDetail={object} /><br />
                </span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsDosage' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Dosage(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsExpression' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Expression(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsHumanName' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.HumanName(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsPeriod' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Period(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsRange' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Range(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsRatio' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Ratio(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsTiming' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Timing(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsCodeableConcept' &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.CodeableConcept(object)}</p></span>
              })}
            {adaptationDetailState.aDdatatype === 'manageAsRelatedArtifact' && JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
              return <span key={objectIndex}><DisplayFromFHIR relatedArtifact={object} /></span>
            })}
            {adaptationDetailState.aDdatatype === 'manageAsRelatesTo' && JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
              return <span key={objectIndex}><DisplayFromFHIR relatesTo={object} /></span>
            })}
            {adaptationDetailState.aDdatatype === 'manageAsstring' && JSON.parse(adaptationDetailState.content.component[0].component[0].summary).map((object, objectIndex) => {
              return <span key={objectIndex}><p>{object}</p></span>
            })}
            {(adaptationDetailState.aDdatatype === 'manageAsBackboneElement' || !adaptationDetailState.aDdatatype) &&
              JSON.parse(adaptationDetailState.content.component[0].component[0].summary).length === 1 ?
              <p>[1 entry]</p>
              :
              <p>[{JSON.parse(adaptationDetailState.content.component[0].component[0].summary).length} entries]</p>
            }
          </div>
        }
        {contentType === "ObjectElement" &&
          <div>
            <br />
            {adaptationDetailState.aDdatatype === 'manageAsCoding' &&
              <p>{getStringFromFHIR.Coding(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsIdentifier' &&
              <p>{getStringFromFHIR.Identifier(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsReference' &&
              <p>{getStringFromFHIR.Reference(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsAddress' &&
              <p>{getStringFromFHIR.Address(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsQuantity' &&
              <p>{getStringFromFHIR.Quantity(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsAnnotation' &&
              <p>{getStringFromFHIR.Annotation(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsContactDetail' &&
              <span>
                <DisplayFromFHIR contactDetail={JSON.parse(adaptationDetailState.content.component[0].component[0].summary)} />
                <br />
              </span>
            }
            {adaptationDetailState.aDdatatype === 'manageAsDosage' &&
              <p>{getStringFromFHIR.Dosage(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsExpression' &&
              <p>{getStringFromFHIR.Expression(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsTiming' &&
              <p>{getStringFromFHIR.Timing(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsHumanName' &&
              <p>{getStringFromFHIR.HumanName(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsPeriod' &&
              <p>{getStringFromFHIR.Period(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsRange' &&
              <p>{getStringFromFHIR.Range(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsRatio' &&
              <p>{getStringFromFHIR.Ratio(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsCodeableConcept' &&
              <p>{getStringFromFHIR.CodeableConcept(JSON.parse(adaptationDetailState.content.component[0].component[0].summary))}</p>
            }
            {adaptationDetailState.aDdatatype === 'manageAsRelatedArtifact' &&
              <DisplayFromFHIR relatedArtifact={JSON.parse(adaptationDetailState.content.component[0].component[0].summary)} />
            }
            {adaptationDetailState.aDdatatype === 'manageAsRelatesTo' &&
              <DisplayFromFHIR relatesTo={JSON.parse(adaptationDetailState.content.component[0].component[0].summary)} />
            }
          </div>
        }
        {adaptationDetailState.aDoriginalJSON &&
          <div>
            <br />
            <p><b>Original Resource Content JSON:</b></p>
            <p>{JSON.stringify(JSON.parse(adaptationDetailState.aDoriginalJSON), null, 2).replace('\n', '')}</p>
          </div>
        }
      </div>
    </div>
    <div>
      <br />
      <p><b>Compared Resource Content:</b></p>
      <div style={{ marginLeft: "24px" }}>
        {(adaptationDetailState.comparisonOriginalSummary && contentType === 'PrimitiveElement') &&
          <><DisplayFromFHIR markdown={adaptationDetailState.comparisonOriginalSummary} /></>}
        {(contentType === "CodingElement" && adaptationDetailState.content.component &&
          adaptationDetailState.content.component[0].component &&
          adaptationDetailState.content.component[0].component[1].component &&
          adaptationDetailState.content.component[0].component[1].component[0].summary) &&
          <div>
            {Array.isArray(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))
              ?
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
              })
              :
              <p>{getStringFromFHIR.Coding(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
          </div>
        }
        {(contentType === "ArrayElement" && adaptationDetailState.content.component &&
          adaptationDetailState.content.component[0].component &&
          adaptationDetailState.content.component[0].component[1].component &&
          adaptationDetailState.content.component[0].component[1].component[0].summary &&
          Array.isArray(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))) &&
          <div>
            <br />
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Coding' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Identifier' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Identifier(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Reference' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Reference(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Quantity' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Quantity(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsAddress' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Address(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Annotation' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Annotation(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-ContactDetail' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}>
                  <DisplayFromFHIR contactDetail={object} /><br />
                </span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Dosage' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Dosage(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Expression' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Expression(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-HumanName' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.HumanName(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Period' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Period(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Range' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Range(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Ratio' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Ratio(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-Timing' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.Timing(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-CodeableConcept' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{getStringFromFHIR.CodeableConcept(object)}</p></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-RelatedArtifact' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><DisplayFromFHIR relatedArtifact={object} /></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-RelatesTo' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><DisplayFromFHIR relatesTo={object} /></span>
              })}
            {adaptationDetailState.comparisonDatatype === 'manageAsarray-of-string' &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).map((object, objectIndex) => {
                return <span key={objectIndex}><p>{object}</p></span>
              })}
            {(adaptationDetailState.comparisonDatatype === 'manageAsarray-of-BackboneElement' || !adaptationDetailState.comparisonDatatype) &&
              JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).length === 1 ?
              <p>[1 entry]</p>
              :
              <p>[{JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary).length} entries]</p>
            }
            {adaptationDetailState.content.component[0].component[1].classifier &&
              adaptationDetailState.content.component[0].component[1].classifier.length > 0 &&
              Array.isArray(adaptationDetailState.content.component[0].component[1].classifier) &&
              <div style={{ marginLeft: "24px" }}>
                <br />
                <p><b>Datatype: </b>
                  {adaptationDetailState.content.component[0].component[1].classifier.length === 1 && <DisplayFromFHIR codeableConcept={adaptationDetailState.content.component[0].component[1].classifier[0]} />}
                  {adaptationDetailState.content.component[0].component[1].classifier.length > 1 && adaptationDetailState.content.component[0].component[1].classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
                </p>
              </div>}
          </div>
        }
        {(contentType === "ObjectElement" && adaptationDetailState.content.component &&
          adaptationDetailState.content.component[0].component &&
          adaptationDetailState.content.component[0].component[1].component &&
          adaptationDetailState.content.component[0].component[1].component[0].summary) &&
          <div>
            <br />
            {adaptationDetailState.comparisonDatatype === 'manageAsCoding' &&
              <p>{getStringFromFHIR.Coding(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsIdentifier' &&
              <p>{getStringFromFHIR.Identifier(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsReference' &&
              <p>{getStringFromFHIR.Reference(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsAddress' &&
              <p>{getStringFromFHIR.Address(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsQuantity' &&
              <p>{getStringFromFHIR.Quantity(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsAnnotation' &&
              <p>{getStringFromFHIR.Annotation(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsContactDetail' &&
              <span>
                <DisplayFromFHIR contactDetail={JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary)} />
                <br />
              </span>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsDosage' &&
              <p>{getStringFromFHIR.Dosage(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsExpression' &&
              <p>{getStringFromFHIR.Expression(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsTiming' &&
              <p>{getStringFromFHIR.Timing(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsHumanName' &&
              <p>{getStringFromFHIR.HumanName(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsPeriod' &&
              <p>{getStringFromFHIR.Period(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsRange' &&
              <p>{getStringFromFHIR.Range(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsRatio' &&
              <p>{getStringFromFHIR.Ratio(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsCodeableConcept' &&
              <p>{getStringFromFHIR.CodeableConcept(JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary))}</p>
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsRelatedArtifact' &&
              <DisplayFromFHIR relatedArtifact={JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary)} />
            }
            {adaptationDetailState.comparisonDatatype === 'manageAsRelatesTo' &&
              <DisplayFromFHIR relatesTo={JSON.parse(adaptationDetailState.content.component[0].component[1].component[0].summary)} />
            }
            {adaptationDetailState.content.component[0].component[1].classifier &&
              adaptationDetailState.content.component[0].component[1].classifier.length > 0 &&
              Array.isArray(adaptationDetailState.content.component[0].component[1].classifier) &&
              <div style={{ marginLeft: "24px" }}>
                <br />
                <p><b>Datatype: </b>
                  {adaptationDetailState.content.component[0].component[1].classifier.length === 1 && <DisplayFromFHIR codeableConcept={adaptationDetailState.content.component[0].component[1].classifier[0]} />}
                  {adaptationDetailState.content.component[0].component[1].classifier.length > 1 && adaptationDetailState.content.component[0].component[1].classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
                </p>
              </div>}
          </div>
        }
        {adaptationDetailState.comparisonOriginalJSON &&
          <div style={{ marginLeft: "24px" }}>
            <br />
            <p><b>Compared Resource Content JSON:</b></p>
            <p>{JSON.stringify(JSON.parse(adaptationDetailState.comparisonOriginalJSON), null, 2).replace('\n', '')}</p>
          </div>
        }
      </div>
    </div>
    <div>
      <br />
      <p><b>Comparison of Resources Content:</b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='comparisonRatingSummary' fieldLabel='Summary'
          startingValue={adaptationDetailState.comparisonRatingSummary || null}
          setResourceState={setAdaptationDetailState} />
        <br />
        <ChooseItemForCodeableConceptArrayEntry elementName='comparisonRatingClassifierClassifier'
          startingValue={adaptationDetailState.comparisonRatingClassifierClassifier}
          fieldLabel='Comparison Rating:'
          valueSet={isComparisonRatingValueSet}
          setResourceState={setAdaptationDetailState} />
        <br />
        <ChooseItemForCodeableConceptArrayEntry elementName='aDchangeSuggestedClassifierClassifier'
          startingValue={adaptationDetailState.aDchangeSuggestedClassifierClassifier}
          fieldLabel='Is change suggested?'
          valueSet={isChangeSuggestedValueSet}
          setResourceState={setAdaptationDetailState} />
      </div>
    </div>
    {(adaptationDetailState.aDsuggestedChangeSummary || adaptationDetailState.aDchangeSuggestedClassifierClassifier[0]?.coding[0].code === "suggested-change") &&
      <div>
        <br />
        <p><b>Suggested Change to Resource Content:</b></p>
        <div style={{ marginLeft: "24px" }}>
          {contentType === "PrimitiveElement" && <DataEntry datatype='markdown' elementName='aDsuggestedChangeSummary' fieldLabel='Summary'
            startingValue={adaptationDetailState.aDsuggestedChangeSummary || null}
            setResourceState={setAdaptationDetailState} />}
          {contentType === "CodingElement" && <>{adaptationDetailState.aDdatatype === 'coding-array' ?
            <DataEntry asArray={true} datatype='Coding' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Coding'
              deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />
            :
            <DataEntry datatype='Coding' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Coding'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />
          }
          </>}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsCoding' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Coding' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Coding' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsstring' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='string' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Data' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsIdentifier' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Identifier' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Identifier' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsCodeableConcept' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='CodeableConcept' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised CodeableConcept' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsReference' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Reference' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Reference' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsQuantity' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Quantity' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Quantity' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsAddress' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Address' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Address' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsAnnotation' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Annotation' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Annotation' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsContactDetail' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='ContactDetail' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised ContactDetail' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsDosage' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Dosage' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Dosage' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsExpression' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Expression' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Expression' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsHumanName' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='HumanName' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised HumanName' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsPeriod' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Period' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Period' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsRange' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Range' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Range' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsRatio' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Ratio' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Ratio' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsTiming' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='Timing' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised Timing' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsRelatedArtifact' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='RelatedArtifact' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised RelatedArtifact' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ArrayElement" && adaptationDetailState.aDdatatype === 'manageAsRelatesTo' &&
            Array.isArray(JSON.parse(adaptationDetailState.aDoriginalJSON))) &&
            <DataEntry asArray={true} datatype='RelatesTo' elementName='aDsuggestedChangeSummary'
              fieldLabel='Revised RelatesTo' deletable={true}
              startingValue={adaptationDetailState.aDsuggestedChangeSummary ?
                JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) :
                adaptationDetailState.aDoriginalJSON ?
                  JSON.parse(adaptationDetailState.aDoriginalJSON) : null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsCoding') &&
            <DataEntry datatype='Coding' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Coding'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsIdentifier') &&
            <DataEntry datatype='Identifier' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Identifier'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON)}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsCodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='aDsuggestedChangeSummary' fieldLabel='Revised CodeableConcept'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsReference') &&
            <DataEntry datatype='Reference' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Reference'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsQuantity') &&
            <DataEntry datatype='Quantity' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Quantity'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsAddress') &&
            <DataEntry datatype='Address' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Address'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsAnnotation') &&
            <DataEntry datatype='Annotation' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Annotation'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsContactDetail') &&
            <DataEntry datatype='ContactDetail' elementName='aDsuggestedChangeSummary' fieldLabel='Revised ContactDetail'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsDosage') &&
            <DataEntry datatype='Dosage' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Dosage'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsExpression') &&
            <DataEntry datatype='Expression' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Expression'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsHumanName') &&
            <DataEntry datatype='HumanName' elementName='aDsuggestedChangeSummary' fieldLabel='Revised HumanName'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsPeriod') &&
            <DataEntry datatype='Period' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Period'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsRange') &&
            <DataEntry datatype='Range' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Range'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsRatio') &&
            <DataEntry datatype='Ratio' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Ratio'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsTiming') &&
            <DataEntry datatype='Timing' elementName='aDsuggestedChangeSummary' fieldLabel='Revised Timing'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsRelatedArtifact') &&
            <DataEntry datatype='RelatedArtifact' elementName='aDsuggestedChangeSummary' fieldLabel='Revised RelatedArtifact'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          {(contentType === "ObjectElement" && adaptationDetailState.aDdatatype === 'manageAsRelatesTo') &&
            <DataEntry datatype='RelatesTo' elementName='aDsuggestedChangeSummary' fieldLabel='Revised RelatesTo'
              startingValue={JSON.parse(JSON.stringify(adaptationDetailState.aDsuggestedChangeSummary)) ||
                JSON.parse(adaptationDetailState.aDoriginalJSON) || null}
              setResourceState={setAdaptationDetailState} />}
          <p>Delete this element
            &nbsp;&nbsp;
            <Checkbox
              onChange={(e) => {
                handleChange('aDsuggestedChangeSummary', "DELETEME", setAdaptationDetailState);
              }}
              color="primary"
              style={{ paddingLeft: "4px", paddingRight: "4px" }}
            />
          </p>
          {adaptationDetailState.aDsuggestedChangeSummary &&
            <ChooseItemForCodeableConceptArrayEntry elementName='aDchangeAcceptedClassifierClassifier'
              startingValue={adaptationDetailState.aDchangeAcceptedClassifierClassifier}
              fieldLabel='Accept or reject?'
              valueSet={isChangeAcceptedValueSet}
              setResourceState={setAdaptationDetailState} />
          }
        </div>
      </div>
    }
    {(adaptationDetailState.aDchangeSuggestedClassifierClassifier[0]?.coding[0].code === "suggested-replacement") &&
      <div>
        <br />
        <p><b>Suggested Change to Resource Content:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <p>The Original Resource Content will be replaced with the Compared Resource Content for this element.</p>
          <br />
          <ChooseItemForCodeableConceptArrayEntry elementName='aDchangeAcceptedClassifierClassifier'
            startingValue={adaptationDetailState.aDchangeAcceptedClassifierClassifier}
            fieldLabel='Accept or reject?'
            valueSet={isChangeAcceptedValueSet}
            setResourceState={setAdaptationDetailState} />
        </div>
      </div>
    }
    {(contentType === "ArrayElement" || contentType === "ObjectElement") &&
      <div>
        <br />
        <p><b>Contained Element Adaptation:</b></p>
        {adaptationDetailState.aDcontainedExpanded?.length > 0 ?
          <p>Use the Detail Navigation (on the left) to view or edit Contained Element Adaptation</p>
          :
          <ExpandElementComponentsForComparisonButton containedElementComponentString={adaptationDetailState.aDcontainedElementSummary}
            parentPath={adaptationDetailState.aDpath[0] || ""} elementName={'aDcontainedExpanded'}
            setInnerState={setAdaptationDetailState}
            comparedContainedElementComponentString={adaptationDetailState.comparisonOriginalJSON || adaptationDetailState.comparisonOriginalSummary}
          />
        }
      </div>
    }
  </div>
}


const ComparisonDetailEdit = ({ formInputsStateRef, fhirEntryState }) => {

  const [adaptationDetailState, setAdaptationDetailState] = useState(getAdaptationComponentValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));

  useImperativeHandle(formInputsStateRef, () => ({
    adaptationDetailState,
  }), [adaptationDetailState]);

  useEffect(() => {
    let adaptationDetailValues = getAdaptationComponentValuesFromFhirJson(fhirEntryState, adaptationDetailState);
    setAdaptationDetailState(adaptationDetailValues);
  }, [fhirEntryState]);

  let conceptPath = fhirEntryState.conceptPath;

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          {fhirEntryState.conceptDelete ?
            <><h1 style={{ color: "#FF0000" }}>Concept "{fhirEntryState.conceptName}" deletion pending, please click "Update" to complete deletion.</h1></>
            :
            <>
              <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
              <ComparisonDetailDataEntry fhirEntryState={fhirEntryState}
                adaptationDetailState={adaptationDetailState}
                setAdaptationDetailState={setAdaptationDetailState} />
              <br /><br />
            </>
          }
        </>
        :
        <>
          <span>Please select a component</span>
          <br />
          <br />
        </>
      }
    </div>
  </div>
}

export { ComparisonDetailEdit, ExpandElementComponentsForComparisonButton };