import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import axios from 'axios';
import server_config from './ServerConfiguration';
import submitToFevirServer from './SubmitToFevirServer';
import { changeSimpleState, changeSimpleStateWithCheck } from './ResourceFunctions';

const node_server_address = server_config["node_server_address"];
const go_server_address = server_config["go_server_address"];

const startingUserState = {
  loading: true,
  displaythetermsofagreement: false,
  id: null,
  firebaseuid: null,
  name: null,
  displayname: null,
  email: null,
  additionalemail: null,
  shortbio: null,
  termsofuseaccept: [],
  datapersonalagreementaccept: [],
  type: [],
  affiliations: [""],
  datecreated: null,
  lastmodified: null,
  usercreated: false
};

const clearUserState = (globalContext) => {
  globalContext.setUserState(prevState => {
    let copyOfStartingUserState = JSON.parse(JSON.stringify(startingUserState));
    copyOfStartingUserState.loading = false;
    return copyOfStartingUserState;
  });
  globalContext.setFavoriteFOIsState([]);
}

const cpLogin = async (idToken, globalContext, agreetoterms) => {
  let userState = globalContext.userState;
  let setUserState = globalContext.setUserState;
  let setFavoriteFOIsState = globalContext.setFavoriteFOIsState;
  let setSignInStatusState = globalContext.setSignInStatusState;

  if (!agreetoterms) {
    agreetoterms = false;
  }

  let returningdata = false;

  if (!userState.id) {
    const body = {
      'functionid': "login",
      'idToken': idToken,
      'agreetoterms': agreetoterms
    };
    returningdata = await axios({
      method: 'POST',
      //url: server_address + ':' + ports["master_server"] + '/',
      url: go_server_address,
      timeout: 8000,
      data: body,
    }).then((resp) => {
      //userdata = resp.data;
      //console.log(userdata);
      if (resp?.data?.success) {
        let loadedData = resp.data;
        if (loadedData.success) {
          loadedData.loading = false;
          loadedData.idToken = idToken;
          if (!resp.data.affiliations || resp.data.affiliations === null || (Array.isArray(resp.data.affiliations) && resp.data.affiliations.length === 0) || resp.data.affiliations === "") {
            loadedData.affiliations = [""];
          }
          if (!loadedData.displaythetermsofagreement) {    //If the user is signing on for the first time, then display the terms of agreement page
            loadedData.displaythetermsofagreement = false;
          }
          setFavoriteFOIsState(loadedData.favoritefois || []);
          delete loadedData.favoritefois;
          setUserState(loadedData); //One of the userState changes that happens on page load
          returningdata = true;
        }
      }
    })
      .catch(e => {
        changeSimpleStateWithCheck(userState, setUserState, false, "loading");
        console.log(e);
        returningdata = false;
      });
    if (setSignInStatusState) {
      setSignInStatusState(true);
    }
  }
  return returningdata;
}

const getCustomToken = async (idToken, globalContext) => {
  let code;
  const node_server_address = server_config["node_server_address"];
  const body = {
    'functionid': 'createssocustomtoken',
    'idToken': idToken
  };
  await axios({
    method: 'POST',
    url: node_server_address,
    timeout: 10000,
    data: body,
  }).then((resp) => {
    console.log(resp);
    if (resp?.data?.success) {
      code = resp.data.code;
      globalContext.setSsoCustomTokenCode(code);
    }
  }).catch(e => { console.log(e); });
  return code; //Nothing uses this return as of now
}

const googleSignInFirebase = async (globalContext) => {
  const firebaseGoogleProvider = globalContext.firebaseGoogleProvider;
  const userState = globalContext.userState;
  const setUserState = globalContext.setUserState;
  const setSignInStatusState = globalContext.setSignInStatusState;
  //let returningData = {"success": true}
  let success = false;
  const auth = getAuth();
  await signInWithPopup(auth, firebaseGoogleProvider).then((result) => {
    //await firebase.auth().signInWithPopup(firebaseGoogleProvider).then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;

    //let accessToken = result.credential.accessToken;
    // The signed-in user info.
    let user = result.user;
    let displayName = user.displayName;
    let token = credential.accessToken;
    //let photoURL = user.photoURL;
    let email = user.email;
    let emailVerified = user.emailVerified;
    //let providerId = credential.providerId;
    //let signInMethod = credential.signInMethod;

    if (user) {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
        await cpLogin(idToken, globalContext, false);
        getCustomToken(idToken, globalContext);
        success = true;
      }).catch((e) => { console.log(e); });

    }
    // ...
  }).catch((e) => {
    let errorCode = e.code;
    let errorMessage = e.message;
    const credential = GoogleAuthProvider.credentialFromError(e);
    if (errorCode !== "auth/cancelled-popup-request" && errorMessage !== "This operation has been cancelled due to another conflicting popup being opened.") {
      globalContext.openAlert({ "header": "Couldn't open the sign-in window", "content": "This is likely because your browser blocks pop-ups. Please allow pop-ups for fevir.net" });
    }
    console.log(e);
  });
  return success;
}

const emailPasswordSignInFirebase = async (globalContext) => {
  let email = globalContext.firebaseModalState.email;
  let password = globalContext.firebaseModalState.password;
  /*
  //THIS CURRENTLY DOESN'T WORK BECAUSE GOOGLE CURRENTLY DOESN'T ALLOW NEW PROJECTS FOR DYNAMIC LINKS
  //https://stackoverflow.com/questions/78429356/firebase-error-auth-invalid-dynamic-link-domain
  const auth = getAuth();
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://fevir.net',
    handleCodeInApp: true,
    dynamicLinkDomain: 'fevir.net.page.link'
  };
  
  //let email = "testUser@test.com";
  let email = "kshahin@computablepublishing.com";
  let cred = await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      console.log("==============");
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((e) => {
      console.log(e);
 
      const errorCode = e.code;
      const errorMessage = e.message;
      // ...
    });
  console.log(cred);
  */

  const auth = getAuth();
  let cred = await signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      console.log(userCredential);
      const user = userCredential.user;
      if (user) {
        if (!user.emailVerified) {
          await sendEmailVerification(user);
          auth.signOut()
            .then(() => {
              alert("Please verify your email. Check your email's spam folder.");
              globalContext.setFirebaseModalState(prevState => { return { ...prevState, 'open': false } });
              globalContext.setUserState(prevState => { return { ...prevState, 'waitingForVerificationEmail': true } });
              //window.location.reload(); 
            })
            .catch((e) => { console.log(e); });
        } else {
          globalContext.setFirebaseModalState(prevState => { return { ...prevState, 'open': false } });
          window.location.reload();
        }
      }
    })
    .catch(async (e) => {
      const errorCode = e.code;
      const errorMessage = e.message;
      console.log(e);
      if (errorCode === "auth/invalid-credential" || errorCode === "auth/user-not-found") {

        let cred = await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed up 
            console.log(userCredential);
            const user = userCredential.user;
            if (user) {
              if (!user.emailVerified) {
                await sendEmailVerification(user);
                //signOutFirebase(globalContext)
                alert("Please verify your email. Check your email's spam folder.");
                globalContext.setFirebaseModalState(prevState => { return { ...prevState, 'open': false } });
                globalContext.setUserState(prevState => { return { ...prevState, 'waitingForVerificationEmail': true } });
                window.location.reload();
              }
            }
          })
          .catch((e) => {
            console.log(e);
            const errorCode = e.code;
            const errorMessage = e.message;
            if (errorCode === "auth/email-already-in-use") {
              alert("Either incorrect password or this email is already being used. Maybe try logging in via Google instead of Email / Password.");
            }
          });
      } else if (errorCode === "auth/email-already-in-use") {
        alert("This email is already being used. Maybe try logging in via Google instead of Email / Password.");
      }
    });
  console.log(cred);
  return;
}

const signInFirebase = async (globalContext) => {
  //MAKE MODAL POP UP, GIVE THE USER A CHOICE
  globalContext.setFirebaseModalState(prevState => { return { ...prevState, 'open': true } });
  //await googleSignInFirebase(globalContext);
}

const signOutFirebase = async (globalContext) => {
  const userState = globalContext.userState;
  const setUserState = globalContext.setUserState;
  const setSignInStatusState = globalContext.setSignInStatusState;
  changeSimpleStateWithCheck(userState, setUserState, true, "loading");
  let idToken;
  if (userState?.idToken) {
    idToken = userState.idToken;
  } else {
    await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (storedIdToken) => { idToken = storedIdToken });
  }
  await firebase.auth().signOut().then(async () => {
    console.log("Signing out...");
    const body = {
      'functionid': 'logout',
      'idToken': idToken
    };
    await axios({
      method: 'POST',
      url: go_server_address,
      timeout: 8000,
      data: body,
    }).then((resp) => {
      if (resp?.data?.success) {
        clearUserState(globalContext);
      }
    })
      .catch(e => {
        console.log(e);
      });

  }).catch((e) => { console.log(e); });
  changeSimpleStateWithCheck(userState, setUserState, false, "loading");
  if (setSignInStatusState) {
    setSignInStatusState(false);
  }
}

//Exile user
const exileUser = async (globalContext, exile, permanentremoval) => {
  try {
    //Get's the user's firebase id token.
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {  //If there is no currentUser, as in they aren't logged in, this will fail and it will go to the catch
      const body = {
        'functionid': 'exileuser',
        'exile': exile,
        'idToken': idToken,
        'permanentremoval': permanentremoval
      };
      axios({
        method: 'POST',
        url: node_server_address,
        timeout: 8000,
        data: body,
      }).then(() => {
        signOutFirebase(globalContext);
      }).catch(e => {
        console.log(e);
      });
    });
  } catch (e) { }
}

//Logs the user back in and load their data after re-visiting or re-loading the site
const continuedSessionLogin = async (globalContext, refreshToken, setSignInStatusState) => {
  let firebaseConfig = globalContext.firebaseConfig;
  const userState = globalContext.userState;
  const setUserState = globalContext.setUserState;

  let returningdata = false;
  changeSimpleStateWithCheck(userState, setUserState, true, "loading");
  //Tries to load a accessCookie from Local Storage
  if (!('indexedDB' in window)) {
    changeSimpleStateWithCheck(userState, setUserState, false, "loading");
    console.log('This browser doesn\'t support IndexedDB');
  } else {
    //window.indexedDB.open("firebaseLocalStorageDb", 3);
    const getRefreshedIdToken = async () => {
      return new Promise((resolve, reject) => {
        let request = window.indexedDB.open("firebaseLocalStorageDb");
        request.onerror = async (event) => {
          changeSimpleStateWithCheck(userState, setUserState, false, "loading");
          console.log("error fetching data", event);
          returningdata = false;
          return false;
        };
        returningdata = request.onsuccess = async (event) => {
          const db = request.result;
          try {
            const transaction = db.transaction("firebaseLocalStorage", "readonly");
            const objectStore = transaction.objectStore("firebaseLocalStorage");
            if ('getAll' in objectStore) {
              objectStore.getAll().onsuccess = async function (getAllEvent) {
                let indexDbStorage = getAllEvent.target.result;

                try {
                  if (indexDbStorage && indexDbStorage[0]) {
                    let accessToken = indexDbStorage[0].value.stsTokenManager.accessToken;
                    let expirationTime = indexDbStorage[0].value.stsTokenManager.expirationTime;
                    let refreshTokenToSubmit;
                    if (refreshToken) {
                      refreshTokenToSubmit = refreshToken;
                    } else {
                      refreshTokenToSubmit = indexDbStorage[0].value.stsTokenManager.refreshToken;
                    }
                    let displayName = indexDbStorage[0].value.providerData[0].displayName;
                    let grantType = "refresh_token";

                    return returningdata = await fetch('https://securetoken.googleapis.com/v1/token?key=' + firebaseConfig.apiKey, {
                      method: 'post',
                      body: 'grant_type=' + grantType + '&refresh_token=' + refreshTokenToSubmit,
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    })
                      .then(res => res.json())
                      .then(async responseJson => {

                        if (responseJson.id_token) {
                          //console.log("Signing in...");
                          //console.log(responseJson.id_token);
                          await cpLogin(responseJson.id_token, globalContext, false);
                          returningdata = responseJson.id_token;
                          //callback(returningdata);
                          resolve(returningdata);
                          return returningdata;
                        }
                      })
                      .catch(e => {
                        changeSimpleStateWithCheck(userState, setUserState, false, "loading");
                        setSignInStatusState(false);
                        console.log(e);
                        returningdata = false;
                        return false;
                      });

                  } else {
                    changeSimpleStateWithCheck(userState, setUserState, false, "loading");
                    setSignInStatusState(false);
                    returningdata = false;
                    return false;
                  }
                } catch (e) {
                  //setTimeout(() => {  /* console.log("Error, trying again"); */ }, 1000); //Waits 1 second
                  //await continuedSessionLogin(globalContext, refreshToken, setSignInStatusState);
                  changeSimpleStateWithCheck(userState, setUserState, false, "loading");
                  try {
                    setSignInStatusState(false);
                  } catch (e) { }
                  returningdata = false;
                  return false;
                }
              };
            } else {
              changeSimpleStateWithCheck(userState, setUserState, false, "loading");
              try {
                setSignInStatusState(false);
              } catch (e) { }
              returningdata = false;
              return false;
            }
          } catch (e) {
            changeSimpleStateWithCheck(userState, setUserState, false, "loading");
            try {
              setSignInStatusState(false);
            } catch (e) { }
            returningdata = false;
            return false;
          }
        };
      });
    }
    await getRefreshedIdToken().then((result) => {
      returningdata = result;
      if (!returningdata) {
        changeSimpleStateWithCheck(userState, setUserState, false, "loading");
      }
    });
  }
  return returningdata;
}

//Gets the user's data from the server
//const getUserData = async (firebaseConfig, userState, setUserState, attempts) => {
const getUserData = async (globalContext, attempts, setSignInStatusState) => {
  try {
    if (attempts < 3) {   //It will attempt to retrieve the data from the server 3 times in a row
      //Get's the user's firebase id token.

      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {  //If there is no currentUser, as in they aren't logged in, this will fail and it will go to the catch
        const body = {
          'functionid': 'getuserdata',
          'idToken': idToken
        };
        await axios({
          method: 'POST',
          //url: server_address + ':' + ports["master_server"] + '/',
          url: go_server_address,
          timeout: 2000,
          data: body,
        }).then((resp) => {
          if (resp?.data?.success) {
            let loadedData = resp.data;
            loadedData.loading = false;
            //console.log(loadedData);
            delete loadedData.success;
            if (JSON.stringify(loadedData) !== JSON.stringify(globalContext.userState)) { //Added on 10-11-2021, and updated on 02-08-2022, doesn't work if the elements are out of order
              if (loadedData["affiliations"] === undefined || loadedData["affiliations"].length === 0) {
                loadedData["affiliations"] = [""];
              }
              globalContext.setUserState(loadedData); //Sets the userstate to the updated data in the database
            }
            return true;
          }
        })
          .catch(e => {
            console.log(e);
          });
      });
    } else { }
  } catch (e) {
    attempts++;
    setTimeout(() => {  /* console.log("Error, trying again"); */ }, 1000); //Waits 1 second
    try {
      await continuedSessionLogin(globalContext, undefined, setSignInStatusState); //Will try to continue with an existing user session in case the page didn't already load it
    } catch (e) { }
    //getUserData(firebaseConfig, userState, setUserState, attempts, setSignInStatusState);  //Re-runs the current function
    await getUserData(globalContext, attempts, setSignInStatusState);  //Re-runs the current function
  }
}

//Makes an API call to retrieve a FHIR resource from the FEVIR server
const getResource = async (resourceId, resourceType, userStateIdToken) => {
  //Checks to make sure it's being passed a resourceID and a resourceType (usually from the URL) before sending a server request
  if (resourceId) {
    //Get's the user's firebase id token. The server is going to use the token to confirm the user's id and keep track of the resources that they viewed.
    let idToken;
    if (userStateIdToken) {
      idToken = userStateIdToken;
    } else {
      let user = firebase.auth().currentUser;
      if (user) {
        await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((resp) => { idToken = resp; }).catch((e) => { });
      }
    }

    const body = {
      'functionid': 'getfhirresource',
      'resourceid': parseInt(resourceId),
      'resourcetype': resourceType,
      'idToken': idToken
    };
    return await axios({
      method: 'POST',
      //url: server_address + ':' + ports["master_server"] + '/',
      url: node_server_address,
      timeout: 100000,
      data: body,
    }).then(async (resp) => {
      if (resp?.data?.success || resp?.data?.found) {
        return {
          'fhirResource': resp.data.fhirjsonstring,
          'title': resp.data.title,
          'resourceType': resp.data.resourcetype,
          'status': resp.data.status,
          'found': resp.data.found,
          'loggedin': resp.data.loggedin,
          'readpermission': resp.data.readpermission,
          'editpermission': resp.data.editpermission,
          'adminpermission': resp.data.adminpermission,
          //'commentslist': resp.data.commentslist,
          'commentsdict': resp.data.commentsdict,
          //'voteslist': resp.data.voteslist,
          'votesdict': resp.data.votesdict,
          //'myvoteslist': resp.data.myvoteslist,
          'myvotesdict': resp.data.myvotesdict
        }
      } else {
        return ""
      }
    });       //Returns the resource as a JSON string
  } else {
    //Displays "Resource Not Found" message
    return "";
  }
};

//Updates the user's personal info on the server
const updateUserAccount = async (globalContext) => {
  let userState = globalContext.userState;
  const body = {
    functionid: 'updateuser',
    idToken: "",
    name: userState.name,
    shortbio: userState.shortbio,
    notificationemail: userState.notificationemail,
    additionalemail: userState.additionalemail,
    affiliations: userState.affiliations
  };
  let loadedData = await submitToFevirServer(globalContext, 8000, body, true, false);
  if (loadedData.success) {
    loadedData.loading = false;
    loadedData.idToken = userState.idToken;
    globalContext.setUserState(loadedData); //Sets the userstate to the updated data in the database     
  } else {
    //throw new Error("error");
    return false;
  }
  return loadedData;
}

const updateUserAccountWithUserProfile = async (addToast, globalContext, userProfileState, setUserProfileState, history, redirect) => {
  let success = false;
  if (userProfileState.notificationemail && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userProfileState.notificationemail))) {
    alert("The notification email address is not valid.");
    return (false);
  }

  if (userProfileState.additionalemail?.trim() && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userProfileState.additionalemail))) {
    alert("The backup email address is not valid.");
    return (false);
  }

  await updateUserAccount(globalContext).then((response) => {
    if (response && response !== "error") {
      success = true;
      if (history && redirect !== undefined) {
        history.push(redirect);
      }
      addToast('Updated Profile', { appearance: 'success' });
      changeSimpleState(setUserProfileState, true, "loading");
      //console.log(response);
    } else {
      console.log("ERROR!!!!: " + response);
      addToast('Error, could not update user profile', { appearance: 'error' });
      //addToast('Error, could not update Profile', { appearance: 'info' });
    }
  });
  return success;
}

const retrieveUserFevirApiToken = async (globalContext, setFevirApiState) => {
  let userState = globalContext.userState;
  let idToken;
  if (userState.idToken) {
    idToken = userState.idToken;
  } else {
    let user = firebase.auth().currentUser;
    if (user) {
      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((resp) => { idToken = resp; }).catch((e) => { });
    }
  }
  if (idToken) {
    let body = {
      'functionid': 'retrievefevirapitoken',
      'idToken': idToken
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success && response.apiToken) {
      if (setFevirApiState) {
        setFevirApiState(response.apiToken);
      }
      return response.apiToken;
    }
  }
}

export { signInFirebase, signOutFirebase, googleSignInFirebase, emailPasswordSignInFirebase, cpLogin, getCustomToken, exileUser, continuedSessionLogin, getUserData, updateUserAccountWithUserProfile, getResource, retrieveUserFevirApiToken };